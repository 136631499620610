import React, { Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { MsalProvider } from '@azure/msal-react';
import PropTypes from 'prop-types';
import AppRoutes from './routes/Routes';
import './styles/App.scss';
import { theme } from './Theme';

import { startSASTokenRefresh } from './service/API';

import '@abi-labs-hexa/web-v2/core/theme/index.css';
import { initDataDog } from './monitoring/monitoring';

initDataDog();

function App({ instance }) {
  const isAuthenticated = localStorage.getItem('isLoggedin');

  useEffect(() => {
    instance
      .initialize()
      .then(() => {
        const fetchSasKey = async () => {
          if (isAuthenticated) {
            await startSASTokenRefresh();
          }
        };
        fetchSasKey();
      })
      .catch((error) => {
        console.error('Initialization error:', error);
      });
  }, [isAuthenticated, instance]);

  return (
    <Suspense>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <MsalProvider instance={instance}>
            <AppRoutes />
          </MsalProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Suspense>
  );
}

App.propTypes = {
  instance: PropTypes.object.isRequired
};

export default App;
