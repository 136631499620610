import React from 'react';
import PropTypes from 'prop-types';
import styles from './DownloadSection.module.css';
import { Divider } from './compound-components/Divider';
import { DownloadItem } from './compound-components/DownloadItem';

export const DownloadSection = ({ title, description, fileUrl }) => {
  return (
    <div className={styles.background}>
      <section className={styles.container}>
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.titleWrapper}>
              <div className={styles.yellowLine} />
              <h1 className={styles.title}>Downloads</h1>
            </div>
          </div>
          <div className={styles.downloadsList}>
            <Divider />
            <DownloadItem title={title} description={description} fileUrl={fileUrl} />
            <Divider />
          </div>
        </div>
      </section>
    </div>
  );
};

DownloadSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  fileUrl: PropTypes.string.isRequired
};
