import React from 'react';
import PropTypes from 'prop-types';
import styles from './StatCard.module.css';

export const StatCard = ({ value, tag, description, statColor, footnote }) => {
  return (
    <div className={styles.statGroup}>
      <div className={styles.stat}>
        <div className={styles.value}>
          {description === 'Average $$ Spend' ? `$${value}` : `${value}%`}
          {footnote && <span className={styles.footnote}>*</span>}
        </div>
        <div className={styles.tag} style={{ backgroundColor: statColor }}>
          {tag}
        </div>
      </div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

StatCard.propTypes = {
  value: PropTypes.number.isRequired,
  tag: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  statColor: PropTypes.string.isRequired,
  footnote: PropTypes.bool.isRequired
};
