import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import FeedbackSection from '../../common/page-sections/FeedbackSection';

export default function SectionNine() {
  const { macroSegment, microSegment } = useParams();
  return (
    <div className="section9">
      <Box sx={{ flexGrow: 1 }}>
        <FeedbackSection macro={macroSegment} micro={microSegment} page="People Segment Template" />
      </Box>
    </div>
  );
}
