export const tripData = [
  {
    id: 1,
    title: 'Alcohol grab & go',
    image:
      'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F2863d2a6cc35415ea888ec31c49b3ea3',
    color: 'rgba(255, 149, 33, 1)',
    trips: '29%',
    spend: '33%',
    description: {
      prefix: 'An',
      highlight: 'in-and-out trip to pick up a single alcohol ',
      suffix: 'category to unwind or share with friends.'
    },
    navigateTo: '/trip-types/alcohol-grab-go'
  },
  {
    id: 2,
    title: 'Alcohol reload',
    image:
      'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F10cf6b0413ec428aa3f07df9ff3a282e',
    color: 'rgba(3, 195, 190, 1)',
    trips: '9%',
    spend: '18%',
    description: {
      prefix: 'An',
      highlight: 'exploratory trip to pick up multiple alcohol categories ',
      suffix: 'to restock the home bar or prepare for a party.'
    },
    navigateTo: '/trip-types/alcohol-reload'
  },
  {
    id: 3,
    title: 'pit stop essentials',
    image:
      'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F0b0720c84b344b7985606502ac31ef3a',
    color: 'rgba(6, 111, 192, 1)',
    trips: '26%',
    spend: '17%',
    description: {
      prefix: 'An',
      highlight: 'in-and-out trip to grab alcohol ',
      suffix: 'typically for immediate consumption, and gas, tobacco, or snacks.'
    },
    navigateTo: '/trip-types/pit-stop-essentials'
  },
  {
    id: 4,
    title: "today's meal+",
    image:
      'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F15df5ab0d7c54175b1b4fa4f3f2797ea',
    color: 'rgba(82, 168, 75, 1)',
    trips: '16%',
    spend: '12%',
    description: {
      prefix: 'A',
      highlight: 'moderate-length trip to get ingredients or prepared food ',
      suffix: 'for an upcoming meal, with alcohol brought into the basket as an addition.'
    },
    navigateTo: '/trip-types/todays-meal-plus'
  },
  {
    id: 5,
    title: 'frequent fill-in',
    image:
      'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F909ab4ef675f4b7ba96e29f9d74166b6',
    color: 'rgba(250, 76, 75, 1)',
    trips: '17%',
    spend: '16%',
    description: {
      prefix: 'A',
      highlight: 'longer trip to get select food and beverage items, ',
      suffix: 'to avoid running out of staples or prepare for upcoming events.'
    },
    navigateTo: '/trip-types/frequent-fill-in'
  },
  {
    id: 6,
    title: 'household stock-up',
    image:
      'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fd424ee083e2a4deeabb39b2d2e7c3871',
    color: 'rgba(164, 70, 197, 1)',
    trips: '3%',
    spend: '3%',
    description: {
      prefix: 'A',
      highlight: 'longer trip to fully restock common household supplies, ',
      suffix: 'pantry items, food, and beverages.'
    },
    navigateTo: '/trip-types/household-stock-up'
  }
];
