import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import styles from './SegmentDisplay.module.css';

export const SegmentDisplay = ({ title, segmentCount, segmentCards }) => {
  const { t } = useTranslation(i18next.language);
  const navigate = useNavigate();

  const clickHandler = (navigateTo) => {
    navigate(navigateTo);
  };

  return (
    <div className={styles.segmentGradientWrap}>
      <div className={styles.segmentGradient} />
      <div className={styles.segmentCardTop}>
        <div className={styles.content}>
          <div className={styles.copy}>
            <div className={styles.copyInner}>
              <h2 className={styles.title}>{title}</h2>
              <div className={styles.segmentCount}>{segmentCount}</div>
            </div>
            <div className={styles.info}>
              <InfoOutlinedIcon className={styles.infoIcon} />
              <span className={styles.infoText}>{t('categoryHub.segmentLegend.instructions')}</span>
            </div>
          </div>
          <div className={styles.segmentCards}>
            {segmentCards.map((card) => (
              <button
                key={card.altText}
                className={styles.segmentCardButton}
                onClick={() => clickHandler(card.navigateTo)}
              >
                <img src={card.imgUrl} alt={card.altText} loading="lazy" className={styles.segmentCard} />
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

SegmentDisplay.propTypes = {
  title: PropTypes.string.isRequired,
  segmentCount: PropTypes.number.isRequired,
  segmentCards: PropTypes.arrayOf(
    PropTypes.shape({
      imgUrl: PropTypes.string.isRequired,
      altText: PropTypes.string.isRequired,
      navigateTo: PropTypes.string.isRequired
    })
  ).isRequired
};
