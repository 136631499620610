import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { COLORS } from '../../../../constants/CatTripColors';
import { getAccentTextColor } from '../../../../constants/GetAccentTextColor';
import ShoppingStats from './compound-components/ShoppingStats';
import { PersonaSection } from './compound-components/PersonaSection';
import styles from './ShoppersVsConsumers.module.css';

export const ShoppersVsConsumers = ({
  tripTypeColor,
  chartImgUrl,
  personaData,
  shopperVsConsumerCopy,
  shopperVsConsumerBold
}) => {
  const { t } = useTranslation(i18next.language);
  const accentTextColor = getAccentTextColor(tripTypeColor);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.subtitle}>{t('tripTypesPageTemplate.shoppersVsConsumers.eyebrow')}</div>
        <div className={styles.title}>{t('tripTypesPageTemplate.shoppersVsConsumers.title')}</div>
      </div>
      <div className={styles.content}>
        <ShoppingStats
          chartImgUrl={chartImgUrl}
          shopperVsConsumerCopy={shopperVsConsumerCopy}
          shopperVsConsumerBold={shopperVsConsumerBold}
          tripTypeColor={COLORS[tripTypeColor]}
          accentTextColor={accentTextColor}
        />
        <PersonaSection personaData={personaData} />
      </div>
    </div>
  );
};

ShoppersVsConsumers.propTypes = {
  tripTypeColor: PropTypes.string.isRequired,
  chartImgUrl: PropTypes.string.isRequired,
  personaData: PropTypes.object.isRequired,
  shopperVsConsumerCopy: PropTypes.string.isRequired,
  shopperVsConsumerBold: PropTypes.array.isRequired
};
