import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';
import PropTypes from 'prop-types';

function AuthDenialModal({ open, setOpen, modalTitle, modalCopy }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '16px'
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
        </Box>
        <LockIcon sx={{ fontSize: '48px', color: 'black' }} />
        <h2 id="modal-title">{modalTitle}</h2>
        <p id="modal-description">{modalCopy}</p>
      </Box>
    </Modal>
  );
}

AuthDenialModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  modalCopy: PropTypes.string.isRequired
};

export default AuthDenialModal;
