import React from 'react';
import PropTypes from 'prop-types';
import styles from './MetricCard.module.css';

export const MetricCard = ({ value, label, accentColor, accentTextColor }) => {
  return (
    <div className={styles.card}>
      <div className={styles.cardBackground} style={{ backgroundColor: accentColor }} />
      <div className={styles.content}>
        <div className={styles.value} style={{ color: accentTextColor }}>
          {value}
        </div>
        <div className={styles.label} style={{ color: accentTextColor }}>
          {label}
        </div>
      </div>
    </div>
  );
};

MetricCard.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  accentColor: PropTypes.string.isRequired,
  accentTextColor: PropTypes.string.isRequired
};
