import React from 'react';
import PropTypes from 'prop-types';
import styles from './GrowthChart.module.css';

export const GrowthChart = ({ chartTitle, chartImage }) => {
  return (
    <div className={styles.chartContainer}>
      <div className={styles.cardBackground} />
      <div className={styles.content}>
        <div className={styles.titleSection}>
          <h2 className={styles.title}>{chartTitle}</h2>
          <div className={styles.divider} />
        </div>
        <img loading="lazy" src={chartImage.imgUrl} alt={chartImage.altText} className={styles.chart} />
      </div>
    </div>
  );
};

GrowthChart.propTypes = {
  chartTitle: PropTypes.string.isRequired,
  chartImage: PropTypes.shape({
    imgUrl: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired
  }).isRequired
};
