import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, Button, Grid } from '@mui/material/';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { DemandSpacesMenuOptions } from '../../constants/DemandSpace';
import { OccasionsMenuOptions } from '../../constants/OccasionsConstants';
import { PeopleSegmentMenuOptions } from '../../constants/PeopleSegment';

function DropDownMenu({ selectedSegment, closeHandler }) {
  const navigate = useNavigate();
  const { t } = useTranslation(i18next.language);

  function getMenuOptions(selectedSegment) {
    let menuOptions = [];
    switch (selectedSegment) {
      case 'demand-spaces':
        menuOptions = DemandSpacesMenuOptions;
        break;
      case 'people-segment':
        menuOptions = PeopleSegmentMenuOptions;
        break;
      case 'occasions':
        menuOptions = OccasionsMenuOptions;
        break;
      default:
        break;
    }

    return menuOptions;
  }

  function getHubTitle(selectedSegment) {
    let hubTitle = '';
    switch (selectedSegment) {
      case 'demand-spaces':
        hubTitle = 'Demand Space Hub';
        break;
      case 'people-segment':
        hubTitle = 'People Segment Hub';
        break;
      case 'occasions':
        hubTitle = 'Occasions Hub';
        break;
      default:
        break;
    }
    return hubTitle;
  }

  function getNavigationPath(segment, params = {}) {
    const basePaths = {
      'demand-spaces': '/demand-landscape/demandspace',
      'people-segment': '/demand-landscape/peoplesegment',
      occasions: '/demand-landscape/occasions'
    };

    if (!basePaths[segment]) return '';

    let path = basePaths[segment];

    if (params.macro) path += `/${params.macro}`;
    if (params.micro) path += `/${params.micro}`;

    return path;
  }

  return (
    <Box className="drop-down-menu-container">
      <Grid container className="menu-container" gap={5.5}>
        <Grid item xs={2} className="left-menu-item">
          <Box className="menu-item-hub-container">
            <Button
              variant="outlined"
              className="menu-item-hub-title-button"
              endIcon={<ChevronRightIcon className="icon" />}
              onClick={() => {
                const path = getNavigationPath(selectedSegment);
                closeHandler();
                navigate(path);
                window.scrollTo(0, 0);
              }}
            >
              <Typography className="button-text">{getHubTitle(selectedSegment)}</Typography>
            </Button>
          </Box>
        </Grid>
        <Grid item xs={9} className="right-menu-item">
          <Grid container className="menu-items-container">
            {getMenuOptions(selectedSegment).map((menu, index) => (
              <Grid
                item
                xs={9 / getMenuOptions(selectedSegment).length}
                key={`dropdown-menuitem-container-${index + 1}`}
              >
                <Box className="menu-list-items">
                  <Box className={`menu-header-container ${selectedSegment}`}>
                    <Typography className={`menu-header ${selectedSegment} header-${index + 1}`}>
                      {t(menu.header)}
                    </Typography>
                  </Box>
                  <List className="menu-list-item-container">
                    {menu.listOfItems.map((item, index) => (
                      <ListItem
                        key={`menu-list-item-${index + 1}`}
                        className="menu-list-item"
                        onClick={() => {
                          const path = getNavigationPath(selectedSegment, item.routeParams);
                          closeHandler();
                          navigate(path);
                          window.scrollTo(0, 0);
                        }}
                      >
                        <ListItemText
                          primary={
                            <Typography className={`menu-list-item-text ${item.type}`}>{t(item.itemText)}</Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

DropDownMenu.propTypes = {
  selectedSegment: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired
};

export default DropDownMenu;
