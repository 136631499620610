import React from 'react';
import PropTypes from 'prop-types';
import styles from './TripCard.module.css';

export default function TripCard({ title, image, color, trips, spend, description, style, onClick }) {
  return (
    <button className={styles.cardButton} onClick={onClick} style={style}>
      <div className={styles.card}>
        <div className={styles.cardTop} style={{ backgroundColor: color }}>
          <div className={styles.imgCopy}>
            <img loading="lazy" src={image} className={styles.img} alt={`${title} icon`} />
            <div>{title}</div>
          </div>
        </div>
        <div className={styles.cardBtm}>
          <div className={styles.stats}>
            <div className={styles.stat} style={{ backgroundColor: color }}>
              <div className={styles.content}>
                <div className={styles.percentage}>{trips}</div>
                <div className={styles.label}>Trips</div>
              </div>
            </div>
            <div className={styles.stat} style={{ backgroundColor: color }}>
              <div className={styles.content}>
                <div className={styles.percentage}>{spend}</div>
                <div className={styles.label}>Alc Spend</div>
              </div>
            </div>
          </div>
          <div className={styles.copy}>
            <span className={styles.prefix}>{description.prefix} </span>
            <span className={styles.highlight}>{description.highlight}</span>
            <span className={styles.suffix}>{description.suffix}</span>
          </div>
        </div>
      </div>
    </button>
  );
}

TripCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  trips: PropTypes.string.isRequired,
  spend: PropTypes.string.isRequired,
  description: PropTypes.shape({
    prefix: PropTypes.string.isRequired,
    highlight: PropTypes.string.isRequired,
    suffix: PropTypes.string.isRequired
  }).isRequired,
  onClick: PropTypes.func,
  style: PropTypes.object
};
