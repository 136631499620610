import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import i18next from 'i18next';

import GroupIcon from '@mui/icons-material/Group';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import NoMealsIcon from '@mui/icons-material/NoMeals';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useParams } from 'react-router-dom';
import { occasionsUGCItems, occasionsMacroMapping, occasionIcons } from '../../../constants/OccasionsConstants';
import { occasionsAssets } from '../../common/OccasionsAssets';

//MUI Icons & Custom components

import UGCCard from '../../custom/Cards/UGCCard';
import FilledOutlinedIcon from '../../custom/Icons/FilledOutlinedIcon';
import Cards from '../../custom/Cards/Cards';

function SectionOne() {
  const { t } = useTranslation(i18next.language);
  const { macro, micro } = useParams();

  useEffect(() => {
    const box1 = document.getElementById('intro-right-box-1');
    const box2 = document.getElementById('intro-right-box-2');

    if (box1 && box2) {
      const maxHeight = Math.max(box1.clientHeight, box2.clientHeight);
      box1.style.height = `${maxHeight / 16}rem`;
      box2.style.height = `${maxHeight / 16}rem`;
    }
  }, []);

  const microItems = occasionsMacroMapping[macro];

  const microUGCItems = occasionsUGCItems[micro];

  useEffect(() => {
    if (localStorage.getItem('i18nnextLng')?.length > 2) {
      i18next.changeLanguage('en');
    }
  }, []);

  function getUGCCard(type) {
    let ugcCardIcon = null;

    switch (type) {
      case 'groupSize':
        ugcCardIcon = <GroupIcon className="card-icon" />;
        break;
      case 'location':
        ugcCardIcon = <LocationOnIcon className="card-icon" />;
        break;
      case 'meal':
        ugcCardIcon = <RestaurantIcon className="card-icon" />;
        break;
      case 'nomeal':
        ugcCardIcon = <NoMealsIcon className="card-icon" />;
        break;
      default:
        break;
    }

    return (
      <UGCCard
        label={t(`occasions.template.howTheyDrink.${type}`)}
        labeltext={t(`occasions.template.howTheyDrink.${micro}.${type}Value`)}
      >
        <FilledOutlinedIcon outlined type="dark">
          {ugcCardIcon}
        </FilledOutlinedIcon>
      </UGCCard>
    );
  }

  return (
    <div className="section1">
      <Box className="intro-container-main">
        <Grid container>
          <Grid item xs={6} className="paragraph-class">
            <Typography variant="h4" className="paragraph">
              <Trans components={{ colored: <i /> }}>{t(`occasions.template.intro.${micro}.paragraph`)}</Trans>
            </Typography>
          </Grid>
        </Grid>
        <Grid container gap={12.5} className="intro-container">
          <Grid item className="left-item" xs={5.4}>
            <Box className="left-item-content">
              <Box className="visual">
                <Box className="visual-heading-macro">
                  <Typography className="macro-text">{t(`occasions.macroOccasion.${macro}`)}</Typography>
                </Box>
                <Box className="visual-cards">
                  {microItems.map((item) => (
                    <Cards
                      key={item}
                      img={occasionsAssets.heroImages[item]?.()}
                      caption={t(`occasions.type.${item}`)}
                      color={item !== micro ? '#3D3D3D' : '#F6E003'}
                      disabled={item !== micro}
                      className="card-container"
                    >
                      {occasionIcons[item] && (
                        <Box className={`icon-background ${item !== micro ? 'light' : 'dark'}`}>
                          <RestaurantIcon color={item !== micro ? 'tertiary' : 'secondary'} />
                        </Box>
                      )}
                    </Cards>
                  ))}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item className="right-item" xs={5.4}>
            <Grid container gap={3}>
              <Grid item xs={5.7}>
                <Box className="intro-right-box">
                  <Typography variant="h4" className="heading">
                    {t(`occasions.macroOccasion.${macro}`)}
                  </Typography>
                  <Typography variant="body" id="intro-right-box-1" className="description">
                    <Trans components={{ colored: <b /> }}>
                      {t(`occasions.template.intro.${micro}.macroDescription`)}
                    </Trans>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={5.7}>
                <Box className="intro-right-box">
                  <Typography variant="h4" className="heading">
                    {t(`occasions.type.${micro}`)}
                  </Typography>
                  <Typography variant="body" id="intro-right-box-2" className="description">
                    <Trans components={{ colored: <b /> }}>
                      {t(`occasions.template.intro.${micro}.microDescription`)}
                    </Trans>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Grid container className="section1-image-list" gap={2.5}>
        {microUGCItems.map((item) => (
          <Grid item xs={2.83} key={item.content} className="section1-image-list-item">
            {item.type === 'card' && getUGCCard(item.content)}
            {item.type === 'image' && <img className="ugcImages" src={item.content?.()} alt="UGC images" />}
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default SectionOne;
