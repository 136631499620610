import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardMedia, Typography, CardActionArea } from '@mui/material';
/* 
  Required props:
  img: Card media image
  caption: Content display text
  color: Content area background color
  disabled: (optional) : To dim the card
  children: (optional) icon if any
*/

function Cards(props) {
  return (
    <Card className={`card-container ${props.disabled ? 'dimmed' : ''}`}>
      <CardActionArea>
        <CardMedia component="img" className="card-media" image={props.img} alt={props.caption} />

        <CardContent className="card-content" sx={{ background: props.color }}>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            className={`card-content-text ${props.disabled ? 'disabled' : 'active'}`}
          >
            {props.caption}
          </Typography>
          {props.children}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

Cards.propTypes = {
  img: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node
};

export default Cards;
