import React from 'react';
import PropTypes from 'prop-types';
import styles from './ResearchCard.module.css';

export function ResearchCard({ image, title, number, description }) {
  return (
    <div className={`${styles.card}`}>
      <img loading="lazy" src={image} alt={`Research visualization for ${title}`} className={styles.cardImage} />
      <div className={styles.content}>
        <div className={styles.titleWrapper}>
          <div className={styles.divider} />
          <div className={styles.title}>{title}</div>
          <div className={styles.divider} />
        </div>
        <div className={styles.number}>{number}</div>
        <div className={styles.description}>{description}</div>
      </div>
    </div>
  );
}

ResearchCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};
