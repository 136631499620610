import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, Typography } from '@mui/material';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useParams } from 'react-router-dom';
import ConsumptionVisualCard from '../../custom/Cards/ConsumptionVisualCard';
import ConsumptionDataTable from '../../custom/Table/ConsumptionDataTable';
import { occasionsAssets } from '../../common/OccasionsAssets';
import SectionIntro from '../../common/page-sections/SectionIntro';
import { downloadsAssets } from '../../common/DownloadsAssets';

function SectionFour({ sectionData }) {
  const { t } = useTranslation(i18next.language);
  const { micro } = useParams();

  useEffect(() => {
    if (localStorage.getItem('i18nnextLng')?.length > 2) {
      i18next.changeLanguage('en');
    }
  }, []);

  function downloadFile(url, fileName) {
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
  }
  function downloadSpreadsheets() {
    downloadFile(downloadsAssets.consumptionData.DataPackSpreadsheet, 'dataPack.xlsx');
  }

  function getTableData(bevData) {
    let data = {};

    data.initialRowCount = 3;
    data.isExpandable = true;
    data.paginationAlignment = 'left';
    data.paginationTextExpand = t('occasions.template.consumption.consumptionData.paginationTextExpandMore');
    data.paginationTextContract = t('occasions.template.consumption.consumptionData.paginationTextExpandLess');
    data.header = [
      t('occasions.template.consumption.consumptionData.columnHeading1', { bevType: bevData.drinkName }),
      t('occasions.template.consumption.consumptionData.columnHeading2'),
      t('occasions.template.consumption.consumptionData.columnHeading3')
    ];
    data.rowData = bevData.drinkTypes;

    return data;
  }

  return (
    <div className="section4">
      <SectionIntro
        heading={t('occasions.template.consumption.heading')}
        description={t('occasions.template.consumption.description')}
        sectionImages={{
          front: occasionsAssets.consumption[micro]['front']?.(),
          back: occasionsAssets.consumption[micro]['back']?.()
        }}
        actionButton={
          <Button
            className="download-all-button outlined-button"
            component="label"
            variant="contained"
            startIcon={<FileDownloadIcon />}
            onClick={downloadSpreadsheets}
          >
            {t('buttons.downloadSpreadsheets')}
          </Button>
        }
      />
      <Grid container gap={2.5} className="section4-image-list">
        {sectionData.map((item) => (
          <Grid item key={item.drinkName} xs={11.33 / sectionData.length}>
            <ConsumptionVisualCard space="occasions" bevData={item} />
            <ConsumptionDataTable theme="occasions" tableData={getTableData(item)} />
          </Grid>
        ))}
      </Grid>
      <Box className="interaction-tooltip-container">
        <InfoOutlinedIcon color="primary" />
        <Typography variant="body1" className="interaction-tooltip-text">
          {t('occasions.template.consumption.consumptionData.tooltipText')}
        </Typography>
      </Box>
    </div>
  );
}

SectionFour.propTypes = {
  sectionData: PropTypes.arrayOf(
    PropTypes.shape({
      drinkName: PropTypes.string.isRequired,
      drinkTypes: PropTypes.array.isRequired
    })
  ).isRequired
};

export default SectionFour;
