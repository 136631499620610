import React, { useEffect, useState } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Divider,
  Grid,
  TableCell,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  Typography
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SectionIntro from '../../common/page-sections/SectionIntro';
import { functionalNeedkey, emotionalNeedKey, demandspacesModularContent } from '../../../constants/DemandSpace';
import { demandSpaceAssets } from '../../common/DemandSpaceAssets';
import { getModularCards } from '../../../constants/constants';
import { downloadsAssets } from '../../common/DownloadsAssets';

function SectionFive({ space, macro }) {
  const { t } = useTranslation(i18next.language);

  const functionalNeedDescription = t(`demandspace.template.driversAndNeeds.needs.functionalNeeds.${space}`, {
    returnObjects: true
  });

  const emotionalNeedDescription = t(`demandspace.template.driversAndNeeds.needs.emotionalNeeds.${space}`, {
    returnObjects: true
  });

  useEffect(() => {
    if (localStorage.getItem('i18nnextLng')?.length > 2) {
      i18next.changeLanguage('en');
    }
  }, []);

  const [dynamicNeedsTableHeight, setDynamicNeedsTableHeight] = useState(0);
  useEffect(() => {
    let emotionalTableHeight = 0,
      functionalTableHeight = 0;
    const emotionalDriversRows = document.querySelectorAll('.emotional');
    const functionalDriversRows = document.querySelectorAll('.functional');

    emotionalDriversRows.forEach((row) => {
      emotionalTableHeight += row.offsetHeight;
    });
    functionalDriversRows.forEach((row) => {
      functionalTableHeight += row.offsetHeight;
    });

    let computedDynamicTableHeight =
      emotionalTableHeight > functionalTableHeight ? emotionalTableHeight + 12 : functionalTableHeight + 12;

    setDynamicNeedsTableHeight(computedDynamicTableHeight);
  }, []);

  function downloadFile(url, fileName) {
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
  }
  function downloadSpreadsheets() {
    downloadFile(downloadsAssets.driverAndNeedsData.DataPackSpreadsheet, 'dataPack.xlsx');
  }

  function getColor(value) {
    if (value < 85) {
      return 'negative';
    } else if (value > 115) {
      return 'positive';
    } else {
      return 'neutral';
    }
  }

  return (
    <>
      <div className="section5-demandSpaceTemplate">
        <SectionIntro
          heading={t('demandspace.template.driversAndNeeds.title')}
          description={t('demandspace.template.driversAndNeeds.subTitle')}
          sectionImages={{
            front: demandSpaceAssets.driverAndNeeds[space].front(),
            back: demandSpaceAssets.driverAndNeeds[space].back()
          }}
          actionButton={
            <Button
              className="download-all-button outlined-button"
              component="label"
              variant="contained"
              startIcon={<FileDownloadIcon />}
              onClick={downloadSpreadsheets}
            >
              {t('buttons.downloadData')}
            </Button>
          }
        />
      </div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 6.25
        }}
      >
        <Grid className="emotional-functional-needs-grid" container gap={7.5}>
          <Grid
            item
            xs={5.68}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1.25
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography className="need-name">
                {t('demandspace.template.driversAndNeeds.needs.emotionalNeeds.name')}
              </Typography>
              <Typography className="need-description">
                {t('demandspace.template.driversAndNeeds.needs.emotionalNeeds.description')}
              </Typography>
            </Box>
            <Box className="needs-cards-container">
              <Box className="population-index">
                <Typography className="population-index-typo">
                  {t('demandspace.template.driversAndNeeds.percentage')}
                </Typography>
                <Typography className="population-index-typo">
                  {t('demandspace.template.driversAndNeeds.index')}
                </Typography>
              </Box>
              <Box className="needs-mapped-cards-box" sx={{ minHeight: dynamicNeedsTableHeight }}>
                <TableContainer className="needs-table-container">
                  <Table className="needs-table">
                    <TableBody className="needs-table-body">
                      {macro?.emotionalNeeds?.map(
                        (item, index) =>
                          emotionalNeedDescription[emotionalNeedKey[item?.emotionalNeedKey]] && (
                            <TableRow key={`needs-row-${index}`} className="needs-table-row emotional">
                              <TableCell>
                                <Box className="needs-mapped-name">
                                  <Typography className="needs-mapped-name-typo1">{item?.emotionalNeed}</Typography>
                                  <Typography className="needs-mapped-name-typo2">
                                    {emotionalNeedDescription[emotionalNeedKey[item?.emotionalNeedKey]]}
                                  </Typography>
                                </Box>
                              </TableCell>
                              <TableCell>
                                <Typography className="needs-mapped-percentage-typo1">
                                  {Math.round(item?.drinkPercent)}%
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography className={`needs-mapped-percentage-typo2 ${getColor(item.drinkIndex)}`}>
                                  {Math.round(item?.drinkIndex)}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={5.68}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1.25
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography className="need-name">
                {t('demandspace.template.driversAndNeeds.needs.functionalNeeds.name')}
              </Typography>
              <Typography className="need-description">
                {t('demandspace.template.driversAndNeeds.needs.functionalNeeds.description')}
              </Typography>
            </Box>
            <Box className="needs-cards-container">
              <Box className="population-index">
                <Typography className="population-index-typo">
                  {t('demandspace.template.driversAndNeeds.percentage')}
                </Typography>
                <Typography className="population-index-typo">
                  {t('demandspace.template.driversAndNeeds.index')}
                </Typography>
              </Box>
              <Box className="needs-mapped-cards-box" sx={{ minHeight: dynamicNeedsTableHeight }}>
                <TableContainer className="needs-table-container">
                  <Table className="needs-table">
                    <TableBody className="needs-table-body">
                      {macro?.functionalNeeds?.map(
                        (item, index) =>
                          functionalNeedDescription[functionalNeedkey[item?.functionalNeedKey]] && (
                            <TableRow key={`needs-row-${index}`} className="needs-table-row functional">
                              <TableCell>
                                <Box className="needs-mapped-name">
                                  <Typography className="needs-mapped-name-typo1">{item?.functionalNeed}</Typography>
                                  <Typography className="needs-mapped-name-typo2">
                                    {functionalNeedDescription[functionalNeedkey[item?.functionalNeedKey]]}
                                  </Typography>
                                </Box>
                              </TableCell>
                              <TableCell>
                                <Typography className="needs-mapped-percentage-typo1">
                                  {Math.round(item?.drinkPercent)}%
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography className={`needs-mapped-percentage-typo2 ${getColor(item.drinkIndex)}`}>
                                  {Math.round(item?.drinkIndex)}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box className="section5-percentage-info-box">
          <InfoOutlinedIcon className="information-icon" />
          <Box className="section5-percentage-info">
            <Typography className="section5-percentage-info-typo">
              <Trans components={{ bold: <b /> }}>
                {t('demandspace.template.driversAndNeeds.percentagePopulation')}
              </Trans>
            </Typography>
            <Typography className="section5-percentage-info-typo">
              <Trans components={{ bold: <b /> }}>{t('demandspace.template.driversAndNeeds.indexShows')}</Trans>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 5
        }}
      >
        <Box className="intheir-ownwords-container">
          <Typography className="intheir-typo" variant="h5">
            {t('demandspace.template.driversAndNeeds.inTheirOwnWords')}
          </Typography>
          <Divider sx={{ flex: '1 0 0' }} className="intheir-divider"></Divider>
        </Box>
        <Grid className="all-cards-container" container spacing={2}>
          {demandspacesModularContent[space].map((modularItem, index) => (
            <Grid item xs={6} key={`demandspace-intheirownwords-modularcard-${index + 1}`} className="modular-item">
              {getModularCards(`demandspace ${space}`, modularItem.cardType, modularItem.data)}
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}

SectionFive.propTypes = {
  space: PropTypes.string.isRequired,
  macro: PropTypes.object.isRequired
};

export default SectionFive;
