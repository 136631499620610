import { createTheme, responsiveFontSizes } from '@mui/material/styles';

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: '#F6E003'
      },
      secondary: {
        main: '#FFFFFF'
      },
      tertiary: {
        main: '#000000'
      },
      Fram_PSGMT_2: {
        //comfort-cravers
        main: '#B983FF'
      },
      Fram_PSGMT_3: {
        //Loyal-Tradionalists
        main: '#ADA1F8'
      },
      Fram_PSGMT_5: {
        //Balanced Enjoyers
        main: '#ffc759'
      },
      Fram_PSGMT_6: {
        //Diligent Discoverers
        main: '#ff8811'
      },
      Fram_PSGMT_8: {
        //Carefree Fun-Lovers
        main: '#8FE8BD'
      },
      Fram_PSGMT_9: {
        //Proud Celebrators
        main: '#79D271'
      },
      Fram_PSGMT_11: {
        //Creative Explorers
        main: '#A1E8F8'
      },
      Fram_PSGMT_12: {
        //Social Curators
        main: '#83BCFF'
      },
      text: {
        main: '#000000',
        primary: '#F6E003', //"#FFFFFF",
        brand: '#F6E003'
      }
    },
    typography: {
      h1: {
        fontSize: '168px'
      },
      h2: {
        fontSize: '128px'
      },
      h3: {
        fontSize: '80px'
      },
      h4: {
        fontSize: '40px'
      },
      h5: {
        fontSize: '28px'
      },
      body: {
        fontSize: '20px'
      },
      body1: {
        fontSize: '16px'
      },
      body2: {
        fontSize: '15px'
      },
      mobileDataLabel: {
        fontSize: '13px'
      }
    },
    components: {
      MuiDivider: {
        styleOverrides: {
          root: {
            //backgroundColor: '#F6E003',
            '&.:before': {
              backgroundColor: '#F6E003'
            },
            '&.:after': {
              backgroundColor: '#F6E003'
            },
            '&.Fram_PSGMT_2': {
              //comfort-cravers
              backgroundColor: '#B983FF'
            },
            '&.Fram_PSGMT_3': {
              //Loyal-Tradionalists
              backgroundColor: '#ADA1F8'
            },
            '&.Fram_PSGMT_5': {
              //Balanced Enjoyers
              backgroundColor: '#ffc759'
            },
            '&.Fram_PSGMT_6': {
              //Diligent Discoverers
              backgroundColor: '#ff8811'
            },
            '&.Fram_PSGMT_8': {
              //Carefree Fun-Lovers
              backgroundColor: '#8FE8BD'
            },
            '&.Fram_PSGMT_9': {
              //Proud Celebrators
              backgroundColor: '#79D271'
            },
            '&.Fram_PSGMT_11': {
              //Creative Explorers
              backgroundColor: '#A1E8F8'
            },
            '&.Fram_PSGMT_12': {
              //Social Curators
              backgroundColor: '#83BCFF'
            }
          }
        }
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: '1rem',
            '&.Fram_PSGMT_2': {
              //comfort-cravers
              backgroundColor: '#B983FF'
            },
            '&.Fram_PSGMT_3': {
              //Loyal-Tradionalists
              backgroundColor: '#ADA1F8'
            }
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            '&.ugc-card-container': {
              backgroundColor: '#F6E003'
            },
            '&.cf-card-container': {
              backgroundColor: 'transparent'
            },
            background: 'transparent'
          }
        }
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            width: '100%',
            overflowX: 'hidden'
          }
        }
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            '&.dropdown': {
              position: 'fixed',
              zIndex: 101,
              height: 'auto'
            }
          }
        }
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            '&.scope-container-outer': {
              height: '166px',
              width: '166px',
              backgroundColor: '#F6E003',
              '&.scopeBadgeDemandspace': {
                width: '124.5px',
                height: '124.5px'
              }
            },
            '&.scope-container-inner': {
              height: '152px',
              width: '152px',
              border: '1px solid #000000',
              backgroundColor: 'transparent',
              '&.scopeBadgeDemandspace': {
                width: '114px',
                height: '114px'
              }
            }
          }
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&.averageFieldRow': {
              borderBottom: '0.2px solid #f6e003'
            }
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            letterSpacing: '0'
          }
        }
      }
    }
  })
);
