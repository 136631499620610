import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import styles from './TripTypeFWHeader.module.css';

export default function TripTypeFWHeader() {
  const { t } = useTranslation(i18next.language);

  return (
    <>
      <div className={styles.mainNav}>
        <div className={styles.moleculeContent}>
          <div className={styles.links} />
        </div>
      </div>
      <div className={styles.hero}>
        <div className={styles.copy}>
          <h1 className={styles.title}>{t('tripTypesFramework.header.title')}</h1>
          <p className={styles.description}>{t('tripTypesFramework.header.description')}</p>
        </div>
      </div>
    </>
  );
}
