import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { COLORS } from '../../../../constants/CatTripColors';
import { getAccentTextColor } from '../../../../constants/GetAccentTextColor';
import { DemographicsCard } from './compound-components/DemographicsCard';
import styles from './ShopperDemographics.module.css';

export const ShopperDemographics = ({ demographicsData, tripTypeColor }) => {
  const { t } = useTranslation(i18next.language);
  const accentTextColor = getAccentTextColor(tripTypeColor);

  return (
    <section className={styles.section}>
      <div className={styles.header}>
        <div className={styles.subtitle}>{t('tripTypesPageTemplate.shopperDemographics.eyebrow')}</div>
        <h2 className={styles.title}>{t('tripTypesPageTemplate.shopperDemographics.title')}</h2>
      </div>

      <div className={styles.cardGrid}>
        {demographicsData.map((item) => (
          <DemographicsCard
            key={item.title}
            title={item.title}
            tripTypeColor={COLORS[tripTypeColor]}
            accentTextColor={accentTextColor}
          >
            <img src={item.image} alt={`${item.title} breakdown`} className={styles.demographicIcon} />
          </DemographicsCard>
        ))}
      </div>

      <footer className={styles.footer}>
        <p>{t('tripTypesPageTemplate.shopperDemographics.footer1')}</p>
        <p>{t('tripTypesPageTemplate.shopperDemographics.footer2')}</p>
      </footer>
    </section>
  );
};

ShopperDemographics.propTypes = {
  demographicsData: PropTypes.array.isRequired,
  tripTypeColor: PropTypes.string.isRequired
};
