import React from 'react';
import PropTypes from 'prop-types';
import styles from './ReusableButton.module.css';

export const ActionButton = ({ children, ...props }) => {
  return (
    <button className={styles.actionButton} tabIndex="0" aria-label="View the Category Structure" {...props}>
      {children}
    </button>
  );
};

ActionButton.propTypes = {
  children: PropTypes.node.isRequired
};
