import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../../../constants/CatTripColors';
import styles from './AboutSection.module.css';

export const AboutSection = ({ aboutTitle, aboutTitleColor, aboutText, guidelines, subcatCansImg }) => {
  return (
    <section className={styles.about} aria-labelledby="about-title">
      <div className={styles.aboutContent}>
        <h2 id="about-title" className={styles.aboutTitle} style={{ color: COLORS[aboutTitleColor] }}>
          {aboutTitle.split('\\n').map((line) => (
            <span key={line} className={styles.aboutTitle}>
              {line}
              <br />
            </span>
          ))}
        </h2>
        <p className={styles.aboutText}>{aboutText}</p>
        <p className={styles.aboutGuidelines}>{guidelines}</p>
      </div>
      <div className={styles.subcatCansWrap}>
        <img src={subcatCansImg.imgUrl} alt={subcatCansImg.altText} className={styles.subcatCans} loading="lazy" />
      </div>
    </section>
  );
};

AboutSection.propTypes = {
  aboutTitle: PropTypes.string.isRequired,
  aboutTitleColor: PropTypes.string.isRequired,
  aboutText: PropTypes.string.isRequired,
  guidelines: PropTypes.string.isRequired,
  subcatCansImg: PropTypes.shape({
    imgUrl: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired
  }).isRequired
};
