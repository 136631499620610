import { AppBar, Box, Button, Grid, Toolbar, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useParams } from 'react-router-dom';

function AnchorNavigationBar({ data, clickAction }) {
  const { t } = useTranslation(i18next.language);
  const { space } = useParams();

  useEffect(() => {
    if (localStorage.getItem('i18nnextLng')?.length > 2) {
      i18next.changeLanguage('en');
    }
  }, []);

  return (
    <AppBar
      component="nav"
      position="sticky"
      className={`anchor-nav-bar ${data.category} ${data.type} bg-color-${space}`}
    >
      <Toolbar className="nav-bar-container" disableGutters={true}>
        <Grid className="nav-bar-grid-container" container>
          <Grid item xs={2.5} className="navbar-left-grid">
            <Box className="navbar-info-container">
              <Typography className="text category">{t(`${data.category}.pagename`)}</Typography>
              <ChevronRightIcon />
              <Typography className="text type">{t(`${data.category}.type.${data.type}`)}</Typography>
            </Box>
          </Grid>
          <Grid item xs={9.5} className="navbar-right-grid">
            <Box className="navbar-items-container-background">
              {data.navItems.map((item) => (
                <Button
                  key={item.key}
                  variant="text"
                  className={`navbar-items ${data.type}`}
                  disableTouchRipple
                  onClick={() => clickAction(item.key)}
                >
                  {t(`${data.category}.anchorItems.${item.text}`)}
                </Button>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

AnchorNavigationBar.propTypes = {
  data: PropTypes.shape({
    category: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    navItems: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired,
  clickAction: PropTypes.func.isRequired
};

export default AnchorNavigationBar;
