import React from 'react';

// import { ReactComponent as QuoteIcon } from "../../../assets/images/PeopleSegment/Template/quotemarks-icon.svg"
import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { ReactComponent as QuoteMarksIcon } from '../../../assets/images/Common/quotemarks-icon.svg';

function QuoteAttributionCard({ theme, cardData }) {
  const { t } = useTranslation(i18next.language);
  const attributions = t(cardData.attribution).split('|');
  return (
    <Box className={`quote-attribution-card-container ${theme}`}>
      <Box className={`quote-attribution-card-content-container ${theme}`}>
        <QuoteMarksIcon className={`quote-icon ${theme}`} />
        <Typography className={`card-text ${theme}`}>{t(cardData.quote)}</Typography>
        <Box className={`card-attribution-container ${theme}`}>
          <Typography className={`attribution-name ${theme}`}>{t(cardData.name)}</Typography>
          <Box className="attribution-items">
            {attributions.map((attributionItem, index) => (
              <React.Fragment key={index}>
                <Typography className={`attribution ${theme}`}>{attributionItem}</Typography>
                {index !== attributions.length - 1 && (
                  <Divider orientation="vertical" className={`attribution-divider ${theme}`} />
                )}
              </React.Fragment>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

QuoteAttributionCard.propTypes = {
  theme: PropTypes.string.isRequired,
  cardData: PropTypes.object.isRequired
};

export default QuoteAttributionCard;
