import React from 'react';
import PropTypes from 'prop-types';
import FeedbackSection from '../../common/page-sections/FeedbackSection';

//Demandspace->Feedback

function SectionNine({ macro, selectedMicro }) {
  return (
    <div className="section9">
      <FeedbackSection macro={macro} micro={selectedMicro} />
    </div>
  );
}

SectionNine.propTypes = {
  macro: PropTypes.string.isRequired,
  selectedMicro: PropTypes.string.isRequired
};

export default SectionNine;
