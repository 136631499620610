import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { LandingHeader } from '../cat-trip-reuse-components/header/LandingHeader';
import { CommercialTeams } from './components/commercial-teams/CommercialTeams';
import { ResearchSection } from './components/research-section/ResearchSection';
import { ShoppingTripSection } from './components/shopping-trips/ShoppingTripSection';
import { ShopQuestions } from './components/shop-questions/ShopQuestions';
import { TripTypesFramework } from './components/trip-types-framework/TripTypesFramework';
import styles from './TripTypesLandingPage.module.css';

export default function TripTypesLandingPage() {
  const { t } = useTranslation(i18next.language);

  return (
    <div className={styles.container}>
      <LandingHeader
        title={t('tripTypesLanding.header.title')}
        introText={t('tripTypesLanding.header.eyebrowText')}
        imageSrc={t('tripTypesLanding.header.imageSrc')}
        altText={t('tripTypesLanding.header.altText')}
        buttonPath="/trip-types/framework"
        buttonCopy={t('tripTypesLanding.header.buttonText')}
      />
      <CommercialTeams />
      <ResearchSection />
      <ShoppingTripSection />
      <ShopQuestions />
      <TripTypesFramework />
    </div>
  );
}
