import { assets } from '../components/common/images';
import { demandSpaceAssets } from '../components/common/DemandSpaceAssets';
import { downloadsAssets } from '../components/common/DownloadsAssets';

export const demandSpacePeopleSegment =
  'The people that make this space fall under the {{type}} macro segment. Get to know them through their differences and data.';

export const microIdentifiers = {
  Fram_PSGMT_2: 'Comfort Cravers',
  Fram_PSGMT_3: 'Loyal Traditionalists',
  Fram_PSGMT_5: 'Balanced Enjoyers',
  Fram_PSGMT_6: 'Diligent Discoverers',
  Fram_PSGMT_8: 'Carefree Fun-Lovers',
  Fram_PSGMT_9: 'Proud Celebrators',
  Fram_PSGMT_11: 'Creative Explorers',
  Fram_PSGMT_12: 'Social Curators'
};

export const microIdentifiersShortHand = {
  Fram_PSGMT_2: 'CC',
  Fram_PSGMT_3: 'LT',
  Fram_PSGMT_5: 'BE',
  Fram_PSGMT_6: 'DD',
  Fram_PSGMT_8: 'CFL',
  Fram_PSGMT_9: 'PC',
  Fram_PSGMT_11: 'CE',
  Fram_PSGMT_12: 'SC'
};

export const demandspaceOccasionsMapping = {
  Fram_DS_1: ['Fram_OCCAS_1', 'Fram_OCCAS_4'],
  Fram_DS_2: ['Fram_OCCAS_8', 'Fram_OCCAS_11'],
  Fram_DS_3: ['Fram_OCCAS_1'],
  Fram_DS_4: ['Fram_OCCAS_1'],
  Fram_DS_5: ['Fram_OCCAS_4'],
  Fram_DS_6: ['Fram_OCCAS_11'],
  Fram_DS_7: ['Fram_OCCAS_4'],
  Fram_DS_8: ['Fram_OCCAS_11'],
  Fram_DS_9: ['Fram_OCCAS_1'],
  Fram_DS_10: ['Fram_OCCAS_4'],
  Fram_DS_11: ['Fram_OCCAS_11'],
  Fram_DS_12: ['Fram_OCCAS_8'],
  Fram_DS_13: ['Fram_OCCAS_8']
};

export const demandSpacePeopleSegmentMacroMapping = {
  Fram_DS_1: ['Fram_PSGMT_1'],
  Fram_DS_2: ['Fram_PSGMT_1'],
  Fram_DS_3: ['Fram_PSGMT_7', 'Fram_PSGMT_10'],
  Fram_DS_4: ['Fram_PSGMT_7', 'Fram_PSGMT_10'],
  Fram_DS_5: ['Fram_PSGMT_7'],
  Fram_DS_6: ['Fram_PSGMT_7'],
  Fram_DS_7: ['Fram_PSGMT_10'],
  Fram_DS_8: ['Fram_PSGMT_10'],
  Fram_DS_9: ['Fram_PSGMT_4'],
  Fram_DS_10: ['Fram_PSGMT_4'],
  Fram_DS_11: ['Fram_PSGMT_4'],
  Fram_DS_12: ['Fram_PSGMT_7', 'Fram_PSGMT_10', 'Fram_PSGMT_4'],
  Fram_DS_13: ['Fram_PSGMT_7', 'Fram_PSGMT_10', 'Fram_PSGMT_4']
};

export const emotionalNeedKey = {
  Att_EN_1: ['Att_EN_1'],
  Att_EN_2: ['Att_EN_2'],
  Att_EN_3: ['Att_EN_3'],
  Att_EN_4: ['Att_EN_4'],
  Att_EN_5: ['Att_EN_5'],
  Att_EN_6: ['Att_EN_6'],
  Att_EN_7: ['Att_EN_7'],
  Att_EN_8: ['Att_EN_8'],
  Att_EN_9: ['Att_EN_9'],
  Att_EN_10: ['Att_EN_10'],
  Att_EN_11: ['Att_EN_11'],
  Att_EN_12: ['Att_EN_12'],
  Att_EN_13: ['Att_EN_13'],
  Att_EN_14: ['Att_EN_14'],
  Att_EN_15: ['Att_EN_15'],
  Att_BC_15: ['Att_BC_15']
};

export const functionalNeedkey = {
  Att_FN_1: ['Att_FN_1'],
  Att_FN_2: ['Att_FN_2'],
  Att_FN_3: ['Att_FN_3'],
  Att_FN_4: ['Att_FN_4'],
  Att_FN_5: ['Att_FN_5'],
  Att_FN_6: ['Att_FN_6'],
  Att_FN_7: ['Att_FN_7'],
  Att_FN_8: ['Att_FN_8'],
  Att_FN_9: ['Att_FN_9'],
  Att_FN_10: ['Att_FN_10'],
  Att_FN_11: ['Att_FN_11'],
  Att_FN_12: ['Att_FN_12'],
  Att_FN_13: ['Att_FN_13'],
  Att_BC_15: ['Att_BC_15']
};

export const demandspaceTheOccasion = {
  Fram_OCCAS_2: [
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_2.nomealValue',
      icon: 'nomeal'
    },
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_2.groupSizeValue',
      icon: 'groupIcon'
    },
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_2.locationValue',
      icon: 'location'
    }
  ],
  Fram_OCCAS_3: [
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_3.mealValue',
      icon: 'meal'
    },
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_3.groupSizeValue',
      icon: 'groupIcon'
    },
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_3.locationValue',
      icon: 'location'
    }
  ],
  Fram_OCCAS_5: [
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_5.nomealValue',
      icon: 'nomeal'
    },
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_5.groupSizeValue',
      icon: 'groupIcon'
    },
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_5.locationValue',
      icon: 'location'
    }
  ],
  Fram_OCCAS_6: [
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_6.nomealValue',
      icon: 'nomeal'
    },
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_6.groupSizeValue',
      icon: 'groupIcon'
    },
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_6.locationValue',
      icon: 'location'
    }
  ],
  Fram_OCCAS_7: [
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_7.mealValue',
      icon: 'meal'
    },
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_7.groupSizeValue',
      icon: 'groupIcon'
    },
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_7.locationValue',
      icon: 'location'
    }
  ],
  Fram_OCCAS_9: [
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_9.mealValue',
      icon: 'meal'
    },
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_9.groupSizeValue',
      icon: 'groupIcon'
    },
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_9.locationValue',
      icon: 'location'
    }
  ],
  Fram_OCCAS_10: [
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_10.mealValue',
      icon: 'meal'
    },
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_10.groupSizeValue',
      icon: 'groupIcon'
    },
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_10.locationValue',
      icon: 'location'
    }
  ],
  Fram_OCCAS_12: [
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_12.mealValue',
      icon: 'meal'
    },
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_12.groupSizeValue',
      icon: 'groupIcon'
    },
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_12.locationValue',
      icon: 'location'
    }
  ],
  Fram_OCCAS_13: [
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_13.nomealValue',
      icon: 'nomeal'
    },
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_13.groupSizeValue',
      icon: 'groupIcon'
    },
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_13.locationValue',
      icon: 'location'
    }
  ],
  Fram_OCCAS_14: [
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_14.nomealValue',
      icon: 'nomeal'
    },
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_14.groupSizeValue',
      icon: 'groupIcon'
    },
    {
      value: 'occasions.template.howTheyDrink.Fram_OCCAS_14.locationValue',
      icon: 'location'
    }
  ]
};

export const DemandspaceToolTipCardData = {
  page: 'demandLandscape',
  tooltipData: [
    {
      label: 'full-screen-experience-img',
      imgPath: assets.home.fullScreenExperienceImg,
      title: 'home.modal.fullScreenExperienceTitle',
      description: 'home.modal.fullScreenExperienceDescription'
    },
    {
      label: 'quick-link-img',
      imgPath: assets.tooltip.quickLinkImg,
      title: 'common.popupCard.quickLinkTitle',
      description: 'common.popupCard.quickLinkDescription'
    },
    {
      label: 'category-img',
      imgPath: assets.tooltip.categorySizingImg,
      title: 'common.popupCard.categorySizingTitle',
      description: 'common.popupCard.categorySizingDescription'
    },
    {
      label: 'download-img',
      imgPath: assets.tooltip.downloadsImg,
      title: 'common.popupCard.downloadsTitle',
      description: 'common.popupCard.downloadsDescription'
    },
    {
      label: 'expanding-img',
      imgPath: assets.tooltip.expandingDataImg,
      title: 'common.popupCard.expandingDataTitle',
      description: 'common.popupCard.expandingDataDescription'
    }
  ]
};

export const DemandSpacesMenuOptions = [
  {
    header: 'Demand spaces',
    listOfItems: [
      {
        itemText: 'demandspace.type.Fram_DS_1',
        type: 'Fram_DS_1',
        routeParams: {
          micro: 'Fram_DS_1'
        }
      },
      {
        itemText: 'demandspace.type.Fram_DS_5',
        type: 'Fram_DS_5',
        routeParams: {
          micro: 'Fram_DS_5'
        }
      },
      {
        itemText: 'demandspace.type.Fram_DS_7',
        type: 'Fram_DS_7',
        routeParams: {
          micro: 'Fram_DS_7'
        }
      },
      {
        itemText: 'demandspace.type.Fram_DS_10',
        type: 'Fram_DS_10',
        routeParams: {
          micro: 'Fram_DS_10'
        }
      }
    ]
  },
  {
    header: 'Demand spaces',
    listOfItems: [
      {
        itemText: 'demandspace.type.Fram_DS_2',
        type: 'Fram_DS_2',
        routeParams: {
          micro: 'Fram_DS_2'
        }
      },
      {
        itemText: 'demandspace.type.Fram_DS_6',
        type: 'Fram_DS_6',
        routeParams: {
          micro: 'Fram_DS_6'
        }
      },
      {
        itemText: 'demandspace.type.Fram_DS_8',
        type: 'Fram_DS_8',
        routeParams: {
          micro: 'Fram_DS_8'
        }
      },
      {
        itemText: 'demandspace.type.Fram_DS_11',
        type: 'Fram_DS_11',
        routeParams: {
          micro: 'Fram_DS_11'
        }
      }
    ]
  },
  {
    header: 'Demand spaces',
    listOfItems: [
      {
        itemText: 'demandspace.type.Fram_DS_3',
        type: 'Fram_DS_3',
        routeParams: {
          micro: 'Fram_DS_3'
        }
      },
      {
        itemText: 'demandspace.type.Fram_DS_4',
        type: 'Fram_DS_4',
        routeParams: {
          micro: 'Fram_DS_4'
        }
      },
      {
        itemText: 'demandspace.type.Fram_DS_9',
        type: 'Fram_DS_9',
        routeParams: {
          micro: 'Fram_DS_9'
        }
      }
    ]
  },
  {
    header: 'Demand spaces',
    listOfItems: [
      {
        itemText: 'demandspace.type.Fram_DS_12',
        type: 'Fram_DS_12',
        routeParams: {
          micro: 'Fram_DS_12'
        }
      },
      {
        itemText: 'demandspace.type.Fram_DS_13',
        type: 'Fram_DS_13',
        routeParams: {
          micro: 'Fram_DS_13'
        }
      }
    ]
  }
];

export const demandspacesModularContent = {
  Fram_DS_1: [
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_1.modularCard1.motivationStatement'
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_1.modularCard2.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_1.modularCard2.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_1.EGH_Mod_2
        }
      }
    },
    {
      cardType: 'StatementImageCardLeft',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_1.modularCard3.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_1.modularCard3.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_1.EGH_Mod_3
        }
      }
    },
    {
      cardType: 'ImageCard',
      data: {
        image: demandSpaceAssets.inTheirOwnWords.Fram_DS_1.EGH_Mod_4
      }
    },
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_1.modularCard5.motivationStatement'
      }
    },
    {
      cardType: 'StatementQuoteImageCardRight',
      data: {
        bigqoute: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_1.modularCard6.bigAttribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_1.modularCard6.bigQuote'
        },
        quoteattributioncarddata: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_1.modularCard6.attribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_1.modularCard6.smallQuote'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_1.EGH_Mod_6
        }
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_1.modularCard7.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_1.modularCard7.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_1.EGH_Mod_7
        }
      }
    },
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_1.modularCard8.motivationStatement'
      }
    },
    {
      cardType: 'StatementQuoteImageCardLeft',
      data: {
        bigqoute: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_1.modularCard9.bigAttribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_1.modularCard9.bigQuote'
        },
        quoteattributioncarddata: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_1.modularCard9.attribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_1.modularCard9.smallQuote'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_1.EGH_Mod_9
        }
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_1.modularCard10.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_1.modularCard10.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_1.EGH_Mod_10
        }
      }
    }
  ],
  Fram_DS_2: [
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_2.modularCard1.motivationStatement'
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_2.modularCard2.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_2.modularCard2.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_2.RL_Mod_2
        }
      }
    },
    {
      cardType: 'StatementImageCardLeft',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_2.modularCard3.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_2.modularCard3.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_2.RL_Mod_3
        }
      }
    },
    {
      cardType: 'ImageCard',
      data: {
        image: demandSpaceAssets.inTheirOwnWords.Fram_DS_2.RL_Mod_4
      }
    },
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_2.modularCard5.motivationStatement'
      }
    },
    {
      cardType: 'StatementQuoteImageCardRight',
      data: {
        bigqoute: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_2.modularCard6.bigAttribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_2.modularCard6.bigQuote'
        },
        quoteattributioncarddata: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_2.modularCard6.attribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_2.modularCard6.smallQuote'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_2.RL_Mod_6
        }
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_2.modularCard7.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_2.modularCard7.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_2.RL_Mod_7
        }
      }
    },
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_2.modularCard8.motivationStatement'
      }
    },
    {
      cardType: 'StatementQuoteImageCardLeft',
      data: {
        bigqoute: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_2.modularCard9.bigAttribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_2.modularCard9.bigQuote'
        },
        quoteattributioncarddata: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_2.modularCard9.attribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_2.modularCard9.smallQuote'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_2.RL_Mod_9
        }
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_2.modularCard10.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_2.modularCard10.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_2.RL_Mod_10
        }
      }
    }
  ],
  Fram_DS_3: [
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_3.modularCard1.motivationStatement'
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_3.modularCard2.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_3.modularCard2.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_3.ENO_Mod_2
        }
      }
    },
    {
      cardType: 'StatementImageCardLeft',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_3.modularCard3.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_3.modularCard3.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_3.ENO_Mod_3
        }
      }
    },
    {
      cardType: 'ImageCard',
      data: {
        image: demandSpaceAssets.inTheirOwnWords.Fram_DS_3.ENO_Mod_4
      }
    },
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_3.modularCard5.motivationStatement'
      }
    },
    {
      cardType: 'StatementQuoteImageCardRight',
      data: {
        bigqoute: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_3.modularCard6.bigAttribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_3.modularCard6.bigQuote'
        },
        quoteattributioncarddata: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_3.modularCard6.attribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_3.modularCard6.smallQuote'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_3.ENO_Mod_6
        }
      }
    },

    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_3.modularCard7.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_3.modularCard7.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_3.ENO_Mod_7
        }
      }
    },
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_3.modularCard8.motivationStatement'
      }
    },
    {
      cardType: 'StatementQuoteImageCardLeft',
      data: {
        bigqoute: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_3.modularCard9.bigAttribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_3.modularCard9.bigQuote'
        },
        quoteattributioncarddata: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_3.modularCard9.attribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_3.modularCard9.smallQuote'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_3.ENO_Mod_9
        }
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_3.modularCard10.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_3.modularCard10.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_3.ENO_Mod_10
        }
      }
    }
  ],
  Fram_DS_4: [
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_4.modularCard1.motivationStatement'
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_4.modularCard2.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_4.modularCard2.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_4.USM_Mod_2
        }
      }
    },
    {
      cardType: 'StatementImageCardLeft',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_4.modularCard3.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_4.modularCard3.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_4.USM_Mod_3
        }
      }
    },
    {
      cardType: 'ImageCard',
      data: {
        image: demandSpaceAssets.inTheirOwnWords.Fram_DS_4.USM_Mod_4
      }
    },
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_4.modularCard5.motivationStatement'
      }
    },
    {
      cardType: 'StatementQuoteImageCardRight',
      data: {
        bigqoute: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_4.modularCard6.bigAttribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_4.modularCard6.bigQuote'
        },
        quoteattributioncarddata: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_4.modularCard6.attribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_4.modularCard6.smallQuote'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_4.USM_Mod_6
        }
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_4.modularCard7.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_4.modularCard7.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_4.USM_Mod_7
        }
      }
    },
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_4.modularCard8.motivationStatement'
      }
    },
    {
      cardType: 'StatementQuoteImageCardLeft',
      data: {
        bigqoute: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_4.modularCard9.bigAttribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_4.modularCard9.bigQuote'
        },
        quoteattributioncarddata: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_4.modularCard9.attribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_4.modularCard9.smallQuote'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_4.USM_Mod_9
        }
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_4.modularCard10.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_4.modularCard10.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_4.USM_Mod_10
        }
      }
    }
  ],
  Fram_DS_5: [
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_5.modularCard1.motivationStatement'
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_5.modularCard2.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_5.modularCard2.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_5.CGT_Mod_2
        }
      }
    },
    {
      cardType: 'StatementImageCardLeft',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_5.modularCard3.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_5.modularCard3.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_5.CGT_Mod_3
        }
      }
    },
    {
      cardType: 'ImageCard',
      data: {
        image: demandSpaceAssets.inTheirOwnWords.Fram_DS_5.CGT_Mod_4
      }
    },
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_5.modularCard5.motivationStatement'
      }
    },
    {
      cardType: 'StatementQuoteImageCardRight',
      data: {
        bigqoute: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_5.modularCard6.bigAttribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_5.modularCard6.bigQuote'
        },
        quoteattributioncarddata: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_5.modularCard6.attribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_5.modularCard6.smallQuote'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_5.CGT_Mod_6
        }
      }
    },

    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_5.modularCard7.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_5.modularCard7.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_5.CGT_Mod_7
        }
      }
    },
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_5.modularCard8.motivationStatement'
      }
    },
    {
      cardType: 'StatementQuoteImageCardLeft',
      data: {
        bigqoute: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_5.modularCard9.bigAttribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_5.modularCard9.bigQuote'
        },
        quoteattributioncarddata: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_5.modularCard9.attribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_5.modularCard9.smallQuote'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_5.CGT_Mod_9
        }
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_5.modularCard10.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_5.modularCard10.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_5.CGT_Mod_10
        }
      }
    }
  ],
  Fram_DS_6: [
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_6.modularCard1.motivationStatement'
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_6.modularCard2.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_6.modularCard2.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_6.SU_Mod_2
        }
      }
    },
    {
      cardType: 'StatementImageCardLeft',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_6.modularCard3.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_6.modularCard3.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_6.SU_Mod_3
        }
      }
    },
    {
      cardType: 'ImageCard',
      data: {
        image: demandSpaceAssets.inTheirOwnWords.Fram_DS_6.SU_Mod_4
      }
    },
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_6.modularCard5.motivationStatement'
      }
    },
    {
      cardType: 'StatementQuoteImageCardRight',
      data: {
        bigqoute: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_6.modularCard6.bigAttribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_6.modularCard6.bigQuote'
        },
        quoteattributioncarddata: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_6.modularCard6.attribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_6.modularCard6.smallQuote'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_6.SU_Mod_6
        }
      }
    },

    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_6.modularCard7.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_6.modularCard7.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_6.SU_Mod_7
        }
      }
    },
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_6.modularCard8.motivationStatement'
      }
    },
    {
      cardType: 'StatementQuoteImageCardLeft',
      data: {
        bigqoute: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_6.modularCard9.bigAttribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_6.modularCard9.bigQuote'
        },
        quoteattributioncarddata: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_6.modularCard9.attribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_6.modularCard9.smallQuote'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_6.SU_Mod_9
        }
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_6.modularCard10.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_6.modularCard10.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_6.SU_Mod_10
        }
      }
    }
  ],
  Fram_DS_7: [
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_7.modularCard1.motivationStatement'
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_7.modularCard2.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_7.modularCard2.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_7.LSG_Mod_2
        }
      }
    },
    {
      cardType: 'StatementImageCardLeft',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_7.modularCard3.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_7.modularCard3.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_7.LSG_Mod_3
        }
      }
    },
    {
      cardType: 'ImageCard',
      data: {
        image: demandSpaceAssets.inTheirOwnWords.Fram_DS_7.LSG_Mod_4
      }
    },
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_7.modularCard5.motivationStatement'
      }
    },
    {
      cardType: 'StatementQuoteImageCardRight',
      data: {
        bigqoute: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_7.modularCard6.bigAttribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_7.modularCard6.bigQuote'
        },
        quoteattributioncarddata: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_7.modularCard6.attribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_7.modularCard6.smallQuote'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_7.LSG_Mod_6
        }
      }
    },

    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_7.modularCard7.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_7.modularCard7.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_7.LSG_Mod_7
        }
      }
    },
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_7.modularCard8.motivationStatement'
      }
    },
    {
      cardType: 'StatementQuoteImageCardLeft',
      data: {
        bigqoute: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_7.modularCard9.bigAttribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_7.modularCard9.bigQuote'
        },
        quoteattributioncarddata: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_7.modularCard9.attribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_7.modularCard9.smallQuote'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_7.LSG_Mod_9
        }
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_7.modularCard10.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_7.modularCard10.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_7.LSG_Mod_10
        }
      }
    }
  ],
  Fram_DS_8: [
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_8.modularCard1.motivationStatement'
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_8.modularCard2.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_8.modularCard2.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_8.IU_Mod_2
        }
      }
    },
    {
      cardType: 'StatementImageCardLeft',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_8.modularCard3.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_8.modularCard3.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_8.IU_Mod_3
        }
      }
    },
    {
      cardType: 'ImageCard',
      data: {
        image: demandSpaceAssets.inTheirOwnWords.Fram_DS_8.IU_Mod_4
      }
    },
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_8.modularCard5.motivationStatement'
      }
    },
    {
      cardType: 'StatementQuoteImageCardRight',
      data: {
        bigqoute: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_8.modularCard6.bigAttribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_8.modularCard6.bigQuote'
        },
        quoteattributioncarddata: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_8.modularCard6.attribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_8.modularCard6.smallQuote'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_8.IU_Mod_6
        }
      }
    },

    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_8.modularCard7.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_8.modularCard7.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_8.IU_Mod_7
        }
      }
    },
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_8.modularCard8.motivationStatement'
      }
    },
    {
      cardType: 'StatementQuoteImageCardLeft',
      data: {
        bigqoute: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_8.modularCard9.bigAttribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_8.modularCard9.bigQuote'
        },
        quoteattributioncarddata: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_8.modularCard9.attribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_8.modularCard9.smallQuote'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_8.IU_Mod_9
        }
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_8.modularCard10.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_8.modularCard10.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_8.IU_Mod_10
        }
      }
    }
  ],
  Fram_DS_9: [
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_9.modularCard1.motivationStatement'
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_9.modularCard2.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_9.modularCard2.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_9.RWO_Mod_2
        }
      }
    },
    {
      cardType: 'StatementImageCardLeft',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_9.modularCard3.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_9.modularCard3.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_9.RWO_Mod_3
        }
      }
    },
    {
      cardType: 'ImageCard',
      data: {
        image: demandSpaceAssets.inTheirOwnWords.Fram_DS_9.RWO_Mod_4
      }
    },
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_9.modularCard5.motivationStatement'
      }
    },
    {
      cardType: 'StatementQuoteImageCardRight',
      data: {
        bigqoute: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_9.modularCard6.bigAttribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_9.modularCard6.bigQuote'
        },
        quoteattributioncarddata: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_9.modularCard6.attribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_9.modularCard6.smallQuote'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_9.RWO_Mod_6
        }
      }
    },

    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_9.modularCard7.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_9.modularCard7.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_9.RWO_Mod_7
        }
      }
    },
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_9.modularCard8.motivationStatement'
      }
    },
    {
      cardType: 'StatementQuoteImageCardLeft',
      data: {
        bigqoute: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_9.modularCard9.bigAttribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_9.modularCard9.bigQuote'
        },
        quoteattributioncarddata: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_9.modularCard9.attribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_9.modularCard9.smallQuote'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_9.RWO_Mod_9
        }
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_9.modularCard10.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_9.modularCard10.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_9.RWO_Mod_10
        }
      }
    }
  ],
  Fram_DS_10: [
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_10.modularCard1.motivationStatement'
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_10.modularCard2.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_10.modularCard2.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_10.TC_Mod_2
        }
      }
    },
    {
      cardType: 'StatementImageCardLeft',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_10.modularCard3.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_10.modularCard3.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_10.TC_Mod_3
        }
      }
    },
    {
      cardType: 'ImageCard',
      data: {
        image: demandSpaceAssets.inTheirOwnWords.Fram_DS_10.TC_Mod_4
      }
    },
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_10.modularCard5.motivationStatement'
      }
    },
    {
      cardType: 'StatementQuoteImageCardRight',
      data: {
        bigqoute: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_10.modularCard6.bigAttribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_10.modularCard6.bigQuote'
        },
        quoteattributioncarddata: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_10.modularCard6.attribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_10.modularCard6.smallQuote'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_10.TC_Mod_6
        }
      }
    },

    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_10.modularCard7.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_10.modularCard7.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_10.TC_Mod_7
        }
      }
    },
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_10.modularCard8.motivationStatement'
      }
    },
    {
      cardType: 'StatementQuoteImageCardLeft',
      data: {
        bigqoute: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_10.modularCard9.bigAttribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_10.modularCard9.bigQuote'
        },
        quoteattributioncarddata: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_10.modularCard9.attribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_10.modularCard9.smallQuote'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_10.TC_Mod_9
        }
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_10.modularCard10.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_10.modularCard10.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_10.TC_Mod_10
        }
      }
    }
  ],
  Fram_DS_11: [
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_11.modularCard1.motivationStatement'
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_11.modularCard2.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_11.modularCard2.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_11.SS_Mod_2
        }
      }
    },
    {
      cardType: 'StatementImageCardLeft',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_11.modularCard3.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_11.modularCard3.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_11.SS_Mod_3
        }
      }
    },
    {
      cardType: 'ImageCard',
      data: {
        image: demandSpaceAssets.inTheirOwnWords.Fram_DS_11.SS_Mod_4
      }
    },
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_11.modularCard5.motivationStatement'
      }
    },
    {
      cardType: 'StatementQuoteImageCardRight',
      data: {
        bigqoute: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_11.modularCard6.bigAttribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_11.modularCard6.bigQuote'
        },
        quoteattributioncarddata: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_11.modularCard6.attribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_11.modularCard6.smallQuote'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_11.SS_Mod_6
        }
      }
    },

    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_11.modularCard7.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_11.modularCard7.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_11.SS_Mod_7
        }
      }
    },
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_11.modularCard8.motivationStatement'
      }
    },
    {
      cardType: 'StatementQuoteImageCardLeft',
      data: {
        bigqoute: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_11.modularCard9.bigAttribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_11.modularCard9.bigQuote'
        },
        quoteattributioncarddata: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_11.modularCard9.attribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_11.modularCard9.smallQuote'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_11.SS_Mod_9
        }
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_11.modularCard10.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_11.modularCard10.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_11.SS_Mod_10
        }
      }
    }
  ],
  Fram_DS_12: [
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_12.modularCard1.motivationStatement'
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_12.modularCard2.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_12.modularCard2.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_12.ID_Mod_2
        }
      }
    },
    {
      cardType: 'StatementImageCardLeft',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_12.modularCard3.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_12.modularCard3.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_12.ID_Mod_3
        }
      }
    },
    {
      cardType: 'ImageCard',
      data: {
        image: demandSpaceAssets.inTheirOwnWords.Fram_DS_12.ID_Mod_4
      }
    },
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_12.modularCard5.motivationStatement'
      }
    },
    {
      cardType: 'StatementQuoteImageCardRight',
      data: {
        bigqoute: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_12.modularCard6.bigAttribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_12.modularCard6.bigQuote'
        },
        quoteattributioncarddata: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_12.modularCard6.attribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_12.modularCard6.smallQuote'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_12.ID_Mod_6
        }
      }
    },

    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_12.modularCard7.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_12.modularCard7.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_12.ID_Mod_7
        }
      }
    },
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_12.modularCard8.motivationStatement'
      }
    },
    {
      cardType: 'StatementQuoteImageCardLeft',
      data: {
        bigqoute: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_12.modularCard9.bigAttribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_12.modularCard9.bigQuote'
        },
        quoteattributioncarddata: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_12.modularCard9.attribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_12.modularCard9.smallQuote'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_12.ID_Mod_9
        }
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_12.modularCard10.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_12.modularCard10.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_12.ID_Mod_10
        }
      }
    }
  ],
  Fram_DS_13: [
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_13.modularCard1.motivationStatement'
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_13.modularCard2.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_13.modularCard2.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_13.EM_Mod_2
        }
      }
    },
    {
      cardType: 'StatementImageCardLeft',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_13.modularCard3.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_13.modularCard3.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_13.EM_Mod_3
        }
      }
    },
    {
      cardType: 'ImageCard',
      data: {
        image: demandSpaceAssets.inTheirOwnWords.Fram_DS_13.EM_Mod_4
      }
    },
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_13.modularCard5.motivationStatement'
      }
    },
    {
      cardType: 'StatementQuoteImageCardRight',
      data: {
        bigqoute: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_13.modularCard6.bigAttribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_13.modularCard6.bigQuote'
        },
        quoteattributioncarddata: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_13.modularCard6.attribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_13.modularCard6.smallQuote'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_13.EM_Mod_6
        }
      }
    },

    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_13.modularCard7.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_13.modularCard7.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_13.EM_Mod_7
        }
      }
    },
    {
      cardType: 'StatementCard',
      data: {
        statement: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_13.modularCard8.motivationStatement'
      }
    },
    {
      cardType: 'StatementQuoteImageCardLeft',
      data: {
        bigqoute: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_13.modularCard9.bigAttribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_13.modularCard9.bigQuote'
        },
        quoteattributioncarddata: {
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_13.modularCard9.attribution',
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_13.modularCard9.smallQuote'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_13.EM_Mod_9
        }
      }
    },
    {
      cardType: 'StatementImageCardRight',
      data: {
        quoteattributioncarddata: {
          quote: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_13.modularCard10.bigQuote',
          attribution: 'demandspace.template.driversAndNeeds.modularContent.Fram_DS_13.modularCard10.attribution'
        },
        imagecarddata: {
          image: demandSpaceAssets.inTheirOwnWords.Fram_DS_13.EM_Mod_10
        }
      }
    }
  ]
};

export const demandspaceOccassionPeopleSegmentMapping = {
  Fram_DS_1: [
    'Fram_DS_1',
    'Fram_OCCAS_2',
    'Fram_OCCAS_3',
    'Fram_OCCAS_5',
    'Fram_OCCAS_6',
    'Fram_OCCAS_7',
    'Fram_PSGMT_2',
    'Fram_PSGMT_3'
  ],
  Fram_DS_2: [
    'Fram_DS_2',
    'Fram_OCCAS_9',
    'Fram_OCCAS_10',
    'Fram_OCCAS_12',
    'Fram_OCCAS_13',
    'Fram_OCCAS_14',
    'Fram_PSGMT_2',
    'Fram_PSGMT_3'
  ],
  Fram_DS_3: ['Fram_DS_3', 'Fram_OCCAS_2', 'Fram_PSGMT_8', 'Fram_PSGMT_9', 'Fram_PSGMT_11', 'Fram_PSGMT_12'],
  Fram_DS_4: ['Fram_DS_4', 'Fram_OCCAS_3', 'Fram_PSGMT_8', 'Fram_PSGMT_9', 'Fram_PSGMT_11', 'Fram_PSGMT_12'],
  Fram_DS_5: ['Fram_DS_5', 'Fram_OCCAS_5', 'Fram_OCCAS_6', 'Fram_OCCAS_7', 'Fram_PSGMT_8', 'Fram_PSGMT_9'],
  Fram_DS_6: ['Fram_DS_6', 'Fram_OCCAS_12', 'Fram_OCCAS_13', 'Fram_OCCAS_14', 'Fram_PSGMT_8', 'Fram_PSGMT_9'],
  Fram_DS_7: ['Fram_DS_7', 'Fram_OCCAS_5', 'Fram_OCCAS_6', 'Fram_OCCAS_7', 'Fram_PSGMT_11', 'Fram_PSGMT_12'],
  Fram_DS_8: ['Fram_DS_8', 'Fram_OCCAS_12', 'Fram_OCCAS_13', 'Fram_OCCAS_14', 'Fram_PSGMT_11', 'Fram_PSGMT_12'],
  Fram_DS_9: ['Fram_DS_9', 'Fram_OCCAS_2', 'Fram_OCCAS_3', 'Fram_PSGMT_5', 'Fram_PSGMT_6'],
  Fram_DS_10: ['Fram_DS_10', 'Fram_OCCAS_5', 'Fram_OCCAS_6', 'Fram_OCCAS_7', 'Fram_PSGMT_5', 'Fram_PSGMT_6'],
  Fram_DS_11: ['Fram_DS_11', 'Fram_OCCAS_12', 'Fram_OCCAS_13', 'Fram_OCCAS_14', 'Fram_PSGMT_5', 'Fram_PSGMT_6'],
  Fram_DS_12: [
    'Fram_DS_12',
    'Fram_OCCAS_9',
    // "Fram_OCCAS_10",
    'Fram_PSGMT_8',
    'Fram_PSGMT_9',
    'Fram_PSGMT_11',
    'Fram_PSGMT_12',
    'Fram_PSGMT_5',
    'Fram_PSGMT_6'
  ],
  Fram_DS_13: [
    'Fram_DS_13',
    // "Fram_OCCAS_9",
    'Fram_OCCAS_10',
    'Fram_PSGMT_8',
    'Fram_PSGMT_9',
    'Fram_PSGMT_11',
    'Fram_PSGMT_12',
    'Fram_PSGMT_5',
    'Fram_PSGMT_6'
  ]
};

export const DemandspaceTemplateDownloadItems = {
  //EasyGoingHangout
  Fram_DS_1: [
    {
      title: 'common.downloads.demandspace.microProfile',
      subtitle: 'common.downloads.demandspace.microProfileSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.demandSpaceTemplateDownloads.Fram_DS_1.EGH_Profile_PDF,
        ppt: downloadsAssets.demandSpaceTemplateDownloads.Fram_DS_1.EGH_Profile_PPT
      }
    },
    {
      title: 'common.downloadDemandSpace',
      subtitle: 'common.downloadDemandSpaceSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.demandSpaceTemplateDownloads.common.demandSpaceProfilesPDF,
        ppt: downloadsAssets.demandSpaceTemplateDownloads.common.demandSpaceProfilesPPT
      }
    },
    {
      title: 'common.downloads.demandspace.fullDataSpreadsheet',
      subtitle: 'common.downloads.demandspace.fullDataSpreadsheetSubtitle',
      multipleFiles: false,
      fileToDownload: downloadsAssets.demandSpaceTemplateDownloads.common.demandspaceTemplateDataSpreadsheet
    }
  ],
  //CasualGetTimes
  Fram_DS_5: [
    {
      title: 'common.downloads.demandspace.microProfile',
      subtitle: 'common.downloads.demandspace.microProfileSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.demandSpaceTemplateDownloads.Fram_DS_5.CGT_Profile_PDF,
        ppt: downloadsAssets.demandSpaceTemplateDownloads.Fram_DS_5.CGT_Profile_PPT
      }
    },
    {
      title: 'common.downloadDemandSpace',
      subtitle: 'common.downloadDemandSpaceSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.demandSpaceTemplateDownloads.common.demandSpaceProfilesPDF,
        ppt: downloadsAssets.demandSpaceTemplateDownloads.common.demandSpaceProfilesPPT
      }
    },
    {
      title: 'common.downloads.demandspace.fullDataSpreadsheet',
      subtitle: 'common.downloads.demandspace.fullDataSpreadsheetSubtitle',
      multipleFiles: false,
      fileToDownload: downloadsAssets.demandSpaceTemplateDownloads.common.demandspaceTemplateDataSpreadsheet
    }
  ],
  //Energetic Nightout
  Fram_DS_3: [
    {
      title: 'common.downloads.demandspace.microProfile',
      subtitle: 'common.downloads.demandspace.microProfileSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.demandSpaceTemplateDownloads.Fram_DS_3.ENO_Profile_PDF,
        ppt: downloadsAssets.demandSpaceTemplateDownloads.Fram_DS_3.ENO_Profile_PPT
      }
    },
    {
      title: 'common.downloadDemandSpace',
      subtitle: 'common.downloadDemandSpaceSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.demandSpaceTemplateDownloads.common.demandSpaceProfilesPDF,
        ppt: downloadsAssets.demandSpaceTemplateDownloads.common.demandSpaceProfilesPPT
      }
    },
    {
      title: 'common.downloads.demandspace.fullDataSpreadsheet',
      subtitle: 'common.downloads.demandspace.fullDataSpreadsheetSubtitle',
      multipleFiles: false,
      fileToDownload: downloadsAssets.demandSpaceTemplateDownloads.common.demandspaceTemplateDataSpreadsheet
    }
  ],
  //UpbeatSocialMeal
  Fram_DS_4: [
    {
      title: 'common.downloads.demandspace.microProfile',
      subtitle: 'common.downloads.demandspace.microProfileSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.demandSpaceTemplateDownloads.Fram_DS_4.USM_Profile_PDF,
        ppt: downloadsAssets.demandSpaceTemplateDownloads.Fram_DS_4.USM_Profile_PPT
      }
    },
    {
      title: 'common.downloadDemandSpace',
      subtitle: 'common.downloadDemandSpaceSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.demandSpaceTemplateDownloads.common.demandSpaceProfilesPDF,
        ppt: downloadsAssets.demandSpaceTemplateDownloads.common.demandSpaceProfilesPPT
      }
    },
    {
      title: 'common.downloads.demandspace.fullDataSpreadsheet',
      subtitle: 'common.downloads.demandspace.fullDataSpreadsheetSubtitle',
      multipleFiles: false,
      fileToDownload: downloadsAssets.demandSpaceTemplateDownloads.common.demandspaceTemplateDataSpreadsheet
    }
  ],
  //RefinedWeekendOut
  Fram_DS_9: [
    {
      title: 'common.downloads.demandspace.microProfile',
      subtitle: 'common.downloads.demandspace.microProfileSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.demandSpaceTemplateDownloads.Fram_DS_9.RWO_Profile_PDF,
        ppt: downloadsAssets.demandSpaceTemplateDownloads.Fram_DS_9.RWO_Profile_PPT
      }
    },
    {
      title: 'common.downloadDemandSpace',
      subtitle: 'common.downloadDemandSpaceSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.demandSpaceTemplateDownloads.common.demandSpaceProfilesPDF,
        ppt: downloadsAssets.demandSpaceTemplateDownloads.common.demandSpaceProfilesPPT
      }
    },
    {
      title: 'common.downloads.demandspace.fullDataSpreadsheet',
      subtitle: 'common.downloads.demandspace.fullDataSpreadsheetSubtitle',
      multipleFiles: false,
      fileToDownload: downloadsAssets.demandSpaceTemplateDownloads.common.demandspaceTemplateDataSpreadsheet
    }
  ],
  //LivelySocialGathering
  Fram_DS_7: [
    {
      title: 'common.downloads.demandspace.microProfile',
      subtitle: 'common.downloads.demandspace.microProfileSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.demandSpaceTemplateDownloads.Fram_DS_7.LSG_Profile_PDF,
        ppt: downloadsAssets.demandSpaceTemplateDownloads.Fram_DS_7.LSG_Profile_PPT
      }
    },
    {
      title: 'common.downloadDemandSpace',
      subtitle: 'common.downloadDemandSpaceSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.demandSpaceTemplateDownloads.common.demandSpaceProfilesPDF,
        ppt: downloadsAssets.demandSpaceTemplateDownloads.common.demandSpaceProfilesPPT
      }
    },
    {
      title: 'common.downloads.demandspace.fullDataSpreadsheet',
      subtitle: 'common.downloads.demandspace.fullDataSpreadsheetSubtitle',
      multipleFiles: false,
      fileToDownload: downloadsAssets.demandSpaceTemplateDownloads.common.demandspaceTemplateDataSpreadsheet
    }
  ],
  //ThoughtfulConnection
  Fram_DS_10: [
    {
      title: 'common.downloads.demandspace.microProfile',
      subtitle: 'common.downloads.demandspace.microProfileSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.demandSpaceTemplateDownloads.Fram_DS_10.TC_Profile_PDF,
        ppt: downloadsAssets.demandSpaceTemplateDownloads.Fram_DS_10.TC_Profile_PPT
      }
    },
    {
      title: 'common.downloadDemandSpace',
      subtitle: 'common.downloadDemandSpaceSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.demandSpaceTemplateDownloads.common.demandSpaceProfilesPDF,
        ppt: downloadsAssets.demandSpaceTemplateDownloads.common.demandSpaceProfilesPPT
      }
    },
    {
      title: 'common.downloads.demandspace.fullDataSpreadsheet',
      subtitle: 'common.downloads.demandspace.fullDataSpreadsheetSubtitle',
      multipleFiles: false,
      fileToDownload: downloadsAssets.demandSpaceTemplateDownloads.common.demandspaceTemplateDataSpreadsheet
    }
  ],
  //RoutineLivinig
  Fram_DS_2: [
    {
      title: 'common.downloads.demandspace.microProfile',
      subtitle: 'common.downloads.demandspace.microProfileSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.demandSpaceTemplateDownloads.Fram_DS_2.RL_Profile_PDF,
        ppt: downloadsAssets.demandSpaceTemplateDownloads.Fram_DS_2.RL_Profile_PPT
      }
    },
    {
      title: 'common.downloadDemandSpace',
      subtitle: 'common.downloadDemandSpaceSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.demandSpaceTemplateDownloads.common.demandSpaceProfilesPDF,
        ppt: downloadsAssets.demandSpaceTemplateDownloads.common.demandSpaceProfilesPPT
      }
    },
    {
      title: 'common.downloads.demandspace.fullDataSpreadsheet',
      subtitle: 'common.downloads.demandspace.fullDataSpreadsheetSubtitle',
      multipleFiles: false,
      fileToDownload: downloadsAssets.demandSpaceTemplateDownloads.common.demandspaceTemplateDataSpreadsheet
    }
  ],
  //InitimateDiningIn
  Fram_DS_12: [
    {
      title: 'common.downloads.demandspace.microProfile',
      subtitle: 'common.downloads.demandspace.microProfileSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.demandSpaceTemplateDownloads.Fram_DS_12.IDI_Profile_PDF,
        ppt: downloadsAssets.demandSpaceTemplateDownloads.Fram_DS_12.IDI_Profile_PPT
      }
    },
    {
      title: 'common.downloadDemandSpace',
      subtitle: 'common.downloadDemandSpaceSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.demandSpaceTemplateDownloads.common.demandSpaceProfilesPDF,
        ppt: downloadsAssets.demandSpaceTemplateDownloads.common.demandSpaceProfilesPPT
      }
    },
    {
      title: 'common.downloads.demandspace.fullDataSpreadsheet',
      subtitle: 'common.downloads.demandspace.fullDataSpreadsheetSubtitle',
      multipleFiles: false,
      fileToDownload: downloadsAssets.demandSpaceTemplateDownloads.common.demandspaceTemplateDataSpreadsheet
    }
  ],
  //EverdayMealIn
  Fram_DS_13: [
    {
      title: 'common.downloads.demandspace.microProfile',
      subtitle: 'common.downloads.demandspace.microProfileSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.demandSpaceTemplateDownloads.Fram_DS_13.EM_Profile_PDF,
        ppt: downloadsAssets.demandSpaceTemplateDownloads.Fram_DS_13.EM_Profile_PPT
      }
    },
    {
      title: 'common.downloadDemandSpace',
      subtitle: 'common.downloadDemandSpaceSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.demandSpaceTemplateDownloads.common.demandSpaceProfilesPDF,
        ppt: downloadsAssets.demandSpaceTemplateDownloads.common.demandSpaceProfilesPPT
      }
    },
    {
      title: 'common.downloads.demandspace.fullDataSpreadsheet',
      subtitle: 'common.downloads.demandspace.fullDataSpreadsheetSubtitle',
      multipleFiles: false,
      fileToDownload: downloadsAssets.demandSpaceTemplateDownloads.common.demandspaceTemplateDataSpreadsheet
    }
  ],
  //SimpleUnplug
  Fram_DS_6: [
    {
      title: 'common.downloads.demandspace.microProfile',
      subtitle: 'common.downloads.demandspace.microProfileSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.demandSpaceTemplateDownloads.Fram_DS_6.SU_Profile_PDF,
        ppt: downloadsAssets.demandSpaceTemplateDownloads.Fram_DS_6.SU_Profile_PPT
      }
    },
    {
      title: 'common.downloadDemandSpace',
      subtitle: 'common.downloadDemandSpaceSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.demandSpaceTemplateDownloads.common.demandSpaceProfilesPDF,
        ppt: downloadsAssets.demandSpaceTemplateDownloads.common.demandSpaceProfilesPPT
      }
    },
    {
      title: 'common.downloads.demandspace.fullDataSpreadsheet',
      subtitle: 'common.downloads.demandspace.fullDataSpreadsheetSubtitle',
      multipleFiles: false,
      fileToDownload: downloadsAssets.demandSpaceTemplateDownloads.common.demandspaceTemplateDataSpreadsheet
    }
  ],
  //IntentionalUnwind
  Fram_DS_8: [
    {
      title: 'common.downloads.demandspace.microProfile',
      subtitle: 'common.downloads.demandspace.microProfileSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.demandSpaceTemplateDownloads.Fram_DS_8.IU_Profile_PDF,
        ppt: downloadsAssets.demandSpaceTemplateDownloads.Fram_DS_8.IU_Profile_PPT
      }
    },
    {
      title: 'common.downloadDemandSpace',
      subtitle: 'common.downloadDemandSpaceSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.demandSpaceTemplateDownloads.common.demandSpaceProfilesPDF,
        ppt: downloadsAssets.demandSpaceTemplateDownloads.common.demandSpaceProfilesPPT
      }
    },
    {
      title: 'common.downloads.demandspace.fullDataSpreadsheet',
      subtitle: 'common.downloads.demandspace.fullDataSpreadsheetSubtitle',
      multipleFiles: false,
      fileToDownload: downloadsAssets.demandSpaceTemplateDownloads.common.demandspaceTemplateDataSpreadsheet
    }
  ],
  //SensibleSlowdown
  Fram_DS_11: [
    {
      title: 'common.downloads.demandspace.microProfile',
      subtitle: 'common.downloads.demandspace.microProfileSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.demandSpaceTemplateDownloads.Fram_DS_11.SS_Profile_PDF,
        ppt: downloadsAssets.demandSpaceTemplateDownloads.Fram_DS_11.SS_Profile_PPT
      }
    },
    {
      title: 'common.downloadDemandSpace',
      subtitle: 'common.downloadDemandSpaceSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.demandSpaceTemplateDownloads.common.demandSpaceProfilesPDF,
        ppt: downloadsAssets.demandSpaceTemplateDownloads.common.demandSpaceProfilesPPT
      }
    },
    {
      title: 'common.downloads.demandspace.fullDataSpreadsheet',
      subtitle: 'common.downloads.demandspace.fullDataSpreadsheetSubtitle',
      multipleFiles: false,
      fileToDownload: downloadsAssets.demandSpaceTemplateDownloads.common.demandspaceTemplateDataSpreadsheet
    }
  ]
};

export const demandspaceHubDownloadItems = [
  {
    title: 'common.downloadDemandSpace',
    subtitle: 'common.downloadDemandSpaceSubtitle',
    multipleFiles: true,
    fileToDownload: {
      pdf: downloadsAssets.commonDownloads.demandSpaceProfilesPDF,
      ppt: downloadsAssets.commonDownloads.demandSpaceProfilesPPT
    }
  },
  {
    title: 'common.downloadDataPack',
    subtitle: 'common.downloadDataPackDemandspaces',
    multipleFiles: false,
    fileToDownload: downloadsAssets.commonDownloads.demandSpaceDataPackSpreadsheet
  }
];
