import React from 'react';
import PropTypes from 'prop-types';
import styles from './BrandRow.module.css';

export const BrandRow = ({ imageUrl, name, shareInfo, brandShareDataColor }) => {
  return (
    <div className={styles.row}>
      <img loading="lazy" src={imageUrl} alt={`${name} brand logo`} className={styles.brandImage} />
      <div className={styles.info}>
        <div className={styles.name}>{name}</div>
        <div className={styles.share} style={{ color: brandShareDataColor }}>
          {shareInfo}
        </div>
      </div>
    </div>
  );
};

BrandRow.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  shareInfo: PropTypes.string.isRequired,
  brandShareDataColor: PropTypes.string.isRequired
};
