import React from 'react';
import PropTypes from 'prop-types';
import { TestimonialCard } from './compound-components/TestimonialCard';
import styles from './TestimonialCardSection.module.css';

export const TestimonialCardSection = ({ sectionName, testimonialTitle, testimonialData }) => {
  return (
    <div className={styles.testimonialSection}>
      <div className={styles.sectionHeader}>
        <div className={styles.subheading}>{sectionName}</div>
        <div className={styles.heading}>{testimonialTitle}</div>
      </div>
      <div className={styles.testimonialGrid}>
        {testimonialData.map((testimonial) => (
          <TestimonialCard key={testimonial.name} {...testimonial} />
        ))}
      </div>
    </div>
  );
};

TestimonialCardSection.propTypes = {
  sectionName: PropTypes.string.isRequired,
  testimonialTitle: PropTypes.string.isRequired,
  testimonialData: PropTypes.array.isRequired
};
