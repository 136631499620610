import { downloadsAssets } from '../components/common/DownloadsAssets';
import { peopleSegmentAssets } from '../components/common/PeopleSegmentAssets.js';
import { assets } from '../components/common/images';

// Fram_PSGMT_2 : "Comfort Cravers",
// Fram_PSGMT_3 : "Loyal Traditionalists",
// Fram_PSGMT_5 : "Balanced Enjoyers",
// Fram_PSGMT_6 : "Diligent Discoverer",
// Fram_PSGMT_8 : "Carefree Fun Lovers",
// Fram_PSGMT_9 : "Proud Celebrators",
// Fram_PSGMT_11 : "Creative Explorers",
// Fram_PSGMT_12 : "Social Curators",

export const FAMILIAR_RESERVED_CARDS = [
  {
    micro: 'Fram_PSGMT_3',
    macro: 'Fram_PSGMT_1',
    img: peopleSegmentAssets.heroImages.Fram_PSGMT_3,
    text: 'people-segment.type.Fram_PSGMT_3',
    color: '#ADA1F8'
  },
  {
    micro: 'Fram_PSGMT_2',
    macro: 'Fram_PSGMT_1',
    img: peopleSegmentAssets.heroImages.Fram_PSGMT_2,
    text: 'people-segment.type.Fram_PSGMT_2',
    color: '#B983FF'
  }
];

export const FAMILIAR_OUTGOING_CARDS = [
  {
    micro: 'Fram_PSGMT_8',
    macro: 'Fram_PSGMT_7',
    img: peopleSegmentAssets.heroImages.Fram_PSGMT_8,
    text: 'people-segment.type.Fram_PSGMT_8',
    color: '#8FE8BD'
  },
  {
    micro: 'Fram_PSGMT_9',
    macro: 'Fram_PSGMT_7',
    img: peopleSegmentAssets.heroImages.Fram_PSGMT_9,
    text: 'people-segment.type.Fram_PSGMT_9',
    color: '#79D271'
  }
];

export const EXPLORATORY_RESERVED_CARDS = [
  {
    micro: 'Fram_PSGMT_5',
    macro: 'Fram_PSGMT_4',
    img: peopleSegmentAssets.heroImages.Fram_PSGMT_5,
    text: 'people-segment.type.Fram_PSGMT_5',
    color: '#ffc759'
  },
  {
    micro: 'Fram_PSGMT_6',
    macro: 'Fram_PSGMT_4',
    img: peopleSegmentAssets.heroImages.Fram_PSGMT_6,
    text: 'people-segment.type.Fram_PSGMT_6',
    color: '#ff8811'
  }
];

export const EXPLORATORY_OUTGOING_CARDS = [
  {
    micro: 'Fram_PSGMT_11',
    macro: 'Fram_PSGMT_10',
    img: peopleSegmentAssets.heroImages.Fram_PSGMT_11,
    text: 'people-segment.type.Fram_PSGMT_11',
    color: '#A1E8F8'
  },
  {
    micro: 'Fram_PSGMT_12',
    macro: 'Fram_PSGMT_10',
    img: peopleSegmentAssets.heroImages.Fram_PSGMT_12,
    text: 'people-segment.type.Fram_PSGMT_12',
    color: '#83BCFF'
  }
];

export const CHARTS_DATA1 = [
  {
    name: 'people-segment.hub.section3.barGraph.alcoholAttitudes.title',
    uv: 31,
    tooltip: 'people-segment.hub.section3.barGraph.alcoholAttitudes.example'
  },
  {
    name: 'people-segment.hub.section3.barGraph.shoppingAttitudes.title',
    uv: 20,
    tooltip: 'people-segment.hub.section3.barGraph.shoppingAttitudes.example'
  },
  {
    name: 'people-segment.hub.section3.barGraph.personalityTraits.title',
    uv: 15,
    tooltip: 'people-segment.hub.section3.barGraph.personalityTraits.example'
  },
  {
    name: 'people-segment.hub.section3.barGraph.lifestyleAttitudes.title',
    uv: 14,
    tooltip: 'people-segment.hub.section3.barGraph.lifestyleAttitudes.example'
  },
  {
    name: 'people-segment.hub.section3.barGraph.lifeValues.title',
    uv: 6,
    tooltip: 'people-segment.hub.section3.barGraph.lifeValues.example'
  }
];

export const CHARTS_DATA2 = [
  {
    name: 'people-segment.hub.section3.barGraph.age.title',
    uv: 6,
    tooltip: 'people-segment.hub.section3.barGraph.age.example'
  },
  {
    name: 'people-segment.hub.section3.barGraph.gender.title',
    uv: 5,
    tooltip: 'people-segment.hub.section3.barGraph.gender.example'
  },
  {
    name: 'people-segment.hub.section3.barGraph.hhIncome.title',
    uv: 1,
    tooltip: 'people-segment.hub.section3.barGraph.hhIncome.example'
  },
  {
    name: 'people-segment.hub.section3.barGraph.ethnicity.title',
    uv: 1,
    tooltip: 'people-segment.hub.section3.barGraph.ethnicity.example'
  }
];

export const FACTOR_CONTENTS = [
  {
    icon: 'fitness',
    title: 'people-segment.hub.section3.factorTitle1',
    content: 'people-segment.hub.section3.factorContent1'
  },
  {
    icon: 'wallet',
    title: 'people-segment.hub.section3.factorTitle2',
    content: 'people-segment.hub.section3.factorContent2'
  },
  {
    icon: 'military',
    title: 'people-segment.hub.section3.factorTitle3',
    content: 'people-segment.hub.section3.factorContent3'
  },
  {
    icon: 'psychology',
    title: 'people-segment.hub.section3.factorTitle4',
    content: 'people-segment.hub.section3.factorContent4'
  }
  // {
  //   icon: "loyalty",
  //   title: "people-segment.hub.section3.factorTitle5",
  //   content: "people-segment.hub.section3.factorContent5",
  // },
];

export const segmentColors = {
  Fram_PSGMT_2: '#B983FF',
  Fram_PSGMT_3: '#ADA1F8',
  Fram_PSGMT_5: '#8FE8BD',
  Fram_PSGMT_6: '#79D271',
  Fram_PSGMT_8: '#A1E8F8',
  Fram_PSGMT_9: '#83BCFF',
  Fram_PSGMT_11: '#F3B2D6',
  Fram_PSGMT_12: '#FF8C8C'
};

export const peopleDemandSpaceMapping = {
  Fram_PSGMT_2: ['Fram_DS_1', 'Fram_DS_2'],
  Fram_PSGMT_3: ['Fram_DS_1', 'Fram_DS_2'],
  Fram_PSGMT_5: ['Fram_DS_9', 'Fram_DS_10', 'Fram_DS_12', 'Fram_DS_13', 'Fram_DS_11'],
  Fram_PSGMT_6: ['Fram_DS_9', 'Fram_DS_10', 'Fram_DS_12', 'Fram_DS_13', 'Fram_DS_11'],
  Fram_PSGMT_8: ['Fram_DS_3', 'Fram_DS_4', 'Fram_DS_5', 'Fram_DS_12', 'Fram_DS_13', 'Fram_DS_6'],
  Fram_PSGMT_9: ['Fram_DS_3', 'Fram_DS_4', 'Fram_DS_5', 'Fram_DS_12', 'Fram_DS_13', 'Fram_DS_6'],
  Fram_PSGMT_11: ['Fram_DS_3', 'Fram_DS_4', 'Fram_DS_7', 'Fram_DS_12', 'Fram_DS_13', 'Fram_DS_8'],
  Fram_PSGMT_12: ['Fram_DS_3', 'Fram_DS_4', 'Fram_DS_7', 'Fram_DS_12', 'Fram_DS_13', 'Fram_DS_8']
};

export const PeopleSegmentMenuOptions = [
  {
    header: 'people-segment.macroPeopleSegment.Fram_PSGMT_1',
    listOfItems: [
      {
        itemText: 'people-segment.type.Fram_PSGMT_3',
        type: 'Fram_PSGMT_3',
        routeParams: {
          macro: 'Fram_PSGMT_1',
          micro: 'Fram_PSGMT_3'
        }
      },
      {
        itemText: 'people-segment.type.Fram_PSGMT_2',
        type: 'Fram_PSGMT_2',
        routeParams: {
          macro: 'Fram_PSGMT_1',
          micro: 'Fram_PSGMT_2'
        }
      }
    ]
  },
  {
    header: 'people-segment.macroPeopleSegment.Fram_PSGMT_7',
    listOfItems: [
      {
        itemText: 'people-segment.type.Fram_PSGMT_8',
        type: 'Fram_PSGMT_8',
        routeParams: {
          macro: 'Fram_PSGMT_7',
          micro: 'Fram_PSGMT_8'
        }
      },
      {
        itemText: 'people-segment.type.Fram_PSGMT_9',
        type: 'Fram_PSGMT_9',
        routeParams: {
          macro: 'Fram_PSGMT_7',
          micro: 'Fram_PSGMT_9'
        }
      }
    ]
  },
  {
    header: 'people-segment.macroPeopleSegment.Fram_PSGMT_10',
    listOfItems: [
      {
        itemText: 'people-segment.type.Fram_PSGMT_11',
        type: 'Fram_PSGMT_11',
        routeParams: {
          macro: 'Fram_PSGMT_10',
          micro: 'Fram_PSGMT_11'
        }
      },
      {
        itemText: 'people-segment.type.Fram_PSGMT_12',
        type: 'Fram_PSGMT_12',
        routeParams: {
          macro: 'Fram_PSGMT_10',
          micro: 'Fram_PSGMT_12'
        }
      }
    ]
  },
  {
    header: 'people-segment.macroPeopleSegment.Fram_PSGMT_4',
    listOfItems: [
      {
        itemText: 'people-segment.type.Fram_PSGMT_5',
        type: 'Fram_PSGMT_5',
        routeParams: {
          macro: 'Fram_PSGMT_4',
          micro: 'Fram_PSGMT_5'
        }
      },
      {
        itemText: 'people-segment.type.Fram_PSGMT_6',
        type: 'Fram_PSGMT_6',
        routeParams: {
          macro: 'Fram_PSGMT_4',
          micro: 'Fram_PSGMT_6'
        }
      }
    ]
  }
];

export const PeopleSegmentTooltipCardData = {
  page: 'peoplesegment',
  tooltipData: [
    {
      label: 'full-screen-experience-img',
      imgPath: assets.home.fullScreenExperienceImg,
      title: 'home.modal.fullScreenExperienceTitle',
      description: 'home.modal.fullScreenExperienceDescription'
    },
    {
      label: 'quick-link-img',
      imgPath: assets.tooltip.quickLinkImg,
      title: 'common.popupCard.quickLinkTitle',
      description: 'common.popupCard.quickLinkDescription'
    },
    {
      label: 'category-img',
      imgPath: assets.tooltip.categorySizingImg,
      title: 'common.popupCard.categorySizingTitle',
      description: 'common.popupCard.categorySizingDescription'
    },
    {
      label: 'keydrivers-img',
      imgPath: assets.tooltip.keyDriversImg,
      title: 'common.popupCard.keyDriversTitle',
      description: 'common.popupCard.keyDriversDescription'
    },
    {
      label: 'download-img',
      imgPath: assets.tooltip.downloadsImg,
      title: 'common.popupCard.downloadsTitle',
      description: 'common.popupCard.downloadsDescription'
    }
  ]
};

export const peopleSegmentPropensitytoExploreModularContent = {
  Fram_PSGMT_2: {
    blockCards: [
      {
        cardType: 'StatementCard',
        data: {
          statement:
            'people-segment.template.whatDrivesThem.Fram_PSGMT_2.propensityToExplore.modularCard1.motivationStatement'
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.propensityToExplore.modularCard2.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_2.propensityToExplore.modularCard2.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_2.CC_Mod_PropensityToExplore_2
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.propensityToExplore.modularCard3.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_2.propensityToExplore.modularCard3.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_2.CC_Mod_PropensityToExplore_3
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.propensityToExplore.modularCard4.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_2.propensityToExplore.modularCard4.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_2.CC_Mod_PropensityToExplore_4
          }
        }
      }
    ],
    carouselCards: [
      {
        cardtype: () => 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.propensityToExplore.modularCard5.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_2.propensityToExplore.modularCard5.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_2.CC_PropensityToExplore_5A_Video(),
            thumbnail: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_2.CC_PropensityToExplore_5A_Video_thumbnail()
          }
        }
      },
      {
        cardtype: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.propensityToExplore.modularCard6.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_2.propensityToExplore.modularCard6.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_2.CC_Mod_PropensityToExplore_5B
          }
        }
      },

      {
        cardtype: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.propensityToExplore.modularCard7.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_2.propensityToExplore.modularCard7.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_2.CC_Mod_PropensityToExplore_5C
          }
        }
      }
    ]
  },
  Fram_PSGMT_3: {
    blockCards: [
      {
        cardType: 'StatementCard',
        data: {
          statement:
            'people-segment.template.whatDrivesThem.Fram_PSGMT_3.propensityToExplore.modularCard1.motivationStatement'
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.propensityToExplore.modularCard2.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_3.propensityToExplore.modularCard2.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_3.LT_Mod_PropensityToExplore_2
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.propensityToExplore.modularCard3.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_3.propensityToExplore.modularCard3.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_3.LT_Mod_PropensityToExplore_3
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.propensityToExplore.modularCard4.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_3.propensityToExplore.modularCard4.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_3.LT_Mod_PropensityToExplore_4
          }
        }
      }
    ],
    carouselCards: [
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.propensityToExplore.modularCard5.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_3.propensityToExplore.modularCard5.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_3.LT_PropensityToExplore_5A_Video(),
            thumbnail: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_3.LT_PropensityToExplore_5A_Video_thumbnail()
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.propensityToExplore.modularCard6.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_3.propensityToExplore.modularCard6.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_3.LT_PropensityToExplore_5B_Video(),
            thumbnail: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_3.LT_PropensityToExplore_5B_Video_thumbnail()
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.propensityToExplore.modularCard7.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_3.propensityToExplore.modularCard7.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_3.LT_PropensityToExplore_5C_Video(),
            thumbnail: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_3.LT_PropensityToExplore_5C_Video_thumbnail()
          }
        }
      },
      {
        cardtype: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.propensityToExplore.modularCard8.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_3.propensityToExplore.modularCard8.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_3.LT_Mod_PropensityToExplore_5D
          }
        }
      },

      {
        cardtype: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.propensityToExplore.modularCard9.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_3.propensityToExplore.modularCard9.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_3.LT_Mod_PropensityToExplore_5E
          }
        }
      }
    ]
  },
  Fram_PSGMT_5: {
    blockCards: [
      {
        cardType: 'StatementCard',
        data: {
          statement:
            'people-segment.template.whatDrivesThem.Fram_PSGMT_5.propensityToExplore.modularCard1.motivationStatement'
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.propensityToExplore.modularCard2.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_5.propensityToExplore.modularCard2.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_5.BE_Mod_PropensityToExplore_2
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.propensityToExplore.modularCard3.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_5.propensityToExplore.modularCard3.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_5.BE_Mod_PropensityToExplore_3
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.propensityToExplore.modularCard4.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_5.propensityToExplore.modularCard4.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_5.BE_Mod_PropensityToExplore_4
          }
        }
      }
    ],
    carouselCards: [
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.propensityToExplore.modularCard5.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_5.propensityToExplore.modularCard5.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_5.BE_PropensityToExplore_5A_Video(),
            thumbnail: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_5.BE_PropensityToExplore_5A_Video_thumbnail()
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.propensityToExplore.modularCard6.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_5.propensityToExplore.modularCard6.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_5.BE_PropensityToExplore_5B_Video(),
            thumbnail: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_5.BE_PropensityToExplore_5B_Video_thumbnail()
          }
        }
      },
      {
        cardtype: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.propensityToExplore.modularCard7.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_5.propensityToExplore.modularCard7.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_5.BE_Mod_PropensityToExplore_5C
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.propensityToExplore.modularCard8.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_5.propensityToExplore.modularCard8.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_5.BE_PropensityToExplore_5D_Video(),
            thumbnail: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_5.BE_PropensityToExplore_5D_Video_thumbnail()
          }
        }
      }
    ]
  },
  Fram_PSGMT_6: {
    blockCards: [
      {
        cardType: 'StatementCard',
        data: {
          statement:
            'people-segment.template.whatDrivesThem.Fram_PSGMT_6.propensityToExplore.modularCard1.motivationStatement'
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.propensityToExplore.modularCard2.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_6.propensityToExplore.modularCard2.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_6.DD_Mod_PropensityToExplore_2
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.propensityToExplore.modularCard3.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_6.propensityToExplore.modularCard3.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_6.DD_Mod_PropensityToExplore_3
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.propensityToExplore.modularCard4.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_6.propensityToExplore.modularCard4.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_6.DD_Mod_PropensityToExplore_4
          }
        }
      }
    ],
    carouselCards: [
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.propensityToExplore.modularCard5.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_6.propensityToExplore.modularCard5.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_6.DD_PropensityToExplore_5A_Video(),
            thumbnail: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_6.DD_PropensityToExplore_5A_Video_thumbnail()
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.propensityToExplore.modularCard6.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_6.propensityToExplore.modularCard6.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_6.DD_PropensityToExplore_5B_Video(),
            thumbnail: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_6.DD_PropensityToExplore_5B_Video_thumbnail()
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.propensityToExplore.modularCard7.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_6.propensityToExplore.modularCard7.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_6.DD_PropensityToExplore_5C_Video(),
            thumbnail: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_6.DD_PropensityToExplore_5C_Video_thumbnail()
          }
        }
      },
      {
        cardtype: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.propensityToExplore.modularCard8.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_6.propensityToExplore.modularCard8.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_6.DD_Mod_PropensityToExplore_5D
          }
        }
      },

      {
        cardtype: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.propensityToExplore.modularCard9.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_6.propensityToExplore.modularCard9.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_6.DD_Mod_PropensityToExplore_5E
          }
        }
      }
    ]
  },
  Fram_PSGMT_8: {
    blockCards: [
      {
        cardType: 'StatementCard',
        data: {
          statement:
            'people-segment.template.whatDrivesThem.Fram_PSGMT_8.propensityToExplore.modularCard1.motivationStatement'
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.propensityToExplore.modularCard2.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_8.propensityToExplore.modularCard2.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_8.CFL_Mod_PropensityToExplore_2
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.propensityToExplore.modularCard3.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_8.propensityToExplore.modularCard3.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_8.CFL_Mod_PropensityToExplore_3
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.propensityToExplore.modularCard4.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_8.propensityToExplore.modularCard4.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_8.CFL_Mod_PropensityToExplore_4
          }
        }
      }
    ],
    carouselCards: [
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.propensityToExplore.modularCard5.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_8.propensityToExplore.modularCard5.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_8.CFL_PropensityToExplore_5A_Video(),
            thumbnail: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_8.CFL_PropensityToExplore_5A_Video_thumbnail()
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.propensityToExplore.modularCard6.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_8.propensityToExplore.modularCard6.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_8.CFL_PropensityToExplore_5B_Video,
            thumbnail: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_8.CFL_PropensityToExplore_5B_Video_thumbnail()
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.propensityToExplore.modularCard7.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_8.propensityToExplore.modularCard7.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_8.CFL_PropensityToExplore_5C_Video(),
            thumbnail: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_8.CFL_PropensityToExplore_5C_Video_thumbnail()
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.propensityToExplore.modularCard8.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_8.propensityToExplore.modularCard8.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_8.CFL_PropensityToExplore_5D_Video(),
            thumbnail: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_8.CFL_PropensityToExplore_5D_Video_thumbnail()
          }
        }
      },
      {
        cardtype: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.propensityToExplore.modularCard9.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_8.propensityToExplore.modularCard9.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_8.CFL_Mod_PropensityToExplore_5E
          }
        }
      }
    ]
  },
  Fram_PSGMT_9: {
    blockCards: [
      {
        cardType: 'StatementCard',
        data: {
          statement:
            'people-segment.template.whatDrivesThem.Fram_PSGMT_9.propensityToExplore.modularCard1.motivationStatement'
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.propensityToExplore.modularCard2.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_9.propensityToExplore.modularCard2.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_9.PC_Mod_PropensityToExplore_2
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.propensityToExplore.modularCard3.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_9.propensityToExplore.modularCard3.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_9.PC_Mod_PropensityToExplore_3
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.propensityToExplore.modularCard4.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_9.propensityToExplore.modularCard4.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_9.PC_Mod_PropensityToExplore_4
          }
        }
      }
    ],
    carouselCards: [
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.propensityToExplore.modularCard5.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_9.propensityToExplore.modularCard5.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_9.PC_PropensityToExplore_5A_Video(),
            thumbnail: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_9.PC_PropensityToExplore_5A_Video_thumbnail()
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.propensityToExplore.modularCard6.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_9.propensityToExplore.modularCard6.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_9.PC_PropensityToExplore_5B_Video(),
            thumbnail: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_9.PC_PropensityToExplore_5B_Video_thumbnail()
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.propensityToExplore.modularCard7.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_9.propensityToExplore.modularCard7.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_9.PC_PropensityToExplore_5C_Video(),
            thumbnail: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_9.PC_PropensityToExplore_5C_Video_thumbnail()
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.propensityToExplore.modularCard8.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_9.propensityToExplore.modularCard8.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_9.PC_PropensityToExplore_5D_Video(),
            thumbnail: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_9.PC_PropensityToExplore_5D_Video_thumbnail()
          }
        }
      },

      {
        cardtype: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.propensityToExplore.modularCard9.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_9.propensityToExplore.modularCard9.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_9.PC_Mod_PropensityToExplore_5E
          }
        }
      }
    ]
  },
  Fram_PSGMT_11: {
    blockCards: [
      {
        cardType: 'StatementCard',
        data: {
          statement:
            'people-segment.template.whatDrivesThem.Fram_PSGMT_11.propensityToExplore.modularCard1.motivationStatement'
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.propensityToExplore.modularCard2.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_11.propensityToExplore.modularCard2.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_11.CE_Mod_PropensityToExplore_2
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.propensityToExplore.modularCard3.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_11.propensityToExplore.modularCard3.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_11.CE_Mod_PropensityToExplore_3
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.propensityToExplore.modularCard4.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_11.propensityToExplore.modularCard4.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_11.CE_Mod_PropensityToExplore_4
          }
        }
      }
    ],
    carouselCards: [
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.propensityToExplore.modularCard5.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_11.propensityToExplore.modularCard5.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_11.CE_PropensityToExplore_5A_Video(),
            thumbnail: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_11.CE_PropensityToExplore_5A_Video_thumbnail()
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.propensityToExplore.modularCard6.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_11.propensityToExplore.modularCard6.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_11.CE_PropensityToExplore_5B_Video(),
            thumbnail: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_11.CE_PropensityToExplore_5B_Video_thumbnail()
          }
        }
      },
      {
        cardtype: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.propensityToExplore.modularCard7.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_11.propensityToExplore.modularCard7.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_11.CE_Mod_PropensityToExplore_5C
          }
        }
      },

      {
        cardtype: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.propensityToExplore.modularCard8.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_11.propensityToExplore.modularCard8.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_11.CE_Mod_PropensityToExplore_5D
          }
        }
      }
    ]
  },
  Fram_PSGMT_12: {
    blockCards: [
      {
        cardType: 'StatementCard',
        data: {
          statement:
            'people-segment.template.whatDrivesThem.Fram_PSGMT_12.propensityToExplore.modularCard1.motivationStatement'
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.propensityToExplore.modularCard2.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_12.propensityToExplore.modularCard2.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_12.SC_Mod_PropensityToExplore_2
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.propensityToExplore.modularCard3.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_12.propensityToExplore.modularCard3.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_12.SC_Mod_PropensityToExplore_3
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.propensityToExplore.modularCard4.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_12.propensityToExplore.modularCard4.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_12.SC_Mod_PropensityToExplore_4
          }
        }
      }
    ],
    carouselCards: [
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.propensityToExplore.modularCard5.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_12.propensityToExplore.modularCard5.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_12.SC_PropensityToExplore_5A_Video(),
            thumbnail: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_12.SC_PropensityToExplore_5A_Video_thumbnail()
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.propensityToExplore.modularCard6.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_12.propensityToExplore.modularCard6.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_12.SC_PropensityToExplore_5B_Video(),
            thumbnail: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_12.SC_PropensityToExplore_5B_Video_thumbnail()
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.propensityToExplore.modularCard7.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_12.propensityToExplore.modularCard7.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_12.SC_PropensityToExplore_5C_Video(),
            thumbnail: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_12.SC_PropensityToExplore_5C_Video_thumbnail()
          }
        }
      },
      {
        cardtype: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.propensityToExplore.modularCard8.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_12.propensityToExplore.modularCard8.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_12.SC_Mod_PropensityToExplore_5D
          }
        }
      },

      {
        cardtype: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.propensityToExplore.modularCard9.bigQuote',
            attribution:
              'people-segment.template.whatDrivesThem.Fram_PSGMT_12.propensityToExplore.modularCard9.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_12.SC_Mod_PropensityToExplore_5E
          }
        }
      }
    ]
  }
};

export const peopleSegmentSociabilityModularContent = {
  Fram_PSGMT_2: {
    blockCards: [
      {
        cardType: 'StatementCard',
        data: {
          statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.sociability.modularCard1.motivationStatement'
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.sociability.modularCard2.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.sociability.modularCard2.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_2.CC_Mod_Sociability_2
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.sociability.modularCard3.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.sociability.modularCard3.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_2.CC_Mod_Sociability_3
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.sociability.modularCard4.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.sociability.modularCard4.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_2.CC_Mod_Sociability_4
          }
        }
      }
    ],
    carouselCards: [
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.sociability.modularCard5.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.sociability.modularCard5.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_2.CC_Sociability_5A_Video
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.sociability.modularCard6.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.sociability.modularCard6.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_2.CC_Sociability_5B_Video
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.sociability.modularCard7.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.sociability.modularCard7.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_2.CC_Sociability_5C_Video
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.sociability.modularCard8.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.sociability.modularCard8.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_2.CC_Sociability_5D_Video
          }
        }
      },
      {
        cardtype: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.sociability.modularCard9.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.sociability.modularCard9.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_2.CC_Mod_Sociability_5E
          }
        }
      }
    ]
  },
  Fram_PSGMT_3: {
    blockCards: [
      {
        cardType: 'StatementCard',
        data: {
          statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.sociability.modularCard1.motivationStatement'
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.sociability.modularCard2.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.sociability.modularCard2.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_3.LT_Mod_Sociability_2
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.sociability.modularCard3.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.sociability.modularCard3.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_3.LT_Mod_Sociability_3
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.sociability.modularCard4.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.sociability.modularCard4.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_3.LT_Mod_Sociability_4
          }
        }
      }
    ],
    carouselCards: [
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.sociability.modularCard5.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.sociability.modularCard5.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_3.LT_Sociability_5A_Video
          }
        }
      },
      {
        cardtype: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.sociability.modularCard6.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.sociability.modularCard6.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_3.LT_Mod_Sociability_5B
          }
        }
      },
      {
        cardtype: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.sociability.modularCard7.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.sociability.modularCard7.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_3.LT_Mod_Sociability_5C
          }
        }
      },
      {
        cardtype: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.sociability.modularCard8.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.sociability.modularCard8.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_3.LT_Mod_Sociability_5D
          }
        }
      }
    ]
  },
  Fram_PSGMT_5: {
    blockCards: [
      {
        cardType: 'StatementCard',
        data: {
          statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.sociability.modularCard1.motivationStatement'
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.sociability.modularCard2.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.sociability.modularCard2.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_5.BE_Mod_Sociability_2
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.sociability.modularCard3.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.sociability.modularCard3.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_5.BE_Mod_Sociability_3
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.sociability.modularCard4.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.sociability.modularCard4.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_5.BE_Mod_Sociability_4
          }
        }
      }
    ],
    carouselCards: [
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.sociability.modularCard5.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.sociability.modularCard5.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_5.BE_Sociability_5A_Video()
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.sociability.modularCard6.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.sociability.modularCard6.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_5.BE_Sociability_5B_Video()
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.sociability.modularCard7.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.sociability.modularCard7.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_5.BE_Sociability_5C_Video()
          }
        }
      },
      {
        cardtype: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.sociability.modularCard8.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.sociability.modularCard8.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_5.BE_Mod_Sociability_5D
          }
        }
      }
    ]
  },
  Fram_PSGMT_6: {
    blockCards: [
      {
        cardType: 'StatementCard',
        data: {
          statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.sociability.modularCard1.motivationStatement'
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.sociability.modularCard2.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.sociability.modularCard2.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_6.DD_Mod_Sociability_2
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.sociability.modularCard3.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.sociability.modularCard3.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_6.DD_Mod_Sociability_3
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.sociability.modularCard4.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.sociability.modularCard4.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_6.DD_Mod_Sociability_4
          }
        }
      }
    ],
    carouselCards: [
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.sociability.modularCard5.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.sociability.modularCard5.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_6.DD_Sociability_5A_Video()
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.sociability.modularCard6.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.sociability.modularCard6.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_6.DD_Sociability_5B_Video()
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.sociability.modularCard7.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.sociability.modularCard7.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_6.DD_Sociability_5C_Video()
          }
        }
      },
      {
        cardtype: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.sociability.modularCard8.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.sociability.modularCard8.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_6.DD_Mod_Sociability_5D
          }
        }
      },
      {
        cardtype: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.sociability.modularCard9.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.sociability.modularCard9.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_6.DD_Mod_Sociability_5E
          }
        }
      }
    ]
  },
  Fram_PSGMT_8: {
    blockCards: [
      {
        cardType: 'StatementCard',
        data: {
          statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.sociability.modularCard1.motivationStatement'
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.sociability.modularCard2.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.sociability.modularCard2.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_8.CFL_Mod_Sociability_2
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.sociability.modularCard3.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.sociability.modularCard3.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_8.CFL_Mod_Sociability_3
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.sociability.modularCard4.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.sociability.modularCard4.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_8.CFL_Mod_Sociability_4
          }
        }
      }
    ],
    carouselCards: [
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.sociability.modularCard5.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.sociability.modularCard5.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_8.CFL_Sociability_5A_Video()
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.sociability.modularCard6.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.sociability.modularCard6.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_8.CFL_Sociability_5B_Video()
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.sociability.modularCard7.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.sociability.modularCard7.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_8.CFL_Sociability_5C_Video()
          }
        }
      },
      {
        cardtype: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.sociability.modularCard8.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.sociability.modularCard8.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_8.CFL_Mod_Sociability_5D
          }
        }
      },
      {
        cardtype: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.sociability.modularCard9.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.sociability.modularCard9.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_8.CFL_Mod_Sociability_5E
          }
        }
      }
    ]
  },
  Fram_PSGMT_9: {
    blockCards: [
      {
        cardType: 'StatementCard',
        data: {
          statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.sociability.modularCard1.motivationStatement'
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.sociability.modularCard2.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.sociability.modularCard2.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_9.PC_Mod_Sociability_2
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.sociability.modularCard3.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.sociability.modularCard3.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_9.PC_Mod_Sociability_3
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.sociability.modularCard4.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.sociability.modularCard4.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_9.PC_Mod_Sociability_4
          }
        }
      }
    ],
    carouselCards: [
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.sociability.modularCard5.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.sociability.modularCard5.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_9.PC_Sociability_5A_Video()
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.sociability.modularCard6.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.sociability.modularCard6.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_9.PC_Sociability_5B_Video()
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.sociability.modularCard7.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.sociability.modularCard7.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_9.PC_Sociability_5C_Video()
          }
        }
      },
      {
        cardtype: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.sociability.modularCard8.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.sociability.modularCard8.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_9.PC_Mod_Sociability_5D
          }
        }
      },
      {
        cardtype: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.sociability.modularCard9.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.sociability.modularCard9.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_9.PC_Mod_Sociability_5E
          }
        }
      }
    ]
  },
  Fram_PSGMT_11: {
    blockCards: [
      {
        cardType: 'StatementCard',
        data: {
          statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.sociability.modularCard1.motivationStatement'
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.sociability.modularCard2.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.sociability.modularCard2.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_11.CE_Mod_Sociability_2
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.sociability.modularCard3.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.sociability.modularCard3.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_11.CE_Mod_Sociability_3
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.sociability.modularCard4.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.sociability.modularCard4.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_11.CE_Mod_Sociability_4
          }
        }
      }
    ],
    carouselCards: [
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.sociability.modularCard5.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.sociability.modularCard5.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_11.CE_Sociability_5A_Video()
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.sociability.modularCard6.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.sociability.modularCard6.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_11.CE_Sociability_5B_Video()
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.sociability.modularCard7.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.sociability.modularCard7.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_11.CE_Sociability_5C_Video()
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.sociability.modularCard8.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.sociability.modularCard8.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_11.CE_Sociability_5D_Video()
          }
        }
      }
    ]
  },
  Fram_PSGMT_12: {
    blockCards: [
      {
        cardType: 'StatementCard',
        data: {
          statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.sociability.modularCard1.motivationStatement'
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.sociability.modularCard2.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.sociability.modularCard2.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_12.SC_Mod_Sociability_2
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.sociability.modularCard3.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.sociability.modularCard3.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_12.SC_Mod_Sociability_3
          }
        }
      },
      {
        cardType: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.sociability.modularCard4.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.sociability.modularCard4.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_12.SC_Mod_Sociability_4
          }
        }
      }
    ],
    carouselCards: [
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.sociability.modularCard5.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.sociability.modularCard5.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_12.SC_Sociability_5A_Video()
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.sociability.modularCard6.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.sociability.modularCard6.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_12.SC_Sociability_5B_Video()
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.sociability.modularCard7.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.sociability.modularCard7.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_12.SC_Sociability_5C_Video()
          }
        }
      },
      {
        cardtype: 'BigQuoteVideoRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.sociability.modularCard8.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.sociability.modularCard8.attribution'
          },
          videocarddata: {
            video: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_12.SC_Sociability_5D_Video()
          }
        }
      },
      {
        cardtype: 'BigQuoteImageRightCard',
        data: {
          quoteattributioncarddata: {
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.sociability.modularCard9.bigQuote',
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.sociability.modularCard9.attribution'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_12.SC_Mod_Sociability_5E
          }
        }
      }
    ]
  }
};

export const peopleSegmentFocusOnHealthModularContent = {
  Fram_PSGMT_2: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.focusOnHealth.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.focusOnHealth.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.focusOnHealth.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_2.CC_Mod_Health
          }
        }
      }
    ]
  },
  Fram_PSGMT_3: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.focusOnHealth.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.focusOnHealth.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.focusOnHealth.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_3.LT_Mod_Health
          }
        }
      }
    ]
  },
  Fram_PSGMT_5: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.focusOnHealth.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.focusOnHealth.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.focusOnHealth.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_5.BE_Mod_Health
          }
        }
      }
    ]
  },
  Fram_PSGMT_6: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.focusOnHealth.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.focusOnHealth.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.focusOnHealth.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_6.DD_Mod_Health
          }
        }
      }
    ]
  },
  Fram_PSGMT_8: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.focusOnHealth.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.focusOnHealth.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.focusOnHealth.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_8.CFL_Mod_Health
          }
        }
      }
    ]
  },
  Fram_PSGMT_9: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.focusOnHealth.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.focusOnHealth.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.focusOnHealth.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_9.PC_Mod_Health
          }
        }
      }
    ]
  },
  Fram_PSGMT_11: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.focusOnHealth.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.focusOnHealth.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.focusOnHealth.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_11.CE_Mod_Health
          }
        }
      }
    ]
  },
  Fram_PSGMT_12: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.focusOnHealth.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.focusOnHealth.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.focusOnHealth.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_12.SC_Mod_Health
          }
        }
      }
    ]
  }
};

export const peopleSegmentSpendingModularContent = {
  Fram_PSGMT_2: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.spending.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.spending.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.spending.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_2.CC_Mod_Spending
          }
        }
      }
    ]
  },
  Fram_PSGMT_3: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.spending.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.spending.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.spending.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_3.LT_Mod_Spending
          }
        }
      }
    ]
  },
  Fram_PSGMT_5: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.spending.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.spending.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.spending.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_5.BE_Mod_Spending
          }
        }
      }
    ]
  },
  Fram_PSGMT_6: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.spending.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.spending.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.spending.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_6.DD_Mod_Spending
          }
        }
      }
    ]
  },
  Fram_PSGMT_8: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.spending.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.spending.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.spending.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_8.CFL_Mod_Spending
          }
        }
      }
    ]
  },
  Fram_PSGMT_9: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.spending.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.spending.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.spending.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_9.PC_Mod_Spending
          }
        }
      }
    ]
  },
  Fram_PSGMT_11: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.spending.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.spending.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.spending.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_11.CE_Mod_Spending
          }
        }
      }
    ]
  },
  Fram_PSGMT_12: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.spending.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.spending.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.spending.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_12.SC_Mod_Spending
          }
        }
      }
    ]
  }
};

export const peopleSegmentStatusModularContent = {
  Fram_PSGMT_2: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.status.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.status.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.status.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_2.CC_Mod_Status
          }
        }
      }
    ]
  },
  Fram_PSGMT_3: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.status.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.status.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.status.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_3.LT_Mod_Status
          }
        }
      }
    ]
  },
  Fram_PSGMT_5: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.status.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.status.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.status.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_5.BE_Mod_Status
          }
        }
      }
    ]
  },
  Fram_PSGMT_6: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.status.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.status.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.status.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_6.DD_Mod_Status
          }
        }
      }
    ]
  },
  Fram_PSGMT_8: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.status.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.status.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.status.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_8.CFL_Mod_Status
          }
        }
      }
    ]
  },
  Fram_PSGMT_9: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.status.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.status.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.status.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_9.PC_Mod_Status
          }
        }
      }
    ]
  },
  Fram_PSGMT_11: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.status.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.status.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.status.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_11.CE_Mod_Status
          }
        }
      }
    ]
  },
  Fram_PSGMT_12: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.status.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.status.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.status.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_12.SC_Mod_Status
          }
        }
      }
    ]
  }
};

export const peopleSegmentOutlookModularContent = {
  Fram_PSGMT_2: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.outlook.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.outlook.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_2.outlook.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_2.CC_Mod_Outlook
          }
        }
      }
    ]
  },
  Fram_PSGMT_3: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.outlook.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.outlook.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_3.outlook.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_3.LT_Mod_Outlook
          }
        }
      }
    ]
  },
  Fram_PSGMT_5: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.outlook.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.outlook.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_5.outlook.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_5.BE_Mod_Outlook
          }
        }
      }
    ]
  },
  Fram_PSGMT_6: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.outlook.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.outlook.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_6.outlook.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_6.DD_Mod_Outlook
          }
        }
      }
    ]
  },
  Fram_PSGMT_8: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.outlook.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.outlook.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_8.outlook.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_8.CFL_Mod_Outlook
          }
        }
      }
    ]
  },
  Fram_PSGMT_9: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.outlook.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.outlook.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_9.outlook.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_9.PC_Mod_Outlook
          }
        }
      }
    ]
  },
  Fram_PSGMT_11: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.outlook.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.outlook.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_11.outlook.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_11.CE_Mod_Outlook
          }
        }
      }
    ]
  },
  Fram_PSGMT_12: {
    modularCard1: [
      {
        cardType: 'StatementQuoteImageCard',
        data: {
          bigStatement: {
            statement: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.outlook.cardStatement'
          },
          quoteattributioncarddata: {
            attribution: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.outlook.attribution',
            quote: 'people-segment.template.whatDrivesThem.Fram_PSGMT_12.outlook.quote'
          },
          imagecarddata: {
            image: peopleSegmentAssets.whatDrivesThem.Fram_PSGMT_12.SC_Mod_Outlook
          }
        }
      }
    ]
  }
};

export const peopleSegmentHubDownloadItems = [
  {
    title: 'common.downloadPeopleSegment',
    subtitle: 'common.downloadPeopleSegmentSubtitle',
    multipleFiles: true,
    fileToDownload: {
      pdf: downloadsAssets.commonDownloads.peopleSegmentsProfilePDF,
      ppt: downloadsAssets.commonDownloads.peopleSegmentsProfilePPT
    }
  },
  {
    title: 'common.downloadDataPack',
    subtitle: 'common.downloadDataPackPeople',
    fileToDownload: downloadsAssets.commonDownloads.peopleSegmentsDataPackSpreadsheet
  }
];

export const peopleSegmentTemplateDownloadItems = {
  Fram_PSGMT_2: [
    {
      title: 'common.downloads.peopleSegment.microProfile',
      subtitle: 'common.downloads.peopleSegment.microProfileSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.peopleSegmentTemplateDownloads.Fram_PSGMT_2.CC_Profile_PDF,
        ppt: downloadsAssets.peopleSegmentTemplateDownloads.Fram_PSGMT_2.CC_Profile_PPT
      }
    },
    {
      title: 'common.downloadPeopleSegment',
      subtitle: 'common.downloadPeopleSegmentSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.peopleSegmentTemplateDownloads.common.peopleSegmentsProfilePDF,
        ppt: downloadsAssets.peopleSegmentTemplateDownloads.common.peopleSegmentsProfilePPT
      }
    },
    {
      title: 'common.downloads.peopleSegment.fullDataSpreadsheet',
      subtitle: 'common.downloads.peopleSegment.fullDataSpreadsheetSubtitle',
      multipleFiles: false,
      fileToDownload: downloadsAssets.peopleSegmentTemplateDownloads.common.peopleSegmentDataSpreadsheet
    }
  ], //"Comfort Cravers",
  Fram_PSGMT_3: [
    {
      title: 'common.downloads.peopleSegment.microProfile',
      subtitle: 'common.downloads.peopleSegment.microProfileSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.peopleSegmentTemplateDownloads.Fram_PSGMT_3.LT_Profile_PDF,
        ppt: downloadsAssets.peopleSegmentTemplateDownloads.Fram_PSGMT_3.LT_Profile_PPT
      }
    },
    {
      title: 'common.downloadPeopleSegment',
      subtitle: 'common.downloadPeopleSegmentSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.peopleSegmentTemplateDownloads.common.peopleSegmentsProfilePDF,
        ppt: downloadsAssets.peopleSegmentTemplateDownloads.common.peopleSegmentsProfilePPT
      }
    },
    {
      title: 'common.downloads.peopleSegment.fullDataSpreadsheet',
      subtitle: 'common.downloads.peopleSegment.fullDataSpreadsheetSubtitle',
      multipleFiles: false,
      fileToDownload: downloadsAssets.peopleSegmentTemplateDownloads.common.peopleSegmentDataSpreadsheet
    }
  ], //"Loyal Traditionalists",
  Fram_PSGMT_5: [
    {
      title: 'common.downloads.peopleSegment.microProfile',
      subtitle: 'common.downloads.peopleSegment.microProfileSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.peopleSegmentTemplateDownloads.Fram_PSGMT_5.BE_Profile_PDF,
        ppt: downloadsAssets.peopleSegmentTemplateDownloads.Fram_PSGMT_5.BE_Profile_PPT
      }
    },
    {
      title: 'common.downloadPeopleSegment',
      subtitle: 'common.downloadPeopleSegmentSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.peopleSegmentTemplateDownloads.common.peopleSegmentsProfilePDF,
        ppt: downloadsAssets.peopleSegmentTemplateDownloads.common.peopleSegmentsProfilePPT
      }
    },
    {
      title: 'common.downloads.peopleSegment.fullDataSpreadsheet',
      subtitle: 'common.downloads.peopleSegment.fullDataSpreadsheetSubtitle',
      multipleFiles: false,
      fileToDownload: downloadsAssets.peopleSegmentTemplateDownloads.common.peopleSegmentDataSpreadsheet
    }
  ], //"Balanced Enjoyers",
  Fram_PSGMT_6: [
    {
      title: 'common.downloads.peopleSegment.microProfile',
      subtitle: 'common.downloads.peopleSegment.microProfileSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.peopleSegmentTemplateDownloads.Fram_PSGMT_6.DD_Profile_PDF,
        ppt: downloadsAssets.peopleSegmentTemplateDownloads.Fram_PSGMT_6.DD_Profile_PPT
      }
    },
    {
      title: 'common.downloadPeopleSegment',
      subtitle: 'common.downloadPeopleSegmentSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.peopleSegmentTemplateDownloads.common.peopleSegmentsProfilePDF,
        ppt: downloadsAssets.peopleSegmentTemplateDownloads.common.peopleSegmentsProfilePPT
      }
    },
    {
      title: 'common.downloads.peopleSegment.fullDataSpreadsheet',
      subtitle: 'common.downloads.peopleSegment.fullDataSpreadsheetSubtitle',
      multipleFiles: false,
      fileToDownload: downloadsAssets.peopleSegmentTemplateDownloads.common.peopleSegmentDataSpreadsheet
    }
  ], //"Diligent Discoverer",
  Fram_PSGMT_8: [
    {
      title: 'common.downloads.peopleSegment.microProfile',
      subtitle: 'common.downloads.peopleSegment.microProfileSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.peopleSegmentTemplateDownloads.Fram_PSGMT_8.CFL_Profile_PDF,
        ppt: downloadsAssets.peopleSegmentTemplateDownloads.Fram_PSGMT_8.CFL_Profile_PPT
      }
    },
    {
      title: 'common.downloadPeopleSegment',
      subtitle: 'common.downloadPeopleSegmentSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.peopleSegmentTemplateDownloads.common.peopleSegmentsProfilePDF,
        ppt: downloadsAssets.peopleSegmentTemplateDownloads.common.peopleSegmentsProfilePPT
      }
    },
    {
      title: 'common.downloads.peopleSegment.fullDataSpreadsheet',
      subtitle: 'common.downloads.peopleSegment.fullDataSpreadsheetSubtitle',
      multipleFiles: false,
      fileToDownload: downloadsAssets.peopleSegmentTemplateDownloads.common.peopleSegmentDataSpreadsheet
    }
  ], //"Carefree Fun Lovers",
  Fram_PSGMT_9: [
    {
      title: 'common.downloads.peopleSegment.microProfile',
      subtitle: 'common.downloads.peopleSegment.microProfileSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.peopleSegmentTemplateDownloads.Fram_PSGMT_9.PC_Profile_PDF,
        ppt: downloadsAssets.peopleSegmentTemplateDownloads.Fram_PSGMT_9.PC_Profile_PPT
      }
    },
    {
      title: 'common.downloadPeopleSegment',
      subtitle: 'common.downloadPeopleSegmentSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.peopleSegmentTemplateDownloads.common.peopleSegmentsProfilePDF,
        ppt: downloadsAssets.peopleSegmentTemplateDownloads.common.peopleSegmentsProfilePPT
      }
    },
    {
      title: 'common.downloads.peopleSegment.fullDataSpreadsheet',
      subtitle: 'common.downloads.peopleSegment.fullDataSpreadsheetSubtitle',
      multipleFiles: false,
      fileToDownload: downloadsAssets.peopleSegmentTemplateDownloads.common.peopleSegmentDataSpreadsheet
    }
  ], //"Proud Celebrators",
  Fram_PSGMT_11: [
    {
      title: 'common.downloads.peopleSegment.microProfile',
      subtitle: 'common.downloads.peopleSegment.microProfileSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.peopleSegmentTemplateDownloads.Fram_PSGMT_11.CE_Profile_PDF,
        ppt: downloadsAssets.peopleSegmentTemplateDownloads.Fram_PSGMT_11.CE_Profile_PPT
      }
    },
    {
      title: 'common.downloadPeopleSegment',
      subtitle: 'common.downloadPeopleSegmentSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.peopleSegmentTemplateDownloads.common.peopleSegmentsProfilePDF,
        ppt: downloadsAssets.peopleSegmentTemplateDownloads.common.peopleSegmentsProfilePPT
      }
    },
    {
      title: 'common.downloads.peopleSegment.fullDataSpreadsheet',
      subtitle: 'common.downloads.peopleSegment.fullDataSpreadsheetSubtitle',
      multipleFiles: false,
      fileToDownload: downloadsAssets.peopleSegmentTemplateDownloads.common.peopleSegmentDataSpreadsheet
    }
  ], //"Creative Explorers",
  Fram_PSGMT_12: [
    // {
    //   title: "people-segment.section8.comfortCraverHeroVideo",
    //   multipleFiles: false,
    //   fileToDownload: downloadsAssets.peopleSegmentTemplateDownloads.Fram_PSGMT_12.SC_HeroVideo
    // },
    {
      title: 'common.downloads.peopleSegment.microProfile',
      subtitle: 'common.downloads.peopleSegment.microProfileSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.peopleSegmentTemplateDownloads.Fram_PSGMT_12.SC_Profile_PDF,
        ppt: downloadsAssets.peopleSegmentTemplateDownloads.Fram_PSGMT_12.SC_Profile_PPT
      }
    },
    {
      title: 'common.downloadPeopleSegment',
      subtitle: 'common.downloadPeopleSegmentSubtitle',
      multipleFiles: true,
      fileToDownload: {
        pdf: downloadsAssets.peopleSegmentTemplateDownloads.common.peopleSegmentsProfilePDF,
        ppt: downloadsAssets.peopleSegmentTemplateDownloads.common.peopleSegmentsProfilePPT
      }
    },
    {
      title: 'common.downloads.peopleSegment.fullDataSpreadsheet',
      subtitle: 'common.downloads.peopleSegment.fullDataSpreadsheetSubtitle',
      multipleFiles: false,
      fileToDownload: downloadsAssets.peopleSegmentTemplateDownloads.common.peopleSegmentDataSpreadsheet
    }
  ] //"Social Curators",
};
