import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import styles from './NoItemCard.module.css';

export const NoItemCard = () => {
  const { t } = useTranslation(i18next.language);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>{t('tripTypesPageTemplate.basketComposition.noItemCard')}</div>
    </div>
  );
};
