//Common download sheets
const getSasKey = () => localStorage.getItem('sasKey') || '';
//An overview of the Demand Landscape as a whole
// const demandLandscapeIntroDeck = `${window.env.REACT_APP_AZURE_STORAGE_URL}/ExcelExport/DemandLandscapeIntro.pdf?${getSasKey()}`;

// //A guide to all People Segments
// const peopleSegmentsProfilePDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/PeopleSegments.pdf?${getSasKey()}`;

// const peopleSegmentsProfilePPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/PeopleSegments.ppt?${getSasKey()}`;

// //A guide to all Occasions
// const occasionsProfilePDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/OccasionsFramework.pdf?${getSasKey()}`;

// const occasionProfilePPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/OccasionsFramework.ppt?${getSasKey()}`;

// //A guide to all Demand Spaces
// const demandSpaceProfilesPDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/DemandLandscape.pdf?${getSasKey()}`;

// const demandSpaceProfilesPPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/DemandLandscape.ppt?${getSasKey()}`;

// //Full data set for total Demand Landscape
// const DataPackSpreadsheet = `${window.env.REACT_APP_AZURE_STORAGE_URL}/ExcelExport/DL_OCG_Data Pack.xlsx?${getSasKey()}`;

// //Full data set for People Segments
// const peopleSegmentDataSpreadsheet = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/PeopleSegments_DataPack.xlsx?${getSasKey()}`;

// //Full data set for Demand Spaces
// const demandspaceTemplateDataSpreadsheet = `${window.env.REACT_APP_AZURE_STORAGE_URL}/ExcelExport/DL_OCG_Data Pack.xlsx?${getSasKey()}`;

// //Full data set for Occasions
// const occasionsDataSpreadsheet = `${window.env.REACT_APP_AZURE_STORAGE_URL}/ExcelExport/DL_OCG_Data Pack.xlsx?${getSasKey()}`;

// // People Segment Downloads
// //Comfort Cravers
// // const CC_HeroVideo = `${//   window.env.REACT_APP_AZURE_STORAGE_URL// }/ExcelExport/PeopleSegments.xlsx?${getSasKey()}`;

// const CC_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/ComfortCravers.pdf?${getSasKey()}`;

// const CC_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/ComfortCravers.ppt?${getSasKey()}`;

// //Loyal Traditionalists
// // const LT_HeroVideo = `${//   window.env.REACT_APP_AZURE_STORAGE_URL// }/ExcelExport/PeopleSegments.xlsx?${getSasKey()}`;

// const LT_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/LoyalTradionalists.pdf?${getSasKey()}`;

// const LT_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/LoyalTradionalists.ppt?${getSasKey()}`;

// //Balanced Enjoyers
// // const BE_HeroVideo = `${//   window.env.REACT_APP_AZURE_STORAGE_URL// }/ExcelExport/PeopleSegments.xlsx?${getSasKey()}`;

// const BE_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/BalancedEnjoyers.pdf?${getSasKey()}`;

// const BE_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/BalancedEnjoyers.ppt?${getSasKey()}`;

// //Diligent Discoverers
// // const DD_HeroVideo = `${//   window.env.REACT_APP_AZURE_STORAGE_URL// }/ExcelExport/PeopleSegments.xlsx?${getSasKey()}`;

// const DD_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/DiligentDiscoverers.pdf?${getSasKey()}`;

// const DD_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/DiligentDiscoverers.ppt?${getSasKey()}`;

// //Care Free Fun-Lovers
// // const CFL_HeroVideo = `${//   window.env.REACT_APP_AZURE_STORAGE_URL// }/ExcelExport/PeopleSegments.xlsx?${getSasKey()}`;

// const CFL_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/CarefreeFunLovers.pdf?${getSasKey()}`;

// const CFL_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/CarefreeFunLovers.ppt?${getSasKey()}`;

// //Proud Celebrators
// // const PC_HeroVideo = `${//   window.env.REACT_APP_AZURE_STORAGE_URL// }/ExcelExport/PeopleSegments.xlsx?${getSasKey()}`;

// const PC_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/ProudCelebrators.pdf?${getSasKey()}`;

// const PC_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/ProudCelebrators.ppt?${getSasKey()}`;

// //Creative Explorers
// // const CE_HeroVideo = `${//   window.env.REACT_APP_AZURE_STORAGE_URL// }/ExcelExport/PeopleSegments.xlsx?${getSasKey()}`;

// const CE_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/CreativeExplorers.pdf?${getSasKey()}`;

// const CE_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/CreativeExplorers.ppt?${getSasKey()}`;

// //Social Curators
// // const SC_HeroVideo = `${//   window.env.REACT_APP_AZURE_STORAGE_URL// }/ExcelExport/PeopleSegments.xlsx?${getSasKey()}`;

// const SC_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/SocialCurators.pdf?${getSasKey()}`;

// const SC_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/SocialCurators.ppt?${getSasKey()}`;

// // Demand Space Template Downloads

// //Casual Good Times
// // const CGT_HeroVideo = `${//   window.env.REACT_APP_AZURE_STORAGE_URL// }/ExcelExport/PeopleSegments.xlsx?${getSasKey()}`;

// const CGT_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/CasualGoodTimes.pdf?${getSasKey()}`;

// const CGT_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/CasualGoodTimes.ppt?${getSasKey()}`;

// //Easy Going Hangout
// // const EGH_HeroVideo = `${//   window.env.REACT_APP_AZURE_STORAGE_URL// }/ExcelExport/PeopleSegments.xlsx?${getSasKey()}`;

// const EGH_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/EasyGoingHangout.pdf?${getSasKey()}`;

// const EGH_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/EasyGoingHangout.ppt?${getSasKey()}`;

// //Routine Living
// // const RL_HeroVideo = `${//   window.env.REACT_APP_AZURE_STORAGE_URL// }/ExcelExport/PeopleSegments.xlsx?${getSasKey()}`;

// const RL_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/RoutineLiving.pdf?${getSasKey()}`;

// const RL_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/RoutineLiving.ppt?${getSasKey()}`;

// //Energetic Night Out
// // const ENO_HeroVideo = `${//   window.env.REACT_APP_AZURE_STORAGE_URL// }/ExcelExport/PeopleSegments.xlsx?${getSasKey()}`;

// const ENO_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/EnergeticNightOut.pdf?${getSasKey()}`;

// const ENO_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/EnergeticNightOut.ppt?${getSasKey()}`;

// //Upbeat Social Meal
// // const USM_HeroVideo = `${//   window.env.REACT_APP_AZURE_STORAGE_URL// }/ExcelExport/PeopleSegments.xlsx?${getSasKey()}`;

// const USM_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/UpbeatSocialMeal.pdf?${getSasKey()}`;

// const USM_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/UpbeatSocialMeal.ppt?${getSasKey()}`;

// //Simple Unplug
// // const SU_HeroVideo = `${//   window.env.REACT_APP_AZURE_STORAGE_URL// }/ExcelExport/PeopleSegments.xlsx?${getSasKey()}`;

// const SU_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/SimpleUnplug.pdf?${getSasKey()}`;

// const SU_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/SimpleUnplug.ppt?${getSasKey()}`;

// //Lively Social Gathering
// // const LSG_HeroVideo = `${//   window.env.REACT_APP_AZURE_STORAGE_URL// }/ExcelExport/PeopleSegments.xlsx?${getSasKey()}`;

// const LSG_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/LivelySocialGathering.pdf?${getSasKey()}`;

// const LSG_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/LivelySocialGathering.ppt?${getSasKey()}`;

// //Intentional Unwind
// // const IU_HeroVideo = `${//   window.env.REACT_APP_AZURE_STORAGE_URL// }/ExcelExport/PeopleSegments.xlsx?${getSasKey()}`;

// const IU_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/IntentionalUnwind.pdf?${getSasKey()}`;

// const IU_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/IntentionalUnwind.ppt?${getSasKey()}`;

// //Refined Weekend Out
// // const RWO_HeroVideo = `${//   window.env.REACT_APP_AZURE_STORAGE_URL// }/ExcelExport/PeopleSegments.xlsx?${getSasKey()}`;

// const RWO_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/RefinedWeekendOut.pdf?${getSasKey()}`;

// const RWO_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/RefinedWeekendOut.ppt?${getSasKey()}`;

// //Thoughtful Connection
// // const TC_HeroVideo = `${//   window.env.REACT_APP_AZURE_STORAGE_URL// }/ExcelExport/PeopleSegments.xlsx?${getSasKey()}`;

// const TC_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/ThoughtfulConnection.pdf?${getSasKey()}`;

// const TC_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/ThoughtfulConnection.ppt?${getSasKey()}`;

// //Sensible Slowdown
// // const SS_HeroVideo = `${//   window.env.REACT_APP_AZURE_STORAGE_URL// }/ExcelExport/PeopleSegments.xlsx?${getSasKey()}`;

// const SS_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/SensibleSlowdown.pdf?${getSasKey()}`;

// const SS_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/SensibleSlowdown.ppt?${getSasKey()}`;

// //Intimate Dining In
// // const IDI_HeroVideo = `${//   window.env.REACT_APP_AZURE_STORAGE_URL// }/ExcelExport/PeopleSegments.xlsx?${getSasKey()}`;

// const IDI_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/IntimateDiningIn.pdf?${getSasKey()}`;

// const IDI_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/IntimateDiningIn.ppt?${getSasKey()}`;

// //Everyday Meal
// // const EM_HeroVideo = `${//   window.env.REACT_APP_AZURE_STORAGE_URL// }/ExcelExport/PeopleSegments.xlsx?${getSasKey()}`;

// const EM_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/EverydayMeal.pdf?${getSasKey()}`;

// const EM_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/EverydayMeal.ppt?${getSasKey()}`;

// //Occasions Template Downloads

// //Big Weekend Out

// const BWO_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/BigWeekendOut.pdf?${getSasKey()}`;

// const BWO_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/BigWeekendOut.pptx?${getSasKey()}`;

// //Lively Meal Out

// const LMO_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/LivelyMealOut.pdf?${getSasKey()}`;

// const LMO_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/LivelyMealOut.pptx?${getSasKey()}`;

// //Weekend Get-Together

// const WGT_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/WeekendGetTogether.pdf?${getSasKey()}`;

// const WGT_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/WeekendGetTogether.pptx?${getSasKey()}`;

// //Weekday Hang

// const WH_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/WeekdayHang.pdf?${getSasKey()}`;

// const WH_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/WeekdayHang.pptx?${getSasKey()}`;

// //Hosted Meal

// const HM_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/HostedMeal.pdf?${getSasKey()}`;

// const HM_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/HostedMeal.pptx?${getSasKey()}`;

// //Weekend Dining In

// const WDI_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/WeekendDiningIn.pdf?${getSasKey()}`;

// const WDI_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/WeekendDiningIn.pptx?${getSasKey()}`;

// //Weekday Meal

// const WM_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/WeekdayMeal.pdf?${getSasKey()}`;

// const WM_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/WeekdayMeal.pptx?${getSasKey()}`;

// //TV and Dinner

// const TVD_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/TVDinner.pdf?${getSasKey()}`;

// const TVD_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/TVDinner.pptx?${getSasKey()}`;

// //Unwinding Together

// const UT_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/UnwindingTogether.pdf?${getSasKey()}`;

// const UT_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/UnwindingTogether.pptx?${getSasKey()}`;

// //Me Time

// const MT_Profile_PDF = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/MeTime.pdf?${getSasKey()}`;

// const MT_Profile_PPT = `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/MeTime.pptx?${getSasKey()}`;

export const downloadsAssets = {
  commonDownloads: {
    demandLandscapeIntroDeck: () =>
      `${window.env.REACT_APP_AZURE_STORAGE_URL}/ExcelExport/DemandLandscapeIntro.pdf?${getSasKey()}`,
    peopleSegmentsDataPackSpreadsheet: () =>
      `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/PeopleSegments_DataPack.xlsx?${getSasKey()}`,
    demandSpaceDataPackSpreadsheet: () =>
      `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/DemandSpaces_DataPack.xlsx?${getSasKey()}`,
    occasionDataPackSpreadsheet: () =>
      `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/Occasions_DataPack.xlsx?${getSasKey()}`,
    DataPackSpreadsheet: () =>
      `${window.env.REACT_APP_AZURE_STORAGE_URL}/ExcelExport/DL_OCG_Data Pack.xlsx?${getSasKey()}`,
    peopleSegmentsProfilePDF: () =>
      `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/PeopleSegments.pdf?${getSasKey()}`,
    peopleSegmentsProfilePPT: () =>
      `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/PeopleSegments.ppt?${getSasKey()}`,
    demandSpaceProfilesPDF: () =>
      `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/DemandLandscape.pdf?${getSasKey()}`,
    demandSpaceProfilesPPT: () =>
      `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/DemandLandscape.ppt?${getSasKey()}`,
    occasionProfilePPT: () =>
      `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/OccasionsFramework.ppt?${getSasKey()}`,
    occasionsProfilePDF: () =>
      `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/OccasionsFramework.pdf?${getSasKey()}`
  },
  peopleSegmentTemplateDownloads: {
    common: {
      peopleSegmentsProfilePDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/PeopleSegments.pdf?${getSasKey()}`,
      peopleSegmentsProfilePPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/PeopleSegments.ppt?${getSasKey()}`,
      peopleSegmentDataSpreadsheet: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/PeopleSegments_DataPack.xlsx?${getSasKey()}`
    },
    Fram_PSGMT_2: {
      //CC_HeroVideo: CC_HeroVideo,
      CC_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/ComfortCravers.pdf?${getSasKey()}`,
      CC_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/ComfortCravers.ppt?${getSasKey()}`
    },
    Fram_PSGMT_3: {
      //LT_HeroVideo: LT_HeroVideo,
      LT_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/LoyalTradionalists.pdf?${getSasKey()}`,
      LT_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/LoyalTradionalists.ppt?${getSasKey()}`
    },
    Fram_PSGMT_5: {
      //BE_HeroVideo: BE_HeroVideo,
      BE_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/BalancedEnjoyers.pdf?${getSasKey()}`,
      BE_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/BalancedEnjoyers.ppt?${getSasKey()}`
    },
    Fram_PSGMT_6: {
      //DD_HeroVideo: DD_HeroVideo,
      DD_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/DiligentDiscoverers.pdf?${getSasKey()}`,
      DD_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/DiligentDiscoverers.ppt?${getSasKey()}`
    },
    Fram_PSGMT_8: {
      //CFL_HeroVideo: CFL_HeroVideo,
      CFL_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/CarefreeFunLovers.pdf?${getSasKey()}`,
      CFL_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/CarefreeFunLovers.ppt?${getSasKey()}`
    },
    Fram_PSGMT_9: {
      //PC_HeroVideo: PC_HeroVideo,
      PC_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/ProudCelebrators.pdf?${getSasKey()}`,
      PC_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/ProudCelebrators.ppt?${getSasKey()}`
    },
    Fram_PSGMT_11: {
      //CE_HeroVideo: CE_HeroVideo,
      CE_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/CreativeExplorers.pdf?${getSasKey()}`,
      CE_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/CreativeExplorers.ppt?${getSasKey()}`
    },
    Fram_PSGMT_12: {
      //SC_HeroVideo: SC_HeroVideo,
      SC_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/SocialCurators.pdf?${getSasKey()}`,
      SC_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/people-segment/SocialCurators.ppt?${getSasKey()}`
    }
  },
  demandSpaceTemplateDownloads: {
    common: {
      demandSpaceProfilesPDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/DemandLandscape.pdf?${getSasKey()}`,
      demandSpaceProfilesPPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/DemandLandscape.ppt?${getSasKey()}`,
      demandspaceTemplateDataSpreadsheet: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/DemandSpaces_DataPack.xlsx?${getSasKey()}`
    },
    Fram_DS_1: {
      //EGH_HeroVideo: EGH_HeroVideo,
      EGH_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/EasyGoingHangout.pdf?${getSasKey()}`,
      EGH_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/EasyGoingHangout.ppt?${getSasKey()}`
    },
    Fram_DS_2: {
      //RL_HeroVideo: RL_HeroVideo,
      RL_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/RoutineLiving.pdf?${getSasKey()}`,
      RL_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/RoutineLiving.ppt?${getSasKey()}`
    },
    Fram_DS_3: {
      //ENO_HeroVideo: ENO_HeroVideo,
      ENO_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/EnergeticNightOut.pdf?${getSasKey()}`,
      ENO_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/EnergeticNightOut.ppt?${getSasKey()}`
    },
    Fram_DS_4: {
      //USM_HeroVideo: USM_HeroVideo,
      USM_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/UpbeatSocialMeal.pdf?${getSasKey()}`,
      USM_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/UpbeatSocialMeal.ppt?${getSasKey()}`
    },
    Fram_DS_5: {
      //CGT_HeroVideo: CGT_HeroVideo,
      CGT_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/CasualGoodTimes.pdf?${getSasKey()}`,
      CGT_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/CasualGoodTimes.ppt?${getSasKey()}`
    },
    Fram_DS_6: {
      //SU_HeroVideo: SU_HeroVideo,
      SU_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/SimpleUnplug.pdf?${getSasKey()}`,
      SU_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/SimpleUnplug.ppt?${getSasKey()}`
    },
    Fram_DS_7: {
      //LSG_HeroVideo: LSG_HeroVideo,
      LSG_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/LivelySocialGathering.pdf?${getSasKey()}`,
      LSG_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/LivelySocialGathering.ppt?${getSasKey()}`
    },
    Fram_DS_8: {
      //IU_HeroVideo: IU_HeroVideo,
      IU_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/IntentionalUnwind.pdf?${getSasKey()}`,
      IU_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/IntentionalUnwind.ppt?${getSasKey()}`
    },
    Fram_DS_9: {
      //RWO_HeroVideo: RWO_HeroVideo,
      RWO_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/RefinedWeekendOut.pdf?${getSasKey()}`,
      RWO_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/RefinedWeekendOut.ppt?${getSasKey()}`
    },
    Fram_DS_10: {
      //TC_HeroVideo: TC_HeroVideo,
      TC_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/ThoughtfulConnection.pdf?${getSasKey()}`,
      TC_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/ThoughtfulConnection.ppt?${getSasKey()}`
    },
    Fram_DS_11: {
      //SS_HeroVideo: SS_HeroVideo,
      SS_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/SensibleSlowdown.pdf?${getSasKey()}`,
      SS_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/SensibleSlowdown.ppt?${getSasKey()}`
    },
    Fram_DS_12: {
      //IDI_HeroVideo: IDI_HeroVideo,
      IDI_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/IntimateDiningIn.pdf?${getSasKey()}`,
      IDI_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/IntimateDiningIn.ppt?${getSasKey()}`
    },
    Fram_DS_13: {
      //EM_HeroVideo: EM_HeroVideo,
      EM_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/EverydayMeal.pdf?${getSasKey()}`,
      EM_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/demandspace/EverydayMeal.ppt?${getSasKey()}`
    }
  },
  occasionTemplateDownloads: {
    common: {
      occasionProfilePPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/OccasionsFramework.ppt?${getSasKey()}`,
      occasionsProfilePDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/OccasionsFramework.pdf?${getSasKey()}`,
      occasionsDataSpreadsheet: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/Occasions_DataPack.xlsx?${getSasKey()}`
    },
    Fram_OCCAS_2: {
      BWO_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/BigWeekendOut.pdf?${getSasKey()}`,
      BWO_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/BigWeekendOut.pptx?${getSasKey()}`
    },
    Fram_OCCAS_3: {
      LMO_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/LivelyMealOut.pdf?${getSasKey()}`,
      LMO_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/LivelyMealOut.pptx?${getSasKey()}`
    },
    Fram_OCCAS_5: {
      WGT_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/WeekendGetTogether.pdf?${getSasKey()}`,
      WGT_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/WeekendGetTogether.pptx?${getSasKey()}`
    },
    Fram_OCCAS_6: {
      WH_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/WeekdayHang.pdf?${getSasKey()}`,
      WH_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/WeekdayHang.pptx?${getSasKey()}`
    },
    Fram_OCCAS_7: {
      HM_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/HostedMeal.pdf?${getSasKey()}`,
      HM_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/HostedMeal.pptx?${getSasKey()}`
    },
    Fram_OCCAS_9: {
      WDI_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/WeekendDiningIn.pdf?${getSasKey()}`,
      WDI_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/WeekendDiningIn.pptx?${getSasKey()}`
    },
    Fram_OCCAS_10: {
      WM_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/WeekdayMeal.pdf?${getSasKey()}`,
      WM_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/WeekdayMeal.pptx?${getSasKey()}`
    },
    Fram_OCCAS_12: {
      TVD_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/TVDinner.pdf?${getSasKey()}`,
      TVD_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/TVDinner.pptx?${getSasKey()}`
    },
    Fram_OCCAS_13: {
      UT_Profile_PDF: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/UnwindingTogether.pdf?${getSasKey()}`,
      UT_Profile_PPT: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/UnwindingTogether.pptx?${getSasKey()}`
    },
    Fram_OCCAS_14: {
      MT_Profile_PDF: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/MeTime.pdf?${getSasKey()}`,
      MT_Profile_PPT: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/Downloads/occasions/MeTime.pptx?${getSasKey()}`
    }
  },
  //IndividualFiles
  contextualFactorsData: {
    DataPackSpreadsheet: `${window.env.REACT_APP_AZURE_STORAGE_URL}/ExcelExport/DL_OCG_Data Pack.xlsx?${getSasKey()}`
  },
  consumptionData: {
    DataPackSpreadsheet: `${window.env.REACT_APP_AZURE_STORAGE_URL}/ExcelExport/DL_OCG_Data Pack.xlsx?${getSasKey()}`
  },
  driverAndNeedsData: {
    DataPackSpreadsheet: `${window.env.REACT_APP_AZURE_STORAGE_URL}/ExcelExport/DL_OCG_Data Pack.xlsx?${getSasKey()}`
  },
  demographicTableData: {
    DataPackSpreadsheet: `${window.env.REACT_APP_AZURE_STORAGE_URL}/ExcelExport/DL_OCG_Data Pack.xlsx?${getSasKey()}`
  }
};
