import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import i18next from 'i18next';
import CustomSlider from '../../custom/Slider/CustomSlider';

const classMapping = {
  Fram_PSGMT_2: 'comfort-craver',
  Fram_PSGMT_3: 'loyal-traditionalist',
  Fram_PSGMT_5: 'mature-discoverer',
  Fram_PSGMT_6: 'diligent-discoverer',
  Fram_PSGMT_8: 'carefree-fun-lover',
  Fram_PSGMT_9: 'proud-celebrator',
  Fram_PSGMT_11: 'creative-explorer',
  Fram_PSGMT_12: 'social-curator'
};

const Modal = ({ onClose, data, microSegment, macroSegment, microMappingData }) => {
  const { t } = useTranslation(i18next.language);
  const [isVisible, setIsVisible] = useState(true);
  const [activeTab, setActiveTab] = useState('sharedTraits');
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const handleClose = () => {
    setIsVisible(false);
    onClose();
  };

  return (
    isVisible && (
      <Dialog
        open={isVisible}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        PaperProps={{ sx: { borderRadius: '16px' } }}
        className="compare-persona-modal"
      >
        <DialogContent className="cp-modal-content">
          <Box className="scrollable-main">
            <Box className="cp-modal-main">
              <Box className="text-contents">
                <Typography className="header-text">
                  <Trans
                    components={{
                      colored: <span className={`${macroSegment}`} />
                    }}
                  >
                    {t('people-segment.template.intro.compare-personas.header', {
                      macro: t(`people-segment.macroPeopleSegment.${macroSegment}`)
                    })}
                  </Trans>
                </Typography>
                <Typography className="header-description">
                  <Trans
                    components={{
                      color1: <span className={`${classMapping[microMappingData[0]]}`} />,
                      color2: <span className={`${classMapping[microMappingData[1]]}`} />
                    }}
                  >
                    {t(`people-segment.template.intro.${microSegment}.modal-desc`)}
                  </Trans>
                </Typography>
              </Box>
              <Box className="tabs-section">
                <p
                  className={`tab ${activeTab === 'sharedTraits' ? 'tab-selected' : 'tab-unselected'}`}
                  onClick={() => handleTabClick('sharedTraits')}
                >
                  {t(`people-segment.template.intro.compare-personas.shared-traits`)}
                </p>
                <p
                  className={`tab ${activeTab === 'compareDrivers' ? 'tab-selected' : 'tab-unselected'}`}
                  onClick={() => handleTabClick('compareDrivers')}
                >
                  {t(`people-segment.template.intro.compare-personas.compare-drivers`)}
                </p>
                <p
                  className={`tab ${activeTab === 'compareValues' ? 'tab-selected' : 'tab-unselected'}`}
                  onClick={() => handleTabClick('compareValues')}
                >
                  {t(`people-segment.template.intro.compare-personas.compare-values`)}
                </p>
                <p
                  className={`tab ${activeTab === 'examples' ? 'tab-selected' : 'tab-unselected'}`}
                  onClick={() => handleTabClick('examples')}
                >
                  {t(`people-segment.template.intro.compare-personas.examples`)}
                </p>
              </Box>
            </Box>
            <TabContent
              data={data}
              microSegment={microSegment}
              macroSegment={macroSegment}
              activeTab={activeTab}
              microMappingData={microMappingData}
            />
          </Box>
          <Box className="close-bg">
            <Button className="close" onClick={handleClose}>
              <p className="close-text">{t(`people-segment.template.intro.compare-personas.close`)}</p>
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    )
  );
};

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  microSegment: PropTypes.string.isRequired,
  macroSegment: PropTypes.string.isRequired,
  microMappingData: PropTypes.array.isRequired
};

const TabContent = ({ activeTab, data, microSegment, macroSegment, microMappingData }) => {
  const { t } = useTranslation(i18next.language);
  const sharedtraitsData = t(`people-segment.template.intro.compare-personas.sharedTraits.${macroSegment}`, {
    returnObjects: true
  });

  if (activeTab === 'sharedTraits') {
    return (
      <Box className="tab-content">
        <Box className="shared-traits-modal-div">
          {/* <Typography className="shared-traits-text">
            {t("demandspace.template.thePeople.sharedTraits.heading")}
          </Typography> */}
          <Grid container className="shared-traits-div">
            {/* {console.log(sharedtraitsData)} */}
            {sharedtraitsData?.map(
              (item, index) =>
                item?.titleItems?.length > 0 && (
                  <Grid
                    item
                    xs={sharedtraitsData.length > 1 ? 11.5 / sharedtraitsData.length : 6}
                    key={index}
                    className="shared-traits-title-div"
                  >
                    <Box className="shared-traits-title-container">
                      <Divider className="shared-divider-line" />
                      <Divider className="shared-traits-typo" textAlign="center">
                        {item?.title}
                      </Divider>
                      <Divider className="shared-divider-line" />
                    </Box>
                    <Box className="shared-traits-Box">
                      {item?.titleItems?.map((desc, subIndex) => (
                        <Box key={subIndex} className="shared-traits-items">
                          <Typography className="shared-traits-desc-typo">{desc}</Typography>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                )
            )}
          </Grid>
        </Box>
      </Box>
    );
  } else if (activeTab === 'compareDrivers') {
    return (
      <Box className="tab-content">
        {data?.sliders?.map((item, index) => {
          return (
            <CustomSlider
              key={index}
              sliderHeading={item?.sliderName}
              sliderEndtValue={item?.sliderEndtValue}
              sliderStartValue={item?.sliderStartValue}
              textColor="#C7C7C7"
              tooltipText={(values, parentIndex) => {
                const text = t(
                  `people-segment.template.intro.${values?.microCode}.keydriversHoverText.${parentIndex + 1}`
                );
                return text;
              }}
              index={index}
              space={microSegment}
              values={item.values}
            />
          );
        })}
      </Box>
    );
  } else if (activeTab === 'compareValues') {
    return (
      <Box className="tab-content">
        <Box className="examples-columns">
          <Box className="examples-col">
            <Box className="examples-content">
              <TableContainer className="compare-values-left">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="data-table-cell-head">
                        <Typography className={`title2 ${classMapping[microMappingData[0]]}`}>
                          {t(`people-segment.type.${microMappingData[0]}`)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell className="data-table-cell-body">
                        <Typography className="text">
                          {t(`people-segment.template.intro.${microSegment}.compare-values-desc.left-items.desc1`)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="data-table-cell-body">
                        <Typography className="text">
                          {t(`people-segment.template.intro.${microSegment}.compare-values-desc.left-items.desc2`)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="data-table-cell-body last-row">
                        <Typography className="text">
                          {t(`people-segment.template.intro.${microSegment}.compare-values-desc.left-items.desc3`)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
          <Box className="examples-col">
            <TableContainer className="compare-values-right">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="data-table-cell-head">
                      <Typography className={`title2 ${classMapping[microMappingData[1]]}`}>
                        {t(`people-segment.type.${microMappingData[1]}`)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className="data-table-cell-body">
                      <Typography className="text">
                        {t(`people-segment.template.intro.${microSegment}.compare-values-desc.right-items.desc1`)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="data-table-cell-body">
                      <Typography className="text">
                        {t(`people-segment.template.intro.${microSegment}.compare-values-desc.right-items.desc2`)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="data-table-cell-body last-row">
                      <Typography className="text">
                        {t(`people-segment.template.intro.${microSegment}.compare-values-desc.right-items.desc3`)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    );
  } else if (activeTab === 'examples') {
    return (
      <Box className="tab-content">
        <Box className="examples-columns">
          <Box className="examples-col">
            <TableContainer className="examples-left">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="data-table-cell-head">
                      <Typography className="title1">
                        {t(`people-segment.template.intro.compare-personas.whereyoumightfind`)}
                      </Typography>
                      <Typography className={`title2 ${classMapping[microMappingData[0]]}`}>
                        {t(`people-segment.type.${microMappingData[0]}`)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className="data-table-cell-body">
                      <Typography className="text">
                        {t(`people-segment.template.intro.${microSegment}.examples-desc.left-items.desc1`)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="data-table-cell-body">
                      <Typography className="text">
                        {t(`people-segment.template.intro.${microSegment}.examples-desc.left-items.desc2`)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="data-table-cell-body last-row">
                      <Typography className="text">
                        {t(`people-segment.template.intro.${microSegment}.examples-desc.left-items.desc3`)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box className="examples-col">
            <TableContainer className="examples-right">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="data-table-cell-head">
                      <Typography className="title1">
                        {t(`people-segment.template.intro.compare-personas.whereyoumightfind`)}
                      </Typography>
                      <Typography className={`title2 ${classMapping[microMappingData[1]]}`}>
                        {t(`people-segment.type.${microMappingData[1]}`)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className="data-table-cell-body">
                      <Typography className="text">
                        {t(`people-segment.template.intro.${microSegment}.examples-desc.right-items.desc1`)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="data-table-cell-body">
                      <Typography className="text">
                        {t(`people-segment.template.intro.${microSegment}.examples-desc.right-items.desc2`)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="data-table-cell-body last-row">
                      <Typography className="text">
                        {t(`people-segment.template.intro.${microSegment}.examples-desc.right-items.desc3`)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    );
  }

  return null;
};

TabContent.propTypes = {
  activeTab: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  microSegment: PropTypes.string.isRequired,
  macroSegment: PropTypes.string.isRequired,
  microMappingData: PropTypes.array.isRequired
};

export default Modal;
