import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { LandingHeader } from '../cat-trip-reuse-components/header/LandingHeader';
import { AlcoholCategories } from './components/alcohol-categories/AlcoholCategories';
import { AlcoholSubcategories } from './components/alcohol-subcategories/AlcoholSubcategories';
import { PriceTiers } from './components/price-tiers/PriceTiers';
import { CategoryStructureIntro } from './components/category-structure/CategoryStructureIntro';
import styles from './CategoryLandingPage.module.css';

const CategoryLandingPage = () => {
  const { t } = useTranslation(i18next.language);

  const imageSrc =
    'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fea6847bf139d4129bf1ed75693459d08';

  return (
    <div className={styles.container}>
      <LandingHeader
        title={t('categoryLanding.pageTitle')}
        introText={t('categoryLanding.eyebrowText')}
        imageSrc={imageSrc}
        altText={t('categoryLanding.headerImgAlt')}
        buttonPath="/categories/framework"
        buttonCopy={t('categoryLanding.buttonText')}
      />
      <AlcoholCategories />
      <AlcoholSubcategories />
      <PriceTiers />
      <CategoryStructureIntro />
    </div>
  );
};

export default CategoryLandingPage;
