import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { occasionIcons } from '../../../constants/OccasionsConstants';
import { occasionsAssets } from '../../common/OccasionsAssets';

const CustomCard = ({ cards, heading, index, keyId }) => {
  const navigate = useNavigate();

  return (
    <Grid key={keyId} item xs={index === 1 ? 4.8 : 7.2}>
      <Box className="column-layout">
        <Box className="column-heading">
          <Divider className="column-heading-sub" textAlign="left">
            {t(`occasions.macroOccasion.${heading}`)}
          </Divider>
          <Divider sx={{ flex: '1 0 0', border: '1px solid #F6E003' }} />
        </Box>
        <Grid container spacing={{ xs: 1, md: 2 }}>
          {cards.map((card, cardIndex) => (
            <Grid item xs={12 / cards.length} key={`MicroCard-${cardIndex}`}>
              <Box className="custom-card-background">
                <Card
                  className="custom-card"
                  onClick={() => {
                    navigate(`${heading}/${card}`);
                    window.scrollTo(0, 0);
                  }}
                >
                  <CardActionArea>
                    <CardMedia component="img" image={occasionsAssets.heroImages[card]()} />

                    <CardContent className="card-title">
                      <Box className="card-text">
                        <Typography variant="body2" className="card-typo">
                          {t(`occasions.type.${card}`)}
                        </Typography>
                        <Typography className="card-typo2" sx={{ marginBottom: '-10px' }}>
                          {occasionIcons[card]}
                        </Typography>
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Grid>
  );
};

CustomCard.propTypes = {
  cards: PropTypes.array.isRequired,
  heading: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  keyId: PropTypes.string.isRequired
};

export default CustomCard;
