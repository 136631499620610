import { COLORS } from './CatTripColors';

export const mainNavLinks = {
  mainCategory: {
    label: 'Beer Hub',
    to: '/categories/beer-hub'
  },
  subCategory: {
    'non-alcoholic': { label: 'Non-Alcoholic', to: '/categories/beer-hub/non-alcoholic' },
    'malts-ices': { label: 'Malts & Ices', to: '/categories/beer-hub/malts-ices' },
    'american-classic': { label: 'American Classics', to: '/categories/beer-hub/american-classic' },
    'american-light': { label: 'American Light', to: '/categories/beer-hub/american-light' },
    'american-balanced': { label: 'American Balanced Light', to: '/categories/beer-hub/american-balanced' },
    imports: { label: 'Imports', to: '/categories/beer-hub/imports' },
    elevated: { label: 'Elevated', to: '/categories/beer-hub/elevated' },
    'ipa-pale-ale': { label: 'IPA/Pale Ale', to: '/categories/beer-hub/ipa-pale-ale' },
    'craft-all-other': { label: 'Craft All Other', to: '/categories/beer-hub/craft-all-other' },
    flavored: { label: 'Flavored', to: '/categories/beer-hub/flavored' }
  }
};

export const subNavLinks = [
  { href: '#definition', text: 'Definition' },
  { href: '#sizing', text: 'Sizing' },
  { href: '#consumer-perceptions', text: 'Consumer Perceptions' },
  { href: '#wrap-up', text: 'Wrap Up' }
];

export const navColorSet = {
  'non-alcoholic': {
    anchorBarColor: COLORS.NON_ALC_YELLOW,
    anchorBarColorSecondary: COLORS.DARK_OVERLAY,
    textColor: COLORS.TEXT_BLACK
  },
  'malts-ices': {
    anchorBarColor: COLORS.MALT_ICE_YELLOW,
    anchorBarColorSecondary: COLORS.DARK_OVERLAY,
    textColor: COLORS.TEXT_BLACK
  },
  'american-classic': {
    anchorBarColor: COLORS.AM_CLASSIC_ORANGE,
    anchorBarColorSecondary: COLORS.DARK_OVERLAY,
    textColor: COLORS.TEXT_BLACK
  },
  'american-light': {
    anchorBarColor: COLORS.AM_LIGHT_ORANGE,
    anchorBarColorSecondary: COLORS.DARK_OVERLAY,
    textColor: COLORS.TEXT_BLACK
  },
  'american-balanced': {
    anchorBarColor: COLORS.AM_BALANCE_ORANGE,
    anchorBarColorSecondary: COLORS.DARK_OVERLAY,
    textColor: COLORS.TEXT_BLACK
  },
  imports: {
    anchorBarColor: COLORS.IMPORTS_ORANGE,
    anchorBarColorSecondary: COLORS.DARK_OVERLAY,
    textColor: COLORS.TEXT_WHITE
  },
  elevated: {
    anchorBarColor: COLORS.ELEVEATED_ORANGE,
    anchorBarColorSecondary: COLORS.DARK_OVERLAY,
    textColor: COLORS.TEXT_WHITE
  },
  'ipa-pale-ale': {
    anchorBarColor: COLORS.IPA_DRK_ORANGE,
    anchorBarColorSecondary: COLORS.DARK_OVERLAY,
    textColor: COLORS.TEXT_WHITE
  },
  'craft-all-other': {
    anchorBarColor: COLORS.CRAFT_OTHER_RED,
    anchorBarColorSecondary: COLORS.DARK_OVERLAY,
    textColor: COLORS.TEXT_WHITE
  },
  flavored: {
    anchorBarColor: COLORS.FLAVORED_DRK_RED,
    anchorBarColorSecondary: COLORS.DARK_OVERLAY,
    textColor: COLORS.TEXT_WHITE
  }
};
