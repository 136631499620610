import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { COLORS } from '../../../../../constants/CatTripColors';
import { BEVERAGE_ICONS } from '../../../../../constants/BeverageIcons';
import { formatSegmentTitle } from '../../../../../constants/FormatSegmentTitle';
import styles from './BasketStatsCard.module.css';

export const BasketStatsCard = ({
  beverageCategory,
  percentage,
  count,
  basketPercentage,
  tagColor,
  tripTypeColor,
  accentTextColor
}) => {
  const { t } = useTranslation(i18next.language);

  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardTop}>
        <div className={styles.titleSection}>
          <img
            loading="lazy"
            src={BEVERAGE_ICONS[`${beverageCategory}_YELLOW`]}
            className={styles.categoryIcon}
            alt={`${beverageCategory} category icon`}
          />
          <div className={styles.categoryTitle}>{formatSegmentTitle(beverageCategory)}</div>
        </div>
        <div className={styles.statsSection}>
          <div className={styles.statsGroup}>
            <div className={styles.percentageText}>{percentage}%</div>
            <div className={styles.countBadge} style={{ backgroundColor: COLORS[tagColor] }}>
              {count}
            </div>
          </div>
          <div className={styles.spendLabel}>{t('tripTypesPageTemplate.basketStats.spendLabel')}</div>
        </div>
      </div>
      <div className={styles.basketPercentage} style={{ backgroundColor: tripTypeColor, color: accentTextColor }}>
        {t('tripTypesPageTemplate.basketStats.footerStart')} {basketPercentage}
        {t('tripTypesPageTemplate.basketStats.footerEnd')}
      </div>
    </div>
  );
};

BasketStatsCard.propTypes = {
  beverageCategory: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  basketPercentage: PropTypes.number.isRequired,
  tagColor: PropTypes.string.isRequired,
  tripTypeColor: PropTypes.string.isRequired,
  accentTextColor: PropTypes.string.isRequired
};
