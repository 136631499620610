import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Grid, MobileStepper, Typography, useTheme } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import ConsumptionVisualCard from '../../custom/Cards/ConsumptionVisualCard';
import ConsumptionDataTable from '../../custom/Table/ConsumptionDataTable';
import UgcVoiceCard from '../../custom/UGC_Cards/UgcVoiceCard';
import { PeopleDetails } from '../../custom/UGC_Cards/UGCCardImage';
import SectionIntro from '../../common/page-sections/SectionIntro';
import { peopleSegmentAssets } from '../../common/PeopleSegmentAssets.js';
import { downloadsAssets } from '../../common/DownloadsAssets';
import QuoteAttributionImageCard from '../../custom/UGC_Cards/QuoteAttributionImageCard';
import { getTemplateColors } from '../../../constants/constants';

function SectionFive({ sectionData }) {
  const { t } = useTranslation(i18next.language);
  const { microSegment } = useParams();

  const whatTheyDrink = t('people-segment.template.whatTheyDrink', {
    returnObjects: true
  });

  useEffect(() => {
    if (localStorage.getItem('i18nnextLng')?.length > 2) {
      i18next.changeLanguage('en');
    }
  }, []);

  function downloadFile(url, fileName) {
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
  }
  function downloadSpreadsheets() {
    downloadFile(downloadsAssets.demographicTableData.DataPackSpreadsheet, 'dataPack.xlsx');
  }

  function getTableData(bevData) {
    let data = {};

    data.initialRowCount = 3;
    data.isExpandable = true;
    data.paginationAlignment = 'left';
    data.paginationTextExpand = t('people-segment.template.whatTheyDrink.consumptionData.paginationTextExpandMore');
    data.paginationTextContract = t('people-segment.template.whatTheyDrink.consumptionData.paginationTextExpandLess');
    data.header = [
      t('people-segment.template.whatTheyDrink.consumptionData.columnHeading1', {
        bevType: bevData.drinkName
      }),
      t('people-segment.template.whatTheyDrink.consumptionData.columnHeading2'),
      t('people-segment.template.whatTheyDrink.consumptionData.columnHeading3')
    ];
    data.rowData = bevData.drinkTypes;

    return data;
  }
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const sliderData = whatTheyDrink?.carousel[microSegment];
  const videoData = whatTheyDrink?.video[microSegment];
  const imageUrls = peopleSegmentAssets.consumption[microSegment].carouselImages?.();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const videoRef = React.createRef();

  const handlePlayButtonClick = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };
  return (
    <div className="section5-container-people-segment">
      <div className="section5-people-segment">
        <SectionIntro
          heading={t('people-segment.anchorItems.whatTheyDrink')}
          sectionImages={{
            front: peopleSegmentAssets.consumption[microSegment]['front'](),
            back: peopleSegmentAssets.consumption[microSegment]['back']()
          }}
          actionButton={
            <Button
              className="download-all-button outlined-button"
              component="label"
              variant="contained"
              startIcon={<FileDownloadIcon />}
              onClick={downloadSpreadsheets}
            >
              {t('buttons.downloadCategorySpreadsheets')}
            </Button>
          }
        />
        <Grid container className="section5-image-list" justifyContent="space-between">
          {sectionData.map((item) => (
            <Grid
              item
              xs={2.85}
              key={item.drinkName}
              sx={{
                display: 'flex',
                alignSelf: 'stretch',
                flexDirection: 'column'
              }}
            >
              <ConsumptionVisualCard key={item.id} bevData={item} space={microSegment} />
              <ConsumptionDataTable
                theme={microSegment}
                color={theme.palette[microSegment].main}
                tableData={getTableData(item)}
              />
            </Grid>
          ))}
        </Grid>
        <Box className="interaction-tooltip-container">
          <InfoOutlinedIcon color="primary" />
          <Typography variant="body1" className="interaction-tooltip-text">
            {t('people-segment.template.whatTheyDrink.consumptionData.tooltipText')}
          </Typography>
        </Box>
        <Box className="section5-drinking-behaviour-content">
          <Box className="section5-drinking-behaviour">
            <Typography className="section5-drinking-typo">
              {t('people-segment.template.whatTheyDrink.ugcDividerText')}
            </Typography>
            <Divider sx={{ flex: '1 0 0' }} className={`section5-drinking-divider ${microSegment}`}></Divider>
          </Box>
          <Grid container className="peoplesegment-drinking-behaviour-container">
            <Grid item xs={6} className="left-item">
              <UgcVoiceCard
                attribution={t(`people-segment.template.whatTheyDrink.audio.${microSegment}.attribution`)}
                color="#FFF"
                space={microSegment}
                dynamicbg={false}
                playIconColor={theme.palette[microSegment].main}
                audioIconColor="white"
                dividerColor={theme.palette[microSegment].main}
                textColor={theme.palette[microSegment].main}
                audioLink={peopleSegmentAssets.consumption[microSegment].audio}
              />

              <Box className="ownWords-card-container">
                <QuoteAttributionImageCard
                  theme={microSegment}
                  cardData={{
                    attribution: sliderData[activeStep]?.attribution,
                    quote: sliderData[activeStep]?.quote,
                    image: imageUrls?.[activeStep] || ''
                  }}
                />
                <MobileStepper
                  variant="dots"
                  steps={sliderData?.length}
                  position="static"
                  activeStep={activeStep}
                  className={`custom-mobile-stepper ${microSegment}`}
                  nextButton={
                    <Button size="small" onClick={handleNext} disabled={activeStep === sliderData?.length - 1}>
                      {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </Button>
                  }
                  backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                      {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    </Button>
                  }
                  sx={{
                    '.MuiMobileStepper-dotActive': {
                      backgroundColor: getTemplateColors(microSegment)
                    }
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={6} className="right-item">
              <Box className="section5-video-container">
                <Grid container direction={'column'} gap={2.5}>
                  {peopleSegmentAssets.consumption[microSegment].videos().map((item, index) => (
                    <Grid key={`${item}-${index}`} item xs={5.7} sx={{ display: 'flex', alignSelf: 'stretch' }}>
                      <Box
                        sx={{ minHeight: 730 / peopleSegmentAssets.consumption[microSegment].videos.length }}
                        className="section5-video-box"
                      >
                        <Box className="section5-video-box-div">
                          <video
                            // eslint-disable-next-line jsx-a11y/media-has-caption
                            width="100%"
                            height="100%"
                            border="15px"
                            onClick={handlePlayButtonClick}
                            preload="metadata"
                            controls
                            src={item}
                          ></video>
                        </Box>
                        <Box className="video-people-details-box">
                          <Typography color={`${microSegment}.main`} className={`name-typo1`}>
                            {videoData[index].name}
                          </Typography>
                          <PeopleDetails textColor="#FFFFFF" attribution={videoData[index].attribution} />
                          {videoData[index].description && videoData[index].description !== '' && (
                            <Typography className="name-typo3">{videoData[index].description}</Typography>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}

SectionFive.propTypes = {
  sectionData: PropTypes.array.isRequired
};

export default SectionFive;
