import React from 'react';
import { AlcFrameworkHeader } from './components/alc-framework-header/AlcFrameworkHeader';
import { CategoryExplorer } from './components/category-explorer/CategoryExplorer';
import { AdditionalCategories } from './components/additional-categories/AdditionalCategories';

export function AlcoholFrameworkPage() {
  return (
    <div>
      <AlcFrameworkHeader />
      <CategoryExplorer />
      <AdditionalCategories />
    </div>
  );
}
