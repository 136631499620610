import { Avatar, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

function ScopeBadge({ data, scopeBadgeStyle }) {
  return (
    <>
      <Avatar className={`scope-container-outer ${scopeBadgeStyle}`} variant="circle">
        <Avatar className={`scope-container-inner ${scopeBadgeStyle}`} variant="circle">
          <Box className={`scope-data-container ${scopeBadgeStyle}`}>
            <Typography className={`value ${scopeBadgeStyle}`}>{data.value}</Typography>
            <Typography className={`label ${scopeBadgeStyle}`}>{data.label}</Typography>
          </Box>
        </Avatar>
      </Avatar>
    </>
  );
}

ScopeBadge.propTypes = {
  data: PropTypes.object.isRequired,
  scopeBadgeStyle: PropTypes.string
};

export default ScopeBadge;

// function ScopeBadge({ data, badgeSizes, gap, values, label }) {
//   const { width, padding } = badgeSizes;

//   const {
//     percentageFontSize,
//     percentageFontWeight,
//     percentageLetterSpacing,
//     percentageLineHeight,
//   } = values;

//   const { textLineHeight, textFontWeight, textFontSize, textSpacing } = label;
//   return (
//     <div
//       className="scope-container-outer"
//       style={{
//         padding: padding,
//       }}
//     >
//       <div
//         className="scope-container-inner"
//         style={{
//           width: width,
//           height: width,
//         }}
//       >
//         <Box className="scope-data-container" style={{ gap: gap }}>
//           <Typography
//             variant="h3"
//             className="value"
//             style={{
//               fontSize: percentageFontSize,
//               fontWeight: percentageFontWeight,
//               lineHeight: percentageLineHeight,
//               letterSpacing: percentageLetterSpacing,
//             }}
//           >
//             {data.value}
//           </Typography>
//           <Typography
//             variant="body1"
//             className="label"
//             style={{
//               lineHeight: textLineHeight,
//               fontWeight: textFontWeight,
//               fontSize: textFontSize,
//               letterSpacing: textSpacing,
//             }}
//           >
//             {data.label}
//           </Typography>
//         </Box>
//       </div>
//     </div>
//   );
// }

// export default ScopeBadge;
