import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { COLORS } from '../../../../constants/CatTripColors';
import { PatternCard } from './compound-components/PatternCard';
import { StatCard } from './compound-components/StatCard';
import { TableRow } from './compound-components/TableRow';
import { NoItems10Basket } from './compound-components/NoItems10Basket';
import styles from './SpendAnalytics.module.css';

export const SpendAnalytics = ({
  whatPeopleBuy,
  whyPeopleBuy,
  averageSpend,
  averageSpendTag,
  averageSpendTagColor,
  alcoholShareOfSpend,
  alcoholShareOfSpendTag,
  alcoholShareOfSpendTagColor,
  tableData,
  footnote
}) => {
  const { t } = useTranslation(i18next.language);

  return (
    <div className={styles.sectionGroup}>
      <div className={styles.paternCardGroup}>
        <PatternCard
          highlightedWord={t('tripTypesPageTemplate.spendAnalytics.card1Highlight')}
          title={t('tripTypesPageTemplate.spendAnalytics.cardText')}
          subtitle={whatPeopleBuy}
        />
        <PatternCard
          highlightedWord={t('tripTypesPageTemplate.spendAnalytics.card2Highlight')}
          title={t('tripTypesPageTemplate.spendAnalytics.cardText')}
          subtitle={whyPeopleBuy}
        />
      </div>

      <div className={styles.spendBreakdownCard}>
        <div className={`${styles.card} ${styles.breakdownCard}`} />
        <div className={styles.titleGroup}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F5374980f997a45ab80ca2db39ddfa913"
            alt={t('tripTypesPageTemplate.spendAnalytics.card3IconAlt')}
            className={styles.icon}
          />
          <div className={styles.title}>{t('tripTypesPageTemplate.spendAnalytics.card3Title')}</div>
        </div>

        <div className={styles.statsContainer}>
          <StatCard
            value={averageSpend}
            tag={averageSpendTag}
            description={t('tripTypesPageTemplate.spendAnalytics.card3StatLabel1')}
            statColor={COLORS[averageSpendTagColor]}
            footnote={footnote}
          />
          <StatCard
            value={alcoholShareOfSpend}
            tag={alcoholShareOfSpendTag}
            description={t('tripTypesPageTemplate.spendAnalytics.card3StatLabel2')}
            statColor={COLORS[alcoholShareOfSpendTagColor]}
            footnote={footnote}
          />
        </div>
      </div>

      <div className={styles.categoryBreakdown}>
        <div className={`${styles.card} ${styles.categoriesCard}`} />
        <div className={styles.header}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F13d13c6da5c44dccb47ad7a92e485e7f"
            alt={t('tripTypesPageTemplate.spendAnalytics.card4IconAlt')}
            className={`${styles.icon} ${styles.basketIcon}`}
          />
          <div className={styles.title}>{t('tripTypesPageTemplate.spendAnalytics.card4Title')}</div>
        </div>

        <div className={styles.tableContainer}>
          {tableData.length ? (
            <>
              <h1 className={styles.tableTitle}>{t('tripTypesPageTemplate.spendAnalytics.tableTitle')}</h1>
              <div className={styles.tableHeader}>
                <div className={styles.columnTitle}>{t('tripTypesPageTemplate.spendAnalytics.columnTitle')}</div>
                <div className={styles.columnStats}>
                  <div className={styles.percentage}>%</div>
                  <div className={styles.index}>{t('tripTypesPageTemplate.spendAnalytics.columnIndex')}</div>
                </div>
              </div>

              <div className={styles.tableLine} />

              {tableData.map((row) => (
                <TableRow
                  key={row.item}
                  item={row.item}
                  percentage={row.percentage}
                  index={row.index}
                  tagColor={COLORS[row.tagColor]}
                />
              ))}
            </>
          ) : (
            <NoItems10Basket />
          )}
        </div>
      </div>
      {footnote && <div className={styles.footnote}>*Spend data does not include gas</div>}
    </div>
  );
};

SpendAnalytics.propTypes = {
  whatPeopleBuy: PropTypes.string.isRequired,
  whyPeopleBuy: PropTypes.string.isRequired,
  averageSpend: PropTypes.number.isRequired,
  averageSpendTag: PropTypes.number.isRequired,
  averageSpendTagColor: PropTypes.string.isRequired,
  alcoholShareOfSpend: PropTypes.number.isRequired,
  alcoholShareOfSpendTag: PropTypes.number.isRequired,
  alcoholShareOfSpendTagColor: PropTypes.string.isRequired,
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.string.isRequired,
      percentage: PropTypes.number.isRequired,
      index: PropTypes.number.isRequired,
      tagColor: PropTypes.string.isRequired
    })
  ),
  footnote: PropTypes.bool.isRequired
};
