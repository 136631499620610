import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../../../constants/CatTripColors';
import { getAccentTextColor } from '../../../../constants/GetAccentTextColor';
import { ThemeCard } from './compound-components/ThemeCard';
import { ContentCard } from './compound-components/ContentCard';
import styles from './CommonThemes.module.css';

export function CommonThemes({ themeData, tripTypeColor }) {
  const accentTextColor = getAccentTextColor(tripTypeColor);

  return (
    <div className={styles.themeSection}>
      <div className={styles.header}>
        <div className={styles.subheading}>Trip Purpose</div>
        <div className={styles.heading}>commonly heard themes</div>
      </div>
      {themeData.map((theme, index) => (
        <div key={index} className={styles.themeGroup}>
          {theme.showThemeCard && (
            <ThemeCard title={theme.title} tripTypeColor={COLORS[tripTypeColor]} accentTextColor={accentTextColor} />
          )}
          {theme.cards.map((card, cardIndex) => (
            <ContentCard
              key={cardIndex}
              imageUrl={card.imageUrl}
              imageAltText={card.text}
              text={card.text}
              highlight={card.highlight}
            />
          ))}
        </div>
      ))}
    </div>
  );
}

CommonThemes.propTypes = {
  themeData: PropTypes.array.isRequired,
  tripTypeColor: PropTypes.string.isRequired
};
