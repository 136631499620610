import React from 'react';
import PropTypes from 'prop-types';
import styles from './KeyPointCard.module.css';

export const KeyPointCard = ({ text, backgroundColor, textColor }) => {
  return (
    <div className={styles.cardWrapper}>
      <div className={styles.card} style={{ backgroundColor: backgroundColor }} />
      <div className={styles.cardText} style={{ color: textColor }}>
        {text}
      </div>
    </div>
  );
};

KeyPointCard.propTypes = {
  text: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string
};
