import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import DemandSpaces from '../../common/page-sections/DemandSpaces';

function SectionFive({ sectionData }) {
  const { macro, micro } = useParams();
  const [demandSpaceData, setDemandSpaceData] = useState({});

  useEffect(() => {
    let data = {};
    for (const item of sectionData) {
      data[item.microCode] = parseFloat(item.volume).toFixed(1);
    }
    setDemandSpaceData(data);
  }, [sectionData]);

  return (
    <div className="section5">
      <DemandSpaces page="occasions" macro={macro} micro={micro} data={demandSpaceData} />
    </div>
  );
}

SectionFive.propTypes = {
  sectionData: PropTypes.array.isRequired
};

export default SectionFive;
