import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../../../../constants/CatTripColors';
import styles from './DataTag.module.css';

export function DataTag({ percentage, value, tagColor }) {
  return (
    <div className={styles.stat}>
      <div className={styles.percentage}>{percentage}%</div>
      <div className={styles.tag} style={{ backgroundColor: COLORS[tagColor] }}>
        {value}
      </div>
    </div>
  );
}

DataTag.propTypes = {
  percentage: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  tagColor: PropTypes.string
};
