import React from 'react';
import PropTypes from 'prop-types';
import DemandSpacesWrapUp from '../../common/page-sections/Wrap-up/DemandSpacesWrapUp';

function SectionSeven({ macro, selectedMicro, sectionData }) {
  return (
    <div className="section7-demandSpaceTemplate">
      <DemandSpacesWrapUp macro={macro} selectedMicro={selectedMicro} sectionContent={sectionData} />
    </div>
  );
}

SectionSeven.propTypes = {
  macro: PropTypes.object.isRequired,
  selectedMicro: PropTypes.string.isRequired,
  sectionData: PropTypes.object.isRequired
};

export default SectionSeven;
