import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { ActionButton } from '../../../cat-trip-reuse-components/reusable-button/ReusableButton';
import styles from './CategoryStructureIntro.module.css';

const CATEGORY_STRUCTURE_IMAGE_SRC =
  'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F7ed56e3c65134d11b28543bb0b7a9b97';

export const CategoryStructureIntro = () => {
  const { t } = useTranslation(i18next.language);
  const navigate = useNavigate();

  const handleButtonClick = useCallback(() => {
    navigate('/categories/framework');
    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>{t('categoryLanding.categoryStructureIntro.title')}</h1>
      <div className={styles.imageContainer}>
        <img
          src={CATEGORY_STRUCTURE_IMAGE_SRC}
          alt="Category structure chart"
          className={styles.categoryStructureChart}
        />
        <div className={styles.buttonContainer}>
          <ActionButton onClick={handleButtonClick}>
            {t('categoryLanding.categoryStructureIntro.buttonText')}
          </ActionButton>
        </div>
      </div>
    </div>
  );
};
