import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { DonutChart } from './DonutChart';
import styles from './ChannelDistribution.module.css';

export const ChannelDistribution = ({ chartImgUrl, channelData }) => {
  const { t } = useTranslation(i18next.language);

  return (
    <div className={styles.chartCard}>
      <div className={styles.chartTitle}>{t('tripTypesPageTemplate.channelSelection.title')}</div>
      <div className={styles.divider} />
      <div className={styles.chartContent}>
        <DonutChart
          chartImgUrl={chartImgUrl}
          chartImgAltText={t('tripTypesPageTemplate.channelSelection.chartAltText')}
          channelData={channelData}
        />
      </div>
    </div>
  );
};

ChannelDistribution.propTypes = {
  chartImgUrl: PropTypes.string.isRequired,
  channelData: PropTypes.array.isRequired
};
