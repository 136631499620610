import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Typography, Box } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { t } from 'i18next';

function DataTable({ theme, tableData }) {
  const [rowCount, setRowCount] = useState(tableData.initialRowCount);
  const [isTableExpanded, setIsTableExpanded] = useState(false);

  function expandTable() {
    if (rowCount < tableData.rowData.length) {
      setRowCount(rowCount + (tableData.rowData.length - rowCount));
    } else {
      setRowCount(tableData.initialRowCount);
    }

    setIsTableExpanded(!isTableExpanded);
  }

  function getColor(value) {
    if (value < 85) {
      return 'negative';
    } else if (value > 115) {
      return 'positive';
    } else {
      return 'neutral';
    }
  }

  function formatDrinkName(drinkName) {
    return drinkName
      .replace(/beverages/i, 'bev')
      .replace(/\s*\(NET\)/, '')
      .replace(/\s*\+ NAB/, '');
  }

  const getRowData = (tableData, rowCount) => {
    if (tableData.rowData.length === 0) return [];
    return tableData.isExpandable ? tableData.rowData.slice(0, rowCount) : tableData.rowData;
  };

  useEffect(() => {
    // Apply border to the last row containing "(net)" in all the displayed tables
    const tables = document.querySelectorAll('.MuiTable-root');
    tables.forEach((table) => {
      const rows = table.querySelectorAll('.data-table-row');
      let lastAverageRowIndex = -1;
      rows.forEach((row, index) => {
        if (row.innerText.toLowerCase().indexOf('(net)') > -1) {
          lastAverageRowIndex = index;
        }
      });
      if (lastAverageRowIndex !== -1) {
        rows[lastAverageRowIndex].classList.add('averageFieldRow');
      }
    });
  }, []);

  return (
    <TableContainer className="data-table-container">
      <Table aria-label="data table" className="data-table">
        {tableData?.header && (
          <TableHead className="data-table-head">
            <TableRow className="data-table-row">
              {tableData.header.map((item) => (
                <TableCell key={item} className={`data-table-cell ${theme}`}>
                  <Typography>{formatDrinkName(item)}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody className="data-table-body">
          {tableData.rowData.length > 0 ? (
            getRowData(tableData, rowCount).map((row, index) => (
              <TableRow key={'row-' + index} className={`data-table-row`}>
                {Object.keys(row).map((keyName, index) => (
                  <TableCell
                    key={'table-cell-' + index}
                    className={`data-table-cell                                             
                                                ${tableData.isExpandable ? 'expandable' : 'non-expandable'}
                                                `}
                    align={index > 0 ? 'center' : 'left'}
                  >
                    {index === Object.keys(row).length - 1 ? (
                      <Typography
                        component="div"
                        className={`table-cell-text text-center
                                                            ${
                                                              tableData.factor === 'groupSizes' ||
                                                              tableData.factor === 'moods' ||
                                                              tableData.factor === 'activities'
                                                                ? 'medium'
                                                                : ''
                                                            }
                                                            ${getColor(row[keyName])}
                                                            
                                                            
                                                            `}
                      >
                        {Math.round(row[keyName])}
                      </Typography>
                    ) : (
                      <Typography
                        className={`table-cell-text
                                                        ${index === Object.keys(row).length - 2 ? 'medium' : ''}
                                                        `}
                        component="div"
                      >
                        {index === Object.keys(row).length - 2 ? `${Math.round(row[keyName])}%` : row[keyName]}
                      </Typography>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <Typography className="data-unavailable-info-text">{t('common.dataUnavailable')}</Typography>
          )}
        </TableBody>
      </Table>
      {
        <Box
          className={`data-table-pagination ${tableData.paginationAlignment} 
                    ${tableData.isExpandable ? 'showExpand' : 'hideExpand'}`}
          theme={theme}
          onClick={expandTable}
        >
          {isTableExpanded ? <ExpandLess className="icon" /> : <ExpandMore className="icon" />}
        </Box>
      }
    </TableContainer>
  );
}

DataTable.propTypes = {
  theme: PropTypes.string,
  tableData: PropTypes.object.isRequired
};

export default DataTable;
