import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Card } from '../../../cat-trip-reuse-components/reusable-card/Card';
import { ResearchCard } from './compound-components/ResearchCard';
import styles from './ResearchSection.module.css';

export function ResearchSection() {
  const { t } = useTranslation(i18next.language);
  const researchData = t('tripTypesLanding.researchSection.data', { returnObjects: true });
  return (
    <div className={styles.cardWrapper}>
      <Card data-testid="research-section-container" height="560px">
        <section className={styles.container}>
          <h2 className={styles.heading}>{t('tripTypesLanding.researchSection.title')}</h2>
          <div className={styles.cardsContainer}>
            {researchData.map((item) => (
              <ResearchCard
                key={item.id}
                image={item.image}
                title={item.title}
                number={item.number}
                description={item.description}
              />
            ))}
          </div>
        </section>
      </Card>
    </div>
  );
}
