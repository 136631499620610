import React from 'react';
import { useParams } from 'react-router-dom';
import FeedbackSection from '../../common/page-sections/FeedbackSection';

export default function SectionEight() {
  const { macro, micro } = useParams();
  return (
    <div className="section8">
      <FeedbackSection macro={macro} micro={micro} />
    </div>
  );
}
