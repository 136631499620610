import React from 'react';
import styles from './TimeDefinitions.module.css';

export function TimeDefinitions() {
  return (
    <div className={styles.definitions}>
      <span className={styles.term1}>Morning</span>: 5am-11am |<span className={styles.term}>Midday</span>: 11am-2pm |
      <span className={styles.term}>Afternoon</span>: 2pm-5pm |<span className={styles.term}>Evening</span>: 5pm-8pm |
      <span className={styles.term}>Late Night</span>: 8pm-12am
    </div>
  );
}
