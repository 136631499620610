import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { ReactComponent as QueryStats } from '../../../../assets/images/Common/query_stats.svg';
import CustomSlider from '../../../custom/Slider/CustomSlider';
import { peopleSegmentAssets } from '../../PeopleSegmentAssets';

const PeopleSegmentWrapup = ({ space, selectedMacro, sectionData }) => {
  const { t } = useTranslation(i18next.language);

  useEffect(() => {
    if (localStorage.getItem('i18nnextLng')?.length > 2) {
      i18next.changeLanguage('en');
    }
  }, []);

  // const classMapping = {
  //   Fram_PSGMT_2: "comfort-craver",
  //   Fram_PSGMT_3: "loyal-traditionalist",
  //   Fram_PSGMT_5: "mature-discoverer",
  //   Fram_PSGMT_6: "diligent-discoverer",
  //   Fram_PSGMT_8: "carefree-fun-lover",
  //   Fram_PSGMT_9: "proud-celebrator",
  //   Fram_PSGMT_11: "creative-explorer",
  //   Fram_PSGMT_12: "social-curator",
  // };
  // const classMappingButton = {
  //   Fram_PSGMT_2: "comfort-craver-button",
  //   Fram_PSGMT_3: "loyal-traditionalist-button",
  //   Fram_PSGMT_5: "mature-discoverer-button",
  //   Fram_PSGMT_6: "diligent-discoverer-button",
  //   Fram_PSGMT_8: "carefree-fun-lover-button",
  //   Fram_PSGMT_9: "proud-celebrator-button",
  //   Fram_PSGMT_11: "creative-explorer-button",
  //   Fram_PSGMT_12: "social-curator-button",
  // };

  // const currentKey = space;
  // const currentClass = classMapping[currentKey] || "";
  // const currentClassButton = classMappingButton[currentKey] || "";

  function navigateToSection(id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start'
      });
    }
  }

  const peopleSegmentKeywords = t(`people-segment.keywords.${space}`, {
    returnObjects: true
  });

  return (
    <>
      <Box className="section7-wrapup">
        <Divider
          className="divider"
          sx={{
            flex: '1 0 0',
            border: '2px solid #F6E003',
            width: '10rem'
          }}
        />
        <Grid container className="wrapup-content-container">
          <Grid className="wrapup-left-item" item xs={6}>
            <>
              <Typography className={`section7-type ${space}`}>{t(`people-segment.type.${space}`)}</Typography>
              <Typography variant="h2" className="section7-head">
                {t('people-segment.anchorItems.wrapUp')}
              </Typography>
              <Box className="micro-attributes">
                <Typography className={`micro-sov ${space}`} variant="body1">
                  <Trans components={{ colored: <i />, bold: <u /> }}>
                    {t('common.wrapup.totalAlcoholVolume', {
                      sov: Math.round(sectionData.totalVolume)
                    })}
                  </Trans>
                </Typography>
                <Divider orientation="vertical" className="divider" />
                <Typography className={`micro-sop ${space}`} variant="body1">
                  <Trans components={{ colored: <i />, bold: <u /> }}>
                    {t('common.wrapup.totalPopulation', {
                      sop: Math.round(sectionData.populationVolume)
                    })}
                  </Trans>
                </Typography>
              </Box>
              <Typography variant="body1" className={`wrap-up-section7-description ${space}`}>
                <Trans components={{ colored: <i /> }}>{t(`people-segment.template.intro.${space}.description`)}</Trans>
              </Typography>
            </>
            <Grid container flexDirection="row" gap={1}>
              <Grid item xs={8}>
                <Box
                  className="wrap-visual-image"
                  sx={{
                    background: `
                      linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.60) 76.56%), 
                      url(${peopleSegmentAssets.heroImages[space]()}),
                      lightgray 50% / cover no-repeat
                    `
                  }}
                >
                  <Typography className={`wrap-visual-text`}>
                    <span className={`${space}`}>{t(`people-segment.type.${space}`)}</span>{' '}
                    {t('people-segment.section7.areinthe')}{' '}
                    <span className={`${selectedMacro}`}>
                      {t(`people-segment.macroPeopleSegment.${selectedMacro}`)}
                    </span>{' '}
                    {t('people-segment.section7.macropeoplesegment')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3} display="flex">
                <Box className="micro-keywords-container">
                  {peopleSegmentKeywords.map((keyword, index) => (
                    <Typography
                      component="div"
                      variant="body2"
                      className={`micro-keyword ${space}`}
                      key={`micro-keyword-${index + 1}`}
                    >
                      {keyword}
                    </Typography>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="wrapup-right-item" item xs={6}>
            <Box className="wrapup-slider-heading">
              <Typography className="wrapup-slider-heading-typo">
                {t('people-segment.anchorItems.whatDrivesThem')}
              </Typography>
            </Box>
            <Box className="wrapup-right-item-slider-background">
              {sectionData?.sliders?.map((item, index) => {
                const values = item.values.filter((subItem) => subItem.microCode === space);
                return (
                  <CustomSlider
                    key={`ps-slider-${index + 1}`}
                    sliderEndtValue={item.sliderEndtValue}
                    sliderStartValue={item.sliderStartValue}
                    index={index}
                    values={values}
                    marks={[{ value: 50 }]}
                    tooltipText={t(`people-segment.template.intro.${space}.keydriversHoverText.${index + 1}`)}
                  />
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="wrapup-bottom">
        <QueryStats />
        <Typography
          className="wrapup-cta-text"
          sx={{ cursor: 'pointer' }}
          onClick={() => navigateToSection('section8')}
        >
          <Trans i18nKey={'people-segment.section7.wrapup-bottom'} components={{ underline: <i /> }}>
            {t('people-segment.section7.wrapup-bottom')}
          </Trans>
        </Typography>
      </Box>
    </>
  );
};

PeopleSegmentWrapup.propTypes = {
  space: PropTypes.string.isRequired,
  selectedMacro: PropTypes.string.isRequired,
  sectionData: PropTypes.object.isRequired
};

export default PeopleSegmentWrapup;
