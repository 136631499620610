import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Card } from '../../../cat-trip-reuse-components/reusable-card/Card';
import styles from './ShopQuestions.module.css';

export function ShopQuestions() {
  const { t } = useTranslation(i18next.language);

  return (
    <div className={styles.cardWrap} data-testid="research-section-container">
      <Card height="400px">
        <section className={styles.container}>
          <h2 className={styles.heading}>{t('tripTypesLanding.shopQuestions.title')}</h2>
          <div className={styles.imageContainer}>
            <img
              className={styles.shopperImage}
              src={t('tripTypesLanding.shopQuestions.whatDidTheyBuyIMG.image')}
              alt={t('tripTypesLanding.shopQuestions.whatDidTheyBuyIMG.alt')}
            />
            <img
              className={styles.shopperImage}
              src={t('tripTypesLanding.shopQuestions.whyDidTheyBuyIMG.image')}
              alt={t('tripTypesLanding.shopQuestions.whyDidTheyBuyIMG.alt')}
            />
          </div>
        </section>
      </Card>
    </div>
  );
}
