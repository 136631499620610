import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import TakeawayCard from './TakeawayCard';
import styles from './KeyTakeaways.module.css';

export default function KeyTakeaways({ takeawayData, takeawayHighlightTexts }) {
  const { t } = useTranslation(i18next.language);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.titleWrapper}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F317bd0da410e4a4b8d0d58367b060bb7"
            className={styles.icon}
            alt="Key takeaways icon"
          />
          <div className={styles.title}>{t('tripTypesPageTemplate.sectionHeader.keyTakeaways.title')}</div>
        </div>
      </div>
      <TakeawayCard takeaways={takeawayData} takeawayHighlightTexts={takeawayHighlightTexts} />
    </div>
  );
}

KeyTakeaways.propTypes = {
  takeawayData: PropTypes.array.isRequired,
  takeawayHighlightTexts: PropTypes.array.isRequired
};
