import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { Bar, BarChart, LabelList, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PsychologyIcon from '@mui/icons-material/Psychology';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ExploreIcon from '@mui/icons-material/Explore';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import { Trans, useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';
import { KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowRight, KeyboardArrowUp } from '@mui/icons-material';
import { peopleSegmentAssets } from '../../common/PeopleSegmentAssets.js';
import SectionIntro from '../../common/page-sections/SectionIntro';
import DividerWithArrows from '../../custom/Divider/DividerWithArrows';
import Cards from '../../custom/Cards/Cards';
import {
  CHARTS_DATA1,
  CHARTS_DATA2,
  FACTOR_CONTENTS,
  EXPLORATORY_OUTGOING_CARDS,
  EXPLORATORY_RESERVED_CARDS,
  FAMILIAR_OUTGOING_CARDS,
  FAMILIAR_RESERVED_CARDS
} from '../../../constants/PeopleSegment';

const iconItems = {
  fitness: FitnessCenterIcon,
  wallet: AccountBalanceWalletIcon,
  military: MilitaryTechIcon,
  psychology: PsychologyIcon,
  loyalty: LoyaltyIcon
};

const FactorContents = ({ icon, title, content }) => {
  const SpecificIcon = iconItems[icon];
  return (
    <Box className="factor-content-stack-box">
      <Stack className="factor-content-stack">
        <SpecificIcon className="icon-styles" />
        <Box className="text-box">
          <Typography className="factorcontent-title-text" variant="h6">
            {title}
          </Typography>
          <Typography className="factorcontent-desc-text">{content}</Typography>
        </Box>
      </Stack>
    </Box>
  );
};

FactorContents.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
};

const CardTitle = ({ title, color }) => {
  return (
    <Stack className="stack-container">
      <Typography className={`ps-section2-card-title ${color}`} component="div">
        {title}
      </Typography>
    </Stack>
  );
};

CardTitle.propTypes = {
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

const CardContent = ({ cardArray }) => {
  const { t } = useTranslation(i18next.language);
  const navigate = useNavigate();
  return (
    <Grid container justifyContent="center" spacing={1.5}>
      {cardArray.map((i) => (
        <Grid
          key={i.text}
          item
          xs={6}
          onClick={() => {
            navigate(`${i.macro}/${i.micro}`);
            window.scrollTo(0, 0);
          }}
        >
          <Box className="card-content-hover-effect">
            <Cards key={i.text} img={i.img?.()} caption={t(`${i.text}`)} color={i.color} />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

CardContent.propTypes = {
  cardArray: PropTypes.array.isRequired
};

const RoundedDiv = ({ text, color1, color2 }) => {
  return (
    <Paper
      className="rounded-div"
      style={{
        background: `linear-gradient(100deg, ${color1} 0%, ${color2} 100%)`
      }}
    >
      <Typography className="rounded-div-text">{text}</Typography>
    </Paper>
  );
};

RoundedDiv.propTypes = {
  text: PropTypes.string.isRequired,
  color1: PropTypes.string.isRequired,
  color2: PropTypes.string.isRequired
};

const combinedData = [...CHARTS_DATA1, ...CHARTS_DATA2];
const maxValue = Math.max(...combinedData.map((item) => item.uv));
const xDomain = [0, maxValue];

function CustomTooltip({ payload, active }) {
  if (active) {
    return (
      <Box className="custom-tooltip2">
        <Typography className="description" variant="mobileDataLabel">
          {payload[0].payload.tooltip}
        </Typography>
      </Box>
    );
  }
  return null;
}

CustomTooltip.propTypes = {
  payload: PropTypes.array,
  active: PropTypes.bool
};

const CustomLabelContent = (props) => (
  <text
    className="linebar-label-text"
    x={props.x - 20}
    y={props.y + 22}
    fill={props.fill}
    fontSize={15}
    textAnchor="end"
  >
    {props.value}
  </text>
);

CustomLabelContent.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  fill: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

const CustomBarShape = (props) => {
  return <rect {...props} fill={props.payload.uv < 20 ? '#F6E003' : '#E5B611'} />;
};

CustomBarShape.propTypes = {
  payload: PropTypes.shape({
    uv: PropTypes.number.isRequired
  }).isRequired
};

//What's In PS Segment Section
export default function SectionThree() {
  const { t } = useTranslation(i18next.language);
  return (
    <Box className="ps-hub-section3-container">
      <Box className="ps-hub-section3-intro">
        <SectionIntro
          heading={t('people-segment.hub.section3.heroTitle')}
          description={t('people-segment.hub.section3.heroText1')}
          sectionImages={{
            front: peopleSegmentAssets.whatsInPeopleSegment.front(),
            back: peopleSegmentAssets.whatsInPeopleSegment.back()
          }}
        />
      </Box>
      <Box className="kf-centertext-box">
        <Typography className="kf-text-header5" variant="h3">
          <Trans components={{ colored: <i /> }}>{t('people-segment.hub.section3.heroText2')}</Trans>
        </Typography>
      </Box>
      <Box className="line-chart-container">
        <Grid className="line-chart-grid1" container>
          <Grid item xs={6.5} className="left-item">
            <Typography variant="h3" className="bar-chart-title">
              {t('people-segment.hub.section3.barGraph.title')}
            </Typography>
            <Box className="left-item-div">
              <Grid container>
                <Grid item xs={0.5} className="grid-left-item">
                  <Typography className="graph-title">
                    {t('people-segment.hub.section3.barGraph.attitude-graph-title')}
                  </Typography>
                </Grid>
                <Grid item xs={11.5}>
                  <ResponsiveContainer className="bar-chart1" width="100%" height="100%">
                    <BarChart
                      data={CHARTS_DATA1.map((item) => {
                        return {
                          name: t(item.name),
                          uv: item.uv,
                          tooltip: <Trans>{t(item.tooltip)}</Trans>
                        };
                      })}
                      layout="vertical"
                      barCategoryGap={7}
                      margin={{ top: 15, right: 50, left: 0, bottom: 0 }}
                    >
                      <XAxis type="number" hide domain={xDomain} />
                      <YAxis type="category" width={200} dataKey="name" display="none" />
                      <Tooltip content={<CustomTooltip />} isAnimationActive={false} cursor={false} shared={false} />
                      <Bar
                        dataKey="uv"
                        fill="#E5B611"
                        shape={CustomBarShape}
                        label={{
                          fill: '#fff',
                          position: 'right',
                          formatter: (value) => `${value}%`,
                          className: 'bar-chart-percent-section3',
                          offset: 12
                        }}
                      >
                        <LabelList
                          dataKey="name"
                          fill="#fff"
                          position="left"
                          className="linebar-label-text"
                          offset={20}
                        />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Grid>
              </Grid>
            </Box>
            <div className="left-item-arrow-div">
              <div className="left-item-arrow-subdiv" />
              <ArrowForwardIosIcon className="left-arrow-icon" />
            </div>
            <Box className="left-item-demographics">
              <Grid container>
                <Grid item xs={0.5} className="grid-left-item">
                  <Typography className="graph-title demographics">
                    {t('people-segment.hub.section3.barGraph.demographic-graph-title')}
                  </Typography>
                </Grid>
                <Grid item xs={11.5}>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={CHARTS_DATA2.map((item) => {
                        return {
                          name: t(item.name),
                          uv: item.uv,
                          tooltip: <Trans>{t(item.tooltip)}</Trans>
                        };
                      })}
                      layout="vertical"
                      barCategoryGap={5}
                      margin={{ top: 15, right: 50, left: 0, bottom: 0 }}
                    >
                      <XAxis type="number" hide domain={xDomain} />
                      <YAxis type="category" width={220} dataKey="name" display="none" />
                      {/* <Tooltip content={<CustomTooltip />} /> */}
                      <Bar
                        dataKey="uv"
                        fill="#C7C7C7"
                        label={{
                          fill: '#fff',
                          position: 'right',
                          formatter: (value) => `${value}%`,
                          className: 'bar-chart-percent-section3',
                          offset: 12
                        }}
                      >
                        <LabelList
                          dataKey="name"
                          fill="#fff"
                          position="left"
                          offset={20}
                          content={CustomLabelContent}
                        />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={5.5}>
            <Box className="right-item">
              <Typography className="right-title-text" variant="body1">
                {t('people-segment.hub.section3.contentText1')}
              </Typography>
              <Typography className="right-desc-text">{t('people-segment.hub.section3.contentText2')}</Typography>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignSelf: 'flex-end' }}>
          <span className="info-div-hub">
            <InfoOutlinedIcon color="primary" />
            <Typography variant="body2" className="info-div-typo">
              {t('people-segment.hub.section3.contentText3')}
            </Typography>
          </span>
        </Box>
      </Box>

      <Box className="key-factors-frame">
        <Box className="kf-centertext-box">
          <Typography className="kf-text-header5" variant="h3">
            <Trans components={{ colored: <i /> }}>{t('people-segment.hub.section3.contentText4')}</Trans>
          </Typography>
        </Box>
        <Box className="div2">
          <Box className="kf-centertext-box">
            <Typography className="kf-text-header5" variant="h5">
              <Trans components={{ colored: <i /> }}>{t('people-segment.hub.section3.contentText5')}</Trans>
            </Typography>
          </Box>
          <Box sx={{ margin: '0 6.25rem' }}>
            <Paper className="rectangular-card-background">
              <Grid container className="rectangular-card-container">
                <Grid xs={3} item>
                  <Typography className="content-left-right-text" variant="body2">
                    {t('people-segment.hub.section3.contentText6')}
                  </Typography>
                </Grid>
                <Grid xs={6} item className="flex-align-center">
                  <div className="flex-align-div">
                    <FavoriteIcon className="yellow-icon-style" />
                    <Typography variant="h4" className="content-icon-text">
                      <Trans>{t('people-segment.hub.section3.contentText8')}</Trans>
                    </Typography>
                  </div>
                  <div>
                    <Typography className="vs-text" variant="h5">
                      {t('people-segment.hub.section3.vs')}
                    </Typography>
                  </div>
                  <div className="flex-align-div">
                    <ExploreIcon className="yellow-icon-style" />
                    <Typography variant="h4" className="content-icon-text">
                      {t('people-segment.hub.section3.contentText9')}
                    </Typography>
                  </div>
                </Grid>
                <Grid xs={3} item>
                  <Typography className="content-left-right-text" variant="body2">
                    {t('people-segment.hub.section3.contentText7')}
                  </Typography>
                </Grid>
              </Grid>
              <div className="greydivider-with-arrow-container">
                <DividerWithArrows />
              </div>
            </Paper>
          </Box>
        </Box>
        <Box className="div3">
          <Box className="kf-centertext-box">
            <Typography className="kf-text-header5" variant="h5">
              <Trans components={{ colored: <i /> }}>{t('people-segment.hub.section3.contentText10')}</Trans>
            </Typography>
          </Box>
          <Box sx={{ margin: '0 6.25rem' }}>
            <Paper className="rectangular-card-background">
              <Grid container className="rectangular-card-container">
                <Grid xs={3} item>
                  <Typography variant="body2" className="content-left-right-text">
                    {t('people-segment.hub.section3.contentText11')}
                  </Typography>
                </Grid>
                <Grid xs={6} item className="flex-align-center">
                  <div className="flex-align-div">
                    <SelfImprovementIcon className="yellow-icon-style" />
                    <Typography variant="h4" className="content-icon-text">
                      {t('people-segment.hub.section3.contentText12')}
                    </Typography>
                  </div>

                  <div>
                    <Typography variant="h5" className="vs-text">
                      {t('people-segment.hub.section3.vs')}
                    </Typography>
                  </div>
                  <div className="flex-align-div">
                    <DirectionsRunIcon className="yellow-icon-style" />
                    <Typography variant="h4" className="content-icon-text">
                      {t('people-segment.hub.section3.contentText13')}
                    </Typography>
                  </div>
                </Grid>
                <Grid xs={3} item>
                  <Typography variant="body2" className="content-left-right-text">
                    {t('people-segment.hub.section3.contentText14')}
                  </Typography>
                </Grid>
              </Grid>
              <div className="greydivider-with-arrow-container">
                <DividerWithArrows />
              </div>
            </Paper>
          </Box>
        </Box>
        <Box className="div4">
          <Box className="defining-ps-container">
            {/* <Paper className="paper-background"> */}
            <Grid container className="grid-container text-with-graph-container">
              <Grid item xs={5} className="stack-texts">
                <Typography variant="h6" className="defining-ps-hero-text">
                  {t('people-segment.hub.section3.heroText3')}
                </Typography>
                <Typography variant="body1" className="defining-ps-content-text">
                  {t('people-segment.hub.section3.contentText18')}
                </Typography>
              </Grid>
              <Grid item xs={7} className="graph-container-with-4-elements">
                <div className="graph-container">
                  {/* Horizontal axis */}
                  <div className="horizontal-axis">
                    <KeyboardArrowLeft className="arrow-left" />
                    <div className="x-axis-label-left label-text">
                      {t('people-segment.hub.section3.contentText8Short')}
                    </div>
                    <div className="x-axis-label-right label-text">{t('people-segment.hub.section3.contentText9')}</div>
                    <KeyboardArrowRight className="arrow-right" />
                  </div>
                  {/* Vertical axis */}
                  <div className="vertical-axis">
                    {/* <div className="arrow-up-container"> */}
                    <KeyboardArrowUp className="arrow-up" />
                    {/* </div> */}
                    <div className="y-axis-label-top label-text">{t('people-segment.hub.section3.contentText13')}</div>
                    <div className="y-axis-label-bottom label-text">
                      {t('people-segment.hub.section3.contentText12')}
                    </div>
                    {/* <div className="arrow-down-container"> */}
                    <KeyboardArrowDown className="arrow-down" />
                    {/* </div> */}
                  </div>

                  <div className="quadrant top-left">
                    <RoundedDiv
                      text={t('people-segment.hub.section3.outgoingFamiliar')}
                      color1="#8fe8bd"
                      color2="#79d271"
                    />
                  </div>
                  <div className="quadrant top-right">
                    <RoundedDiv
                      text={t('people-segment.hub.section3.outgoingExploratory')}
                      color1="#A1E8F8"
                      color2="#83BCFF"
                    />
                  </div>
                  <div className="quadrant bottom-left">
                    <RoundedDiv
                      text={t('people-segment.hub.section3.reservedFamiliar')}
                      color1="#ADA1F8"
                      color2="#B983FF"
                    />
                  </div>
                  <div className="quadrant bottom-right">
                    <RoundedDiv
                      text={t('people-segment.hub.section3.reservedExploratory')}
                      color1="#FFC759"
                      color2="#F81"
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
            {/* </Paper> */}
          </Box>
        </Box>
        <Box className="div5">
          <Box className="kf-centertext-box">
            <Typography variant="h3" className="kf-text-header5">
              <Trans components={{ colored: <i /> }}>{t('people-segment.hub.section3.contentText15')}</Trans>
            </Typography>
          </Box>
          <Box className="supporting-factors-box">
            <Stack direction="row" spacing={5}>
              {FACTOR_CONTENTS.map((i) => (
                <FactorContents key={i.title} icon={i.icon} title={t(i.title)} content={t(i.content)} />
              ))}
            </Stack>
          </Box>
        </Box>
      </Box>
      <Box className="defining-ps-frame">
        <Box className="defining-ps-container">
          {/* <Paper className="paper-background"> */}
          <Grid container className="text-with-graph-container">
            <Grid item xs={5} className="stack-texts">
              <Typography variant="h6" className="defining-ps-hero-text">
                {t('people-segment.hub.section3.heroText3')}
              </Typography>
              <Typography variant="body1" className="defining-ps-content-text">
                {t('people-segment.hub.section3.contentText16')}
              </Typography>
            </Grid>
            <Grid item xs={7} className="graph-container-with-8-elements">
              <div className="graph-container">
                {/* Horizontal axis */}
                <div className="horizontal-axis">
                  <KeyboardArrowLeft className="arrow-left" />
                  <div className="x-axis-label-left label-text">
                    {t('people-segment.hub.section3.contentText8Short')}
                  </div>
                  <div className="x-axis-label-right label-text">{t('people-segment.hub.section3.contentText9')}</div>
                  <KeyboardArrowRight className="arrow-right" />
                </div>
                {/* Vertical axis */}
                <div className="vertical-axis">
                  <KeyboardArrowUp className="arrow-up" />
                  <div className="y-axis-label-top label-text">{t('people-segment.hub.section3.contentText13')}</div>
                  <div className="y-axis-label-bottom label-text">{t('people-segment.hub.section3.contentText12')}</div>
                  <KeyboardArrowDown className="arrow-down" />
                </div>

                <div className="quadrant top-left">
                  <div className="item1">
                    <RoundedDiv
                      text={t('people-segment.type.Fram_PSGMT_9')} //"Proud Celebrators"
                      color1="#79d271"
                      color2="#79d271"
                    />
                  </div>
                  <div className="item2">
                    <RoundedDiv
                      text={t('people-segment.type.Fram_PSGMT_8')} //"Carefree Fun-Lovers"
                      color1="#8fe8bd"
                      color2="#8fe8bd"
                    />
                  </div>
                </div>
                <div className="quadrant top-right">
                  <div className="item1">
                    <RoundedDiv
                      text={t('people-segment.type.Fram_PSGMT_12')} //"Social Curators"
                      color1="#83BCFF"
                      color2="#83BCFF"
                    />
                  </div>
                  <div className="item2">
                    <RoundedDiv
                      text={t('people-segment.type.Fram_PSGMT_11')} //"Creative Explorers"
                      color1="#A1E8F8"
                      color2="#A1E8F8"
                    />
                  </div>
                </div>
                <div className="quadrant bottom-left">
                  <div className="item1">
                    <RoundedDiv
                      text={t('people-segment.type.Fram_PSGMT_3')} //"Loyal Traditionalists"
                      color1="#ADA1F8"
                      color2="#ADA1F8"
                    />
                  </div>
                  <div className="item2">
                    <RoundedDiv
                      text={t('people-segment.type.Fram_PSGMT_2')} //"Comfort Cravers"
                      color1="#B983FF"
                      color2="#B983FF"
                    />
                  </div>
                </div>
                <div className="quadrant bottom-right">
                  <div className="item1">
                    <RoundedDiv
                      text={t('people-segment.type.Fram_PSGMT_6')} //"Diligent Discoverers"
                      color1="#FF8811"
                      color2="#FF8811"
                    />
                  </div>
                  <div className="item2">
                    <RoundedDiv
                      text={t('people-segment.type.Fram_PSGMT_5')} //"Balanced Enjoyers"
                      color1="#FFC759"
                      color2="#FFC759"
                    />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          {/* </Paper> */}
        </Box>
      </Box>
      <Box className="ps-horizontal-card-box">
        <Box className="kf-centertext-box">
          <Typography variant="h3" className="kf-text-header5">
            <Trans components={{ colored: <i /> }}>{t('people-segment.hub.section3.contentText17')}</Trans>
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.25rem', margin: '0 6.25rem' }}>
          <Box className="divider-with-text-container">
            <Typography className="arrow-text">{t('people-segment.hub.section3.contentText19')}</Typography>
            <DividerWithArrows />
            <Typography className="arrow-text">{t('people-segment.hub.section3.contentText20')}</Typography>
          </Box>

          <Box className="people-segment-cards">
            <Grid container spacing={1.5}>
              <Grid item xs={3}>
                <CardTitle
                  color="Fram_PSGMT_1"
                  title={
                    //t("people-segment.hub.section2.title1")
                    t('people-segment.macroPeopleSegment.Fram_PSGMT_1')
                  }
                />
                <CardContent cardArray={FAMILIAR_RESERVED_CARDS} />
              </Grid>
              <Grid item xs={3}>
                <CardTitle
                  color="Fram_PSGMT_7"
                  title={
                    //t("people-segment.hub.section2.title2")
                    t('people-segment.macroPeopleSegment.Fram_PSGMT_7')
                  }
                />
                <CardContent cardArray={FAMILIAR_OUTGOING_CARDS} />
              </Grid>
              <Grid item xs={3}>
                <CardTitle
                  color="Fram_PSGMT_10"
                  title={
                    //t("people-segment.hub.section2.title4")
                    t('people-segment.macroPeopleSegment.Fram_PSGMT_10')
                  }
                />
                <CardContent cardArray={EXPLORATORY_OUTGOING_CARDS} />
              </Grid>
              <Grid item xs={3}>
                <CardTitle
                  color="Fram_PSGMT_4"
                  title={
                    //t("people-segment.hub.section2.title3")
                    t('people-segment.macroPeopleSegment.Fram_PSGMT_4')
                  }
                />
                <CardContent cardArray={EXPLORATORY_RESERVED_CARDS} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
