import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Box, Toolbar, Typography, Button, Container } from '@mui/material/';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PropTypes from 'prop-types';
import { assets } from './images';
import CatTripDropDownMenu from './CatTripDropDownMenu';

function CatTripHeaderNav({ navOptions }) {
  const navigate = useNavigate();
  const [hasHeaderItemHovered, setHasHeaderItemHovered] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState('');

  function navItemHoverHandler(params) {
    setHasHeaderItemHovered(params.isHovered);
    setSelectedSegment(params.segment);
  }

  function toggleMenu() {
    setHasHeaderItemHovered(false);
    setSelectedSegment('');
  }

  function navigationClickHandler(path) {
    navigate(`/${path}`);
    window.scrollTo(0, 0);
  }

  return (
    <nav className="page-header" data-testid="page-header" onMouseLeave={() => toggleMenu()}>
      <AppBar component="nav" position="fixed" sx={{ bgcolor: '#000000' }}>
        <Toolbar variant="dense" className="nav-bar-container">
          <Button
            className="header-logo"
            startIcon={<img src={assets.logo} alt="Brand logo" />}
            onClick={() => {
              navigate('/');
              window.scrollTo(0, 0);
            }}
          >
            <Box className="header-text">
              <Typography className="header-text-typo">Human Understanding</Typography>
              <Typography className="header-text-typo">Ecosystem</Typography>
            </Box>
          </Button>
          <Typography variant="h6" component="div" className="header-typo1" />
          <Box className="box-header-content">
            <Button className="header-nav-item2 logged-in" onClick={() => navigationClickHandler('home')}>
              {navOptions.homeLink.title}
            </Button>
            <Button
              className="header-nav-item2 logged-in"
              onClick={() => navigationClickHandler(navOptions.landingPageLink.path)}
            >
              {navOptions.landingPageLink.title}
            </Button>
            <Button
              className="header-nav-item2 logged-in"
              onClick={() => navigationClickHandler(navOptions.frameworkLink.path)}
            >
              {navOptions.frameworkLink.title}
            </Button>
            <Button
              className="header-nav-item2 logged-in"
              key={navOptions.navDropdown.title}
              onMouseOver={() =>
                navItemHoverHandler({
                  isHovered: true,
                  segment: navOptions.navDropdown.segment
                })
              }
            >
              {navOptions.navDropdown.title}
              <KeyboardArrowDownIcon />
            </Button>

            <Button className="header-nav-item2 logged-in" onClick={() => navigationClickHandler('help')}>
              User Guide
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      {hasHeaderItemHovered && (
        <Container className="dropdown" data-testid="dropdown-menu" disableGutters={true}>
          <CatTripDropDownMenu selectedSegment={selectedSegment} closeHandler={toggleMenu} />
        </Container>
      )}
    </nav>
  );
}

CatTripHeaderNav.propTypes = {
  navOptions: PropTypes.shape({
    homeLink: PropTypes.object.isRequired,
    landingPageLink: PropTypes.object.isRequired,
    frameworkLink: PropTypes.object.isRequired,
    navDropdown: PropTypes.object.isRequired
  }).isRequired
};

export default CatTripHeaderNav;
