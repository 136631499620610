import React from 'react';
import PropTypes from 'prop-types';
import styles from './TestimonialCard.module.css';

export function TestimonialCard({
  variant,
  quote,
  width,
  subcatAccentColor,
  textColor1,
  textColor2,
  quoteIcon1,
  quoteIcon2
}) {
  return (
    <article className={`${styles.card} ${styles[variant]} ${styles[width]}`}>
      <div
        className={styles.cardBackground}
        style={variant === 'colored' ? { backgroundColor: subcatAccentColor } : {}}
      />
      <img
        src={variant === 'colored' ? quoteIcon2 : quoteIcon1}
        alt="quote icon"
        className={styles.icon}
        loading="lazy"
      />
      <blockquote
        className={styles.quote}
        style={variant === 'transparent' ? { color: textColor2 } : { color: textColor1 }}
      >
        {quote}
      </blockquote>
    </article>
  );
}

TestimonialCard.propTypes = {
  variant: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
  width: PropTypes.string,
  subcatAccentColor: PropTypes.string.isRequired,
  textColor1: PropTypes.string.isRequired,
  textColor2: PropTypes.string.isRequired,
  quoteIcon1: PropTypes.string.isRequired,
  quoteIcon2: PropTypes.string.isRequired
};
