import React from 'react';
import PropTypes from 'prop-types';
import { DonutChart } from './DonutChart';
import styles from './ChartCard.module.css';

export const ChartCard = ({ chartTitle, legendData, chartImgUrl }) => {
  return (
    <div className={styles.chartCard}>
      <div className={styles.chartTitle}>{chartTitle}</div>
      <div className={styles.divider} />
      <div className={styles.chartContent}>
        <DonutChart legendData={legendData} chartImgUrl={chartImgUrl} chartImgAltText={`${chartTitle} chart`} />
      </div>
    </div>
  );
};

ChartCard.propTypes = {
  chartTitle: PropTypes.string.isRequired,
  legendData: PropTypes.array.isRequired,
  chartImgUrl: PropTypes.string.isRequired
};
