import React from 'react';
import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { peopleSegmentAssets } from '../../common/PeopleSegmentAssets';

//Page-hero Section
export default function SectionOne() {
  const { t } = useTranslation(i18next.language);
  return (
    <>
      <Box
        className="section1-hero-container-people-segment"
        sx={{
          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%),
        url(${peopleSegmentAssets.hubHeroImage()})`
        }}
      >
        <Box className="hero-content">
          <Typography className="hero-text" variant="h2">
            {t('people-segment.hub.section1.heroTitleIntro')}
          </Typography>
          <Box className="hero-title-text-description">
            <Typography component="span" className="hero-content-text" variant="h5">
              <Trans components={{ colored: <i /> }}>{t('people-segment.hub.section1.heroTitleText')}</Trans>
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
