import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../../../../constants/CatTripColors';
import styles from './PersonaCard.module.css';
import { StatCard } from './StatCard';

export const PersonaCard = ({ personas, peopleSegmentColor }) => {
  return (
    <div className={styles.personaContainer}>
      {personas.map((persona) => (
        <div key={persona.title} className={styles.personaItem}>
          <img
            loading="lazy"
            src={persona.imageUrl}
            alt={`${persona.title} persona icon`}
            className={styles.personaImage}
            style={{ border: `4px solid ${peopleSegmentColor}` }}
          />
          <StatCard
            title={persona.title}
            percentage={persona.percentage}
            count={persona.count}
            tagColor={COLORS[persona.tagColor]}
            peopleSegmentColor={peopleSegmentColor}
          />
        </div>
      ))}
    </div>
  );
};

PersonaCard.propTypes = {
  personas: PropTypes.array.isRequired,
  peopleSegmentColor: PropTypes.string.isRequired
};
