import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { COLORS } from '../../../../constants/CatTripColors';
import { getAccentTextColor } from '../../../../constants/GetAccentTextColor';
import { StatCard } from './compound-components/StatCard';
import { BasketStats } from './compound-components/BasketStats';
import { CategoryTable } from './compound-components/CategoryTable';
import styles from './BasketComposition.module.css';

export function BasketComposition({
  tripTypeColor,
  tableFooter,
  basketData,
  statsData,
  categoryData,
  nonAlcItemCount
}) {
  const { t } = useTranslation(i18next.language);
  const accentTextColor = getAccentTextColor(tripTypeColor);

  return (
    <div className={styles.basketComposition}>
      <header className={styles.header}>
        <div className={styles.subtitle}>{t('tripTypesPageTemplate.basketComposition.eyebrow')}</div>
        <h1 className={styles.title}>{t('tripTypesPageTemplate.basketComposition.title')}</h1>
      </header>

      <div className={styles.content}>
        <div className={styles.mainStats}>
          <StatCard stats={basketData} tableFooter={tableFooter} />
          <BasketStats stats={statsData} />
        </div>

        <div className={styles.categorySection}>
          <div className={styles.nonAlcHeader} style={{ backgroundColor: COLORS[tripTypeColor] }}>
            {accentTextColor === '#fff' ? (
              <img
                src="https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fab4c86f55fa84586b85a2a88a9d3258f"
                alt={t('tripTypesPageTemplate.basketComposition.nonAlcItemCard.nonAlcIconAlt')}
                className={styles.nonAlcIcon}
              />
            ) : (
              <img
                src="https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F90a1d91b43dc40c2b30fdc2c9b69d304"
                alt={t('tripTypesPageTemplate.basketComposition.nonAlcItemCard.nonAlcIconAlt')}
                className={styles.nonAlcIcon}
              />
            )}
            <div className={styles.nonAlcStats}>
              <div className={styles.nonAlcTitle} style={{ color: accentTextColor }}>
                {t('tripTypesPageTemplate.basketComposition.nonAlcItemCard.title')}
              </div>
              <div className={styles.nonAlcAmount} style={{ color: accentTextColor }}>
                ~{nonAlcItemCount} {t('tripTypesPageTemplate.basketComposition.nonAlcItemCard.amount')}
              </div>
            </div>
          </div>

          <CategoryTable categories={categoryData} />
        </div>
      </div>

      {tableFooter && <footer className={styles.footer}>*Total and Non-Alc Spend data does not include gas</footer>}
    </div>
  );
}

BasketComposition.propTypes = {
  tripTypeColor: PropTypes.string.isRequired,
  tableFooter: PropTypes.bool,
  basketData: PropTypes.object.isRequired,
  statsData: PropTypes.arrayOf(PropTypes.object).isRequired,
  categoryData: PropTypes.array,
  nonAlcItemCount: PropTypes.number.isRequired
};
