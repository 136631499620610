import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function Loader() {
  const { t } = useTranslation(i18next.language);
  return (
    <Box className="loading-component-container">
      <CircularProgress />
      <Typography className="loading-text">{t('common.loading')}</Typography>
    </Box>
  );
}

export default Loader;
