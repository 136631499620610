import React from 'react';
import PropTypes from 'prop-types';
import styles from './CategoryHubHeader.module.css';

export function CategoryHubHeader({ backgroundImageUrl, title, descriptionStart, highlightedText, descriptionEnd }) {
  return (
    <section className={styles.hero}>
      <img loading="lazy" src={backgroundImageUrl} className={styles.heroBackground} alt={title} />
      <div className={styles.heroContent}>
        <h1 className={styles.heroTitle}>{title}</h1>
        <p className={styles.heroDescription}>
          {descriptionStart} <span className={styles.highlightedText}>{highlightedText}</span> {descriptionEnd}
        </p>
      </div>
    </section>
  );
}

CategoryHubHeader.propTypes = {
  backgroundImageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  descriptionStart: PropTypes.string.isRequired,
  highlightedText: PropTypes.string.isRequired,
  descriptionEnd: PropTypes.string.isRequired
};
