import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, Grid } from '@mui/material/';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TripTypesMenuOptions } from '../../constants/TripTypesCats';
import { AlcoholCategoriesMenuOptions } from '../../constants/AlcoholCats';

function CatTripDropDownMenu({ selectedSegment, closeHandler }) {
  const navigate = useNavigate();

  function getMenuOptions(selectedSegment) {
    let menuOptions = [];
    switch (selectedSegment) {
      case 'alcohol-categories':
        menuOptions = AlcoholCategoriesMenuOptions;
        break;
      case 'trip-types':
        menuOptions = TripTypesMenuOptions;
        break;
      default:
        break;
    }

    return menuOptions;
  }

  function getNavigationPath(segment, params) {
    let path = '';
    switch (segment) {
      case 'alcohol-categories':
        path = params ? `/categories/${params.path}` : '/categories';
        break;
      case 'trip-types':
        path = params ? `/trip-types/${params.path}` : '/trip-types';
        break;
      default:
        break;
    }
    return path;
  }

  return (
    <Box className="cat-trip-drop-down-menu-container">
      <Grid
        container
        className="cat-trip-menu-container"
        gap={5.5}
        sx={selectedSegment === 'trip-types' && { paddingLeft: '53vw' }}
      >
        <Grid item xs={9} className="cat-trip-right-menu-item" data-testid="right-menu-grid">
          <Grid container className="cat-trip-menu-items-container">
            {getMenuOptions(selectedSegment).map((menu, index) => (
              <Grid
                item
                xs={9 / getMenuOptions(selectedSegment).length}
                key={`dropdown-menuitem-container-${index + 1}`}
              >
                <Box
                  className="cat-trip-menu-list-items"
                  sx={selectedSegment === 'trip-types' && { marginTop: '-20px' }}
                >
                  {selectedSegment === 'alcohol-categories' && (
                    <Box className={`cat-trip-menu-header-container ${selectedSegment}`}>
                      {menu.header && (
                        <Typography
                          className={`cat-trip-menu-header ${selectedSegment} header-${index + 1}`}
                          onClick={() => {
                            closeHandler();
                            navigate(menu.header.path);
                            window.scrollTo(0, 0);
                          }}
                        >
                          {menu.header.category} <ArrowForwardIcon className="arrow-icon" />
                        </Typography>
                      )}
                    </Box>
                  )}
                  <List className="cat-trip-menu-list-item-container">
                    {menu.listOfItems.map((item, index) => (
                      <ListItem
                        key={`cat-trip-menu-list-item-${index + 1}`}
                        className="cat-trip-menu-list-item"
                        onClick={() => {
                          const path = getNavigationPath(selectedSegment, item.routeParams);
                          closeHandler();
                          navigate(path);
                          window.scrollTo(0, 0);
                        }}
                      >
                        <ListItemText
                          primary={<Typography className={`cat-trip-menu-list-item-text`}>{item.itemText}</Typography>}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

CatTripDropDownMenu.propTypes = {
  selectedSegment: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired
};

export default CatTripDropDownMenu;
