import { AppBar, Box, Toolbar, Typography, Button } from '@mui/material/';
import { assets } from './images';

function HomeHeader() {
  return (
    <div className="page-header">
      <AppBar component="nav" position="fixed" sx={{ bgcolor: '#000000' }}>
        <Toolbar variant="dense" className="nav-bar-container">
          <Button className="header-logo" startIcon={<img src={assets.logo} alt="Brand logo" />}>
            <Box className="header-text">
              <Typography className="header-text-typo">Human Understanding</Typography>
              <Typography className="header-text-typo">Ecosystem</Typography>
            </Box>
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default HomeHeader;
