import { Box, Card, CardContent, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { ReactComponent as BeerIcon } from '../../../assets/images/Common/consumption-cards/Beer.svg';
import { ReactComponent as SpiritsIcon } from '../../../assets/images/Common/consumption-cards/Spirits.svg';
import { ReactComponent as WineIcon } from '../../../assets/images/Common/consumption-cards/Wine.svg';
import { ReactComponent as HardbevIcon } from '../../../assets/images/Common/consumption-cards/HardBev.svg';
import { getTemplateColors } from '../../../constants/constants';

function ConsumptionVisualCard({ bevData, space }) {
  const { t } = useTranslation(i18next.language);
  const color = getTemplateColors(space);

  useEffect(() => {
    if (localStorage.getItem('i18nnextLng')?.length > 2) {
      i18next.changeLanguage('en');
    }
  }, []);

  function getBeverageIcon(type) {
    let icon = null;
    switch (type) {
      case 'Fram_CBR_1':
        icon = <BeerIcon fill={color} stroke={color} className="bev-icon" />;
        break;
      case 'Fram_CS_1':
        icon = <SpiritsIcon fill={color} stroke={color} className="bev-icon" />;
        break;
      case 'Fram_CW_1':
        icon = <WineIcon fill={color} className="bev-icon" />;
        break;
      case 'Fram_CHB_1':
        icon = <HardbevIcon fill={color} stroke={color} className="bev-icon" />;
        break;
      default:
        break;
    }
    return icon;
  }

  function getColor(score) {
    if (score < 85) {
      return 'negative';
    } else if (score >= 85 && score <= 115) {
      return 'neutral';
    } else {
      return 'positive';
    }
  }

  function formatDrinkName(drinkName) {
    return drinkName
      .replace(/beverages/i, 'bev')
      .replace(/\s*\(NET\)/, '')
      .replace(/\s*\+ NAB/, '');
  }
  return (
    <Card className="consumption-card-container">
      <CardContent className="consumption-card-content">
        {getBeverageIcon(bevData.drinkIdentifier)}
        <Typography className="beverage-name" variant="h4">
          {formatDrinkName(bevData.drinkName)}
        </Typography>
        <Box className="beverage-data-container">
          <Box className="volume-data">
            <Typography className="data-heading" variant="body1">
              {t('common.consumptionCard.percentVolumeHeading')}
            </Typography>
            <Typography className="data-value" variant="h4">
              {t('common.consumptionCard.percentVolumeData', {
                percentVolume: Math.round(bevData.drinkVolume)
              })}
            </Typography>
          </Box>
          <Box className="index-data">
            <Typography className="data-heading" variant="body1">
              {t('common.consumptionCard.indexHeading')}
            </Typography>
            <Typography className={`data-value ${getColor(bevData.drinkIndex)}`} variant="h4">
              {Math.round(bevData.drinkIndex)}
            </Typography>
          </Box>
        </Box>
        {/* <Typography className="beverage-info" variant="body1">
          {t("common.consumptionCard.indexScoreInfo", {
            indexScore: Math.round(Math.abs(100 - bevData.drinkIndex)),
            adjective:
              bevData.drinkIndex < 100 ? t("common.less") : t("common.more"),
            bevType: bevData.drinkName.match(/hard/gi)
              ? t("common.consumptionCard.hardbevDisplayText")
              : formatDrinkName(bevData.drinkName),
          })}
        </Typography> */}
      </CardContent>
    </Card>
  );
}

ConsumptionVisualCard.propTypes = {
  bevData: PropTypes.object.isRequired,
  space: PropTypes.string.isRequired
};

export default ConsumptionVisualCard;
