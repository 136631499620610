import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../../../constants/CatTripColors';
import { getAccentTextColor } from '../../../../constants/GetAccentTextColor';
import { BasketStatsCard } from './compound-components/BasketStatsCard';
import styles from './BasketStats.module.css';

export const BasketStats = ({ tripTypeColor, categoryData }) => {
  const accentTextColor = getAccentTextColor(tripTypeColor);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.subheading}>Basket</div>
        <div className={styles.heading}>Categories Purchased</div>
      </div>
      <div className={styles.statsGrid}>
        {categoryData.map((category) => (
          <BasketStatsCard
            key={category.beverageCategory}
            {...category}
            tripTypeColor={COLORS[tripTypeColor]}
            accentTextColor={accentTextColor}
          />
        ))}
      </div>
    </div>
  );
};

BasketStats.propTypes = {
  tripTypeColor: PropTypes.string.isRequired,
  categoryData: PropTypes.array.isRequired
};
