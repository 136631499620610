import { createSlice } from '@reduxjs/toolkit';

export const LoginSlice = createSlice({
  name: 'login',
  initialState: {
    isLoggedIn: false,
    isTermsConditionsAccepted: false
  },
  reducers: {
    setLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setIsTermsConditionsAccepted: (state, action) => {
      state.isTermsConditionsAccepted = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setLoggedIn, setIsTermsConditionsAccepted } = LoginSlice.actions;

export default LoginSlice.reducer;
