import { Box, Grid } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import QuoteAttributionCard from './QuoteAttributionCard';
import ImageCard from './ImageCard';

export default function JumboQuoteImageLeft({ theme, cardData }) {
  return (
    <Box className="jumboquoteimageleft-card-container">
      <Grid container className="card-content-container">
        <Grid item xs={6} xl={6}>
          <ImageCard theme={theme} cardData={cardData.imagecarddata} />
        </Grid>
        <Grid item xs={6} xl={6}>
          <QuoteAttributionCard theme={theme} cardData={cardData.quoteattributioncarddata} />
        </Grid>
      </Grid>
    </Box>
  );
}

JumboQuoteImageLeft.propTypes = {
  theme: PropTypes.string.isRequired,
  cardData: PropTypes.object.isRequired
};
