import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Box, Toolbar, Typography, Button, Container } from '@mui/material/';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { NavOptions } from '../../constants/NavOptions';
import { assets } from './images';

import DropDownMenu from './DropDownMenu';

function DemandLandscapeHeader() {
  const navigate = useNavigate();
  const { t } = useTranslation(i18next.language);
  const [hasHeaderItemHovered, setHasHeaderItemHovered] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState('');

  useEffect(() => {
    if (localStorage.getItem('i18nnextLng')?.length > 2) {
      i18next.changeLanguage('en');
    }
  });

  function navItemHoverHandler(params) {
    setHasHeaderItemHovered(params.isHovered);
    setSelectedSegment(params.segment);
  }

  function toggleMenu() {
    setHasHeaderItemHovered(false);
    setSelectedSegment('');
  }

  function goToUserGuide() {
    navigate('/help');
    window.scrollTo(0, 0);
  }

  const sanitizeLocation = (data) => {
    return data === 'Not Available' ? 'USA' : data;
  };

  return (
    <div className="page-header" onMouseLeave={() => toggleMenu()}>
      <AppBar component="nav" position="fixed" sx={{ bgcolor: '#000000' }}>
        <Toolbar variant="dense" className="nav-bar-container">
          <Button
            className="header-logo"
            startIcon={
              <img
                src={assets.logo}
                // onClick={() => {
                //   navigate("/")
                //   window.scrollTo(0,0)
                // }}
                alt="Brand logo"
              />
            }
            onClick={() => {
              navigate('/demand-landscape');
              window.scrollTo(0, 0);
            }}
          >
            <Box className="header-text">
              <Typography className="header-text-typo">{t('projectTitle')}</Typography>
              <Typography className="header-text-typo2">{sanitizeLocation(t('location'))}</Typography>
            </Box>
          </Button>
          <Typography variant="h6" component="div" className="header-typo1" />
          <Box className="box-header-content">
            {NavOptions.homeHeaderOptions.map((item) => (
              <Button
                className="header-nav-item2 logged-in"
                key={item.title}
                onMouseOver={() =>
                  navItemHoverHandler({
                    isHovered: true,
                    segment: item.segment
                  })
                }
                onClick={() => {
                  navigate(item.path);
                  window.scrollTo(0, 0);
                }}
              >
                {item.title}
                <KeyboardArrowDownIcon />
              </Button>
            ))}
            <Button className="header-nav-item2 logged-in" onClick={goToUserGuide}>
              {t('header.userGuide')}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      {/* <Drawer
        className="dropdown"
        anchor="top"
        open={hasHeaderItemHovered}
        onClose={toggleMenu}
        hideBackdrop={true}
      >
        <DropDownMenu
          selectedSegment={selectedSegment}
          closeHandler={toggleMenu}
        />
      </Drawer> */}
      {hasHeaderItemHovered && (
        <Container className="dropdown" disableGutters={true}>
          <DropDownMenu selectedSegment={selectedSegment} closeHandler={toggleMenu} />
        </Container>
      )}
    </div>
  );
}

export default DemandLandscapeHeader;
