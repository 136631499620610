export const CatTripNavOptions = {
  categoryStructureNavOptions: {
    homeLink: {
      title: 'HUE Home',
      path: 'home'
    },
    landingPageLink: {
      title: 'What is Category Structure',
      path: 'categories'
    },
    frameworkLink: {
      title: 'Category Structure Framework',
      path: 'categories/framework'
    },
    navDropdown: {
      title: 'Category Structure',
      segment: 'alcohol-categories',
      type: 'drawer'
    }
  },

  tripTypesNavOptions: {
    homeLink: {
      title: 'HUE Home',
      path: 'home'
    },
    landingPageLink: {
      title: 'What Are Trip Types',
      path: 'trip-types'
    },
    frameworkLink: {
      title: 'Trip Types Framework',
      path: 'trip-types/framework'
    },
    navDropdown: {
      title: 'Trip Types',
      segment: 'trip-types',
      type: 'drawer'
    }
  }
};
