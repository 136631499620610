import { Box, Card, CardContent, CardMedia, Divider, Grid, Typography } from '@mui/material';
import FormatQuoteSharpIcon from '@mui/icons-material/FormatQuoteSharp';
import React from 'react';
import PropTypes from 'prop-types';
import { css, styled } from '@mui/material/styles';

export const PeopleDetails = ({ textColor = 'black', attribution }) => {
  const attributions = attribution.split('|');
  return (
    <Box className="people-details-full-box">
      {attributions.map((attributionItem, index) => (
        <React.Fragment key={`attributionItem${index}`}>
          <Typography className="people-details-typos" variant="body1" color={textColor}>
            {attributionItem}
          </Typography>
          {index !== attributions.length - 1 && (
            <Divider orientation="vertical" className={`attributions-divider`} color={textColor} />
          )}
        </React.Fragment>
      ))}
      {/*  <Typography className="people-details-typos"
          variant="body1" color={textColor}>
          { 
            attributions[0] 
          }
        </Typography>
        <Typography component='div'
          className='attributions-divider'
          color={textColor}>
          { " | " }
        </Typography>
        <Typography className="people-details-typos"
          variant="body1" color={textColor}>
          { 
            attributions[1] 
          }
        </Typography>
        {
          attributions[2] && 
          (
            <>
              <Typography component='div'
                className='attributions-divider'
                color={textColor}>
                { " | " }
              </Typography>
              <Typography className="people-details-typos"
                variant="body1" color={textColor}>
                { 
                  attributions[2]
                }
              </Typography>
            </>
          )
        }
      */}
    </Box>
  );
};

PeopleDetails.propTypes = {
  textColor: PropTypes.string.isRequired,
  attribution: PropTypes.string.isRequired
};

const stylesFromProps = {
  shouldForwardProp: (prop) => prop !== 'image1' && prop !== 'image2' && prop !== 'alignImage'
};

const ImageCardMedia = styled(
  CardMedia,
  stylesFromProps
)(({ image1, image2, alignImage }) =>
  css({
    height: (image1 && image2) || alignImage === 'top' || alignImage === 'bottom' ? '50%' : '100%'
  })
);

export const BoxImage = ({ image1, image2, alignImage }) => {
  return (
    <Box
      className="ugc-box-image-div2"
      style={{
        height: (image1 && image2) || alignImage === 'top' || alignImage === 'bottom' ? '50%' : '100%'
      }}
    >
      {image1 && image2 && (
        <ImageCardMedia component="img" image={image1} image2={image2} alignImage={alignImage} alt="Live" />
      )}
      {image2 && <ImageCardMedia component="img" image={image2} alt="Live" />}
    </Box>
  );
};

BoxImage.propTypes = {
  image1: PropTypes.string.isRequired,
  image2: PropTypes.string.isRequired,
  alignImage: PropTypes.string.isRequired
};

export const BoxContent = ({ image2, quoteText, attribution, color, QuoteColor, textColor = 'black' }) => {
  return (
    <CardContent className="ugc-boxcontent-div">
      <Box className="ugc-boxcontent-div-box" height={image2 ? '90%' : '61%'}>
        <FormatQuoteSharpIcon style={{ color: QuoteColor }} className={image2 ? 'image2Quote' : 'image1Quote'} />
        <Typography
          className="ugc-boxcontent-div-box-typo1"
          component="div"
          color={color}
          fontSize={image2 ? '1.3rem' : '1rem'}
          fontWeight={image2 ? '700' : '600'}
        >
          {quoteText}
        </Typography>
        {/*name && (
            <Typography
              className="ugc-boxcontent-div-box-typo2"
              component="div"
              variant={image2 ? "h4" : "h5"}
              letterSpacing={1}
              marginBottom={image2 ?? "1rem"}
            >
              {name}
            </Typography>
          )
        */}
        <PeopleDetails attribution={attribution} image2={image2} textColor={textColor} />
      </Box>
    </CardContent>
  );
};

BoxContent.propTypes = {
  image2: PropTypes.string.isRequired,
  quoteText: PropTypes.string.isRequired,
  attribution: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  QuoteColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired
};

const UGCCardImage = ({
  image1,
  image2,
  quoteText,
  attribution,
  height,
  alignImage,
  color,
  space,
  QuoteColor,
  textColor,
  borderRadius
}) => {
  switch (alignImage) {
    case 'top':
      return;
    case 'bottom':
    case 'left':
    case 'right':
    default:
  }

  return (
    <Card
      className={`ugc-card-img-background ${space} ${!space && 'ugc-card-greycolor '}`}
      sx={{ height, borderRadius }}
    >
      <Grid container>
        <Grid item sm={6} xs={6} xl={6} md={6} direction={'row'}>
          {alignImage === 'right' || alignImage === 'bottom' ? (
            <BoxContent
              image2={image2}
              quoteText={quoteText}
              attribution={attribution}
              color={color}
              space={space}
              QuoteColor={QuoteColor}
              textColor={textColor}
              borderRadius={borderRadius}
            />
          ) : (
            <BoxImage
              color={color}
              image1={image1}
              image2={image2}
              alignImage={alignImage}
              borderRadius={borderRadius}
            />
          )}
        </Grid>
        <Grid item sm={6} xs={6} xl={6} md={6} direction={'row'}>
          {alignImage === 'right' || alignImage === 'bottom' ? (
            <BoxImage
              color={color}
              image1={image1}
              image2={image2}
              alignImage={alignImage}
              borderRadius={borderRadius}
            />
          ) : (
            <BoxContent
              image2={image2}
              quoteText={quoteText}
              QuoteColor={QuoteColor}
              attribution={attribution}
              color={color}
              space={space}
              textColor={textColor}
              borderRadius={borderRadius}
            />
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

UGCCardImage.propTypes = {
  image1: PropTypes.string.isRequired,
  image2: PropTypes.string.isRequired,
  quoteText: PropTypes.string.isRequired,
  attribution: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  alignImage: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  space: PropTypes.string.isRequired,
  QuoteColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  borderRadius: PropTypes.string.isRequired
};

export default UGCCardImage;
