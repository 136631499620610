import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import styles from './CategoryBars.module.css';

export default function CategoryBars() {
  const { t } = useTranslation(i18next.language);

  return (
    <div className={styles.container}>
      <div className={`${styles.barSection} ${styles.barNarrow}`}>
        <div className={styles.sectionBar}>{t('tripTypesFramework.categoryBars.tripFocus1')}</div>
        <div className={styles.scale}>
          <div className={styles.text}>
            <div className={styles.arrowLeft}>{t('tripTypesFramework.categoryBars.tripTypePurpose1')}</div>
            <div className={styles.arrowRight}>{t('tripTypesFramework.categoryBars.tripTypePurpose2')}</div>
          </div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F8eaab61204cb4c0a99bc561191bec8b7"
            className={styles.scaleImage}
            alt="Scale visualization"
          />
        </div>
      </div>
      <div className={`${styles.barSection} ${styles.barWide}`}>
        <div className={styles.sectionBar}>{t('tripTypesFramework.categoryBars.tripFocus2')}</div>
        <div className={styles.scale}>
          <div className={styles.text}>
            <div className={styles.arrowLeft}>{t('tripTypesFramework.categoryBars.tripTypePurpose1')}</div>
            <div className={styles.arrowRight}>{t('tripTypesFramework.categoryBars.tripTypePurpose2')}</div>
          </div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F418ca4b0a3e644388e99f3fadc133a7a"
            className={styles.scaleImage}
            alt="Scale visualization"
          />
        </div>
      </div>
    </div>
  );
}
