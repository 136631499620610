import { Avatar, Box, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import i18next from 'i18next';
import { Trans, useTranslation } from 'react-i18next';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from 'react-router-dom';
import { peopleSegmentAssets } from '../../common/PeopleSegmentAssets';

function SectionOne() {
  const { t } = useTranslation(i18next.language);
  const navigate = useNavigate();

  return (
    <Box className="hero-demand">
      <Box className="hero-head">
        <Box className="head-text-class">
          <Typography variant="h1" className="title-head">
            {t('demandspace.title')}
          </Typography>
          <Typography variant="h5" className="subtitle-head">
            <Trans i18nKey={'demandspaces.header-subtitle'} components={{ colored: <strong /> }}>
              {t('demandspace.hub.demandSpaceTitleDescription')}
            </Trans>
          </Typography>
        </Box>
        <Box className="wayfinder-component">
          <Paper className="layout-grid">
            {/* sx={{ width: "100%" }} */}
            <Grid container spacing={1.5}>
              <Grid container spacing={1.5} mt={0.5} mb={1.5}>
                <Grid item xs={1.3}></Grid>
                <Grid item xs={1.3}></Grid>
                <Grid item xs={2.35}>
                  <Grid container columnSpacing={1}>
                    <Grid item xs={12} mb={1}>
                      <Typography className="occ-layout-text1">
                        {t('demandspace.hub.segmentheading.familiarReserved')}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Paper
                        onClick={() => {
                          navigate('/demand-landscape/peoplesegment/Fram_PSGMT_1/Fram_PSGMT_3');
                          window.scrollTo(0, 0);
                        }}
                        sx={{
                          position: 'relative',
                          width: '100%',
                          overflow: 'hidden'
                        }}
                        className="type-of-occasions loyal-traditionalist"
                      >
                        <img
                          src={peopleSegmentAssets.heroImages.Fram_PSGMT_3()}
                          alt="loyaltraditionals"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover' // Ensure the image covers the entire container
                          }}
                        />
                        <Typography
                          className="card-text"
                          sx={{
                            position: 'absolute',
                            bottom: 8,
                            left: 0,
                            width: '100%',
                            color: 'white' // Optional: Set text color
                          }}
                        >
                          {/*t(
                          "demandspace.segmentheadinglist.loyaltraditionalists"
                        ) */}
                          {t('people-segment.type.Fram_PSGMT_3').split(' ').join('\n')}
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={6}>
                      <Paper
                        onClick={() => {
                          navigate('/demand-landscape/peoplesegment/Fram_PSGMT_1/Fram_PSGMT_2');
                          window.scrollTo(0, 0);
                        }}
                        sx={{
                          position: 'relative',
                          width: '100%',
                          overflow: 'hidden'
                        }}
                        className="type-of-occasions comfort-craver image-container"
                      >
                        <img
                          src={peopleSegmentAssets.heroImages.Fram_PSGMT_2()}
                          alt="comfortcravers"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover' // Ensure the image covers the entire container
                          }}
                        />
                        <Typography
                          className="card-text"
                          sx={{
                            position: 'absolute',
                            bottom: 8,
                            left: 0,
                            width: '100%',
                            color: 'white'
                          }}
                        >
                          {t('people-segment.type.Fram_PSGMT_2').split(' ').join('\n')}
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2.35}>
                  <Grid container columnSpacing={1}>
                    <Grid item xs={12} mb={1}>
                      <Typography className="occ-layout-text2">
                        {t('demandspace.hub.segmentheading.familiarOutgoing')}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Paper
                        onClick={() => {
                          navigate('/demand-landscape/peoplesegment/Fram_PSGMT_7/Fram_PSGMT_8');
                          window.scrollTo(0, 0);
                        }}
                        sx={{
                          position: 'relative',
                          width: '100%',
                          overflow: 'hidden'
                        }}
                        className="type-of-occasions carefree-fun-lover"
                      >
                        <img
                          src={peopleSegmentAssets.heroImages.Fram_PSGMT_8()}
                          alt="carefreefun"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover' // Ensure the image covers the entire container
                          }}
                        />
                        <Typography
                          className="card-text"
                          sx={{
                            position: 'absolute',
                            bottom: 8,
                            left: 0,
                            width: '100%',
                            color: 'white' // Optional: Set text color
                          }}
                        >
                          {/*t("demandspace.segmentheadinglist.carefreefunlovers") */}
                          {t('people-segment.type.Fram_PSGMT_8').split(' ').join('\n')}
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={6}>
                      <Paper
                        onClick={() => {
                          navigate('/demand-landscape/peoplesegment/Fram_PSGMT_7/Fram_PSGMT_9');
                          window.scrollTo(0, 0);
                        }}
                        sx={{
                          position: 'relative',
                          width: '100%',
                          overflow: 'hidden'
                        }}
                        className="type-of-occasions proud-celebrator"
                      >
                        <img
                          src={peopleSegmentAssets.heroImages.Fram_PSGMT_9()}
                          alt="proudcelebrators"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover' // Ensure the image covers the entire container
                          }}
                        />
                        <Typography
                          className="card-text"
                          sx={{
                            position: 'absolute',
                            bottom: 8,
                            left: 0,
                            width: '100%',
                            color: 'white' // Optional: Set text color
                          }}
                        >
                          {/*t("demandspace.segmentheadinglist.proudcelebrators")*/}
                          {t('people-segment.type.Fram_PSGMT_9').split(' ').join('\n')}
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={2.35}>
                  <Grid container columnSpacing={1}>
                    <Grid item xs={12} mb={1}>
                      <Typography className="occ-layout-text3">
                        {t('demandspace.hub.segmentheading.exploratoryOutgoing')}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Paper
                        onClick={() => {
                          navigate('/demand-landscape/peoplesegment/Fram_PSGMT_10/Fram_PSGMT_11');
                          window.scrollTo(0, 0);
                        }}
                        sx={{
                          position: 'relative',
                          width: '100%',
                          overflow: 'hidden'
                        }}
                        className="type-of-occasions creative-explorer"
                      >
                        <img
                          src={peopleSegmentAssets.heroImages.Fram_PSGMT_11()}
                          alt="creativeexplorers"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover' // Ensure the image covers the entire container
                          }}
                        />
                        <Typography
                          className="card-text"
                          sx={{
                            position: 'absolute',
                            bottom: 8,
                            left: 0,
                            width: '100%',
                            color: 'white' // Optional: Set text color
                          }}
                        >
                          {/*t("demandspace.segmentheadinglist.creativeexplorers")*/}
                          {t('people-segment.type.Fram_PSGMT_11').split(' ').join('\n')}
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={6}>
                      <Paper
                        onClick={() => {
                          navigate('/demand-landscape/peoplesegment/Fram_PSGMT_10/Fram_PSGMT_12');
                          window.scrollTo(0, 0);
                        }}
                        sx={{
                          position: 'relative',
                          width: '100%',
                          overflow: 'hidden'
                        }}
                        className="type-of-occasions social-curator"
                      >
                        <img
                          src={peopleSegmentAssets.heroImages.Fram_PSGMT_12()}
                          alt="socialcurators"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover' // Ensure the image covers the entire container
                          }}
                        />
                        <Typography
                          className="card-text"
                          sx={{
                            position: 'absolute',
                            bottom: 8,
                            left: 0,
                            width: '100%',
                            color: 'white' // Optional: Set text color
                          }}
                        >
                          {/*t("demandspace.segmentheadinglist.socialcurators")*/}
                          {t('people-segment.type.Fram_PSGMT_12').split(' ').join('\n')}
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={2.35}>
                  <Grid container columnSpacing={1}>
                    <Grid item xs={12} mb={1}>
                      <Typography className="occ-layout-text4">
                        {t('demandspace.hub.segmentheading.exploratoryReserved')}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Paper
                        onClick={() => {
                          navigate('/demand-landscape/peoplesegment/Fram_PSGMT_4/Fram_PSGMT_5');
                          window.scrollTo(0, 0);
                        }}
                        sx={{
                          position: 'relative',
                          width: '100%',
                          overflow: 'hidden'
                        }}
                        className="type-of-occasions balanced-enjoyers"
                      >
                        <img
                          src={peopleSegmentAssets.heroImages.Fram_PSGMT_5()}
                          alt="balancedEnjoyers"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover' // Ensure the image covers the entire container
                          }}
                        />
                        <Typography
                          className="card-text"
                          sx={{
                            position: 'absolute',
                            bottom: 8,
                            left: 0,
                            width: '100%',
                            color: 'white' // Optional: Set text color
                          }}
                        >
                          {/*t("demandspace.segmentheadinglist.balancedenjoyers")*/}
                          {t('people-segment.type.Fram_PSGMT_5').split(' ').join('\n')}
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={6}>
                      <Paper
                        onClick={() => {
                          navigate('/demand-landscape/peoplesegment/Fram_PSGMT_4/Fram_PSGMT_6');
                          window.scrollTo(0, 0);
                        }}
                        sx={{
                          position: 'relative',
                          width: '100%',
                          overflow: 'hidden'
                        }}
                        className="type-of-occasions diligent-discoverer"
                      >
                        <img
                          src={peopleSegmentAssets.heroImages.Fram_PSGMT_6()}
                          alt="delightdissovers"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover' // Ensure the image covers the entire container
                          }}
                        />
                        <Typography
                          className="card-text"
                          sx={{
                            position: 'absolute',
                            bottom: 8,
                            left: 0,
                            width: '100%',
                            color: 'white' // Optional: Set text color
                          }}
                        >
                          {/*t(
                          "demandspace.segmentheadinglist.diligentdiscoverers"
                        )*/}
                          {t('people-segment.type.Fram_PSGMT_6').split(' ').join('\n')}
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={1.5}>
                <Grid item xs={2.6} mb={1.5}>
                  <Grid container spacing={1.5}>
                    <Grid item xs={12}>
                      <Grid container spacing={1.5}>
                        <Grid item xs={2} style={{ position: 'relative' }}>
                          <div className="vertical-line">
                            <div className="major-occ-title" style={{ marginTop: '6px' }}>
                              {t('occasions.macroOccasion.Fram_OCCAS_1')}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={10}>
                          <Grid container spacing={1.5}>
                            <Grid item xs={12}>
                              <Paper
                                onClick={() => {
                                  navigate('/demand-landscape/occasions/Fram_OCCAS_1/Fram_OCCAS_2');
                                  window.scrollTo(0, 0);
                                }}
                                className="grid-layout-left occasion-text-style"
                              >
                                {t('occasions.type.Fram_OCCAS_2')}
                              </Paper>
                            </Grid>
                            <Grid item xs={12}>
                              <Paper
                                onClick={() => {
                                  navigate('/demand-landscape/occasions/Fram_OCCAS_1/Fram_OCCAS_3');
                                  window.scrollTo(0, 0);
                                }}
                                className="grid-layout-left occasion-text-style"
                              >
                                <Grid container justifyContent="space-between" spacing={1}>
                                  <Grid item mt={0.3}>
                                    {t('occasions.type.Fram_OCCAS_3')}
                                  </Grid>
                                  <Grid item sx={{ marginRight: '8px' }}>
                                    <Avatar
                                      sx={{
                                        width: 17.5, //25px Adjust the size of the circle
                                        height: 17.5, //25px Adjust the size of the circle
                                        backgroundColor: 'white' // Set the background color to white
                                      }}
                                    >
                                      <RestaurantIcon
                                        sx={{
                                          fontSize: '10.5px', //15px
                                          color: 'black'
                                        }}
                                      />
                                    </Avatar>
                                  </Grid>
                                </Grid>
                              </Paper>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={1.5}>
                        <Grid
                          item
                          xs={2}
                          style={{
                            position: 'relative'
                          }}
                        >
                          <div className="vertical-line">
                            <div
                              className="major-occ-title"
                              style={{
                                transformOrigin: 'bottom',
                                marginTop: '-40px'
                              }}
                            >
                              {t('occasions.macroOccasion.Fram_OCCAS_4')}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={10}>
                          <Grid container spacing={1.5}>
                            <Grid item xs={12}>
                              <Paper
                                className="grid-layout-left occasion-text-style"
                                onClick={() => {
                                  navigate('/demand-landscape/occasions/Fram_OCCAS_4/Fram_OCCAS_6');
                                  window.scrollTo(0, 0);
                                }}
                              >
                                {t('occasions.type.Fram_OCCAS_6')}
                              </Paper>
                            </Grid>
                            <Grid item xs={12}>
                              <Paper
                                className="grid-layout-left occasion-text-style"
                                onClick={() => {
                                  navigate('/demand-landscape/occasions/Fram_OCCAS_4/Fram_OCCAS_5');
                                  window.scrollTo(0, 0);
                                }}
                              >
                                {t('occasions.type.Fram_OCCAS_5')}
                              </Paper>
                            </Grid>
                            <Grid item xs={12}>
                              <Paper
                                className="grid-layout-left occasion-text-style"
                                onClick={() => {
                                  navigate('/demand-landscape/occasions/Fram_OCCAS_4/Fram_OCCAS_7');
                                  window.scrollTo(0, 0);
                                }}
                              >
                                <Grid container justifyContent="space-between" spacing={1}>
                                  <Grid item mt={0.3}>
                                    {t('occasions.type.Fram_OCCAS_7')}
                                  </Grid>
                                  <Grid item sx={{ marginRight: '8px' }}>
                                    <Avatar
                                      sx={{
                                        width: 17.5, //25px Adjust the size of the circle
                                        height: 17.5, //25px Adjust the size of the circle
                                        backgroundColor: 'white' // Set the background color to white
                                      }}
                                    >
                                      <RestaurantIcon
                                        sx={{
                                          fontSize: '10.5px', //15px
                                          color: 'black'
                                        }}
                                      />
                                    </Avatar>
                                  </Grid>
                                </Grid>
                              </Paper>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={1.5}>
                        <Grid item xs={2} style={{ position: 'relative' }}>
                          <div className="vertical-line">
                            <div className="major-occ-title" style={{ marginTop: '2px' }}>
                              {t('occasions.macroOccasion.Fram_OCCAS_8')}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={10}>
                          <Grid container spacing={1.5}>
                            <Grid item xs={12}>
                              <Paper
                                className="grid-layout-left occasion-text-style"
                                onClick={() => {
                                  navigate('/demand-landscape/occasions/Fram_OCCAS_8/Fram_OCCAS_9');
                                  window.scrollTo(0, 0);
                                }}
                              >
                                <Grid container justifyContent="space-between" spacing={1}>
                                  <Grid item mt={0.3}>
                                    {t('occasions.type.Fram_OCCAS_9')}
                                  </Grid>
                                  <Grid item sx={{ marginRight: '8px' }}>
                                    <Avatar
                                      sx={{
                                        width: 17.5, //25px Adjust the size of the circle
                                        height: 17.5, //25px Adjust the size of the circle
                                        backgroundColor: 'white' // Set the background color to white
                                      }}
                                    >
                                      <RestaurantIcon
                                        sx={{
                                          fontSize: '10.5px', //15px
                                          color: 'black'
                                        }}
                                      />
                                    </Avatar>
                                  </Grid>
                                </Grid>
                              </Paper>
                            </Grid>
                            <Grid item xs={12}>
                              <Paper
                                className="grid-layout-left occasion-text-style"
                                onClick={() => {
                                  navigate('/demand-landscape/occasions/Fram_OCCAS_8/Fram_OCCAS_10');
                                  window.scrollTo(0, 0);
                                }}
                              >
                                <Grid container justifyContent="space-between" spacing={1}>
                                  <Grid item mt={0.3}>
                                    {t('occasions.type.Fram_OCCAS_10')}
                                  </Grid>
                                  <Grid item sx={{ marginRight: '8px' }}>
                                    <Avatar
                                      sx={{
                                        width: 17.5, //25px Adjust the size of the circle
                                        height: 17.5, //25px Adjust the size of the circle
                                        backgroundColor: 'white' // Set the background color to white
                                      }}
                                    >
                                      <RestaurantIcon
                                        sx={{
                                          fontSize: '10.5px', //15px
                                          color: 'black'
                                        }}
                                      />
                                    </Avatar>
                                  </Grid>
                                </Grid>
                              </Paper>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={1.5}>
                        <Grid item xs={2} style={{ position: 'relative' }}>
                          <div className="vertical-line">
                            <div
                              className="major-occ-title"
                              style={{
                                transformOrigin: 'bottom',
                                marginTop: '-15px'
                              }}
                            >
                              {t('occasions.macroOccasion.Fram_OCCAS_11')}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={10}>
                          <Grid container spacing={1.5}>
                            <Grid item xs={12}>
                              <Paper
                                className="grid-layout-left occasion-text-style"
                                onClick={() => {
                                  navigate('/demand-landscape/occasions/Fram_OCCAS_11/Fram_OCCAS_12');
                                  window.scrollTo(0, 0);
                                }}
                              >
                                <Grid container spacing={1} justifyContent="space-between">
                                  <Grid item mt={0.3}>
                                    {t('occasions.type.Fram_OCCAS_12')}
                                  </Grid>
                                  <Grid item sx={{ marginRight: '8px' }}>
                                    <Avatar
                                      sx={{
                                        width: 17.5, //25px Adjust the size of the circle
                                        height: 17.5, //25px Adjust the size of the circle
                                        backgroundColor: 'white' // Set the background color to white
                                      }}
                                    >
                                      <RestaurantIcon
                                        sx={{
                                          fontSize: '10.5px', //15px
                                          color: 'black'
                                        }}
                                      />
                                    </Avatar>
                                  </Grid>
                                </Grid>
                              </Paper>
                            </Grid>
                            <Grid item xs={12}>
                              <Paper
                                className="grid-layout-left occasion-text-style"
                                onClick={() => {
                                  navigate('/demand-landscape/occasions/Fram_OCCAS_11/Fram_OCCAS_13');
                                  window.scrollTo(0, 0);
                                }}
                              >
                                {t('occasions.type.Fram_OCCAS_13')}
                              </Paper>
                            </Grid>
                            <Grid item xs={12}>
                              <Paper
                                className="grid-layout-left occasion-text-style"
                                onClick={() => {
                                  navigate('/demand-landscape/occasions/Fram_OCCAS_11/Fram_OCCAS_14');
                                  window.scrollTo(0, 0);
                                }}
                              >
                                {t('occasions.type.Fram_OCCAS_14')}
                              </Paper>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2.3}>
                  <Grid container spacing={1.5} mb={1.5}>
                    <Grid item xs={12}>
                      <Box
                        onClick={() => {
                          navigate('/demand-landscape/demandspace/Fram_DS_1');
                          window.scrollTo(0, 0);
                        }}
                      >
                        <Paper
                          sx={{
                            transition: 'background-color 0.3s', // Optional: Add a smooth transition effect
                            '&:hover': {
                              background: 'linear-gradient(100deg, #ADA1F8 0%, #B983FF 100%)' // Change to the desired hover color
                            }
                          }}
                          className="big-square occasion-text-style"
                        >
                          {t('demandspace.type.Fram_DS_1')}
                        </Paper>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        onClick={() => {
                          navigate('/demand-landscape/demandspace/Fram_DS_2');
                          window.scrollTo(0, 0);
                        }}
                      >
                        <Paper
                          sx={{
                            transition: 'background-color 0.3s', // Optional: Add a smooth transition effect
                            '&:hover': {
                              background: 'linear-gradient(100deg, #ADA1F8 0%, #B983FF 100%)' // Change to the desired hover color
                            }
                          }}
                          className="big-square occasion-text-style"
                        >
                          {t('demandspace.type.Fram_DS_2')}
                        </Paper>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={7.1}>
                  <Grid container spacing={1.5}>
                    <Grid item xs={8}>
                      <Grid container spacing={1.5}>
                        <Grid item xs={12}>
                          <Box
                            onClick={() => {
                              navigate('/demand-landscape/demandspace/Fram_DS_3');
                              window.scrollTo(0, 0);
                            }}
                          >
                            <Paper
                              sx={{
                                transition: 'background-color 0.3s', // Optional: Add a smooth transition effect
                                '&:hover': {
                                  background:
                                    'linear-gradient(90deg, rgba(131, 188, 255, 0.00) 26.56%, #83BCFF 68.49%, #A1E8F8 100%), linear-gradient(100deg, #8FE8BD 0%, #79D271 74.48%, #59B650 100%)' // Change to the desired hover color
                                }
                              }}
                              className="long-bar occasion-text-style"
                            >
                              {t('demandspace.type.Fram_DS_3')}
                            </Paper>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box
                            onClick={() => {
                              navigate('/demand-landscape/demandspace/Fram_DS_4');
                              window.scrollTo(0, 0);
                            }}
                          >
                            <Paper
                              sx={{
                                transition: 'background-color 0.3s', // Optional: Add a smooth transition effect
                                '&:hover': {
                                  background:
                                    'linear-gradient(90deg, rgba(131, 188, 255, 0.00) 26.56%, #83BCFF 68.49%, #A1E8F8 100%), linear-gradient(100deg, #8FE8BD 0%, #79D271 74.48%, #59B650 100%)' // Change to the desired hover color
                                }
                              }}
                              className="long-bar occasion-text-style"
                            >
                              {t('demandspace.type.Fram_DS_4')}
                            </Paper>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={1.5}>
                            <Grid item xs={6}>
                              <Box
                                onClick={() => {
                                  navigate('/demand-landscape/demandspace/Fram_DS_5');
                                  window.scrollTo(0, 0);
                                }}
                              >
                                <Paper
                                  sx={{
                                    transition: 'background-color 0.3s', // Optional: Add a smooth transition effect
                                    '&:hover': {
                                      background: 'linear-gradient(100deg, #8FE8BD 0%, #79D271 100%)' // Change to the desired hover color
                                    }
                                  }}
                                  className="below-long-bar occasion-text-style"
                                >
                                  {t('demandspace.type.Fram_DS_5')}
                                </Paper>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box
                                onClick={() => {
                                  navigate('/demand-landscape/demandspace/Fram_DS_7');
                                  window.scrollTo(0, 0);
                                }}
                              >
                                <Paper
                                  sx={{
                                    transition: 'background-color 0.3s', // Optional: Add a smooth transition effect
                                    '&:hover': {
                                      background: 'linear-gradient(100deg, #A1E8F8 0%, #83BCFF 100%)' // Change to the desired hover color
                                    }
                                  }}
                                  className="below-long-bar occasion-text-style"
                                >
                                  {t('demandspace.type.Fram_DS_7')}
                                </Paper>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container spacing={1.5}>
                        <Grid item xs={12}>
                          <Box
                            onClick={() => {
                              navigate('/demand-landscape/demandspace/Fram_DS_9');
                              window.scrollTo(0, 0);
                            }}
                          >
                            <Paper
                              sx={{
                                transition: 'background-color 0.3s', // Optional: Add a smooth transition effect
                                '&:hover': {
                                  background: 'linear-gradient(100deg, #FFC759 0%, #F81 100%)' // Change to the desired hover color
                                }
                              }}
                              className="refined-box occasion-text-style"
                            >
                              {t('demandspace.type.Fram_DS_9')}
                            </Paper>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box
                            onClick={() => {
                              navigate('/demand-landscape/demandspace/Fram_DS_10');
                              window.scrollTo(0, 0);
                            }}
                          >
                            <Paper
                              sx={{
                                transition: 'background-color 0.3s', // Optional: Add a smooth transition effect
                                '&:hover': {
                                  background: 'linear-gradient(100deg, #FFC759 0%, #F81 100%)' // Change to the desired hover color
                                }
                              }}
                              className="below-long-bar occasion-text-style"
                            >
                              {t('demandspace.type.Fram_DS_10')}
                            </Paper>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1.5}>
                        <Grid item xs={12}>
                          <Box
                            onClick={() => {
                              navigate('/demand-landscape/demandspace/Fram_DS_12');
                              window.scrollTo(0, 0);
                            }}
                          >
                            <Paper
                              sx={{
                                transition: 'background-color 0.3s', // Optional: Add a smooth transition effect
                                '&:hover': {
                                  background:
                                    'linear-gradient(90deg, #8FE8BB 0%, #7BD375 20%, #A1E8F8 40%, #83BCFF 60%, #FFC759 80%, #FF8811 100%)' // Change to the desired hover color
                                }
                              }}
                              className="long-bar occasion-text-style"
                            >
                              {t('demandspace.type.Fram_DS_12')}
                            </Paper>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box
                            onClick={() => {
                              navigate('/demand-landscape/demandspace/Fram_DS_13');
                              window.scrollTo(0, 0);
                            }}
                          >
                            <Paper
                              sx={{
                                transition: 'background-color 0.3s', // Optional: Add a smooth transition effect
                                '&:hover': {
                                  background:
                                    'linear-gradient(90deg, #8FE8BB 0%, #7BD375 20%, #A1E8F8 40%, #83BCFF 60%, #FFC759 80%, #FF8811 100%)' // Change to the desired hover color
                                }
                              }}
                              className="long-bar occasion-text-style"
                            >
                              {t('demandspace.type.Fram_DS_13')}
                            </Paper>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box
                            onClick={() => {
                              navigate('/demand-landscape/demandspace/Fram_DS_6');
                              window.scrollTo(0, 0);
                            }}
                          >
                            <Paper
                              sx={{
                                transition: 'background-color 0.3s', // Optional: Add a smooth transition effect
                                '&:hover': {
                                  background: 'linear-gradient(100deg, #8FE8BD 0%, #79D271 100%)' // Change to the desired hover color
                                }
                              }}
                              className="below-long-bar occasion-text-style"
                            >
                              {t('demandspace.type.Fram_DS_6')}
                            </Paper>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box
                            onClick={() => {
                              navigate('/demand-landscape/demandspace/Fram_DS_8');
                              window.scrollTo(0, 0);
                            }}
                          >
                            <Paper
                              sx={{
                                transition: 'background-color 0.3s', // Optional: Add a smooth transition effect
                                '&:hover': {
                                  background: 'linear-gradient(100deg, #A1E8F8 0%, #83BCFF 100%)' // Change to the desired hover color
                                }
                              }}
                              className="below-long-bar occasion-text-style"
                            >
                              {t('demandspace.type.Fram_DS_8')}
                            </Paper>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box
                            onClick={() => {
                              navigate('/demand-landscape/demandspace/Fram_DS_11');
                              window.scrollTo(0, 0);
                            }}
                          >
                            <Paper
                              sx={{
                                transition: 'background-color 0.3s', // Optional: Add a smooth transition effect
                                '&:hover': {
                                  background: 'linear-gradient(100deg, #FFC759 0%, #F81 100%)' // Change to the desired hover color
                                }
                              }}
                              className="below-long-bar occasion-text-style"
                            >
                              {t('demandspace.type.Fram_DS_11')}
                            </Paper>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <Box className="full-meal-present">
            <div className="meal-disclaimer-container">
              <Avatar className="full-meal-icon">
                <RestaurantIcon className="restaurant-icon" />
              </Avatar>
              <Typography className="meal-disclaimer">{t('demandspace.hub.mealDisclaimer')}</Typography>
            </div>
            <div className="tool-tip-container">
              <InfoOutlinedIcon className="info-icon" />
              <Typography className="tool-tip-text">{t('demandspace.hub.tooltipInfo')}</Typography>
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default SectionOne;
