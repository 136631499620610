import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { COLORS } from '../../../../../constants/CatTripColors';
import styles from './StatCard.module.css';

export function StatCard({ stats, tableFooter }) {
  const { t } = useTranslation(i18next.language);

  return (
    <div className={styles.statCard}>
      <img
        src="https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F5374980f997a45ab80ca2db39ddfa913"
        alt="Basket statistics icon"
        className={styles.cardIcon}
      />

      <div className={styles.statsContainer}>
        <div className={styles.statGroup}>
          <div className={styles.amtIdxWrap}>
            <div className={styles.amount}>
              ${stats.totalSpend.amount}
              {tableFooter ? '*' : null}
            </div>
            <div className={styles.index} style={{ backgroundColor: COLORS[stats.totalSpend.tagColor] }}>
              {stats.totalSpend.index}
            </div>
          </div>
          <div className={styles.label}>{t('tripTypesPageTemplate.basketComposition.statCard.statLabel1')}</div>
        </div>

        <div className={styles.statGroup}>
          <div className={styles.amtIdxWrap}>
            <div className={styles.amount}>
              {stats.alcShare.amount}%{tableFooter ? '*' : null}
            </div>
            <div className={styles.index} style={{ backgroundColor: COLORS[stats.alcShare.tagColor] }}>
              {stats.alcShare.index}
            </div>
          </div>
          <div className={styles.label}>{t('tripTypesPageTemplate.basketComposition.statCard.statLabel2')}</div>
        </div>
      </div>
    </div>
  );
}

StatCard.propTypes = {
  stats: PropTypes.object.isRequired,
  tableFooter: PropTypes.bool.isRequired
};
