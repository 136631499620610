import React from 'react';
import PropTypes from 'prop-types';
import { LegendItem } from './LegendItem';
import styles from './DonutChart.module.css';

export const DonutChart = ({ channelData, chartImgUrl, chartImgAltText }) => {
  return (
    <div className={styles.donutChart}>
      <img loading="lazy" src={chartImgUrl} className={styles.chartImage} alt={chartImgAltText} />
      <div className={styles.legend}>
        {channelData.map((item) => (
          <LegendItem key={item.label} {...item} />
        ))}
      </div>
    </div>
  );
};

DonutChart.propTypes = {
  channelData: PropTypes.array.isRequired,
  chartImgUrl: PropTypes.string.isRequired,
  chartImgAltText: PropTypes.string.isRequired
};
