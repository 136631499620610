import { Box, Slider, SliderThumb, Tooltip, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { microIdentifiersShortHand } from '../../../constants/DemandSpace';

const PeopleSlider = styled(Slider)(() => ({
  color: 'grey',
  cursor: 'auto',
  '& .MuiSlider-thumb': {
    PointerEvent: 'none !important',
    '&:focus, &.Mui-active': {}
  },
  '& .MuiSlider-rail': {
    color: 'grey'
  },
  '& .Mui-focusVisible': {
    boxShadow: 'none !important',
    PointerEvent: 'none !important'
  }
}));

const SliderThumbComponent = (props) => {
  const { children, ...other } = props;
  const sortedValues = props.name.slice().sort((a, b) => a.percent - b.percent);
  const sliderValues = sortedValues[props['data-index']];

  return (
    <Box className="slider-thumb-main-box">
      <Tooltip
        classes={{ tooltip: 'custom-tooltip' }}
        placement="top"
        style={{ zIndex: 1000 }}
        title={
          props.name.length > 1 ? props.tooltipText(sliderValues, props.index, props['data-index']) : props.tooltipText
        }
      >
        <SliderThumb {...other}>
          <span className={`selected-slider-thumb ${sliderValues?.microCode}`}>
            {microIdentifiersShortHand[sliderValues?.microCode]}
          </span>
        </SliderThumb>
      </Tooltip>
    </Box>
  );
};

SliderThumbComponent.propTypes = {
  children: PropTypes.node,
  name: PropTypes.array.isRequired,
  'data-index': PropTypes.number,
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  index: PropTypes.number
};

const ThumbRenderer =
  ({ values, tooltipText, index }) =>
  (thumbProps) => <SliderThumbComponent {...thumbProps} name={values} tooltipText={tooltipText} index={index} />;

const CustomSlider = ({
  index,
  sliderEndtValue,
  sliderStartValue,
  sliderHeading,
  values,
  tooltipText,
  space,
  textColor = 'white'
}) => {
  const theme = useTheme();
  const headingColor = space ? theme?.palette[space]?.main : 'white';
  const handleSliderClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  return (
    <Box className="slider-main-div" key={index} width="100%">
      <Typography color={headingColor} className="slider-heading-value">
        {sliderHeading}
      </Typography>
      <Box className="sliderstartvalue">
        <Typography color={textColor} className={`sliderstartvalue-typo1`}>
          {sliderStartValue}
        </Typography>
        <Typography color={textColor} className={`sliderstartvalue-typo1`}>
          {sliderEndtValue}
        </Typography>
      </Box>
      <PeopleSlider
        slots={{
          thumb: ThumbRenderer({ values, tooltipText, index })
        }}
        track={false}
        marks={[{ value: 50 }]}
        disableSwap
        disableFocusRipple
        valueLabelDisplay="auto"
        classes={{
          thumb: 'custom-thumb',
          mark: 'custom-mark'
        }}
        defaultValue={values?.map((val) => Number(val?.percent))}
        onClick={handleSliderClick}
        onMouseDown={handleSliderClick}
        onChange={() => false}
      />
    </Box>
  );
};

CustomSlider.propTypes = {
  index: PropTypes.number,
  sliderEndtValue: PropTypes.string,
  sliderStartValue: PropTypes.string,
  sliderHeading: PropTypes.string,
  values: PropTypes.array.isRequired,
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  space: PropTypes.string,
  textColor: PropTypes.string
};

export default CustomSlider;
