import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../../../../constants/CatTripColors';
import styles from './LegendItem.module.css';

export const LegendItem = ({ color, label, percentage, count, tagColor }) => {
  return (
    <div className={styles.legendItem}>
      <div className={styles.colorBox} style={{ backgroundColor: COLORS[color] }} />
      <div className={styles.label}>{label}</div>
      <div className={styles.stats}>
        <div className={styles.percentage}>{percentage}%</div>
        <div className={styles.tag} style={{ backgroundColor: COLORS[tagColor] }}>
          {count}
        </div>
      </div>
    </div>
  );
};

LegendItem.propTypes = {
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  tagColor: PropTypes.string
};
