import * as React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import styles from './NoItems10Basket.module.css';

export const NoItems10Basket = () => {
  const { t } = useTranslation(i18next.language);

  return (
    <div className={styles.messageCard}>
      <div className={styles.cardBackground} />
      <div className={styles.messageContent}>{t('tripTypesPageTemplate.spendAnalytics.noItemsCardTitle')}</div>
    </div>
  );
};
