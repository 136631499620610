import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

// Occasions Contextual Factors Data Card Icons
import RestaurantIcon from '@mui/icons-material/Restaurant';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GroupIcon from '@mui/icons-material/Group';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import FilledOutlinedIcon from '../Icons/FilledOutlinedIcon';
import DataTable from '../Table/DataTable';
import ContextualFactorCard from './ContextualFactorCard';

function ContextualFactorsDataCard({ visualData, tableData }) {
  function getUGCIcon(icon) {
    let ugcIcon = null;
    switch (icon) {
      case 'meals':
        ugcIcon = <RestaurantIcon className="icon" />;
        break;
      case 'drinkTimes':
        ugcIcon = <CalendarTodayIcon className="icon" />;
        break;
      case 'locations':
        ugcIcon = <LocationOnIcon className="icon" />;
        break;
      case 'groupSizes':
        ugcIcon = <GroupIcon className="icon" />;
        break;
      case 'activities':
        ugcIcon = <LocalActivityIcon className="icon" />;
        break;
      case 'moods':
        ugcIcon = <EmojiEmotionsIcon className="icon" />;
        break;
      default:
        break;
    }
    return ugcIcon;
  }

  return (
    <Box className="contextual-factors-datacard-container">
      <ContextualFactorCard label={visualData.label} labeltext={visualData.labeltext}>
        <FilledOutlinedIcon outlined type={visualData.mode}>
          {getUGCIcon(visualData.icon)}
        </FilledOutlinedIcon>
      </ContextualFactorCard>
      <DataTable tableData={tableData} />
    </Box>
  );
}

ContextualFactorsDataCard.propTypes = {
  visualData: PropTypes.object.isRequired,
  tableData: PropTypes.object.isRequired
};

export default ContextualFactorsDataCard;
