import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardMedia, CardContent, Typography, Button, Box } from '@mui/material';

import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';

function OccasionsExampleAudioCard({ cardData }) {
  const [isPlaying, setIsPlaying] = useState(false);

  function playAudio() {
    setIsPlaying(!isPlaying);
  }
  return (
    <Card className="occasions-example-audio-card">
      <CardMedia className="card-media" component="img" image={cardData.media?.()} alt="Example Image" />
      <CardContent className="card-content-container">
        <Box className="card-content-text-container">
          {cardData.title && (
            <Typography className="title-text" variant="body1" component="div">
              {cardData.title}
            </Typography>
          )}
          {cardData.subtitle && (
            <Typography className="subtitle-text" variant="body1" component="div">
              {cardData.subtitle}
            </Typography>
          )}
        </Box>
        {cardData.audioLink && (
          <Button className="card-action-button" onClick={playAudio}>
            {isPlaying ? (
              <PauseCircleIcon color="primary" className="icon" />
            ) : (
              <PlayCircleIcon color="primary" className="icon" />
            )}
          </Button>
        )}
      </CardContent>
    </Card>
  );
}

OccasionsExampleAudioCard.propTypes = {
  cardData: PropTypes.object.isRequired
};

export default OccasionsExampleAudioCard;
