export const BEVERAGE_ICONS = {
  BEER_YELLOW:
    'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F9945dae3d3ed4d9b860793782bac991d',
  SPIRITS_YELLOW:
    'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fc89bcdbf12fc45089ea8eff793b9b064',
  WINE_YELLOW:
    'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fa3281bd210d2428e86cad678e484eeef',
  HARD_BEV_YELLOW:
    'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fbb682479f7164ee3a43ffb586005b9bb',
  BEER_BLACK:
    'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F64b48ec51b074132917e5d6a6bef3167',
  HARD_BEV_BLACK:
    'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fbe0c351dcc2e44ff89588b0100373325'
};
