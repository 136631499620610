import { Grid, Paper } from '@mui/material';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function WayFinderCommon({ ids, highlightIds, wayfinderID }) {
  const { t } = useTranslation(i18next.language);
  useEffect(() => {
    if (localStorage.getItem('i18nnextLng')?.length > 2) {
      i18next.changeLanguage('en');
    }
  }, []);

  const boxStyle = {
    background: '#1D1D1D', // Set your initial color here
    position: 'relative',
    height: '15.111px',
    overflow: 'hidden',
    borderRadius: '2px'
  };

  const boxStyleBig = {
    background: 'var(--grayscale-800, #1D1D1D)',
    position: 'relative',
    height: '85.63px',
    overflow: 'hidden',
    borderRadius: '2px'
  };
  const boxStyleMid = {
    background: 'var(--grayscale-800, #1D1D1D)',
    position: 'relative',
    height: '50.371px',
    overflow: 'hidden',
    borderRadius: '2px'
  };
  const boxStyleRight = {
    background: 'var(--grayscale-800, #1D1D1D)',
    position: 'relative',
    height: '32px',
    overflow: 'hidden',
    borderRadius: '2px'
  };
  return (
    <div className={`main-content-wayfinder ${wayfinderID || ''}`}>
      <Grid container className="wayfinder-content">
        <Grid item xs={1}>
          <div className="occasions-vertical-title">{t('occasions.pagename')}</div>
        </Grid>
        <Grid item xs={11}>
          <Grid container spacing="2.5px">
            <Grid item xs={12}>
              <div className="people-segments-title">{t('people-segment.pagename')}</div>
              <Grid container spacing="2.5px" id="people-segments">
                <Grid item xs={1.3}>
                  <Paper></Paper>
                </Grid>
                <Grid item xs={1.3}>
                  <Paper
                    id="Fram_PSGMT_3"
                    className={`wayfinderitem ${highlightIds.includes('Fram_PSGMT_3') ? 'highlight' : ''}`}
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes('Fram_PSGMT_3') ? '#ADA1F8' : '#1D1D1D'
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={1.3}>
                  <Paper
                    id="Fram_PSGMT_2"
                    className={`wayfinderitem ${highlightIds.includes('Fram_PSGMT_2') ? 'highlight' : ''}`}
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes('Fram_PSGMT_2') ? '#B983FF' : '#1D1D1D'
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={1.35}>
                  <Paper
                    id="Fram_PSGMT_8"
                    className={`wayfinderitem ${highlightIds.includes('Fram_PSGMT_8') ? 'highlight' : ''}`}
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes('Fram_PSGMT_8') ? '#8FE8BD' : '#1D1D1D'
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={1.35}>
                  <Paper
                    id="Fram_PSGMT_9"
                    className={`wayfinderitem ${highlightIds.includes('Fram_PSGMT_9') ? 'highlight' : ''}`}
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes('Fram_PSGMT_9') ? '#79D271' : '#1D1D1D'
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={1.35}>
                  <Paper
                    id="Fram_PSGMT_11"
                    className={`wayfinderitem ${highlightIds.includes('Fram_PSGMT_11') ? 'highlight' : ''}`}
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes('Fram_PSGMT_11') ? '#A1E8F8' : '#1D1D1D'
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={1.35}>
                  <Paper
                    id="Fram_PSGMT_12"
                    className={`wayfinderitem ${highlightIds.includes('Fram_PSGMT_12') ? 'highlight' : ''}`}
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes('Fram_PSGMT_12') ? '#83BCFF' : '#1D1D1D'
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={1.35}>
                  <Paper
                    id="Fram_PSGMT_5"
                    className={`wayfinderitem ${highlightIds.includes('Fram_PSGMT_5') ? 'highlight' : ''}`}
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes('Fram_PSGMT_5') ? '#ffc759' : '#1D1D1D'
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={1.35}>
                  <Paper
                    id="Fram_PSGMT_6"
                    className={`wayfinderitem ${highlightIds.includes('Fram_PSGMT_6') ? 'highlight' : ''}`}
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes('Fram_PSGMT_6') ? '#ff8811' : '#1D1D1D'
                    }}
                  ></Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1.3}>
              <Grid container spacing="2.5px" id="occasions">
                <Grid item xs={12}>
                  <Paper
                    id="Fram_OCCAS_2"
                    className={`wayfinderitem ${highlightIds.includes('Fram_OCCAS_2') ? 'highlight' : ''}`}
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes('Fram_OCCAS_2') ? '#F6E003' : '#1D1D1D'
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    id="Fram_OCCAS_3"
                    className={`wayfinderitem ${highlightIds.includes('Fram_OCCAS_3') ? 'highlight' : ''}`}
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes('Fram_OCCAS_3') ? '#F6E003' : '#1D1D1D'
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    id="Fram_OCCAS_6"
                    className={`wayfinderitem ${highlightIds.includes('Fram_OCCAS_6') ? 'highlight' : ''}`}
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes('Fram_OCCAS_6') ? '#F6E003' : '#1D1D1D'
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    id="Fram_OCCAS_5"
                    className={`wayfinderitem ${highlightIds.includes('Fram_OCCAS_5') ? 'highlight' : ''}`}
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes('Fram_OCCAS_5') ? '#F6E003' : '#1D1D1D'
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    id="Fram_OCCAS_7"
                    className={`wayfinderitem ${highlightIds.includes('Fram_OCCAS_7') ? 'highlight' : ''}`}
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes('Fram_OCCAS_7') ? '#F6E003' : '#1D1D1D'
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    id="Fram_OCCAS_9"
                    className={`wayfinderitem ${highlightIds.includes('Fram_OCCAS_9') ? 'highlight' : ''}`}
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes('Fram_OCCAS_9') ? '#F6E003' : '#1D1D1D'
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    id="Fram_OCCAS_10"
                    className={`wayfinderitem ${highlightIds.includes('Fram_OCCAS_10') ? 'highlight' : ''}`}
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes('Fram_OCCAS_10') ? '#F6E003' : '#1D1D1D'
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    id="Fram_OCCAS_12"
                    className={`wayfinderitem ${highlightIds.includes('Fram_OCCAS_12') ? 'highlight' : ''}`}
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes('Fram_OCCAS_12') ? '#F6E003' : '#1D1D1D'
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    id="Fram_OCCAS_13"
                    className={`wayfinderitem ${highlightIds.includes('Fram_OCCAS_13') ? 'highlight' : ''}`}
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes('Fram_OCCAS_13') ? '#F6E003' : '#1D1D1D'
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    id="Fram_OCCAS_14"
                    className={`wayfinderitem ${highlightIds.includes('Fram_OCCAS_14') ? 'highlight' : ''}`}
                    sx={{
                      ...boxStyle,
                      backgroundColor: ids.includes('Fram_OCCAS_14') ? '#F6E003' : '#1D1D1D'
                    }}
                  ></Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2.6}>
              <Grid container spacing="2.5px">
                <Grid item xs={12}>
                  <Paper
                    id="Fram_DS_1"
                    className={`wayfinderitem ${highlightIds.includes('Fram_DS_1') ? 'highlight' : ''}`}
                    sx={{
                      ...boxStyleBig,
                      backgroundColor: ids.includes('Fram_DS_1') ? '#3D3D3D' : '#1D1D1D'
                    }}
                  ></Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    id="Fram_DS_2"
                    className={`wayfinderitem ${highlightIds.includes('Fram_DS_2') ? 'highlight' : ''}`}
                    sx={{
                      ...boxStyleBig,
                      backgroundColor: ids.includes('Fram_DS_2') ? '#3D3D3D' : '#1D1D1D'
                    }}
                  ></Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8.1}>
              <Grid container spacing="2.5px">
                <Grid item xs={8}>
                  <Grid container spacing="2.5px">
                    <Grid item xs={12}>
                      <Paper
                        id="Fram_DS_3"
                        className={`wayfinderitem ${highlightIds.includes('Fram_DS_3') ? 'highlight' : ''}`}
                        sx={{
                          ...boxStyle,
                          backgroundColor: ids.includes('Fram_DS_3') ? '#3D3D3D' : '#1D1D1D'
                        }}
                      ></Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Paper
                        id="Fram_DS_4"
                        className={`wayfinderitem ${highlightIds.includes('Fram_DS_4') ? 'highlight' : ''}`}
                        sx={{
                          ...boxStyle,
                          backgroundColor: ids.includes('Fram_DS_4') ? '#3D3D3D' : '#1D1D1D'
                        }}
                      ></Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing="2.5px">
                        <Grid item xs={6}>
                          <Paper
                            id="Fram_DS_5"
                            className={`wayfinderitem ${highlightIds.includes('Fram_DS_5') ? 'highlight' : ''}`}
                            sx={{
                              ...boxStyleMid,
                              backgroundColor: ids.includes('Fram_DS_5') ? '#3D3D3D' : '#1D1D1D'
                            }}
                          ></Paper>
                        </Grid>
                        <Grid item xs={6}>
                          <Paper
                            id="Fram_DS_7"
                            className={`wayfinderitem ${highlightIds.includes('Fram_DS_7') ? 'highlight' : ''}`}
                            sx={{
                              ...boxStyleMid,
                              backgroundColor: ids.includes('Fram_DS_7') ? '#3D3D3D' : '#1D1D1D'
                            }}
                          ></Paper>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container spacing="2.5px">
                    <Grid item xs={12}>
                      <Paper
                        id="Fram_DS_9"
                        className={`wayfinderitem ${highlightIds.includes('Fram_DS_9') ? 'highlight' : ''}`}
                        sx={{
                          ...boxStyleRight,
                          backgroundColor: ids.includes('Fram_DS_9') ? '#3D3D3D' : '#1D1D1D'
                        }}
                      ></Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Paper
                        id="Fram_DS_10"
                        className={`wayfinderitem ${highlightIds.includes('Fram_DS_10') ? 'highlight' : ''}`}
                        sx={{
                          ...boxStyleMid,
                          backgroundColor: ids.includes('Fram_DS_10') ? '#3D3D3D' : '#1D1D1D'
                        }}
                      ></Paper>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing="2.5px">
                    <Grid item xs={12}>
                      <Paper
                        id="Fram_DS_12"
                        className={`wayfinderitem ${highlightIds.includes('Fram_DS_12') ? 'highlight' : ''}`}
                        sx={{
                          ...boxStyle,
                          backgroundColor: ids.includes('Fram_DS_12') ? '#3D3D3D' : '#1D1D1D'
                        }}
                      ></Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Paper
                        id="Fram_DS_13"
                        className={`wayfinderitem ${highlightIds.includes('Fram_DS_13') ? 'highlight' : ''}`}
                        sx={{
                          ...boxStyle,
                          backgroundColor: ids.includes('Fram_DS_13') ? '#3D3D3D' : '#1D1D1D'
                        }}
                      ></Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing="2.5px">
                        <Grid item xs={4}>
                          <Paper
                            id="Fram_DS_6"
                            className={`wayfinderitem ${highlightIds.includes('Fram_DS_6') ? 'highlight' : ''}`}
                            sx={{
                              ...boxStyleMid,
                              backgroundColor: ids.includes('Fram_DS_6') ? '#3D3D3D' : '#1D1D1D'
                            }}
                          ></Paper>
                        </Grid>
                        <Grid item xs={4}>
                          <Paper
                            id="Fram_DS_8"
                            className={`wayfinderitem ${highlightIds.includes('Fram_DS_8') ? 'highlight' : ''}`}
                            sx={{
                              ...boxStyleMid,
                              backgroundColor: ids.includes('Fram_DS_8') ? '#3D3D3D' : '#1D1D1D'
                            }}
                          ></Paper>
                        </Grid>
                        <Grid item xs={4}>
                          <Paper
                            id="Fram_DS_11"
                            className={`wayfinderitem ${highlightIds.includes('Fram_DS_11') ? 'highlight' : ''}`}
                            sx={{
                              ...boxStyleMid,
                              backgroundColor: ids.includes('Fram_DS_11') ? '#3D3D3D' : '#1D1D1D'
                            }}
                          ></Paper>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

WayFinderCommon.propTypes = {
  ids: PropTypes.array.isRequired,
  highlightIds: PropTypes.array,
  wayfinderID: PropTypes.string
};
