import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

import { Trans, useTranslation } from 'react-i18next';
import i18next from 'i18next';

import MobileOffIcon from '@mui/icons-material/MobileOff';
import { ReactComponent as ScreenMonitor } from '../../assets/images/Login/screenshot_monitor.svg';

function NotCompatibleComponent() {
  const { t } = useTranslation(i18next.language);
  return (
    <>
      <Grid container className="login-content-not-compatible" direction="column">
        <Grid item>
          <Box className="logo-container-nc">
            <Typography className="logo-text-nc">{t('login.hue')}</Typography>
          </Box>
        </Grid>
        <Grid item sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box className={`app-description-container-nc`}>
            <Box className="app-introduction-container-nc">
              <Typography className="login-title-nc">{t('login.heading')}</Typography>
              <Typography variant="h1" className="login-project-title-nc">
                {t('projectTitle')}
              </Typography>
            </Box>
            <Typography className="login-project-summary-nc">
              <Trans components={{ coloredyellow: <b /> }}>{t('login.notCompatibleDescription')}</Trans>
            </Typography>
          </Box>
          <Box className="screen-image-container">
            <ScreenMonitor className="screen-image" />
            <MobileOffIcon className="screen-image2" />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default NotCompatibleComponent;
