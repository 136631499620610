import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { COLORS } from '../../../../../constants/CatTripColors';
import { BEVERAGE_ICONS } from '../../../../../constants/BeverageIcons';
import { formatSegmentTitle } from '../../../../../constants/FormatSegmentTitle';
import styles from './StatBlock.module.css';

const StatBlock = ({ statCardData }) => {
  const { t } = useTranslation(i18next.language);

  return (
    <div className={styles.block}>
      <div className={styles.topBlock}>
        <img
          src={BEVERAGE_ICONS[`${statCardData.categoryTitle}_BLACK`]}
          alt={`${statCardData.categoryTitle} category icon`}
          className={styles.categoryIcon}
        />
        <h2 className={styles.categoryTitle}>{formatSegmentTitle(statCardData.categoryTitle)}</h2>
        <div className={styles.statGroup}>
          <div className={styles.stat}>
            <div className={styles.statWrapper}>
              <div className={styles.value}>{statCardData.statGroup.stat1.value}%</div>
              <div className={styles.tag} style={{ backgroundColor: COLORS[statCardData.statGroup.stat1.tagColor] }}>
                {statCardData.statGroup.stat1.tag}
              </div>
            </div>
            <div className={styles.label}>{t('tripTypesPageTemplate.basketCategoryStats.statBlock.stat1Label')}</div>
          </div>
          <div className={styles.stat}>
            <div className={styles.statWrapper}>
              <div className={styles.value}>${statCardData.statGroup.stat2.value}</div>
              <div className={styles.tag} style={{ backgroundColor: COLORS[statCardData.statGroup.stat2.tagColor] }}>
                {statCardData.statGroup.stat2.tag}
              </div>
            </div>
            <div className={styles.label}>{t('tripTypesPageTemplate.basketCategoryStats.statBlock.stat2Label')}</div>
          </div>
        </div>
      </div>
      <div className={styles.bottomBlock}>
        <div className={styles.metric}>
          <span className={styles.largeValue}>{statCardData.metricGroup.metric1.value}%</span>
          <br />
          {statCardData.metricGroup.metric1.label}
        </div>
        <div className={styles.divider} />
        <div className={styles.metric}>
          <span className={styles.largeValue}>{statCardData.metricGroup.metric2.value}</span>
          <br />
          {statCardData.metricGroup.metric2.label}
        </div>
      </div>
    </div>
  );
};

StatBlock.propTypes = {
  statCardData: PropTypes.object.isRequired
};

export default StatBlock;
