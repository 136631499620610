import { useEffect, useRef } from 'react';

export const useDragScroll = () => {
  const elementRef = useRef(null);

  useEffect(() => {
    const slider = elementRef.current;
    let isDown = false;
    let startX;
    let scrollLeft;
    let velocity = 0;
    let lastX;
    let frameId;
    let lastTimestamp;

    const handleMouseDown = (e) => {
      e.preventDefault();
      isDown = true;
      slider.style.cursor = 'grabbing';
      startX = e.clientX;
      lastX = e.clientX;
      scrollLeft = slider.scrollLeft;
      velocity = 0;
      lastTimestamp = Date.now();

      cancelAnimationFrame(frameId);
    };

    const stopDragging = () => {
      if (isDown) {
        isDown = false;
        slider.style.cursor = 'grab';
        startMomentumScroll();
      }
    };

    const handleMouseMove = (e) => {
      if (!isDown) return;
      e.preventDefault();

      const now = Date.now();
      const dt = Math.max(1, now - lastTimestamp);
      const dx = e.clientX - lastX;

      const newVelocity = dx / dt;
      velocity = velocity * 0.8 + newVelocity * 0.2;

      slider.scrollLeft = scrollLeft - (e.clientX - startX);

      lastX = e.clientX;
      lastTimestamp = now;
    };

    const startMomentumScroll = () => {
      const friction = 0.95;
      const minVelocity = 0.01;
      const momentumMultiplier = 128;

      let lastTime = performance.now();

      const momentumScroll = (currentTime) => {
        const deltaTime = currentTime - lastTime;
        lastTime = currentTime;

        if (Math.abs(velocity) > minVelocity) {
          const movement = velocity * momentumMultiplier * (deltaTime / 16);
          slider.scrollLeft -= movement;

          velocity *= Math.pow(friction, deltaTime / 16);
          frameId = requestAnimationFrame(momentumScroll);
        }
      };

      if (Math.abs(velocity) > minVelocity) {
        frameId = requestAnimationFrame(momentumScroll);
      }
    };

    const handleDragStart = (e) => {
      e.preventDefault();
    };

    if (slider) {
      slider.addEventListener('mousedown', handleMouseDown);
      slider.addEventListener('mouseleave', stopDragging);
      slider.addEventListener('mouseup', stopDragging);
      slider.addEventListener('mousemove', handleMouseMove);

      const images = slider.getElementsByTagName('img');
      Array.from(images).forEach((img) => {
        img.addEventListener('dragstart', handleDragStart);
      });
    }

    return () => {
      if (slider) {
        cancelAnimationFrame(frameId);
        slider.removeEventListener('mousedown', handleMouseDown);
        slider.removeEventListener('mouseleave', stopDragging);
        slider.removeEventListener('mouseup', stopDragging);
        slider.removeEventListener('mousemove', handleMouseMove);

        const images = slider.getElementsByTagName('img');
        Array.from(images).forEach((img) => {
          img.removeEventListener('dragstart', handleDragStart);
        });
      }
    };
  }, []);

  return elementRef;
};
