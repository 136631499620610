import { useEffect, useCallback } from 'react';
import { useNavigate, useOutletContext, useLocation } from 'react-router-dom';
import { useIsPreviewing } from '@builder.io/react';
import demandLandscapeUsers from '../data/demand-landscape-access.json';
import categoryFrameworkUsers from '../data/category-framework-access.json';
import tripTypesUsers from '../data/trip-types-access.json';

const AccessLevels = {
  DEMAND_LANDSCAPE: 'DEMAND_LANDSCAPE',
  CATEGORY_FRAMEWORK: 'CATEGORY_FRAMEWORK',
  TRIP_TYPES: 'TRIP_TYPES',
  NONE: 'NONE'
};

const getUserAccessLevels = (email) => {
  if (!email) {
    return [AccessLevels.NONE];
  }
  const normalizedEmail = email.toLowerCase();
  const accessLevels = [];

  if (demandLandscapeUsers.some((user) => user.email.toLowerCase() === normalizedEmail)) {
    accessLevels.push(AccessLevels.DEMAND_LANDSCAPE);
  }

  if (categoryFrameworkUsers.some((user) => user.email.toLowerCase() === normalizedEmail)) {
    accessLevels.push(AccessLevels.CATEGORY_FRAMEWORK);
  }

  if (tripTypesUsers.some((user) => user.email.toLowerCase() === normalizedEmail)) {
    accessLevels.push(AccessLevels.TRIP_TYPES);
  }

  return accessLevels.length ? accessLevels : [AccessLevels.NONE];
};

const canAccessFeature = (userAccessLevels, requiredAccessLevel) => {
  return userAccessLevels.includes(requiredAccessLevel);
};

const usePreviewMode = () => {
  const location = useLocation();
  return useIsPreviewing() || location.search.includes('builder.preview=page');
};

const getUserEmail = (navigate, isPreviewMode) => {
  const userEmail = !isPreviewMode ? localStorage.getItem('user_email') : 'builder-preview@test.com';

  if (!userEmail) {
    if (navigate) {
      navigate('/home');
      window.scrollTo(0, 0);
    }
    return null;
  }

  return userEmail.toLowerCase();
};

const getHueSection = (segment) => {
  const sectionMap = {
    'alcohol-categories': AccessLevels.CATEGORY_FRAMEWORK,
    'trip-types': AccessLevels.TRIP_TYPES,
    'demand-landscape': AccessLevels.DEMAND_LANDSCAPE
  };

  return sectionMap[segment] || null;
};

const getDenialModalCopy = (segment) => {
  const sectionMap = {
    'alcohol-categories': 'Alcohol Categories section',
    'trip-types': 'Trip Types section',
    'demand-landscape': 'Demand Landscape section'
  };

  return sectionMap[segment] || null;
};

export const useAccessControl = (navOptions) => {
  const navigate = useNavigate();
  const isPreviewMode = usePreviewMode();
  const { setShowAuthModal, setDeniedSegment } = useOutletContext();

  const handleRedirect = useCallback(() => {
    const denialModalCopy = getDenialModalCopy(navOptions);
    setDeniedSegment(denialModalCopy);
    setShowAuthModal(true);
    navigate('/home');
    window.scrollTo(0, 0);
  }, [navigate, setShowAuthModal, setDeniedSegment, navOptions]);

  useEffect(() => {
    const hueSection = getHueSection(navOptions);
    const userEmail = getUserEmail(navigate, isPreviewMode);
    const accessLevels = getUserAccessLevels(userEmail);

    if (accessLevels.length === 1 && accessLevels[0] === AccessLevels.NONE) {
      navigate('/home');
      window.scrollTo(0, 0);
    } else if (!canAccessFeature(accessLevels, AccessLevels[hueSection])) {
      handleRedirect();
    }
  }, [navOptions, handleRedirect, navigate, isPreviewMode]);
};
