import React from 'react';
import PropTypes from 'prop-types';
import styles from './CategoryMethod.module.css';

export const CategoryMethod = ({ title, description, imageSrc, imageAlt }) => {
  return (
    <section className={styles.methodologySection}>
      <img loading="lazy" src={imageSrc} className={styles.methodologyImage} alt={imageAlt} />
      <div className={styles.contentWrapper}>
        <h2 className={styles.heading}>{title}</h2>
        <p className={styles.description}>{description}</p>
      </div>
    </section>
  );
};

CategoryMethod.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired
};
