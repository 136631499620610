import React from 'react';
import { Box, Typography, Card, Grid, Divider } from '@mui/material';
import RestaurantMenuOutlinedIcon from '@mui/icons-material/RestaurantMenuOutlined';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GroupIcon from '@mui/icons-material/Group';
import { Trans, useTranslation } from 'react-i18next';
import i18next from 'i18next';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import { ReactComponent as RelaxingIcon } from '../../../assets/images/Common/Relaxing.svg';
import { ReactComponent as DailydiningIcon } from '../../../assets/images/Common/dailydining.svg';
import { ReactComponent as SocializingIcon } from '../../../assets/images/Common/socializing.svg';
import { ReactComponent as GoingoutIcon } from '../../../assets/images/Common/goingout.svg';
import RestaurantIcon from '../../custom/Icons/RestaurantRoundedIcon.js';
import SectionIntro from '../../common/page-sections/SectionIntro.js';
import MacroOccasionCard from '../../custom/Cards/MacroOccasionCard.js';
import CustomCard from '../../custom/Cards/CustomCard.js';
import { occasionsAssets } from '../../common/OccasionsAssets';
import {
  occasionsMacroMapping,
  occasionIcons,
  occasionsKeyElementsCardData,
  occasionsMacroOccasionsCardData,
  occasionsTempoLevelMacroMapping
} from '../../../constants/OccasionsConstants';

import { ReactComponent as ChevronLeft } from '../../../assets/images/Occasions/Hub/ChevronLeft.svg';

const SectionTwo = () => {
  const keyOccasionIconItems = {
    eating: <RestaurantMenuOutlinedIcon color="primary" fontSize="large" className="card-icon" />,
    activity: <LocalActivityIcon color="primary" fontSize="large" className="card-icon" />,
    location: <LocationOnIcon color="primary" fontSize="large" className="card-icon" />,
    time: <CalendarTodayIcon color="primary" fontSize="large" className="card-icon" />,
    group: <GroupIcon color="primary" fontSize="large" className="card-icon" />,
    mood: <EmojiEmotionsIcon color="primary" fontSize="large" className="card-icon" />
  };

  const macrooccasionIcons = {
    relaxing: <RelaxingIcon color="primary" />,
    dailydining: <DailydiningIcon color="primary" />,
    socializing: <SocializingIcon color="primary" />,
    goingout: <GoingoutIcon color="primary" />
  };

  const { t } = useTranslation(i18next.language);

  return (
    <Box className="occasions-hub-section2">
      <Box className="section1-background">
        <Box className="ugc-container">
          <Grid container spacing={4}>
            {Object.keys(occasionsMacroMapping)
              .slice(0, 2)
              .map((item, index) => (
                <React.Fragment key={item}>
                  <CustomCard
                    keyId={`OccasionsHub-Section2-MacroOccasionGroup-${index}`}
                    index={index}
                    cards={occasionsMacroMapping[item]}
                    heading={item}
                  />
                </React.Fragment>
              ))}
          </Grid>
          <Grid container spacing={4}>
            {Object.keys(occasionsMacroMapping)
              .slice(2, 4)
              .map((item, index) => (
                <React.Fragment key={item}>
                  <CustomCard
                    keyId={`OccasionsHub-Section2-MacroOccasionGroup-${index + 2}`}
                    index={index}
                    cards={occasionsMacroMapping[item]}
                    heading={item}
                  />
                </React.Fragment>
              ))}
          </Grid>
        </Box>
      </Box>
      <Box className="occasion-hub-section2-background">
        <Box className="occasion-hub-section2-main">
          <SectionIntro
            heading={t('occasions.hub.whatDefinesAnOccasion.heading')}
            description={t('occasions.hub.whatDefinesAnOccasion.description')}
            sectionImages={{
              back: occasionsAssets.whatDefinesAnOccasion.back(),
              front: occasionsAssets.whatDefinesAnOccasion.front()
            }}
          />
        </Box>
      </Box>
      <Box className="key-occassion-elements-main">
        <Box className="key-occassion-elements">
          <Typography className="key-occassion-text" variant="h3">
            {t('occasions.hub.whatDefinesAnOccasion.keyElements.heading')}
          </Typography>
          <Typography className="key-occassion-subtext">
            <Trans components={{ colored: <i /> }}>
              {t('occasions.hub.whatDefinesAnOccasion.keyElements.description')}
            </Trans>
          </Typography>
        </Box>
        <Grid container spacing={1.25} className="key-occassion-cards-main">
          {occasionsKeyElementsCardData.map((card) => (
            <Grid item xs={12 / occasionsKeyElementsCardData.length} key={card.icon}>
              <Card className="key-occassion-cards">
                <Box className="card-content">
                  {keyOccasionIconItems[card.icon]}
                  <Box className="card-text">
                    <Typography variant="body2" className="card-title">
                      {t(card.title)}
                    </Typography>
                    <Typography className="card-description">{t(card.description)}</Typography>
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box className="macro-occassion-elements-main">
        <Box className="macro-occassion-elements">
          <Typography className="macro-occassion-text" variant="h3">
            {t('occasions.hub.whatDefinesAnOccasion.macroOccasions.heading')}
          </Typography>
          <Typography className="macro-occassion-subtext">
            <Trans components={{ colored: <i /> }}>
              {t('occasions.hub.whatDefinesAnOccasion.macroOccasions.description')}
            </Trans>
          </Typography>
        </Box>
        <Box className="macro-occassion">
          <Grid container justifyContent="center" spacing={1.25}>
            {occasionsMacroOccasionsCardData.map((macrocard) => (
              <Grid
                item
                alignSelf="stretch"
                key={macrocard.icon}
                xs={12 / occasionsMacroOccasionsCardData.length}
                sm={6}
                md={4}
                lg={3}
              >
                <MacroOccasionCard
                  icon={macrooccasionIcons[macrocard.icon]}
                  title={t(macrocard.title)}
                  description={t(macrocard.description)}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Box className="macro-occassion-elements-main">
        <Box className="macro-occassion-elements">
          <Typography className="macro-occassion-text" variant="h3">
            {t('occasions.hub.whatDefinesAnOccasion.microOccassions.heading')}
          </Typography>
          <Typography className="macro-occassion-subtext">
            <Trans components={{ colored: <i /> }}>
              {t('occasions.hub.whatDefinesAnOccasion.microOccassions.description')}
            </Trans>
          </Typography>
        </Box>
        <Box className="micro-section">
          <Box className="micro-section-content-container">
            <Box className="home-container">
              {/* <Typography className="home-typo">
                {t(
                  "occasions.hub.whatDefinesAnOccasion.microOccassions.premise1.inhome"
                )}
              </Typography>
              <Box className="top-arrow-container">
                <img
                  src={leftArrow}
                  alt="Towards In-Home"
                  className="top-left-arrow"
                />
                <img
                  src={rightArrow}
                  alt="Towards Out Of Home"
                  className="top-right-arrow"
                />
              </Box>
              <Typography className="home-typo">
                {t(
                  "occasions.hub.whatDefinesAnOccasion.microOccassions.premise1.outofhome"
                )}
              </Typography> */}
              <Box className="occasion-location-spectrum-container">
                <Box className="off-premise-divider-box">
                  <Box className="dot" />
                  <Divider className="location-spectrum-divider" />
                  <Typography className="home-typo">
                    {t('occasions.hub.whatDefinesAnOccasion.microOccassions.premise1.inhome')}
                  </Typography>
                  <Divider className="location-spectrum-divider" />
                  <Box className="dot" />
                </Box>
                <Box className="on-premise-divider-box">
                  <Box className="dot" />
                  <Divider className="location-spectrum-divider" />
                  <Typography className="home-typo">
                    {t('occasions.hub.whatDefinesAnOccasion.microOccassions.premise1.outofhome')}
                  </Typography>
                  <Divider className="location-spectrum-divider" />
                  <Box className="dot" />
                </Box>
              </Box>
            </Box>
            <Box className="tempo-container">
              <Box className="tempo-spectrum-divider-container">
                <ChevronLeft className="arrow left" />
                <Divider className="location-spectrum-divider" />
                <ChevronLeft className="arrow right" />
              </Box>
              <Box className="tempo-label-container">
                <Typography className="tempo-typo left">
                  {t('occasions.hub.whatDefinesAnOccasion.microOccassions.premise2.lowtempo')}
                </Typography>
                <Typography className="tempo-typo-info">
                  {t('occasions.hub.whatDefinesAnOccasion.microOccassions.premise2.spectrumInfo')}
                </Typography>
                <Typography className="tempo-typo right">
                  {t('occasions.hub.whatDefinesAnOccasion.microOccassions.premise2.hightempo')}
                </Typography>
              </Box>
              {/* <Typography className="tempo-typo">
                {t(
                  "occasions.hub.whatDefinesAnOccasion.microOccassions.premise2.lowtempo"
                )}
              </Typography>
              <img
                src={line}
                alt="Low-Tempo - High-Tempo"
                className="bottom-arrow"
              />
              <Typography className="tempo-typo">
                {t(
                  "occasions.hub.whatDefinesAnOccasion.microOccassions.premise2.hightempo"
                )}
              </Typography> */}
            </Box>
            {/* <Box className="micro-section-cards-main">
              {Object.keys(occasionsTempoLevelMacroMapping)?.map((item, index) => (
                <Card key={item} className="micro-section-card-div">
                  <Box className="micro-section-card-heading-container">
                    <Typography className="micro-section-card-heading">
                      {t(`occasions.macroOccasion.${item}`)}
                    </Typography>
                  </Box>
                  <Box className="micro-section-card-box">
                    {occasionsTempoLevelMacroMapping[item]?.map(
                      (card, subIndex) => (
                        <Box key={card} className="micro-section-card-container">
                          <Card className="micro-section-card-content">
                            <Typography className="micro-section-card-content-text">
                              {t(`occasions.type.${card}`)}
                            </Typography>
                          </Card>
                          <Box className="micro-section-card-icon">
                            {occasionIcons[card] && <RestaurantIcon color="#000000"/>}
                          </Box>
                        </Box>
                      )
                    )}
                  </Box>
                </Card>
              ))}
            </Box> */}
            <Grid container className="micro-section-cards-main" gap={1}>
              {Object.keys(occasionsTempoLevelMacroMapping)?.map((item, index) => (
                <Grid item key={item} xs={index % 2 !== 0 ? 2.34 : 3.51}>
                  <Card className="micro-section-card-div">
                    <Box className="micro-section-card-heading-container">
                      <Typography className="micro-section-card-heading">
                        {t(`occasions.macroOccasion.${item}`)}
                      </Typography>
                    </Box>
                    {/* <Box className="micro-section-card-box">
                      {occasionsTempoLevelMacroMapping[item]?.map(
                        (card, subIndex) => (
                          <Box key={card} className="micro-section-card-container">
                            <Card className="micro-section-card-content">
                              <Typography className="micro-section-card-content-text">
                                {t(`occasions.type.${card}`)}
                              </Typography>
                            </Card>
                            <Box className="micro-section-card-icon">
                              {occasionIcons[card] && <RestaurantIcon color="#000000"/>}
                            </Box>
                          </Box>
                        )
                      )}
                    </Box> */}
                    <Grid container className="micro-section-card-box" gap={1}>
                      {occasionsTempoLevelMacroMapping[item]?.map((card) => (
                        <Grid
                          item
                          xs={11 / occasionsTempoLevelMacroMapping[item].length}
                          key={card}
                          className="micro-section-card-container"
                        >
                          <Card className="micro-section-card-content">
                            <Typography className="micro-section-card-content-text">
                              {t(`occasions.type.${card}`)}
                            </Typography>
                          </Card>
                          <Box className="micro-section-card-icon">
                            {occasionIcons[card] && <RestaurantIcon color="#000000" />}
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Box className="full-meal-container">
              <Box className="meal-disclaimer-container">
                <Box className="full-meal-icon-container">
                  <RestaurantIcon className="full-meal-icon" />
                </Box>

                <Typography className="meal-disclaimer" variant="body1">
                  {t('occasions.hub.whatDefinesAnOccasion.microOccassions.mealdisclaimer1')}
                </Typography>
              </Box>
              <Box className="hosted-meal-container">
                <Typography className="hosted-meal" variant="body1">
                  {t('occasions.hub.whatDefinesAnOccasion.microOccassions.mealdisclaimer2')}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SectionTwo;
