import React from 'react';
import PropTypes from 'prop-types';
import styles from './IntroText.module.css';

export const IntroText = ({ tripTypeColor, weekpartText, boldTexts, accentTextColor }) => {
  const textValues = boldTexts.map((obj) => obj.text);
  return (
    <div className={styles.introText} style={{ backgroundColor: tripTypeColor, color: accentTextColor }}>
      <div className={styles.textWrap}>
        <div>
          {weekpartText.split('\\n').map((line) => (
            <span key={line} className={styles.regular}>
              {line.split(new RegExp(`(\\b(?:${textValues.join('|')})\\b)`)).map((part) =>
                textValues.includes(part) ? (
                  <span key={part} className={styles.bold}>
                    {part}
                  </span>
                ) : (
                  part
                )
              )}
              <br />
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

IntroText.propTypes = {
  tripTypeColor: PropTypes.string.isRequired,
  weekpartText: PropTypes.string.isRequired,
  boldTexts: PropTypes.arrayOf(PropTypes.object).isRequired,
  accentTextColor: PropTypes.string.isRequired
};
