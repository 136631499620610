import { Box, Typography, Rating, Snackbar, Alert } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useMsal } from '@azure/msal-react';
import { submitFeedback } from '../../../service/API';

function Icon(props) {
  return (
    <Box className="rating-icon">
      <Typography className="rating-icon-text">{props.value}</Typography>
    </Box>
  );
}

Icon.propTypes = {
  value: PropTypes.number.isRequired
};

function FeedbackSection({ page }) {
  const { t } = useTranslation(i18next.language);
  const { accounts } = useMsal();

  useEffect(() => {
    if (localStorage.getItem('i18nnextLng')?.length > 2) {
      i18next.changeLanguage('en');
    }
  }, []);

  FeedbackSection.propTypes = {
    page: PropTypes.string
  };

  const [feedbackValue, setFeedbackValue] = useState(5);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const getFeedbackText = (rating) => {
    if (rating === 1) return 'Not helpful';
    if (rating === 5) return 'Very helpful';
    return 'Helpful';
  };

  async function sendFeedback(rating) {
    setFeedbackValue(rating);
    let feedbackData = {
      userName: accounts[0].name,
      userId: accounts[0].username,
      feedback: JSON.stringify(rating),
      userFeedbacks: getFeedbackText(rating),
      page: page
    };
    try {
      const response = await submitFeedback(feedbackData);

      if (response) {
        setFeedbackValue(rating);
        setShowSuccessPopup(true);
      }
    } catch (error) {
      console.error('API Error:', error);
    }
  }

  return (
    <Box className="feedback-section-container">
      <Typography className="feedback-header" variant="h5">
        {t('common.rating.header')}
      </Typography>
      <Box className="feedback-container">
        <Rating
          className="smiley"
          name="highlight-selected-only"
          value={feedbackValue}
          IconContainerComponent={Icon}
          getLabelText={(value) => t('common.rating.selectedRating', { value: value })}
          highlightSelectedOnly
          onChange={(event) => sendFeedback(Number(event.target.value))}
        />
        <Box className="smiley-text-container">
          <Box className="smiley-text-low-rating">
            <ArrowDropUpIcon className="smiley-icon" />
            <Typography className="smiley-text" variant="mobileDataLabel">
              {t('common.rating.notHelpful')}
            </Typography>
          </Box>
          <Box className="smiley-text-high-rating">
            <ArrowDropUpIcon className="smiley-icon" />
            <Typography className="smiley-text" variant="mobileDataLabel">
              {t('common.rating.veryHelpful')}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Snackbar
        open={showSuccessPopup}
        onClose={() => setShowSuccessPopup(false)}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
          <Typography className="feedback-received-notification" variant="body1">
            {t('common.rating.feedbackSubmitted')}
          </Typography>
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default FeedbackSection;
