/* eslint-disable jsx-a11y/media-has-caption */
import { Box } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

function VideoCard({ cardData }) {
  return (
    <Box className="video-card-container">
      <video key={cardData.video} controls className="video-visual" poster={cardData.thumbnail}>
        <source src={cardData.video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Box>
  );
}

VideoCard.propTypes = {
  cardData: PropTypes.object.isRequired
};

export default VideoCard;
