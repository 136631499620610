import { Box, Grid } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import QuoteAttributionCard from './QuoteAttributionCard';
import ImageCard from './ImageCard';
import SmallQuoteAttributionCard from './SmallQuoteAttributionCard';

export default function JumboSmallQuoteImageLeft({ theme, dataprops }) {
  return (
    <Box className="jumbosmallquoteimageleft-card-container">
      <Grid container className="card-content-container">
        <Grid item xs={6}>
          <Box sx={{ height: '50%' }}>
            <ImageCard theme={theme} cardData={dataprops.imagecarddata} />
          </Box>
          <Box sx={{ height: '50%' }}>
            <SmallQuoteAttributionCard theme={theme} cardData={dataprops.quoteattributioncarddata} />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <QuoteAttributionCard theme={theme} cardData={dataprops.bigqoute} />
        </Grid>
      </Grid>
    </Box>
  );
}

JumboSmallQuoteImageLeft.propTypes = {
  theme: PropTypes.string.isRequired,
  dataprops: PropTypes.object.isRequired
};
