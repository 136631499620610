import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { COLORS } from '../../../../constants/CatTripColors';
import styles from './FlavorExpectations.module.css';

export function FlavorExpectations({
  flavorProfile,
  descriptionStart,
  descriptionHighlight,
  descriptionEnd,
  highlightColor,
  flavorSliderImg
}) {
  const { t } = useTranslation(i18next.language);
  return (
    <article className={styles.container}>
      <section className={styles.card}>
        <div className={styles.cardBackground} />
        <h2 className={styles.title}>{t('alcoholSubcategory.flavorExpectations.title')}</h2>
        <p className={styles.subtitle}>{flavorProfile}</p>
        <img
          src={flavorSliderImg.imgUrl}
          alt={flavorSliderImg.altText}
          className={styles.flavorSlider}
          loading="lazy"
        />
      </section>
      <p className={styles.description}>
        {descriptionStart} <span style={{ color: COLORS[highlightColor] }}>{descriptionHighlight}</span>{' '}
        {descriptionEnd}
      </p>
    </article>
  );
}

FlavorExpectations.propTypes = {
  flavorProfile: PropTypes.string.isRequired,
  descriptionStart: PropTypes.string.isRequired,
  descriptionHighlight: PropTypes.string.isRequired,
  descriptionEnd: PropTypes.string.isRequired,
  highlightColor: PropTypes.string.isRequired,
  flavorSliderImg: PropTypes.shape({
    imgUrl: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired
  }).isRequired
};
