import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SectionIntro from '../../common/page-sections/SectionIntro';
import { ReactComponent as RelaxingIcon } from '../../../assets/images/Common/Relaxing.svg';
import { ReactComponent as DailydiningIcon } from '../../../assets/images/Common/dailydining.svg';
import { ReactComponent as SocializingIcon } from '../../../assets/images/Common/socializing.svg';
import { ReactComponent as GoingoutIcon } from '../../../assets/images/Common/goingout.svg';
// import SpaIcon from "@mui/icons-material/Spa";
// import FlatwareIcon from "@mui/icons-material/Flatware";
// import Diversity3Icon from "@mui/icons-material/Diversity3";
// import NightlifeIcon from "@mui/icons-material/Nightlife";
import MacroOccasionCard from '../../custom/Cards/MacroOccasionCard';
import MicroOccasionCard from '../../custom/Cards/MicroOccasionCard';
import { demandSpaceAssets } from '../../common/DemandSpaceAssets';
import { demandspaceTheOccasion } from '../../../constants/DemandSpace';

function SectionThree({ macro, space }) {
  const macroOccasionIcons = {
    Fram_OCCAS_11: <RelaxingIcon color="primary" />,
    Fram_OCCAS_8: <DailydiningIcon color="primary" />,
    Fram_OCCAS_4: <SocializingIcon color="primary" />,
    Fram_OCCAS_1: <GoingoutIcon color="primary" />
  };

  const macroItemDetails = t(`demandspace.template.theOccasions.${macro?.segment}`, {
    returnObjects: true
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 12.5 }}>
      <Box className="occasion-background">
        <Box className="occasion-main">
          <SectionIntro
            heading={t('demandspace.template.theOccasions.occasionHeading')}
            description={t('demandspace.template.theOccasions.occasionDescription')}
            sectionImages={{
              back: demandSpaceAssets.theOccasions[space].back(),
              front: demandSpaceAssets.theOccasions[space].front()
            }}
          />
        </Box>
      </Box>
      <Box className="content">
        <Grid
          container
          gap={2.5}
          className="demandspace-occasion-content-container"
          //rowGap={3} spacing={3}
        >
          {macro?.occasions?.map((item, index) => (
            <Grid item xs={12} key={`occasion-${index}`} className="demandspace-occasion-content-container-item">
              <Box sx={{ flexGrow: 1 }}>
                <Grid container gap={2.5}>
                  <Grid item xs={2.8}>
                    <MacroOccasionCard
                      icon={macroOccasionIcons[item?.macroCode]}
                      icontext={macroItemDetails[item?.macroCode]?.title}
                      title={item.occasionTypes.replace(/\s*\(NET\)/i, '')}
                      description={macroItemDetails[item?.macroCode]?.macroOccasionDescription}
                    />
                  </Grid>
                  {item?.occasionItems?.map((subItem, microIndex) => (
                    <Grid item xs={2.8} key={microIndex}>
                      <MicroOccasionCard
                        title={t(`occasions.type.${item.occasionItems[microIndex].occasionCode}`)}
                        description={macroItemDetails[subItem.occasionCode]?.description}
                        occasionsoi={subItem?.occasionSoi}
                        occasionsov={subItem?.occasionSov}
                        locations={demandspaceTheOccasion[subItem?.occasionCode]}
                        sov={'sov'}
                        soi={'soi'}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box className="demandspace-sovsoicontent">
          <Typography className="demandspace-sovsoicontent-typo">
            <InfoOutlinedIcon className="sov-soi-icon" />
            {t('demandspace.template.theOccasions.sovsoicontent')}
          </Typography>
        </Box>
      </Box>
      {/* <Box className="demandspace-sovsoicontent">
        <Typography className="demandspace-sovsoicontent-typo">
          <InfoOutlinedIcon className="sov-soi-icon" />
          {t("demandspace.template.theOccasions.sovsoicontent")}
        </Typography>
      </Box> */}
    </Box>
  );
}

SectionThree.propTypes = {
  macro: PropTypes.object.isRequired,
  space: PropTypes.string.isRequired
};

export default SectionThree;
