import React from 'react';
import PropTypes from 'prop-types';
import { TableHeader } from './TableHeader';
import { TableRow } from './TableRow';
import { TimeDefinitions } from './TimeDefinitions';
import styles from './WeekpartTable.module.css';

export const WeekpartTable = ({ headerData, dayPartData }) => {
  return (
    <div className={styles.table}>
      <TableHeader data={headerData} />
      {dayPartData.map((rowData) => (
        <TableRow key={rowData.title} data={rowData} />
      ))}
      <TimeDefinitions />
    </div>
  );
};

WeekpartTable.propTypes = {
  headerData: PropTypes.object.isRequired,
  dayPartData: PropTypes.arrayOf(PropTypes.object).isRequired
};
