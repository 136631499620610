import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { submitInfoPopupStatus } from '../../service/API';

function TermsConditions() {
  const { instance, accounts } = useMsal();
  const { t } = useTranslation(i18next.language);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('i18nnextLng')?.length > 2) {
      i18next.changeLanguage('en');
    }
  }, []);

  function handleAcceptTerms() {
    // Send Acceptance response to database
    sendTermsAcceptanceStatus();
  }

  function handleDeclineTerms() {
    window.sessionStorage.setItem('tncAccepted', false);
    const logoutRequest = {
      account: instance.getAccountByHomeId(accounts[0].homeAccountId)
    };
    instance.logoutRedirect(logoutRequest).catch((error) => console.log(`Logout Error: ${error}`));
  }

  function sendTermsAcceptanceStatus() {
    let popupData = {
      userId: accounts[0].username,
      pageName: 'terms'
    };
    try {
      submitInfoPopupStatus(popupData).then(() => {
        navigate('/demand-landscape');
        window.scrollTo(0, 0);
      });
      window.sessionStorage.setItem('tncAccepted', true);
    } catch (error) {
      console.error('API Error:', error);
    }
  }

  return (
    <div className="content">
      <Grid container className="tnc-container">
        <Grid item xs={12} className="tnc-container-item">
          <Box className="tnc-content">
            <Box className="tnc-heading">
              <Typography className="heading">{t('terms.heading')}</Typography>
            </Box>
            <Box className="tnc-body">
              <Typography className="tnc-subline">{t('terms.termsSubline')}</Typography>
              <Typography className="tnc-points">{t('terms.termsPoints')}</Typography>
              <Typography className="tnc-disclaimer">
                <Trans>{t('terms.disclaimer')}</Trans>
              </Typography>
            </Box>
            <Box className="tnc-actions-items">
              <Button className="tnc-button accept" variant="contained" onClick={handleAcceptTerms}>
                {t('buttons.accept')}
              </Button>
              <Button className="tnc-button decline" variant="contained" onClick={handleDeclineTerms}>
                {t('buttons.decline')}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default TermsConditions;
