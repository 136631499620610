import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import styles from './ShoppingStats.module.css';

const ShoppingStats = ({
  tripTypeColor,
  chartImgUrl,
  shopperVsConsumerCopy,
  shopperVsConsumerBold,
  accentTextColor
}) => {
  const { t } = useTranslation(i18next.language);
  const textValues = shopperVsConsumerBold.map((obj) => obj.text);
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <header className={styles.header}>
          <div>{t('tripTypesPageTemplate.shoppersVsConsumers.shoppingStats.title')}</div>
          <div className={styles.divider} />
        </header>

        <main className={styles.content}>
          <div className={styles.pieChart}>
            <img
              loading="lazy"
              src={chartImgUrl}
              className={styles.pieChartImg}
              alt={t('tripTypesPageTemplate.shoppersVsConsumers.shoppingStats.shoppingStatsImgAlt')}
            />
          </div>
        </main>

        <footer className={styles.footer} style={{ backgroundColor: tripTypeColor, color: accentTextColor }}>
          <div>
            {shopperVsConsumerCopy.split(new RegExp(`(\\b(?:${textValues.join('|')})\\b)`)).map((part) =>
              textValues.includes(part.trim()) ? (
                <span key={part} className={styles.bold}>
                  {part}
                </span>
              ) : (
                part
              )
            )}
          </div>
        </footer>
      </div>
    </div>
  );
};

ShoppingStats.propTypes = {
  tripTypeColor: PropTypes.string.isRequired,
  chartImgUrl: PropTypes.string.isRequired,
  shopperVsConsumerCopy: PropTypes.string.isRequired,
  shopperVsConsumerBold: PropTypes.arrayOf(PropTypes.object).isRequired,
  accentTextColor: PropTypes.string.isRequired
};

export default ShoppingStats;
