import PropTypes from 'prop-types';
import styles from './StatCard.module.css';

export function StatCard({ text, tripTypeColor, accentTextColor }) {
  return (
    <div className={styles.statCard} style={{ backgroundColor: tripTypeColor }}>
      <p className={styles.cardText} style={{ color: accentTextColor }}>
        {text}
      </p>
    </div>
  );
}

StatCard.propTypes = {
  text: PropTypes.string.isRequired,
  tripTypeColor: PropTypes.string.isRequired,
  accentTextColor: PropTypes.string.isRequired
};
