import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { BrandRow } from './BrandRow';
import styles from './BrandShare.module.css';

export const BrandShare = ({ brandShareData, brandShareDataColor }) => {
  const { t } = useTranslation(i18next.language);
  return (
    <div className={styles.container}>
      <div className={styles.cardBackground} />
      <div className={styles.content}>
        <div className={styles.titleSection}>
          <h2 className={styles.title}>{t('alcoholSubcategory.sizing.productTableHeader')}</h2>
          <div className={styles.divider} />
        </div>
        <div className={styles.brandsList}>
          {brandShareData.map((brand) => (
            <BrandRow key={brand.name} {...brand} brandShareDataColor={brandShareDataColor} />
          ))}
        </div>
      </div>
    </div>
  );
};

BrandShare.propTypes = {
  brandShareData: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      shareInfo: PropTypes.string.isRequired
    })
  ).isRequired,
  brandShareDataColor: PropTypes.string.isRequired
};
