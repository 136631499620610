import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { COLORS } from '../../../../constants/CatTripColors';
import { getAccentTextColor } from '../../../../constants/GetAccentTextColor';
import { ChartCard } from './compound-components/ChartCard';
import { DescriptionBox } from './compound-components/DescriptionBox';
import { AvgTripDuration } from './compound-components/AvgTripDuration';
import styles from './TripDuration.module.css';

export const TripDuration = ({ tripTypeColor, duration, description, chartImgUrl, tripDescription }) => {
  const { t } = useTranslation(i18next.language);
  const accentTextColor = getAccentTextColor(tripTypeColor);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.subtitle}>{t('tripTypesPageTemplate.tripDuration.eyebrow')}</div>
        <div className={styles.title}>{t('tripTypesPageTemplate.tripDuration.title')}</div>
      </div>
      <AvgTripDuration
        tripTypeColor={COLORS[tripTypeColor]}
        accentTextColor={accentTextColor}
        duration={duration}
        description={description}
      />
      <div className={styles.content}>
        <DescriptionBox
          style={
            chartImgUrl
              ? { backgroundColor: COLORS[tripTypeColor], color: accentTextColor }
              : { backgroundColor: '#1D1D1D', width: '100%', height: '400px', color: '#fff' }
          }
          titleWidth={chartImgUrl ? '350px' : '100%'}
        >
          {tripDescription}
        </DescriptionBox>
        {chartImgUrl && <ChartCard chartImgUrl={chartImgUrl} />}
      </div>
    </div>
  );
};

TripDuration.propTypes = {
  tripTypeColor: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  tripDescription: PropTypes.string.isRequired,
  chartImgUrl: PropTypes.string
};
