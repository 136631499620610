import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, MobileStepper, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { getModularCards } from '../../../constants/constants';

function CarouselModal({ microSegment, carouselData, theme }) {
  const carouselCardData = carouselData;
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeCardData, setActiveCardData] = React.useState(carouselCardData[0]);
  const maxSteps = carouselCardData.length;

  const handleNext = () => {
    if (activeStep === maxSteps - 1) {
      setActiveStep(0);
      setActiveCardData(carouselCardData[0]);
    } else if (activeStep < maxSteps) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setActiveCardData(carouselCardData[activeStep + 1]);
    }
  };
  const handlePrev = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setActiveCardData(carouselCardData[activeStep - 1]);
    }
  };

  return (
    <>
      <Box className="carousel-with-mobile-stepper-container">
        <Grid className="carousel-card-container" container spacing={2}>
          {
            <Grid item xs={12} className="modular-item">
              {getModularCards(`${microSegment}`, activeCardData.cardtype, activeCardData.data)}
            </Grid>
          }
        </Grid>
        <Box className={`mobile-stepper-container ${theme}`}>
          <IconButton className="left-chevron-icon" onClick={handlePrev}>
            <ChevronLeftIcon style={{ color: '#F6E003' }} />
          </IconButton>
          <MobileStepper steps={maxSteps} position="static" activeStep={activeStep} sx={{ background: 'none' }} />
          <IconButton className="right-chevron-icon" onClick={handleNext}>
            <ChevronRightIcon style={{ color: '#F6E003' }} />
          </IconButton>
        </Box>
      </Box>
    </>
  );
}

CarouselModal.propTypes = {
  microSegment: PropTypes.string.isRequired,
  carouselData: PropTypes.array.isRequired,
  theme: PropTypes.string.isRequired
};

export default CarouselModal;
