import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import styles from './FlavorSpectrum.module.css';

export const FlavorSpectrum = ({ title, gradient }) => {
  const { t } = useTranslation(i18next.language);
  return (
    <div className={styles.spectrumContainer}>
      <div className={styles.cardBackground} />
      <div className={styles.spectrumContent}>
        <div className={styles.light}>{t('categoryFramework.categoryExplorer.light')}</div>
        <div className={styles.spectrumTitle}>{title}</div>
        <div className={styles.flavorful}>{t('categoryFramework.categoryExplorer.flavorful')}</div>
      </div>
      <div className={styles.spectrumBar} style={{ background: gradient }} />
    </div>
  );
};

FlavorSpectrum.propTypes = {
  title: PropTypes.string.isRequired,
  gradient: PropTypes.string.isRequired
};
