import React, { useEffect } from 'react';
import SectionTwo from './SectionTwo';
import SectionOne from './SectionOne';
import SectionThree from './SectionThree';
import SectionFour from './SectionFour';
import SectionFive from './SectionFive';

function Occasions() {
  useEffect(() => {
    //window.scrollTo(0,0)
  });
  return (
    <div className="main-content">
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
    </div>
  );
}

export default Occasions;
