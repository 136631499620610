import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { ColorGradient } from '../../../cat-trip-reuse-components/color-gradient/ColorGradient';
import { IconCard } from './compound-components/IconCard';
import { SectionHeader } from './compound-components/SectionHeader';
import { strategyIcons, activationIcons, retailIcons } from './data/iconData';
import styles from './CommercialTeams.module.css';

export const CommercialTeams = () => {
  const { t } = useTranslation(i18next.language);

  return (
    <div className={styles.container} data-testid="commercial-teams-container">
      <ColorGradient data-testid="color-gradient" />
      <div className={styles.contentWrao}>
        <div className={styles.heading}>
          {t('tripTypesLanding.commercialTeams.title1')}
          <span className={styles.highlight}>{t('tripTypesLanding.commercialTeams.highlight1')}</span>
          {t('tripTypesLanding.commercialTeams.title2')}
          <span className={styles.highlight}>{t('tripTypesLanding.commercialTeams.highlight2')}</span>
        </div>
        <div className={styles.content}>
          <div className={styles.sections}>
            <div className={styles.strategySection}>
              <SectionHeader title={t('tripTypesLanding.commercialTeams.teamIconLabels.strategy')} />
            </div>
            <div className={styles.activationSection}>
              <SectionHeader title={t('tripTypesLanding.commercialTeams.teamIconLabels.activation')} />
            </div>
            <div className={styles.retailSection}>
              <SectionHeader title={t('tripTypesLanding.commercialTeams.teamIconLabels.retail')} />
            </div>
          </div>
          <div className={styles.iconContainer}>
            <div className={styles.iconWrapper}>
              <div className={styles.iconGrid}>
                {strategyIcons.map((icon) => (
                  <IconCard key={icon.id} {...icon} />
                ))}
                {activationIcons.map((icon) => (
                  <IconCard key={icon.id} {...icon} />
                ))}
                {retailIcons.map((icon) => (
                  <IconCard key={icon.id} {...icon} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
