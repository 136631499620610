export const tripTypes = [
  {
    bgColor: 'GRAB_GO_ORANGE',
    iconSrc:
      'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F2863d2a6cc35415ea888ec31c49b3ea3',
    iconWidth: '54px',
    iconAspectRatio: '0.9',
    text: 'Alcohol grab & go',
    navigateTo: '/trip-types/alcohol-grab-go'
  },
  {
    bgColor: 'ALC_RELOAD_TEAL',
    iconSrc:
      'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F10cf6b0413ec428aa3f07df9ff3a282e',
    iconWidth: '55px',
    iconAspectRatio: '0.92',
    text: 'Alcohol reload',
    navigateTo: '/trip-types/alcohol-reload'
  },
  {
    bgColor: 'PITSTOP_BLUE',
    iconSrc:
      'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F0b0720c84b344b7985606502ac31ef3a',
    iconWidth: '38px',
    iconAspectRatio: '0.63',
    text: 'pit stop essentials',
    navigateTo: '/trip-types/pit-stop-essentials'
  },
  {
    bgColor: 'TODAY_MEAL_GREEN',
    iconSrc:
      'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F15df5ab0d7c54175b1b4fa4f3f2797ea',
    iconWidth: '46px',
    iconAspectRatio: '0.75',
    text: "today's meal+",
    navigateTo: '/trip-types/todays-meal-plus'
  },
  {
    bgColor: 'FREQ_FILL_IN_RED',
    iconSrc:
      'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F909ab4ef675f4b7ba96e29f9d74166b6',
    iconWidth: '49px',
    iconAspectRatio: '0.82',
    text: 'frequent fill-in',
    navigateTo: '/trip-types/frequent-fill-in'
  },
  {
    bgColor: 'STOCKUP_PURPLE',
    iconSrc:
      'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fd424ee083e2a4deeabb39b2d2e7c3871',
    iconWidth: '44px',
    iconAspectRatio: '0.73',
    text: 'household stock-up',
    navigateTo: '/trip-types/household-stock-up'
  }
];
