import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../../../constants/CatTripColors';
import { getAccentTextColor } from '../../../../constants/GetAccentTextColor';
import { ChannelDistribution } from './compound-components/ChannelDistribution';
import { DescriptionBox } from './compound-components/DescriptionBox';
import styles from './ChannelSelection.module.css';

export const ChannelSelection = ({ tripTypeColor, channelData, chartImgUrl, selectionDescription }) => {
  const accentTextColor = getAccentTextColor(tripTypeColor);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.subtitle}>Store Selection</div>
        <div className={styles.title}>Channel Selection</div>
      </div>
      <div className={styles.content}>
        <DescriptionBox backgroundColor={COLORS[tripTypeColor]} accentTextColor={accentTextColor}>
          {selectionDescription}
        </DescriptionBox>
        <ChannelDistribution channelData={channelData} chartImgUrl={chartImgUrl} />
      </div>
    </div>
  );
};

ChannelSelection.propTypes = {
  tripTypeColor: PropTypes.string.isRequired,
  selectionDescription: PropTypes.string.isRequired,
  channelData: PropTypes.array.isRequired,
  chartImgUrl: PropTypes.string.isRequired
};
