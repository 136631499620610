import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import DemandSpaceSectionUGCCard from '../../custom/Cards/DemandSpaceSectionUGCCard';

import { demandspaceSectionWayfinderMapping } from '../../../constants/constants';
import { peopleDemandSpaceMapping } from '../../../constants/PeopleSegment';
import { occasionsDemandSpaceMapping } from '../../../constants/OccasionsConstants';
import { demandspaceOccasionsMapping } from '../../../constants/DemandSpace';
import WayFinderCommon from './WayFinderCommon';

const DemandSpaces = ({ page, micro, data, space }) => {
  const { t } = useTranslation(i18next.language);

  useEffect(() => {
    if (localStorage.getItem('i18nnextLng')?.length > 2) {
      i18next.changeLanguage('en');
    }
  });

  function getDemandSpaces(pageName, selectedMicro) {
    let demandSpaces = [];
    switch (pageName) {
      case 'people-segment':
        demandSpaces = peopleDemandSpaceMapping[selectedMicro];
        break;
      case 'occasions':
        demandSpaces = occasionsDemandSpaceMapping[selectedMicro];
        break;
      default:
        break;
    }
    return demandSpaces;
  }

  function getCardData(demandSpace) {
    const demandSpaceName = t(`demandspace.type.${demandSpace}`);
    const demandSpaceOccasions = demandspaceOccasionsMapping[demandSpace]
      .map((occasion) => t(`occasions.macroOccasion.${occasion}`))
      .join(' + ');

    return {
      title: demandSpaceName,
      subtitle: demandSpaceOccasions,
      scopeData: data[demandSpace]
    };
  }
  const [wayfinderHighlightedDemandSpace, setWayfinderHighlightedDemandSpace] = useState(['']);
  function demandspaceUGCCardHoverHandler(hoveredItem) {
    setWayfinderHighlightedDemandSpace([hoveredItem]);
  }

  return (
    <div className="demand-spaces-section-container">
      <Grid container className="section-intro">
        <Grid item className="demandspaces-intro-left-item" xs={7.5}>
          <Divider className="divider" color="primary" />
          <Typography variant="h2" component="div" className="intro-text">
            <Trans>
              {t(`common.demandspaceSection.${page}.title`, {
                micro: t(`${page}.type.${micro}`)
              })}
            </Trans>
          </Typography>
        </Grid>
        <Grid item className="demandspaces-intro-right-item" xs={4.5}>
          <Box className="wayfinder-container">
            {
              /*page === "occasions" ? (
              <WayFinderCommon
                ids={demandspaceSectionWayfinderMapping[micro]}
              />
            ) : (
              <WayFinderCommon
                ids={demandspaceSectionWayfinderMapping[micro]}
                highlightIds={wayfinderHighlightedDemandSpace}
                wayfinderID="wayfinder-common"
              />
            )*/
              <WayFinderCommon
                ids={demandspaceSectionWayfinderMapping[micro]}
                highlightIds={wayfinderHighlightedDemandSpace}
                wayfinderID="wayfinder-common"
              />
            }
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2.5} className="demand-space-ugc-card-list">
        {getDemandSpaces(page, micro).map((item) => (
          <Grid item key={item} xs={2} className="demand-space-ugc-card-list-item">
            <DemandSpaceSectionUGCCard
              pageName={page}
              space={space}
              demandspace={item}
              cardData={getCardData(item)}
              hoverHandler={(hoveredItem) => demandspaceUGCCardHoverHandler(hoveredItem)}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

DemandSpaces.propTypes = {
  page: PropTypes.string.isRequired,
  micro: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  space: PropTypes.string
};

export default DemandSpaces;
