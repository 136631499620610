import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './ThemeCard.module.css';

export const ThemeCard = ({ themeTitle, themes, highlightTexts, tripTypeColor, accentTextColor }) => {
  const textValues = highlightTexts.map((obj) => obj.text);
  return (
    <div className={styles.themeCard}>
      <div className={styles.header} style={{ backgroundColor: tripTypeColor }}>
        <div className={styles.title} style={{ color: accentTextColor }}>
          {themeTitle}
        </div>
      </div>
      <div className={styles.content}>
        {themes.map((theme, index) => (
          <Fragment key={theme.theme}>
            <div className={styles.themeText}>
              {theme.theme.split(new RegExp(`(\\b(?:${textValues.join('|')})\\b)`)).map((part) =>
                textValues.includes(part.trim()) ? (
                  <span key={part} className={styles.highlight}>
                    {part}
                  </span>
                ) : (
                  part
                )
              )}
            </div>
            {index < themes.length - 1 && <div className={styles.divider} />}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

ThemeCard.propTypes = {
  themeTitle: PropTypes.string.isRequired,
  themes: PropTypes.array.isRequired,
  highlightTexts: PropTypes.arrayOf(PropTypes.object).isRequired,
  tripTypeColor: PropTypes.string.isRequired,
  accentTextColor: PropTypes.string.isRequired
};
