import { Box, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const UGCCardSection = ({ title, content, selectedSegment }) => {
  return (
    <Box className={`people-section2-experiences ${selectedSegment}`}>
      <Typography className="people-section2-experiences-typo">{title}</Typography>
      <Typography className="people-section2-experiences-typo2">{content}</Typography>
    </Box>
  );
};

UGCCardSection.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  selectedSegment: PropTypes.string.isRequired
};

export default UGCCardSection;
