import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Button, Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { assets } from '../common/images';

function SectionOne() {
  const { t } = useTranslation(i18next.language);
  const navigate = useNavigate();

  return (
    // <div className="section1-hero">
    //   <div className="offset-video">
    //     <img src={assets.home.homepage_offsetImage_top} alt="offset top" />
    //   </div>

    //   <div className="hero-title-container">
    //     <Typography variant="body1" className="title-intro">
    //       {t("home.section1.heroTitleIntro")}
    //     </Typography>
    //     <Typography variant="body1" className="title-text">
    //       {t("home.section1.heroTitleText")}
    //     </Typography>
    //     <Button
    //       variant="contained"
    //       className="explore-demandlandscape-btn outlined-button"
    //       onClick={() => navigate("/demandspace")}
    //     >
    //       {t("buttons.viewDemandLandscape")}
    //     </Button>
    //   </div>

    //   <div className="subhead">
    //     <div className="subhead-content">
    //       <div className="homepage-subhead-image">
    //         <img
    //           src={assets.home.homepage_offsetImage_front}
    //           alt="Offset front"
    //         />
    //       </div>

    //       <div className="homepage-subhead-video">
    //         <video
    //           autostart="true"
    //           autoPlay
    //           loop
    //           src={assets.home.homepage_offsetvideo}
    //           type="video/mp4"
    //           alt="homepage subhead"
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <Stack className="section1-hero" spacing={-20}>
      <Box className="offset-video">
        <img src={assets.home.homepage_offsetImage_top()} alt="offset top" />
      </Box>
      <Stack spacing={-20}>
        <Box className="hero-title-container">
          <Typography variant="body1" className="title-intro">
            {t('home.section1.heroTitleIntro')}
          </Typography>
          <Typography variant="body1" className="title-text">
            {t('home.section1.heroTitleText')}
          </Typography>
          <Button
            variant="contained"
            className="explore-demandlandscape-btn outlined-button"
            onClick={() => {
              navigate('/demand-landscape/demandspace');
              window.scrollTo(0, 0);
            }}
          >
            {t('buttons.viewDemandLandscape')}
          </Button>
        </Box>
        <Box className="subhead">
          <Stack direction={'row'} className="subhead-content" spacing={-10}>
            <Box className="homepage-subhead-image">
              <img src={assets.home.homepage_offsetImage_front()} alt="Offset front" />
            </Box>

            <Box className="homepage-subhead-video">
              <video
                autoPlay
                playsInline
                muted
                loop
                src={assets.home.homepage_offsetvideo()}
                type="video/mp4"
                alt="homepage subhead"
              />
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
}

export default SectionOne;
