import { Box, Grid } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import ImageCard from './ImageCard';
import SmallQuoteAttributionCard from './SmallQuoteAttributionCard';

export default function BigQuoteImageRightCard({ theme, dataprops }) {
  const hasImageData = !!dataprops.imagecarddata.image; // Check if image card data is present

  return (
    <Box className="bigquoteimageright-card-container">
      <Grid container className="card-content-container">
        <Grid
          item
          xs={hasImageData ? 6 : 12}
          // sm={4} lg={6} md={4}
          maxHeight="31.25rem"
          className="bigquoteimageright-card-left-item"
        >
          <SmallQuoteAttributionCard theme={theme} cardData={dataprops.quoteattributioncarddata} />
        </Grid>
        {hasImageData && (
          <Grid
            item
            xs={6}
            //  sm={4} lg={6} md={4}
            maxHeight="31.25rem"
            className="bigquoteimageright-card-right-item"
          >
            <ImageCard theme={theme} cardData={dataprops.imagecarddata} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

BigQuoteImageRightCard.propTypes = {
  theme: PropTypes.string.isRequired,
  dataprops: PropTypes.object.isRequired
};
