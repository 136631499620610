import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import CategoryBars from '../../../trip-types-framework/compound-components/CategoryBars.js';
import TripCard from '../../../trip-types-framework/compound-components/TripCard.js';
import { tripData } from '../../../../constants/TripTypeExplorerData.js';
import styles from './TripTypesFramework.module.css';

export function TripTypesFramework() {
  const { t } = useTranslation(i18next.language);
  const navigate = useNavigate();

  const clickHandler = useCallback(
    (navigateTo) => {
      navigate(navigateTo);
    },
    [navigate]
  );

  return (
    <div className={styles.categoryStructureFramework}>
      <h2 className={styles.heading}>{t('tripTypesLanding.tripTypesFramework.title')}</h2>
      <CategoryBars />
      <div className={styles.tripGrid}>
        {tripData.map((trip) => (
          <TripCard key={trip.id} {...trip} onClick={() => clickHandler(trip.navigateTo)} />
        ))}
      </div>
    </div>
  );
}
