import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Card } from '../../../cat-trip-reuse-components/reusable-card/Card';
import { StatBlock } from './compound-components/StatBlock';
import styles from './ShoppingTripSection.module.css';

export const ShoppingTripSection = () => {
  const { t } = useTranslation(i18next.language);
  return (
    <Card height="400px">
      <div className={styles.statsCard} data-testid="shopping-trip-section-container">
        <div className={styles.statsTitle}>
          {t('tripTypesLanding.shoppingTrips.title1')}
          <span className={styles.highlightedText}>{t('tripTypesLanding.shoppingTrips.highlight')}</span>
          {t('tripTypesLanding.shoppingTrips.title2')}
        </div>
        <div className={styles.statsContent}>
          <StatBlock
            number={t('tripTypesLanding.shoppingTrips.stats')}
            description={t('tripTypesLanding.shoppingTrips.statDescription')}
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F4e6f50f20f144e5d9aae1e0d6f39f30e"
            className={styles.statsImage}
            alt={t('tripTypesLanding.shoppingTrips.shopImgAlt')}
          />
        </div>
        <div className={styles.sourceText}>{t('tripTypesLanding.shoppingTrips.source')}</div>
      </div>
    </Card>
  );
};
