import { COLORS } from './CatTripColors';

export const mainNavLinks = {
  mainCategory: {
    label: 'Hard Beverage Hub',
    to: '/categories/hard-bev-hub'
  },
  subCategory: {
    'seltzer-spirit': { label: 'Seltzers (Spirit-Based)', to: '/categories/hard-bev-hub/seltzer-spirit' },
    'seltzer-malt': { label: 'Seltzers (Malt-Sugar)', to: '/categories/hard-bev-hub/seltzer-malt' },
    'hard-cider': { label: 'Hard Cider', to: '/categories/hard-bev-hub/hard-cider' },
    'fmwbs-low-abv': { label: 'FMWBS (Low ABV)', to: '/categories/hard-bev-hub/fmwbs-low-abv' },
    'fmwbs-high-abv': { label: 'FMWBS (High ABV)', to: '/categories/hard-bev-hub/fmwbs-high-abv' },
    'spirit-based-cocktails': {
      label: 'Spirit-Based Cocktails',
      to: '/categories/hard-bev-hub/spirit-based-cocktails'
    },
    'multi-serve': { label: 'Multi-Serve', to: '/categories/hard-bev-hub/multi-serve' }
  }
};

export const subNavLinks = [
  { href: '#definition', text: 'Definition' },
  { href: '#sizing', text: 'Sizing' },
  { href: '#consumer-perceptions', text: 'Consumer Perceptions' },
  { href: '#wrap-up', text: 'Wrap Up' }
];

export const navColorSet = {
  'seltzer-spirit': {
    anchorBarColor: COLORS.SELTZER_SPIRIT_PURPLE,
    anchorBarColorSecondary: COLORS.DARK_OVERLAY,
    textColor: COLORS.TEXT_WHITE
  },
  'seltzer-malt': {
    anchorBarColor: COLORS.SELTZER_MALT_PURPLE,
    anchorBarColorSecondary: COLORS.DARK_OVERLAY,
    textColor: COLORS.TEXT_WHITE
  },
  'hard-cider': {
    anchorBarColor: COLORS.HARD_CIDER_PURPLE,
    anchorBarColorSecondary: COLORS.DARK_OVERLAY,
    textColor: COLORS.TEXT_WHITE
  },
  'fmwbs-low-abv': {
    anchorBarColor: COLORS.FMWBS_LOW_PURPLE,
    anchorBarColorSecondary: COLORS.DARK_OVERLAY,
    textColor: COLORS.TEXT_WHITE
  },
  'fmwbs-high-abv': {
    anchorBarColor: COLORS.FMWBS_HIGH_PURPLE,
    anchorBarColorSecondary: COLORS.DARK_OVERLAY,
    textColor: COLORS.TEXT_WHITE
  },
  'spirit-based-cocktails': {
    anchorBarColor: COLORS.SPIRIT_COCKTAILS_PURPLE,
    anchorBarColorSecondary: COLORS.DARK_OVERLAY,
    textColor: COLORS.TEXT_WHITE
  },
  'multi-serve': {
    anchorBarColor: COLORS.MULTI_SERVE_PURPLE,
    anchorBarColorSecondary: COLORS.DARK_OVERLAY,
    textColor: COLORS.TEXT_WHITE
  }
};
