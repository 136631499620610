import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useAccessControl } from '../../constants/AccessControl';
import Footer from './Footer';
import DemandLandscapeHeader from './DemandLandscapeHeader';
import Loader from './Loader';

function DemandLandscapeLayout() {
  useAccessControl('demand-landscape');

  const navigate = useNavigate();
  const [sasStored, setSasStored] = useState(!!localStorage.getItem('sasKey'));

  useEffect(() => {
    const checkSas = () => {
      const sas = localStorage.getItem('sasKey');
      if (sas) {
        setSasStored(true);
      }
    };
    // This code is required to refresh the image after the token is acquired.
    const handleStorageChange = () => {
      const isRedirect = localStorage.getItem('isredirect');

      if (isRedirect) {
        localStorage.setItem('isredirect', false);

        navigate('/demand-landscape');
      }
    };
    window.addEventListener('storage', handleStorageChange);

    const interval = setInterval(checkSas, 100);
    return () => {
      clearInterval(interval);
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [navigate]);

  return (
    <>
      {sasStored ? (
        <div className="container">
          <DemandLandscapeHeader />
          <Outlet />
          <Footer />
        </div>
      ) : (
        <div style={{ backgroundColor: '#000000' }}>
          <Loader />
        </div>
      )}
    </>
  );
}

export default DemandLandscapeLayout;
