import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import OccasionsWrapUp from '../../common/page-sections/Wrap-up/OccasionsWrapUp';

function SectionSix({ sectionData }) {
  const { macro, micro } = useParams();
  return (
    <div className="section6">
      <OccasionsWrapUp macro={macro} selectedMicro={micro} sectionContent={sectionData} />
    </div>
  );
}

SectionSix.propTypes = {
  sectionData: PropTypes.object.isRequired
};

export default SectionSix;
