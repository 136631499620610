import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { store } from './store/Store';
import { startSASTokenRefresh } from './service/API';

import './i18n/i18n';

import { b2cConfig } from './auth-config';
const { default: accessData } = await import(`./data/${window.env.REACT_APP_ACCESS_JSON || 'dev-access'}.json`);

export const msalInstance = new PublicClientApplication(b2cConfig);

const emails = Array.isArray(accessData) ? accessData : [];
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Add MSAL event callback for handling login success
msalInstance.addEventCallback(async (event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    try {
      const account = event.payload.account;
      const username = account.username;
      console.log(`Login successful for user: ${username}`);

      // Validate the logged-in user against allowed emails
      const email = emails.find((email) => email.email.toLowerCase() === username.toLowerCase());
      if (!email) {
        console.error(`Unauthorized email detected: ${username}. Logging out.`);
        msalInstance.logoutRedirect();
        localStorage.setItem('isLoggedin', false);
      } else {
        localStorage.setItem('isLoggedin', true);
        msalInstance.setActiveAccount(account);
        // TODO: re-implement usage of getAssetsSASToken
        // await getAssetsSASToken();
        await startSASTokenRefresh();
        console.log(`Account set as active: ${username}`);
        localStorage.setItem('user_email', username);
        localStorage.setItem('user_name', account.name);
      }
    } catch (error) {
      localStorage.setItem('isLoggedin', false);
      console.error('Error handling login success:', error);
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App instance={msalInstance} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
