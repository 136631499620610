import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { COLORS } from '../../../../../constants/CatTripColors';
import { calculateProgress } from '../../../../../constants/CalculateProgress';
import ProgressBar from './ProgressBar';
import styles from './SegmentTable.module.css';

const SegmentTable = ({ tripTypeColor, segmentTitle, segmentTableData, accentTextColor }) => {
  const { t } = useTranslation(i18next.language);

  return (
    <div className={styles.tableWrapper}>
      <div className={styles.header} style={{ backgroundColor: tripTypeColor, color: accentTextColor }}>
        {segmentTitle} {t('tripTypesPageTemplate.basketCategoryStats.segmentsTable.title')}
      </div>
      <div className={`${styles.content} ${styles.segmentContent}`}>
        <div className={styles.label}>{t('tripTypesPageTemplate.basketCategoryStats.segmentsTable.label')}</div>
        {segmentTableData.map((segment) => (
          <div key={segment.name} className={styles.row}>
            <div className={styles.statsProgBarWrap}>
              <div className={styles.catStatWrapper}>
                <div className={styles.name}>{segment.name}</div>
                <div className={styles.stats}>
                  <div className={styles.percentage}>{segment.percentage}%</div>
                  <div className={styles.index} style={{ backgroundColor: COLORS[segment.tagColor] }}>
                    {segment.index}
                  </div>
                </div>
              </div>
              <ProgressBar progress={calculateProgress(segment.percentage)} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

SegmentTable.propTypes = {
  tripTypeColor: PropTypes.string.isRequired,
  segmentTitle: PropTypes.string.isRequired,
  segmentTableData: PropTypes.array.isRequired,
  accentTextColor: PropTypes.string.isRequired
};

export default SegmentTable;
