/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useLocation, useParams } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import AnchorNavigationBar from '../../common/AnchorNavigationBar';
import { DemandSpaceAnchorNavItems } from '../../../constants/constants';
import Runner from '../../custom/Runner';
import { fetchDemandSpaceData, getInfoPopupStatus } from '../../../service/API';
import Loader from '../../common/Loader';
import ToolTipPopUp from '../../custom/Modal/ToolTipPopUp';
import { DemandspaceToolTipCardData } from '../../../constants/DemandSpace';
import SectionOne from './SectionOne';
import SectionTwo from './SectionTwo';
import SectionThree from './SectionThree';
import SectionFour from './SectionFour';
import SectionFive from './SectionFive';
import SectionSix from './SectionSix';
import SectionSeven from './SectionSeven';
import SectionEight from './SectionEight';
import SectionNine from './SectionNine';
import SectionZero from './SectionZero';

function DemandSpaceTemplate() {
  const { space } = useParams();
  const { accounts } = useMsal();
  const [demandSpaceResponse, setDemandSpaceResponse] = useState(null);
  const [popupStatus, setPopupStatus] = useState(true);
  const [previousPath, setPreviousPath] = useState('');
  const [visibleHover, setVisibleHover] = useState(null);
  const location = useLocation();
  if (location.pathname !== previousPath) {
    setPreviousPath(location.pathname);
    setDemandSpaceResponse(null);
  }

  useEffect(() => {
    if (localStorage.getItem('i18nnextLng')?.length > 2) {
      i18next.changeLanguage('en');
    }
  });

  useEffect(() => {
    if (!demandSpaceResponse) {
      fetchData();
    }
  });

  const anchorNavItems = DemandSpaceAnchorNavItems.map((item) => {
    return {
      key: item.key,
      text: item.text
    };
  });

  const fetchData = async () => {
    try {
      const response = await fetchDemandSpaceData(space);
      setDemandSpaceResponse(response);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  function navigateToSection(id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  }
  const fetchPopupData = async () => {
    try {
      const response = await getInfoPopupStatus(accounts[0].username);
      setPopupStatus(response.demandLandscape);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchPopupData();
  }, []);

  return (
    <div className="main-content">
      {!popupStatus && <ToolTipPopUp CardData={DemandspaceToolTipCardData} />}

      {demandSpaceResponse ? (
        <>
          <AnchorNavigationBar
            data={{
              category: 'demandspace',
              type: space,
              navItems: anchorNavItems
            }}
            visibleHover={visibleHover}
            clickAction={navigateToSection}
          />
          <div className="ds-section-0">
            <SectionZero macro={demandSpaceResponse} space={space} />
          </div>

          <div className="ds-section-1">
            <SectionOne macro={demandSpaceResponse?.consumptions?.drinks} space={space} />
          </div>
          <button
            onMouseOver={() => {
              setVisibleHover('section2');
            }}
            onFocus={() => {
              setVisibleHover('section2');
            }}
            className="ds-section-2"
            id="section2"
            tabIndex={0}
            style={{ background: 'none', border: 'none', width: '100%', padding: 0 }}
          >
            <SectionTwo macro={demandSpaceResponse} space={space} />
          </button>
          <button
            onMouseOver={() => setVisibleHover('section3')}
            onFocus={() => setVisibleHover('section3')}
            className="ds-section3"
            id="section3"
            tabIndex={0}
            style={{ background: 'none', border: 'none', width: '100%', padding: 0 }}
          >
            <SectionThree macro={demandSpaceResponse} space={space} />
          </button>

          <div className="section4-container-section4-contextual-factor" id="section4">
            <SectionFour space={space} sectionData={demandSpaceResponse?.contexualFactors} />
          </div>

          <div className="ds-section5" id="section5">
            <SectionFive space={space} macro={demandSpaceResponse?.drivers} />
          </div>
          <div className="section6-container" id="section6">
            <SectionSix space={space} sectionData={demandSpaceResponse?.consumptions} />
          </div>
          <div className="ds-section7" id="section7">
            <SectionSeven macro={demandSpaceResponse} selectedMicro={space} sectionData={{ sov: '10', sop: '7' }} />
          </div>
          <div className="section8-container-downloads" id="section8">
            <SectionEight space={space} />
          </div>
          <div className="section9-container">
            <SectionNine macro={space} selectedMicro={space} />
          </div>
          <div className="runner">
            <Runner forPage={'demandspaceTemplatePage'} forType={space} />
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default DemandSpaceTemplate;
