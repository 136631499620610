import React from 'react';
import { Outlet } from 'react-router';
import PropTypes from 'prop-types';
import { useAccessControl } from '../../constants/AccessControl';
import Footer from './Footer';
import CatTripHomeHeader from './CatTripHeaderNav';

function HueHubPageLayout({ navOptions }) {
  useAccessControl(navOptions.navDropdown.segment);

  return (
    <div className="container">
      <CatTripHomeHeader navOptions={navOptions} />
      <Outlet />
      <Footer />
    </div>
  );
}

HueHubPageLayout.propTypes = {
  navOptions: PropTypes.object.isRequired
};

export default HueHubPageLayout;
