import React, { useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { InstructionsCard } from '../instruction-card/InstructionsCard';
import styles from './AlcFrameworkHeader.module.css';

export const AlcFrameworkHeader = () => {
  const { t } = useTranslation(i18next.language);
  const scrollContainerRef = useRef(null);

  useLayoutEffect(() => {
    const centerScroll = () => {
      if (scrollContainerRef.current) {
        const scrollContainer = scrollContainerRef.current;
        const scrollWidth = scrollContainer.scrollWidth;
        const clientWidth = scrollContainer.clientWidth;
        const scrollTo = (scrollWidth - clientWidth) / 2;

        scrollContainer.scrollLeft = scrollTo;
      }
    };

    centerScroll();

    const timeoutId = setTimeout(centerScroll, 100);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <header className={styles.hero}>
      <img
        src="https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fbb6f6866485c496386462b841bef8a44"
        alt={t('categoryFramework.header.images.heroGradient.alt')}
        className={styles.heroImage}
      />
      <div className={styles.heroContent}>
        <h1 className={styles.heroTitle}>{t('categoryFramework.header.title')}</h1>
        <p className={styles.description}>
          {t('categoryFramework.header.description1')}
          <span className={styles.highlight}>{t('categoryFramework.header.highlight')}</span>
          {t('categoryFramework.header.description2')}
        </p>
        <img
          src="https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Feaa6200ff638440cb49b5fc639597810"
          alt={t('categoryFramework.header.images.flavorSpectrum.alt')}
          className={styles.flavorSpectrumImage}
        />
        <div ref={scrollContainerRef} className={styles.scrollContainer}>
          <div className={styles.categoryFrameworkWrapper}>
            <img
              src="https://res.cloudinary.com/johnnymohawk/image/upload/v1738168013/alc_framework_1_X_4_yow3lu.png"
              alt={t('categoryFramework.header.images.categoryFramework.alt')}
              className={styles.categoryFrameworkImage}
            />
          </div>
        </div>
        <div className={styles.instructionsCardWrapper}>
          <InstructionsCard />
        </div>
      </div>
    </header>
  );
};
