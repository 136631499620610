import React, { useEffect } from 'react';
import SectionOne from './SectionOne';
import SectionTwo from './SectionTwo';

function Occasions() {
  useEffect(() => {
    //window.scrollTo(0,0)
  });
  return (
    <div className="main-content">
      <SectionOne />
      <SectionTwo />
    </div>
  );
}

export default Occasions;
