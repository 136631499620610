import React from 'react';
import PropTypes from 'prop-types';
import styles from './PricingTable.module.css';

export const PricingTable = ({ pricingTableImage }) => {
  return (
    <div className={styles.pricingTable}>
      <img className={styles.pricingTableImage} src={pricingTableImage.imgUrl} alt={pricingTableImage.altText} />
    </div>
  );
};

PricingTable.propTypes = {
  pricingTableImage: PropTypes.shape({
    imgUrl: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired
  }).isRequired
};
