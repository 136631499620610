import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useParams } from 'react-router-dom';
import SectionIntro from '../../common/page-sections/SectionIntro';
import ContextualFactorsDataCard from '../../custom/Cards/ContextualFactorsDataCard';

import { occasionsAssets } from '../../common/OccasionsAssets';
import { downloadsAssets } from '../../common/DownloadsAssets';

function SectionTwo({ sectionData }) {
  const { t } = useTranslation(i18next.language);
  const { micro } = useParams();

  //const contextualFactorData = t('occasions.template.section2.contextualFactorData', { returnObjects: true})

  useEffect(() => {
    if (localStorage.getItem('i18nnextLng')?.length > 2) {
      i18next.changeLanguage('en');
    }
  }, []);

  function getVisualData(factor) {
    return {
      label: t(`occasions.template.contextualFactors.contextualFactorData.${factor}.label`),
      labeltext: t(`occasions.template.contextualFactors.contextualFactorData.${factor}.description`),
      icon: factor
    };
  }

  function getTableData(factor) {
    let initialRowCount = 0;
    //let isExpandable = false
    let paginationAlignment = 'center';
    let paginationText = '';
    let data = {};

    switch (factor) {
      case 'meals':
      case 'drinkTimes':
      case 'locations':
        initialRowCount = 2;
        //isExpandable = true
        break;
      default:
        initialRowCount = 5;
        //isExpandable = true
        break;
    }

    data.initialRowCount = initialRowCount;
    //data.isExpandable = isExpandable
    data.isExpandable = sectionData[factor].length > initialRowCount;
    data.paginationAlignment = paginationAlignment;
    data.paginationText = paginationText;
    data.heading = [];
    data.rowData = sectionData[factor];
    data.factor = factor;

    return data;
  }

  function downloadFile(url, fileName) {
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
  }

  function downloadSpreadsheets() {
    downloadFile(downloadsAssets.consumptionData.DataPackSpreadsheet, 'dataPack.xlsx');
  }

  return (
    <div className="section2">
      <SectionIntro
        heading={t('occasions.template.contextualFactors.heading')}
        description={t('occasions.template.contextualFactors.description')}
        sectionImages={{
          front: occasionsAssets.contextualFactors[micro]['front']?.(),
          back: occasionsAssets.contextualFactors[micro]['back']?.()
        }}
        actionButton={
          <Button
            className="download-all-button outlined-button"
            component="label"
            variant="contained"
            startIcon={<FileDownloadIcon />}
            onClick={downloadSpreadsheets}
          >
            {t('buttons.downloadSpreadsheets')}
          </Button>
        }
      />
      <Grid container className="section2-image-list" gap={2.5}>
        {Object.keys(sectionData).map((factor) => (
          <Grid item key={factor} xs={3.85}>
            <ContextualFactorsDataCard visualData={getVisualData(factor)} tableData={getTableData(factor)} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

SectionTwo.propTypes = {
  sectionData: PropTypes.object.isRequired
};

export default SectionTwo;
