export const QUOTE_ICONS = {
  NON_ALC_YELLOW:
    'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F8b84726a9f3343df83a70f8d518220f7',
  MALT_ICE_YELLOW:
    'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F345db1dfee0342e4a3fbdb05a3e8563e',
  AM_CLASSIC_ORANGE:
    'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F426e16608f0e4253a3d2f2977046608a',
  AM_LIGHT_ORANGE:
    'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F3edf79c3385b49dc8b75ba7497bcb4d2',
  AM_BALANCE_ORANGE:
    'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F51fabea46d2b49d58bbd0e45ef8d7e40',
  IMPORTS_ORANGE:
    'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Ff1251ac9c95d4a41b70ffc1433ca6b36',
  BLACK:
    'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fc69857dbb4f9426da700ac11c0a6df78',
  WHITE:
    'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F929dd519fe58493491d84fee55e3dd10'
};
