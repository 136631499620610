import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Typography,
  Box,
  Button,
  Popover
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
// import { downloadAllFiles, downloadFile } from "../../../service/API";

function DownloadResources(props) {
  const { t } = useTranslation(i18next.language);

  useEffect(() => {
    if (localStorage.getItem('i18nnextLng')?.length > 2) {
      i18next.changeLanguage('en');
    }
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filesToDownload, setFilesToDownload] = useState(null);

  const handleClick = (event, files) => {
    setFilesToDownload(files);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'download-options-popover' : undefined;

  /*function download(e,filename) {
    const target = e.currentTarget
    downloadFile(filename)
  }*/

  function downloadAsFile(e, fileLink) {
    const a = document.createElement('a');
    a.href = fileLink;
    a.download = 'download';
    a.target = '_blank';
    a.click();
    a.remove();
  }

  function downloadAllFiles() {
    if (props.listItems && props.listItems.length > 0) {
      const allFileLinks = [];
      props.listItems.forEach((item) => {
        // Check if it's a single file or multiple files
        if (item.multipleFiles) {
          Object.values(item.fileToDownload).forEach((fileLink) => {
            const fileLink1 = fileLink(); // Call the function to get the actual URL
            allFileLinks.push(fileLink1);
          });
        } else {
          const fileLink2 = item.fileToDownload();
          allFileLinks.push(fileLink2);
        }
      });

      // Download all files in the array with a delay
      let delay = 0;
      allFileLinks.forEach((fileLink) => {
        setTimeout(() => {
          downloadAsFile(null, fileLink);
        }, delay);
        delay += 1000; // Add 1 second delay between downloads
      });
    }
  }

  return (
    <Grid container className="download-resource-container">
      <Grid item xs={6}>
        <Box className="download-resource-left-items-container">
          <Typography className="download-resource-heading">{t('common.downloadSectionHeading')}</Typography>
          <Button
            className="download-all-button outlined-button"
            // component="label"
            variant="contained"
            startIcon={<FileDownloadIcon />}
            onClick={downloadAllFiles}
          >
            {t('buttons.downloadAll')}
          </Button>
        </Box>
      </Grid>
      <Grid item xs={6} alignItems="center" justifyContent="flex-start">
        <Box alignItems="center" justifyContent="flex-start">
          <List className="download-resource-list">
            {props.listItems.map((item) => (
              <ListItem key={item.title} className="list-item" disableGutters={true} disablePadding={true}>
                <ListItemButton disableGutters={true}>
                  <ListItemText
                    className="text"
                    primary={
                      <Typography variant="h5" className="primary">
                        {item.title}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body1" className="secondary">
                        {item.subtitle}
                      </Typography>
                    }
                  />
                  <ListItemIcon className="icon">
                    {item.multipleFiles ? (
                      <FileDownloadIcon
                        aria-label="Download"
                        aria-describedby={id}
                        onClick={(e) => handleClick(e, item.fileToDownload)}
                      />
                    ) : (
                      <FileDownloadIcon
                        aria-label="Download"
                        onClick={(e) => downloadAsFile(e, item.fileToDownload?.())}
                      ></FileDownloadIcon>
                    )}
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                      }}
                    >
                      <Box className="popover-items-container">
                        <a
                          href={filesToDownload ? filesToDownload.pdf?.() : '#'}
                          target="_blank"
                          rel="noreferrer"
                          download
                          // onClick={() => handleClose}
                        >
                          <Button variant="text" className="popover-clickable-button" onClick={() => handleClose}>
                            {t('common.downloads.downloadPDF')}
                          </Button>
                        </a>
                        <a
                          href={filesToDownload ? filesToDownload.ppt?.() : '#'}
                          target="_blank"
                          rel="noreferrer"
                          download
                          // onClick={() => handleClose}
                        >
                          <Button variant="text" className="popover-clickable-button" onClick={() => handleClose}>
                            {t('common.downloads.downloadPPT')}
                          </Button>
                        </a>
                      </Box>
                    </Popover>
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Grid>
    </Grid>
  );
}

DownloadResources.propTypes = {
  listItems: PropTypes.array.isRequired
};

export default DownloadResources;
