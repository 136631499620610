import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import { hueHubCopy, features, buttons } from '../../constants/HueHubHomeConstants';
import HomeModal from '../home/HomeModal';
import { HomeTooltipCardData } from '../../constants/constants';
import { CopyCarousel } from './components/copy-carousel/CopyCarousel';
import { HueHubVisualization } from './components/hue-hub-visualization/HueHubVisualization';
import { FeatureCard } from './components/feature-card/FeatureCard';
import { Button } from './components/navigation-button/NavigationButton';
import styles from './HueHomePage.module.css';

export default function HueHomePage() {
  const navigate = useNavigate();

  const [selectedSection, setSelectedSection] = useState('aboutHue');
  const [modalShown, setModalShown] = useState(false);

  const termsAccepted = window.sessionStorage.getItem('tncAccepted') === 'true';
  const modalShownThisSession = window.sessionStorage.getItem('modalShown') === 'true';

  useEffect(() => {
    if (localStorage.getItem('i18nnextLng')?.length > 2) {
      i18next.changeLanguage('en');
    }

    const fetchPopupData = async () => {
      try {
        if (!termsAccepted) {
          navigate('/terms', { replace: true });
          window.scrollTo(0, 0);
        } else if (!modalShownThisSession) {
          setModalShown(true);
          window.sessionStorage.setItem('modalShown', 'true');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchPopupData();
  }, [navigate, termsAccepted, modalShownThisSession]);

  const handleSectionClick = useCallback((section) => {
    setSelectedSection(section);
  }, []);

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        {modalShown && <HomeModal CardData={HomeTooltipCardData} />}
        <main className={styles.mainContent}>
          <CopyCarousel
            eyebrowText={hueHubCopy[selectedSection].eyebrowText}
            titleFirstLine={hueHubCopy[selectedSection].titleFirstLine}
            titleSecondLine={hueHubCopy[selectedSection].titleSecondLine}
            descriptionFirstParagraph={hueHubCopy[selectedSection].descriptionFirstParagraph}
            descriptionSecondParagraph={hueHubCopy[selectedSection].descriptionSecondParagraph}
            selectedSection={selectedSection}
            clickHandler={handleSectionClick}
          />
          <div className={styles.imageContainer}>
            <HueHubVisualization clickHandler={handleSectionClick} />
          </div>
        </main>
        <section className={styles.featureSection}>
          <ul className={styles.featureGrid}>
            {features.map((feature) => (
              <li key={feature.title}>
                <FeatureCard {...feature} />
              </li>
            ))}
          </ul>

          <hr className={styles.divider} />

          <ul className={styles.buttonGroup}>
            {buttons.map((buttonData) => (
              <li key={buttonData.buttonCopy}>
                <Button
                  onClick={() => {
                    navigate(buttonData.navigateTo);
                    window.scrollTo(0, 0);
                  }}
                >
                  {buttonData.buttonCopy}
                </Button>
              </li>
            ))}
          </ul>
        </section>
      </div>
    </div>
  );
}
