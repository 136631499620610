import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';
import Cards from '../../custom/Cards/Cards';
import {
  EXPLORATORY_OUTGOING_CARDS,
  EXPLORATORY_RESERVED_CARDS,
  FAMILIAR_OUTGOING_CARDS,
  FAMILIAR_RESERVED_CARDS
} from '../../../constants/PeopleSegment';

const CardTitle = ({ title }) => {
  return (
    <Stack className="stack-container">
      <Typography className="ps-section2-card-title">{title}</Typography>
      <Divider className="card-title-divider" />
    </Stack>
  );
};

CardTitle.propTypes = {
  title: PropTypes.string.isRequired
};

const CardContent = ({ cardArray }) => {
  const { t } = useTranslation(i18next.language);
  const navigate = useNavigate();
  return (
    <Grid container justifyContent="center" spacing={2.5}>
      {cardArray.map((i) => (
        <Grid
          item
          key={t(i.text)}
          xs={12 / cardArray.length}
          onClick={() => {
            navigate(`${i.macro}/${i.micro}`);
            window.scrollTo(0, 0);
          }}
        >
          <Box className="card-content-hover">
            <Cards img={i.img()} caption={t(i.text)} color={i.color} data={i} />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

CardContent.propTypes = {
  cardArray: PropTypes.array.isRequired
};

//People-Segment-Cards Section
export default function SectionTwo() {
  const { t } = useTranslation(i18next.language);

  return (
    <div className="ps-hub-section2-background">
      <Box className="section2-visual-container">
        <Grid className="section2-visual-container-grid" container gap={5}>
          <Grid className="grid-item" item xs={5.77}>
            <CardTitle title={t('people-segment.macroPeopleSegment.Fram_PSGMT_1')} />
            <CardContent cardArray={FAMILIAR_RESERVED_CARDS} />
          </Grid>
          <Grid className="grid-item" item xs={5.77}>
            <CardTitle title={t('people-segment.macroPeopleSegment.Fram_PSGMT_7')} />
            <CardContent cardArray={FAMILIAR_OUTGOING_CARDS} />
          </Grid>
        </Grid>
        <Grid
          className="section2-visual-container-grid"
          container
          gap={5}
          // spacing={5}
        >
          <Grid className="grid-item" item xs={5.77}>
            <CardTitle title={t('people-segment.macroPeopleSegment.Fram_PSGMT_10')} />
            <CardContent cardArray={EXPLORATORY_OUTGOING_CARDS} />
          </Grid>
          <Grid className="grid-item" item xs={5.77}>
            <CardTitle title={t('people-segment.macroPeopleSegment.Fram_PSGMT_4')} />
            <CardContent cardArray={EXPLORATORY_RESERVED_CARDS} />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
