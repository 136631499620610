import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../../../constants/CatTripColors';
import { getAccentTextColor } from '../../../../constants/GetAccentTextColor';
import { ChartCard } from './compound-components/ChartCard';
import { ThemeCard } from './compound-components/ThemeCard';
import styles from './ShoppingAnalytics.module.css';

export const ShoppingAnalytics = ({
  cardAlignment,
  underlineWord,
  title,
  chartTitle,
  legendData,
  chartImgUrl,
  themeTitle,
  themesTexts,
  highlightThemesTexts,
  tripTypeColor
}) => {
  const accentTextColor = getAccentTextColor(tripTypeColor);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        <span className={styles.underline}>{underlineWord}</span> {title}
      </h1>
      <div className={styles.content}>
        {cardAlignment === 'GRAPH_LEFT' && (
          <>
            <ChartCard chartTitle={chartTitle} legendData={legendData} chartImgUrl={chartImgUrl} />
            <ThemeCard
              tripTypeColor={COLORS[tripTypeColor]}
              accentTextColor={accentTextColor}
              themeTitle={themeTitle}
              themes={themesTexts}
              highlightTexts={highlightThemesTexts}
            />
          </>
        )}
        {cardAlignment === 'GRAPH_RIGHT' && (
          <>
            <ThemeCard
              tripTypeColor={COLORS[tripTypeColor]}
              accentTextColor={accentTextColor}
              themeTitle={themeTitle}
              themes={themesTexts}
              highlightTexts={highlightThemesTexts}
            />
            <ChartCard chartTitle={chartTitle} legendData={legendData} chartImgUrl={chartImgUrl} />
          </>
        )}
      </div>
    </div>
  );
};

ShoppingAnalytics.propTypes = {
  underlineWord: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  chartTitle: PropTypes.string.isRequired,
  legendData: PropTypes.array.isRequired,
  chartImgUrl: PropTypes.string.isRequired,
  themeTitle: PropTypes.string.isRequired,
  themesTexts: PropTypes.array.isRequired,
  tripTypeColor: PropTypes.string.isRequired,
  highlightThemesTexts: PropTypes.arrayOf(PropTypes.object).isRequired,
  cardAlignment: PropTypes.string.isRequired
};
