import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography } from '@mui/material';

function UGCCard(props) {
  return (
    <Card className="ugc-card-container">
      <CardContent className="ugc-card-content">
        {props.children}
        <Typography variant="h5" component="div" className="ugc-card-label">
          {props.label}
        </Typography>
        <Typography variant="h5" component="div" className="ugc-card-labeltext">
          {props.labeltext}
        </Typography>
      </CardContent>
    </Card>
  );
}

UGCCard.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  labeltext: PropTypes.string.isRequired
};

export default UGCCard;
