import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import styles from './AvgTripDuration.module.css';

export const AvgTripDuration = ({ duration, description, tripTypeColor, accentTextColor }) => {
  const { t } = useTranslation(i18next.language);

  return (
    <div className={styles.statsCard} style={{ backgroundColor: tripTypeColor, color: accentTextColor }}>
      <div className={styles.titleWrapper}>
        {accentTextColor === '#fff' ? (
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F8a49d9ba675b4ee993128c14f48663cf"
            className={styles.icon}
            alt={t('tripTypesPageTemplate.tripDuration.tripDurationIconAlt')}
          />
        ) : (
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F20f1cd6e75804dc580d9d0c777b832b2"
            className={styles.icon}
            alt={t('tripTypesPageTemplate.tripDuration.tripDurationIconAlt')}
          />
        )}
        <div className={styles.title}>{t('tripTypesPageTemplate.tripDuration.card1Title')}</div>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.statValue}>
          {duration} {t('tripTypesPageTemplate.tripDuration.timeValue')}
        </div>
        <div className={styles.description}>{description}</div>
      </div>
    </div>
  );
};

AvgTripDuration.propTypes = {
  duration: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  tripTypeColor: PropTypes.string.isRequired,
  accentTextColor: PropTypes.string.isRequired
};
