import { Typography, Grid, Box, Divider } from '@mui/material';
import {
  // Handshake,
  // Extension,
  WorkspacePremium,
  AppShortcut
  // LocalShipping,
  // SportsBar,
  // LocalMall,
} from '@mui/icons-material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import i18next from 'i18next';
import FilledOutlinedIcon from '../custom/Icons/FilledOutlinedIcon';
import { ReactComponent as Portfolio } from '../../../src/assets/images/Common/Portfolio.svg';
import { ReactComponent as Innovation } from '../../../src/assets/images/Common/Innovation.svg';
import { ReactComponent as Trade } from '../../../src/assets/images/Common/Trade.svg';
import { ReactComponent as Growth } from '../../../src/assets/images/Common/Growth.svg';
import { ReactComponent as Handshake } from '../../../src/assets/images/Common/Handshake.svg';

function SectionTwo() {
  const { t } = useTranslation(i18next.language);

  return (
    <div className="home-section2-background">
      <div className="section2-hero">
        <Typography className="section2-paragraph" variant="h3">
          <Trans components={{ colored: <span /> }}>{t('home.section2.paragraph')}</Trans>
        </Typography>
        <Box className="section2-heading-with-icon-container">
          <Grid item container xs={12} gap="0.25rem" className="section2-visual">
            <Grid item xs={5.755} className="visual-item">
              <Box className="visual-item-container">
                <Box className="divider-box">
                  {/* <Divider className="divider"> */}
                  <Typography component="div" variant="h5" className="divider-text">
                    {t('home.section2.visual.item1Heading')}
                  </Typography>
                  {/* </Divider> */}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3.555} className="visual-item">
              <Box className="visual-item-container">
                <Box className="divider-box">
                  <Divider className="divider">
                    <Typography component="div" variant="h5" className="divider-text">
                      {t('home.section2.visual.item2Heading')}
                    </Typography>
                  </Divider>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2.55} className="visual-item">
              <Box className="visual-item-container">
                <Box className="divider-box">
                  <Divider className="divider">
                    <Typography component="div" variant="h5" className="divider-text">
                      {t('home.section2.visual.item3Heading')}
                    </Typography>
                  </Divider>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid item container xs={12} gap="0.25rem" className="visual-item-content-container">
            <Grid item xs={1.6} className="visual-item-inner">
              <FilledOutlinedIcon outlined>
                {/* <SportsBar className="icon" /> */}
                <Growth />
              </FilledOutlinedIcon>
              <Typography component="div" variant="h6" className="text">
                {t('home.section2.visual.item3Visual1Text')}
              </Typography>
            </Grid>
            <Grid item xs={1.65} className="visual-item-inner">
              <FilledOutlinedIcon outlined>
                {/* <Handshake className="icon" /> */}
                <Portfolio />
              </FilledOutlinedIcon>
              <Typography component="div" variant="h6" className="text factor1">
                {t('home.section2.visual.item1Visual1Text')}
              </Typography>
            </Grid>
            <Grid item xs={1.65} className="visual-item-inner">
              <FilledOutlinedIcon outlined>
                {/* <Extension className="icon" /> */}
                <Innovation />
              </FilledOutlinedIcon>
              <Typography component="div" variant="h6" className="text">
                {t('home.section2.visual.item1Visual2Text')}
              </Typography>
            </Grid>
            <Grid item xs={1.65} className="visual-item-inner">
              <FilledOutlinedIcon outlined>
                <WorkspacePremium className="icon" />
              </FilledOutlinedIcon>
              <Typography component="div" variant="h6" className="text">
                {t('home.section2.visual.item2Visual1Text')}
              </Typography>
            </Grid>
            <Grid item xs={1.8} className="visual-item-inner">
              <FilledOutlinedIcon outlined>
                <AppShortcut className="icon" />
              </FilledOutlinedIcon>
              <Typography component="div" variant="h6" className="text">
                {t('home.section2.visual.item2Visual2Text')}
              </Typography>
            </Grid>
            <Grid item xs={1.8} className="visual-item-inner">
              <FilledOutlinedIcon outlined>
                {/* <LocalShipping className="icon" /> */}
                <Trade />
              </FilledOutlinedIcon>
              <Typography component="div" variant="h6" className="text">
                {t('home.section2.visual.item2Visual3Text')}
              </Typography>
            </Grid>
            <Grid item xs={1.5} className="visual-item-inner" id="last-icon">
              <FilledOutlinedIcon outlined>
                {/* <LocalMall className="icon" /> */}
                <Handshake />
              </FilledOutlinedIcon>
              <Typography component="div" variant="h6" className="text" id="last-icon-text">
                {t('home.section2.visual.item3Visual2Text')}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}

export default SectionTwo;
