import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const BarChartComponent = ({ data = [], title, color = '' }) => {
  function getIndexColor(indexValue) {
    if (indexValue < 85) {
      return 'negative';
    } else if (indexValue > 115) {
      return 'positive';
    } else {
      return 'neutral';
    }
  }

  return (
    <Box className="horizontal-main">
      <Typography className="horizontal-heading">{title}</Typography>
      <Typography className="horizontal-line" />
      <Box className="bar-chart-main">
        <Box className="bar-chart-submain">
          {data?.map((item, index) => (
            <Box key={`bar-chart-box${index}`} className="bar-chart-box">
              <Typography className="bar-chart-agegroup">{item.ageGroup}</Typography>
              <Box
                sx={{
                  width: '13px',
                  bgcolor: color,
                  height: `${item.ageGroupPercent}%`,
                  borderRadius: '10px'
                }}
              ></Box>
              <Box className={`bar-chart-index-box ${getIndexColor(item.ageGroupIndex)}`}>
                <Typography className="bar-chart-index">{Math.round(item.ageGroupIndex)}</Typography>
              </Box>
              <Typography className="bar-chart-percent">{Math.round(item.ageGroupPercent)}%</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

BarChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export default BarChartComponent;
