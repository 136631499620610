import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import DemandSpaces from '../../common/page-sections/DemandSpaces';

export default function SectionSix({ sectionData, space }) {
  const { macroSegment, microSegment } = useParams();
  const [demandSpaceData, setDemandspaceData] = useState({});

  useEffect(() => {
    let data = {};
    for (let i = 0; i < sectionData.length; i++) {
      data[sectionData[i].microCode] = parseFloat(sectionData[i].volume).toFixed(1);
    }
    setDemandspaceData(data);
  }, [sectionData]);

  return (
    <div className="section6">
      <DemandSpaces
        page="people-segment"
        macro={macroSegment}
        micro={microSegment}
        data={demandSpaceData}
        space={space}
      />
    </div>
  );
}

SectionSix.propTypes = {
  sectionData: PropTypes.array.isRequired,
  space: PropTypes.string.isRequired
};
