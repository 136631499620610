import { Box, Grid } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import ImageCard from './ImageCard';
import SmallQuoteAttributionCard from './SmallQuoteAttributionCard';
import StatementCard from './StatementCard';

export default function StatementQuoteImageCard({ theme, dataprops }) {
  return (
    <Box className="statementquoteimage-card-container">
      <Grid container className="card-content-container">
        <Grid item xs={6}>
          <StatementCard theme={theme} cardData={dataprops.bigStatement} />
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ height: '50%' }}>
            <ImageCard theme={theme} cardData={dataprops.imagecarddata} />
          </Box>
          <Box sx={{ height: '50%' }}>
            <SmallQuoteAttributionCard theme={theme} cardData={dataprops.quoteattributioncarddata} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

StatementQuoteImageCard.propTypes = {
  theme: PropTypes.string.isRequired,
  dataprops: PropTypes.object.isRequired
};
