import { Box } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

function ImageCard({ cardData }) {
  return (
    <Box className="image-card-container">
      <img key={cardData.image} className="image-card-visual" alt="visual" src={cardData.image?.()} />
    </Box>
  );
}

ImageCard.propTypes = {
  cardData: PropTypes.object.isRequired
};

export default ImageCard;
