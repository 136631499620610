import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { tripTypes } from '../../../../constants/TripTypesExplorerData';
import { TripTypeCard } from './compound-components/TripTypeCard';
import styles from './ExploreTripTypes.module.css';

export const ExploreTripTypes = () => {
  const { t } = useTranslation(i18next.language);

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.divider} />
          <div className={styles.title}>{t('tripTypesPageTemplate.exploreTripTypes.title')}</div>
          <div className={styles.cardGrid}>
            {tripTypes.map((trip) => (
              <TripTypeCard key={trip.text} {...trip} navigateTo={trip.navigateTo} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
