export const hueHubCopy = {
  aboutHue: {
    eyebrowText: 'understanding the framework',
    titleFirstLine: 'what is',
    titleSecondLine: 'hue hub?',
    descriptionFirstParagraph:
      'HUE stands for Human Understanding Ecosystem and explores the connected insights that frame the alcohol category.',
    descriptionSecondParagraph:
      'Click a segment in the HUE Hub graphic to learn more about how How Consumers Use, How People Organize, and How Shoppers Choose.'
  },
  demandLandscape: {
    eyebrowText: 'how consumers use',
    titleFirstLine: 'demand',
    titleSecondLine: 'landscape',
    descriptionFirstParagraph:
      'The Demand Landscape serves as a single, people-first source of truth to capture total alcohol demand in the US.'
  },
  categoryStructure: {
    eyebrowText: 'how people organize',
    titleFirstLine: 'category',
    titleSecondLine: 'structure',
    descriptionFirstParagraph:
      "A category-centric map that defines what products are in and what's outside of total alcohol, how those products are organized into categories and segments, and the drivers of this classification."
  },
  tripTypes: {
    eyebrowText: 'how shoppers choose',
    titleFirstLine: 'alcohol',
    titleSecondLine: 'trip types',
    descriptionFirstParagraph:
      "A shopper-centric map of trips across the alcohol category that creates the frame to understand shoppers' needs and what drives store and product choice across channels"
  }
};

export const features = [
  {
    icon: 'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fbe61590898e848ce8422384160d72639',
    title: 'One set of connected Alcohol frameworks',
    description: 'That follow consumers to shoppers, along the path to purchase',
    alt: 'Connected frameworks icon'
  },
  {
    icon: 'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F9b95a1f5e5f74bcb9d131420aa213e81',
    title: 'Common language and shared understanding',
    description: 'Segments in categories, people & occasions, and shoppers across channels',
    alt: 'Common language icon'
  },
  {
    icon: 'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fde660494e2dc45809b90868d92c73131',
    title: 'Linked data sources and immersive learning',
    description: 'Used for deep human diagnostics that update over time',
    alt: 'Linked data icon'
  },
  {
    icon: 'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F656d3448edc346f290df3562c71b9543',
    title: 'Multiple Commercial Use Cases',
    description: 'From strategy to activation and execution',
    alt: 'Commercial use cases icon'
  }
];

const homePageLinks = {
  tripTypes: '/trip-types',
  categoryStructure: '/categories',
  demandLandscape: '/demand-landscape'
};

export const buttons = [
  {
    buttonCopy: 'Explore Trip Types',
    navigateTo: homePageLinks.tripTypes
  },
  {
    buttonCopy: 'Explore Category Structure',
    navigateTo: homePageLinks.categoryStructure
  },
  {
    buttonCopy: 'Explore Demand Landcape',
    navigateTo: homePageLinks.demandLandscape
  }
];

export const hueSections = ['aboutHue', 'demandLandscape', 'categoryStructure', 'tripTypes'];

export const sectionNavigationMap = {
  demandLandscape: {
    fullName: 'Demand Landscape',
    navigateTo: homePageLinks.demandLandscape
  },
  categoryStructure: {
    fullName: 'Category Structure',
    navigateTo: homePageLinks.categoryStructure
  },
  tripTypes: {
    fullName: 'Alcohol Trip Types',
    navigateTo: homePageLinks.tripTypes
  }
};
