import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { COLORS } from '../../../../../constants/CatTripColors';
import styles from './TripTypeCard.module.css';

export const TripTypeCard = ({ bgColor, iconSrc, iconWidth, iconAspectRatio, text, navigateTo }) => {
  const navigate = useNavigate();

  const clickHandler = useCallback(
    (navigateTo) => {
      navigate(navigateTo);
      window.scrollTo(0, 0);
    },
    [navigate]
  );

  const backgroundColor = COLORS[bgColor];

  return (
    <button className={styles.buttonWrap} onClick={() => clickHandler(navigateTo)}>
      <div className={styles.card} style={{ backgroundColor }}>
        <div className={styles.cardContent}>
          <img
            loading="lazy"
            src={iconSrc}
            className={styles.cardIcon}
            style={{ width: iconWidth, aspectRatio: iconAspectRatio }}
            alt={text}
          />
          <div className={styles.cardText}>{text}</div>
        </div>
      </div>
    </button>
  );
};

TripTypeCard.propTypes = {
  bgColor: PropTypes.string.isRequired,
  iconSrc: PropTypes.string.isRequired,
  iconWidth: PropTypes.string.isRequired,
  iconAspectRatio: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  navigateTo: PropTypes.string.isRequired
};
