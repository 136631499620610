import React from 'react';
import PropTypes from 'prop-types';
import { ColorGradient } from '../../../cat-trip-reuse-components/color-gradient/ColorGradient';
import { SectionHeader } from '../common-components/SectionHeader';
import { COLORS } from '../../../../constants/CatTripColors';
import { SegmentGrowthSection } from './compound-components/SegmentGrowthSection';
import styles from './SizingSection.module.css';

export const SizingSection = ({
  sectionName,
  sectionTitle,
  chartTitle,
  subcatAccentColor,
  onAccentTextColor,
  sectionNameColor,
  brandShareDataColor,
  chartImage,
  growthMetrics,
  brandShareData,
  headerAlignment
}) => {
  return (
    <div className={styles.sizingSection} data-testid="sizing-section">
      <ColorGradient />
      <SectionHeader
        sectionName={sectionName}
        sectionTitle={sectionTitle}
        alignment={headerAlignment}
        accentColor={COLORS[subcatAccentColor]}
        sectionNameColor={COLORS[sectionNameColor]}
      />
      <SegmentGrowthSection
        chartTitle={chartTitle}
        chartImage={chartImage}
        growthMetrics={growthMetrics}
        brandShareData={brandShareData}
        subcatAccentColor={COLORS[subcatAccentColor]}
        onAccentTextColor={COLORS[onAccentTextColor]}
        brandShareDataColor={COLORS[brandShareDataColor]}
      />
    </div>
  );
};

SizingSection.propTypes = {
  sectionName: PropTypes.string.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  chartTitle: PropTypes.string.isRequired,
  subcatAccentColor: PropTypes.string.isRequired,
  onAccentTextColor: PropTypes.string.isRequired,
  chartImage: PropTypes.shape({
    imgUrl: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired
  }).isRequired,
  growthMetrics: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  brandShareData: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      shareInfo: PropTypes.string.isRequired
    })
  ).isRequired,
  headerAlignment: PropTypes.string.isRequired,
  sectionNameColor: PropTypes.string.isRequired,
  brandShareDataColor: PropTypes.string.isRequired
};
