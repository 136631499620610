import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

export default function LoginLayout() {
  return (
    <div className="container">
      <Header />
      {/*<LayoutContext.Provider value={{}}>
            {props.children}
        {/*</LayoutContext.Provider>*/}
      <Outlet />
      <Footer />
    </div>
  );
}
