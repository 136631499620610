/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import styled from 'styled-components';
import '../../styles/SubNav.scss';
import PropTypes from 'prop-types';

const Header = styled.header`
  background-color: ${(props) => props['data-backgroundcolor'] || '#cfbc09'};
`;

const ContentSpacer = styled.div`
  height: 40px;
`;

const SubNavigation = styled.nav`
  background-color: ${(props) => props['data-backgroundcolor'] || '#cfbc09'};
`;

const NavLink = styled.a`
  font-family: 'Inter-Bold', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
  color: ${(props) => props['data-textcolor'] || '#000'};
  padding: 0 12px;
  display: inline-flex;
  align-items: center;
  height: 40px;

  &:hover {
    background-color: ${(props) => props['data-hovercolor']};
  }

  &.active {
    background-color: ${(props) => props['data-activecolor']};
  }
`;

const NavLabel = styled.span`
  a {
    color: rgba(0, 0, 0, 0.5);
    font-family: Inter-Bold;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  span {
    color: ${(props) => props['data-textcolor'] || '#000'};
    font-family: Inter-Bold;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
`;

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

function CatTripSubNav({ subCategory, mainNavLinks, subNavLinks, anchorBarColor, anchorBarColorSecondary, textColor }) {
  const [activeSection, setActiveSection] = useState('');
  const location = useLocation();
  const observerRef = useRef(null);
  const mutationObserverRef = useRef(null);
  const isScrollingRef = useRef(false);

  const handleIntersection = useCallback((entries, debouncedSetActive, currentActiveSection) => {
    if (!isScrollingRef.current) {
      const visibleEntries = entries.filter((entry) => entry.isIntersecting && entry.intersectionRatio > 0.15);

      if (visibleEntries.length > 0) {
        const mostVisible = visibleEntries.reduce((prev, current) => {
          const prevRatio = prev.intersectionRatio;
          const currentRatio = current.intersectionRatio;
          return currentRatio > prevRatio ? current : prev;
        }, visibleEntries[0]);

        if (mostVisible.target.id !== currentActiveSection) {
          debouncedSetActive(mostVisible.target.id);
        }
      }
    }
  }, []);

  useEffect(() => {
    setActiveSection('');
    isScrollingRef.current = false;

    if (observerRef.current) {
      observerRef.current.disconnect();
      observerRef.current = null;
    }

    if (mutationObserverRef.current) {
      mutationObserverRef.current.disconnect();
      mutationObserverRef.current = null;
    }

    const debouncedSetActive = debounce((newSection) => {
      setActiveSection(newSection);
    }, 75);

    const setupIntersectionObserver = () => {
      const sections = document.querySelectorAll('.builder-block[id]');

      if (sections.length > 0) {
        if (observerRef.current) {
          observerRef.current.disconnect();
        }

        observerRef.current = new IntersectionObserver(
          (entries) => handleIntersection(entries, debouncedSetActive, activeSection),
          {
            root: null,
            threshold: Array.from({ length: 20 }, (_, i) => i * 0.05),
            rootMargin: '-10% 0px -10% 0px'
          }
        );

        sections.forEach((section) => {
          observerRef.current.observe(section);
        });
      }
    };

    const handleNavClick = (id) => {
      const element = document.getElementById(id);
      if (element) {
        isScrollingRef.current = true;
        setActiveSection(id);
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });

        setTimeout(() => {
          isScrollingRef.current = false;
        }, 1000);
      }
    };

    const navLinks = document.querySelectorAll('.subNav a');
    navLinks.forEach((link) => {
      const href = link?.getAttribute('href');
      if (href?.startsWith('#')) {
        const id = href.substring(1);
        link.addEventListener('click', (e) => {
          e.preventDefault();
          handleNavClick(id);
        });
      }
    });

    mutationObserverRef.current = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length > 0) {
          setupIntersectionObserver();
        }
      });
    });

    mutationObserverRef.current.observe(document.body, {
      childList: true,
      subtree: true
    });

    setupIntersectionObserver();

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      if (mutationObserverRef.current) {
        mutationObserverRef.current.disconnect();
      }
    };
  }, [location.pathname, handleIntersection]);

  return (
    <>
      <Header className="anchorBar" data-backgroundcolor={anchorBarColor}>
        <nav className="mainNavAtomAnchorBarLink">
          <NavLabel data-textcolor={textColor}>
            <Link to={mainNavLinks.mainCategory.to} className="navLinkLabel">
              {mainNavLinks.mainCategory.label}
            </Link>
          </NavLabel>
          <ChevronRightIcon className="navIcon" />
          <NavLabel data-textcolor={textColor}>
            <span className="navLinkLabel">{mainNavLinks.subCategory[subCategory].label}</span>
          </NavLabel>
        </nav>
        <SubNavigation className="subNav" data-backgroundcolor={anchorBarColorSecondary}>
          {subNavLinks.map(({ href, text }) => (
            <NavLink
              href={href}
              key={text}
              className={activeSection === href.substring(1) ? 'active' : ''}
              data-hovercolor={anchorBarColor}
              data-activecolor={anchorBarColor}
              data-textcolor={textColor}
            >
              {text}
            </NavLink>
          ))}
        </SubNavigation>
      </Header>
      <ContentSpacer />
    </>
  );
}

CatTripSubNav.propTypes = {
  subCategory: PropTypes.string.isRequired,
  mainNavLinks: PropTypes.shape({
    mainCategory: PropTypes.shape({
      to: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    }).isRequired,
    subCategory: PropTypes.object.isRequired
  }).isRequired,
  subNavLinks: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ).isRequired,
  anchorBarColor: PropTypes.string,
  anchorBarColorSecondary: PropTypes.string,
  textColor: PropTypes.string
};

export default CatTripSubNav;
