export const b2cConfig = {
  auth: {
    clientId: window.env.REACT_APP_B2C_AZURE_CLIENT_ID,
    authority: window.env.REACT_APP_B2C_AUTHORITY,
    redirectUri: window.env.REACT_APP_B2C_REDIRECT_URL,
    knownAuthorities: [window.env.REACT_APP_B2C_KNOWNAUTHORITIES]
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" to save cache in cookies to address trusted zones limitations in IE (see: https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issues-on-IE-and-Edge-Browser)
  }
};

export const b2cLoginRequest = {
  scopes: ['openid', 'profile', 'offline_access', window.env.REACT_APP_B2C_SCOPE] // B2C scopes
};

// offline_access
