import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { COLORS } from '../../../../../constants/CatTripColors';
import { calculateProgress } from '../../../../../constants/CalculateProgress';
import ProgressBar from './ProgressBar';
import styles from './PriceTierTable.module.css';

const PriceTierTable = ({ tripTypeColor, priceTierTableData, accentTextColor }) => {
  const { t } = useTranslation(i18next.language);

  return (
    <div className={styles.tableWrapper}>
      <div className={styles.header} style={{ backgroundColor: tripTypeColor, color: accentTextColor }}>
        {t('tripTypesPageTemplate.basketCategoryStats.priceTierTable.title')}
      </div>
      <div className={styles.content}>
        <div className={styles.label}>{t('tripTypesPageTemplate.basketCategoryStats.priceTierTable.label')}</div>
        {priceTierTableData.map((tier) => (
          <div key={tier.name} className={styles.row}>
            <div className={styles.statsProgBarWrap}>
              <div className={styles.catStatWrapper}>
                <div className={styles.name}>{tier.name}</div>
                <div className={styles.stats}>
                  <div className={styles.percentage}>{tier.percentage}%</div>
                  <div className={styles.index} style={{ backgroundColor: COLORS[tier.tagColor] }}>
                    {tier.index}
                  </div>
                </div>
              </div>
              <ProgressBar progress={calculateProgress(tier.percentage)} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

PriceTierTable.propTypes = {
  tripTypeColor: PropTypes.string.isRequired,
  priceTierTableData: PropTypes.array.isRequired,
  accentTextColor: PropTypes.string.isRequired
};

export default PriceTierTable;
