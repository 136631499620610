import React from 'react';
import PropTypes from 'prop-types';
import './FilledOutlinedIcon.scss';

function FilledOutlinedIcon(props) {
  return (
    <div className={`icon-container ${props.type}`}>
      {props.outlined && <div className={`outline ${props.type}`} />}
      <div className="icon-box">{props.children}</div>
    </div>
  );
}

FilledOutlinedIcon.propTypes = {
  type: PropTypes.string,
  outlined: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default FilledOutlinedIcon;
