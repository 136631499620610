import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { CategoryCard } from './compound-components/CategoryCard';
import { FlavorSpectrum } from './compound-components/FlavorSpectrum';
import { SegmentCard } from './compound-components/SegmentCard';
import styles from './CategoryExplorer.module.css';

const CATEGORY_HUBS = Object.freeze({
  BEER: '/categories/beer-hub',
  HARD_BEV: '/categories/hard-bev-hub'
});

const beerCategorySegments = [
  { label: 'Non-Alcoholic', to: '/categories/beer-hub/non-alcoholic' },
  { label: 'Malts & Ices', to: '/categories/beer-hub/malts-ices' },
  { label: 'American Classics', to: '/categories/beer-hub/american-classic' },
  { label: 'American Light', to: '/categories/beer-hub/american-light' },
  { label: 'American Balanced Light', to: '/categories/beer-hub/american-balanced' },
  { label: 'Imports', to: '/categories/beer-hub/imports' },
  { label: 'Elevated', to: '/categories/beer-hub/elevated' },
  { label: 'IPA/Pale Ale', to: '/categories/beer-hub/ipa-pale-ale' },
  { label: 'Craft All Other', to: '/categories/beer-hub/craft-all-other' },
  { label: 'Flavored', to: '/categories/beer-hub/flavored' }
];

const hardBeverageCategorySegments = [
  { label: 'Seltzers (Spirit-Based)', to: '/categories/hard-bev-hub/seltzer-spirit' },
  { label: 'Seltzers (Malt-Sugar)', to: '/categories/hard-bev-hub/seltzer-malt' },
  { label: 'Hard Cider', to: '/categories/hard-bev-hub/hard-cider' },
  { label: 'FMWBS (Low ABV)', to: '/categories/hard-bev-hub/fmwbs-low-abv' },
  { label: 'FMWBS (High ABV)', to: '/categories/hard-bev-hub/fmwbs-high-abv' },
  { label: 'Spirit-Based Cocktails', to: '/categories/hard-bev-hub/spirit-based-cocktails' },
  { label: 'Multi-Serve', to: '/categories/hard-bev-hub/multi-serve' }
];

export const CategoryExplorer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(i18next.language);

  const clickHandler = useCallback(
    (navigateTo) => {
      navigate(navigateTo);
    },
    [navigate]
  );

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h2 className={styles.title}>{t('categoryFramework.categoryExplorer.title')}</h2>
        <p className={styles.description}>{t('categoryFramework.categoryExplorer.description')}</p>
      </header>

      <div className={styles.segments}>
        <section className={styles.beerSection}>
          <button className={styles.categoryCardButton} onClick={() => clickHandler(CATEGORY_HUBS.BEER)}>
            <CategoryCard
              icon="https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fea329e0b53e24b108a1c51d95083af0e"
              title="beer"
              backgroundColor="rgba(242, 206, 25, 0.25)"
            />
          </button>
          <div className={styles.beerContent}>
            <FlavorSpectrum
              title={t('categoryFramework.categoryExplorer.beer.flavorSpectrum')}
              gradient="linear-gradient(90deg, #F2CE19 31.5%, #D4B31C 51%, #8B6E0F 72%)"
            />
            <div className={styles.segmentGrid}>
              {beerCategorySegments.map((segment) => (
                <button
                  key={segment.label}
                  className={styles.segmentCardButton}
                  onClick={() => clickHandler(segment.to)}
                >
                  <SegmentCard
                    key={segment.label}
                    title={segment.label}
                    category={t('categoryFramework.categoryExplorer.beer.title')}
                  />
                </button>
              ))}
            </div>
          </div>
        </section>

        <section className={styles.hardBeverageSection}>
          <button className={styles.categoryCardButton} onClick={() => clickHandler(CATEGORY_HUBS.HARD_BEV)}>
            <CategoryCard
              icon="https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fd6b85aeb9ff64667ba31cbd64777a8b5"
              title="hard beverage"
              backgroundColor="rgba(127, 97, 146, 0.5)"
            />
          </button>
          <div className={styles.hardBeverageContent}>
            <FlavorSpectrum
              title={t('categoryFramework.categoryExplorer.hardBeverage.flavorSpectrum')}
              gradient="linear-gradient(90deg, #A484BC 31.5%, #7F6192 51%, #4B2F8B 72%)"
            />
            <div className={styles.segmentGrid}>
              {hardBeverageCategorySegments.map((segment) => (
                <button
                  key={segment.label}
                  className={styles.segmentCardButton}
                  onClick={() => clickHandler(segment.to)}
                >
                  <SegmentCard key={segment.label} title={segment.label} category="hardBev" />
                </button>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
