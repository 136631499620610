import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Divider, Grid, Stack } from '@mui/material';

function SectionIntro({ heading, description, sectionImages }) {
  return (
    <Grid container className="section-main">
      <Grid item className="section-left-item" xs={5}>
        <Divider className="divider" />
        <Stack
          direction={'column'}
          className="section-title-container"
          alignItems="flex-start"
          justifyContent="space-between"
          sx={{ height: '100%' }}
        >
          <Typography className="section-heading" variant="h2" color="secondary">
            {heading}
          </Typography>
          <Typography className="section-description" variant="body" color="secondary">
            {description}
          </Typography>
        </Stack>
      </Grid>
      <Grid item className="section-right-item" xs={6.5}>
        <Stack direction={'row'} className="visual-container" spacing={-40}>
          <Box className="front-image">
            <img className="visual" src={sectionImages.front} alt="Section intro" />
          </Box>
          <Box className="back-image">
            <img className="visual" src={sectionImages.back} alt="Section intro" />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
}

SectionIntro.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  sectionImages: PropTypes.object.isRequired
};

export default SectionIntro;
