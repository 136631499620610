import React from 'react';
import PropTypes from 'prop-types';
import styles from './ContentCard.module.css';

export function ContentCard({ imageUrl, imageAltText, text, highlight }) {
  const textValues = highlight.map((obj) => obj.text);
  return (
    <div className={styles.contentCard}>
      <img loading="lazy" src={imageUrl} alt={imageAltText} className={styles.cardImage} />
      <div className={styles.textContent}>
        <div>
          {text.split(new RegExp(`(\\b(?:${textValues.join('|')})\\b)`)).map((part) =>
            textValues.includes(part.trim()) ? (
              <span key={part} className={styles.highlightText}>
                {part}
              </span>
            ) : (
              part
            )
          )}
        </div>
      </div>
    </div>
  );
}

ContentCard.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  imageAltText: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  highlight: PropTypes.arrayOf(PropTypes.object).isRequired
};
