import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useDragScroll } from '../../../../constants/useDragScroll';
import { TestimonialCard } from './compound-components/TestimonialCard';
import styles from './ShopperBasketCarousel.module.css';

export const ShopperBasketCarousel = ({ testimonials, cartImages }) => {
  const testimonialGridRef = useDragScroll();
  const cartImagesRef = useDragScroll();
  const { t } = useTranslation(i18next.language);
  return (
    <section className={styles.testimonialSection}>
      <div className={styles.sectionHeader}>
        <h2 className={styles.subheading}>{t('tripTypesPageTemplate.shopperBasketCarousel.eyebrow')}</h2>
        <h1 className={styles.heading}>{t('tripTypesPageTemplate.shopperBasketCarousel.title')}</h1>
      </div>

      <div className={styles.containerWrapper}>
        <div ref={testimonialGridRef} className={styles.testimonialGrid}>
          {testimonials.map((testimonial) => (
            <TestimonialCard key={testimonial.author} {...testimonial} />
          ))}
        </div>

        <div ref={cartImagesRef} className={styles.cartImages}>
          {cartImages.map((image) => (
            <img key={image.imageUrl} src={image.imageUrl} alt="" className={styles.cartImage} loading="lazy" />
          ))}
        </div>
      </div>
    </section>
  );
};

ShopperBasketCarousel.propTypes = {
  testimonials: PropTypes.array.isRequired,
  cartImages: PropTypes.array.isRequired
};
