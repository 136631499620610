import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Button, Grid } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SectionIntro from '../../common/page-sections/SectionIntro';
import ContextualFactorsDataCard from '../../custom/Cards/ContextualFactorsDataCard';
import { demandSpaceAssets } from '../../common/DemandSpaceAssets';
import { downloadsAssets } from '../../common/DownloadsAssets';

function SectionFour({ space, sectionData }) {
  const { t } = useTranslation(i18next.language);
  function downloadFile(url, fileName) {
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
  }
  function downloadSpreadsheets() {
    downloadFile(downloadsAssets.contextualFactorsData.DataPackSpreadsheet(), 'dataPack.xlsx');
  }

  function getVisualData(factor) {
    return {
      label: t(`demandspace.template.contextualFactor.contextualFactorData.${factor}.insightTitle`),
      labeltext: t(`demandspace.template.contextualFactor.contextualFactorData.${factor}.insightDescription`),
      icon: factor
    };
  }

  function getTableData(factor) {
    let initialRowCount = 0;
    //let isExpandable = false;
    let paginationAlignment = 'center';
    let paginationText = '';
    let data = {};

    switch (factor) {
      case 'meals':
      case 'drinkTimes':
      case 'locations':
        initialRowCount = 2;
        //isExpandable = true;
        break;
      default:
        initialRowCount = 5;
        //isExpandable = true;
        break;
    }

    data.initialRowCount = initialRowCount;
    //data.isExpandable = isExpandable;
    data.isExpandable = sectionData[factor].length > initialRowCount;
    data.paginationAlignment = paginationAlignment;
    data.paginationText = paginationText;
    data.heading = [];
    data.rowData = sectionData[factor];

    return data;
  }

  return (
    <div className="section4-contextual-factor">
      <SectionIntro
        heading={t('demandspace.template.contextualFactor.title')}
        description={t('demandspace.template.contextualFactor.subhead')}
        actionButton={
          <Button
            className="download-all-button outlined-button"
            component="label"
            variant="contained"
            startIcon={<FileDownloadIcon />}
            onClick={downloadSpreadsheets}
          >
            {t('buttons.downloadDataPack')}
          </Button>
        }
        sectionImages={{
          front: demandSpaceAssets.contextualFactor[space].front(),
          back: demandSpaceAssets.contextualFactor[space].back()
        }}
      />
      <Grid container className="section4-image-list" gap={2.5}>
        {Object?.keys(sectionData)?.map((factor) => (
          <Grid item key={factor} xs={3.8}>
            <ContextualFactorsDataCard visualData={getVisualData(factor)} tableData={getTableData(factor)} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

SectionFour.propTypes = {
  space: PropTypes.string.isRequired,
  sectionData: PropTypes.object.isRequired
};

export default SectionFour;
