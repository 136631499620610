import { COLORS } from './CatTripColors';

export const mainNavLinks = {
  mainCategory: {
    label: 'Trip Types',
    to: '/trip-types'
  },
  subCategory: {
    'alcohol-grab-go': { label: 'Alcohol Grab & Go', to: '/trip-types/alcohol-grab-go' },
    'alcohol-reload': { label: 'Alcohol Reload', to: '/trip-types/alcohol-reload' },
    'pit-stop-essentials': { label: 'Pit Stop Essentials', to: '/trip-types/pit-stop-essentials' },
    'todays-meal-plus': { label: "Today's Meal+", to: '/trip-types/todays-meal-plus' },
    'frequent-fill-in': { label: 'Frequent Fill-In', to: '/trip-types/frequent-fill-in' },
    'household-stock-up': { label: 'Household Stock-Up', to: '/trip-types/household-stock-up' }
  }
};

export const subNavLinks = [
  { href: '#trip-purpose', text: 'Trip Purpose' },
  { href: '#store-selection', text: 'Store Selection' },
  { href: '#basket', text: 'Basket' },
  { href: '#in-store-experience', text: 'In-Store Experience' },
  { href: '#who-when', text: 'Who & When' },
  { href: '#occasions', text: 'Occasions' },
  { href: '#shopper-journey', text: 'Journeys' },
  { href: '#wrap-up', text: 'Wrap Up' }
];

export const navColorSet = {
  'alcohol-grab-go': {
    anchorBarColor: COLORS.GRAB_GO_ORANGE,
    anchorBarColorSecondary: COLORS.DARK_OVERLAY,
    textColor: COLORS.TEXT_WHITE
  },
  'alcohol-reload': {
    anchorBarColor: COLORS.ALC_RELOAD_TEAL,
    anchorBarColorSecondary: COLORS.DARK_OVERLAY,
    textColor: COLORS.TEXT_WHITE
  },
  'pit-stop-essentials': {
    anchorBarColor: COLORS.PITSTOP_BLUE,
    anchorBarColorSecondary: COLORS.DARK_OVERLAY,
    textColor: COLORS.TEXT_WHITE
  },
  'todays-meal-plus': {
    anchorBarColor: COLORS.TODAY_MEAL_GREEN,
    anchorBarColorSecondary: COLORS.DARK_OVERLAY,
    textColor: COLORS.TEXT_WHITE
  },
  'frequent-fill-in': {
    anchorBarColor: COLORS.FREQ_FILL_IN_RED,
    anchorBarColorSecondary: COLORS.DARK_OVERLAY,
    textColor: COLORS.TEXT_WHITE
  },
  'household-stock-up': {
    anchorBarColor: COLORS.STOCKUP_PURPLE,
    anchorBarColorSecondary: COLORS.DARK_OVERLAY,
    textColor: COLORS.TEXT_WHITE
  }
};
