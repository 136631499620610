import React from 'react';
import PropTypes from 'prop-types';
import { DataTag } from './DataTag';
import styles from './TableHeader.module.css';

export function TableHeader({ data }) {
  return (
    <div className={styles.header}>
      <div className={styles.emptyCell} />
      {data.columns.map((column, index) => (
        <div key={index} className={styles.headerCell}>
          <div className={styles.title}>{column.title}</div>
          <DataTag percentage={column.percentage} value={column.tag} tagColor={column.tagColor} />
        </div>
      ))}
    </div>
  );
}

TableHeader.propTypes = {
  data: PropTypes.object.isRequired
};
