import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useParams } from 'react-router-dom';
import MeetThePeople from '../../common/page-sections/MeetThePeople';
import SectionIntro from '../../common/page-sections/SectionIntro';
import { peopleSegmentAssets } from '../../common/PeopleSegmentAssets';

const SectionThree = () => {
  const { t } = useTranslation(i18next.language);
  const { microSegment } = useParams();

  useEffect(() => {
    if (localStorage.getItem('i18nnextLng')?.length > 2) {
      i18next.changeLanguage('en');
    }
  }, []);

  return (
    <div className="section3-main">
      <Box className="background">
        <SectionIntro
          heading={t('people-segment.anchorItems.meetThePeople')}
          description={t('people-segment.template.meetThePeople.subtitle', {
            featuredPerson: t(`people-segment.template.intro.${microSegment}.featuredPerson`),
            micro: t(`people-segment.type.${microSegment}`)
          })}
          sectionImages={{
            back: peopleSegmentAssets.meetThePeople[microSegment]['back'](),
            front: peopleSegmentAssets.meetThePeople[microSegment]['front']()
          }}
        />
        <MeetThePeople space={microSegment} />
      </Box>
    </div>
  );
};

export default SectionThree;
