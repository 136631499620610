export const NavOptions = {
  loginHeaderOptions: [
    {
      title: 'Need Help?',
      segment: 'help',
      path: '',
      type: 'nav'
    }
  ],
  homeHeaderOptions: [
    {
      title: 'People Segments',
      segment: 'people-segment',
      path: '/demand-landscape/peoplesegment',
      type: 'drawer'
    },
    {
      title: 'Occasions',
      segment: 'occasions',
      path: '/demand-landscape/occasions',
      type: 'drawer'
    },
    {
      title: 'Demand Spaces',
      segment: 'demand-spaces',
      path: '/demand-landscape/demandspace',
      type: 'drawer'
    }
  ],
  footerOptions: [
    {
      title: 'Updates',
      segment: 'updates',
      path: '/updates',
      type: 'nav'
    },
    {
      title: 'Privacy Policy',
      segment: 'privacy',
      path: '/privacy',
      type: 'nav'
    },
    {
      title: 'Terms & Conditions',
      segment: 'terms',
      path: '/terms',
      type: 'nav'
    }
  ]
};
