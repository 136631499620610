import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { COLORS } from '../../../../constants/CatTripColors';
import { getAccentTextColor } from '../../../../constants/GetAccentTextColor';
import { StatCard } from './compound-components/StatCard';
import { ChartCard } from './compound-components/ChartCard';
import styles from './TimeToConsumption.module.css';

export function TimeToConsumption({ tripTypeColor, timeToConsumptionData, consumptionOccasionsData }) {
  const { t } = useTranslation(i18next.language);
  const accentTextColor = getAccentTextColor(tripTypeColor);

  return (
    <section className={styles.section}>
      <div className={styles.sectionHeader}>
        <h3 className={styles.sectionSubtitle}>{t('tripTypesPageTemplate.timeToConsumption.eyebrow')}</h3>
        <h2 className={styles.sectionTitle}>{t('tripTypesPageTemplate.timeToConsumption.title')}</h2>
      </div>

      <div className={styles.consumptionGroup}>
        <StatCard
          text={timeToConsumptionData.descriptionText}
          tripTypeColor={COLORS[tripTypeColor]}
          accentTextColor={accentTextColor}
        />
        <ChartCard title={timeToConsumptionData.title} image={timeToConsumptionData.image} />
      </div>

      <div className={styles.occasionsGroup}>
        <ChartCard
          title={consumptionOccasionsData.title}
          image={consumptionOccasionsData.image}
          footerNote={t('tripTypesPageTemplate.timeToConsumption.footerNote')}
        />
        <StatCard
          text={consumptionOccasionsData.descriptionText}
          tripTypeColor={COLORS[tripTypeColor]}
          accentTextColor={accentTextColor}
        />
      </div>
    </section>
  );
}

TimeToConsumption.propTypes = {
  timeToConsumptionData: PropTypes.object.isRequired,
  consumptionOccasionsData: PropTypes.object.isRequired,
  tripTypeColor: PropTypes.string.isRequired
};
