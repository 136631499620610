import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useParams } from 'react-router-dom';
import ConsumptionVisualCard from '../../custom/Cards/ConsumptionVisualCard';
import ConsumptionDataTable from '../../custom/Table/ConsumptionDataTable';
import PriceBrandTable from '../../custom/Table/PriceBrandTable';
import SectionIntro from '../../common/page-sections/SectionIntro';
import PriceBrandTableHeading from '../../custom/Table/PriceBrandTableHeading';
import DemandspaceHowTheyDrink from '../../common/page-sections/DemandspaceHowTheyDrink';
import { demandSpaceAssets } from '../../common/DemandSpaceAssets';
import { downloadsAssets } from '../../common/DownloadsAssets';

function SectionSix({ space, sectionData }) {
  const { microsegment } = useParams();
  const { t } = useTranslation(i18next.language);
  useEffect(() => {
    if (localStorage.getItem('i18nnextLng')?.length > 2) {
      i18next.changeLanguage('en');
    }
  }, []);

  function downloadFile(url, fileName) {
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
  }
  function downloadSpreadsheets() {
    downloadFile(downloadsAssets.driverAndNeedsData.DataPackSpreadsheet, 'dataPack.xlsx');
  }

  function getTableData(bevData) {
    let data = {};

    data.initialRowCount = 3;
    data.isExpandable = true;
    data.paginationAlignment = 'left';
    data.paginationTextExpand = t('occasions.template.consumption.consumptionData.paginationTextExpandMore');
    data.paginationTextContract = t('occasions.template.consumption.consumptionData.paginationTextExpandLess');
    data.header = [
      t('occasions.template.consumption.consumptionData.columnHeading1', {
        bevType: bevData.drinkName
      }),
      t('occasions.template.consumption.consumptionData.columnHeading2'),
      t('occasions.template.consumption.consumptionData.columnHeading3')
    ];
    data.rowData = bevData.drinkTypes;

    return data;
  }

  let priceTierData = [];
  if (sectionData.drinkPriceTier.length < 2) {
    if (sectionData.drinkPriceTier[0].drinkPriceTierType === 'Beer') {
      priceTierData = [...sectionData.drinkPriceTier, {}];
    } else {
      priceTierData = [{}, ...sectionData.drinkPriceTier];
    }
  } else {
    priceTierData = sectionData.drinkPriceTier;
  }

  let drinkBrandData = [];
  if (sectionData.drinkBrand.length < 2) {
    if (sectionData.drinkBrand[0].drinkBrandType === 'Beer') {
      drinkBrandData = [...sectionData.drinkBrand, {}];
    } else {
      drinkBrandData = [{}, ...sectionData.drinkBrand];
    }
  } else {
    drinkBrandData = sectionData.drinkBrand;
  }

  return (
    <div className="section6-consumption">
      <SectionIntro
        heading={t('demandspace.template.consumption.title')}
        description={t('demandspace.template.consumption.subtitle')}
        actionButton={
          <Button
            className="download-all-button outlined-button"
            component="label"
            variant="contained"
            startIcon={<FileDownloadIcon />}
            onClick={downloadSpreadsheets}
          >
            {t('common.downloads.demandspace.downloadSpreadSheets')}
          </Button>
        }
        sectionImages={{
          front: demandSpaceAssets.consumption[space].front(),
          back: demandSpaceAssets.consumption[space].back()
        }}
      />
      <Grid container className="section6-image-list-consumption" gap={2.5}>
        {sectionData?.drinks?.map((item) => (
          <Grid item className="consumption-card-item" key={item.id} xs={2.8}>
            <ConsumptionVisualCard space="demand-space" bevData={item} />
            <ConsumptionDataTable theme={microsegment} tableData={getTableData(item)} />
          </Grid>
        ))}
      </Grid>
      <Box className="interaction-tooltip-container">
        <InfoOutlinedIcon color="primary" />
        <Typography variant="body1" className="interaction-tooltip-text">
          {t('occasions.template.consumption.consumptionData.tooltipText')}
        </Typography>
      </Box>
      <Box className="beer-hard-deep-dive-container">
        <Grid container gap={5}>
          <Grid item xs={5.7} xl={5.7} sm={5.7} lg={5.7}>
            <Box className="howtheydrink-container">
              <Typography className="howtheydrink-typo" variant="h5">
                {t('demandspace.template.consumption.beerDeepDive')}
              </Typography>
              <Divider className="howtheydrink-divider"></Divider>
            </Box>
          </Grid>
          <Grid item xs={5.7} xl={5.7} sm={5.7} lg={5.7}>
            <Box className="howtheydrink-container">
              <Typography className="howtheydrink-typo" variant="h5">
                {t('demandspace.template.consumption.hardBevDeepDive')}
              </Typography>
              <Divider className="howtheydrink-divider"></Divider>
            </Box>
          </Grid>
        </Grid>
        <Grid container gap={5}>
          {priceTierData.map((item) => (
            <Grid item xs={5.7} xl={5.7} sm={5.7} lg={5.7} key={item.drinkPriceTierType}>
              <Box className="beer-deep-dive-table">
                <Box className="price-tier">
                  <Typography className="price-tier-typo">{t('demandspace.template.consumption.priceTier')}</Typography>
                  <Divider className="price-tier-divider"></Divider>
                </Box>
                {item.drinkPriceTier ? (
                  <Box className="price-tier-table">
                    <PriceBrandTableHeading heading={item.drinkPriceTierType} showIndex={true} />
                    {item?.drinkPriceTier?.map((priceItem) => (
                      <PriceBrandTable
                        key={priceItem.drinkPriceBrand}
                        drinkPricePercent={Math.round(priceItem?.drinkPricePercent)}
                        drinkPriceBrand={priceItem?.drinkPriceBrand}
                        drinkPriceIndex={Math.round(priceItem?.drinkPriceIndex)}
                        showIndex={true}
                      />
                    ))}
                  </Box>
                ) : (
                  <Typography className="price-tier-no-data">{t('common.dataUnavailable')}</Typography>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
        <Grid container gap={5}>
          {drinkBrandData.map((item) => (
            <Grid item xs={5.7} xl={5.7} sm={5.7} lg={5.7} key={item.drinkBrandType}>
              <Box className="beer-deep-dive-table">
                <Box className="price-tier">
                  <Typography className="price-tier-typo">{t('demandspace.template.consumption.brand')}</Typography>
                  <Divider className="price-tier-divider"></Divider>
                </Box>
                {item.drinkBrands ? (
                  <Box className="price-tier-table">
                    <PriceBrandTableHeading heading={item.drinkBrandType} showIndex={false} />
                    {item?.drinkBrands?.map((item) => (
                      <PriceBrandTable
                        key={item.drinkBrand}
                        drinkPricePercent={Math.round(item?.drinkPercent)}
                        drinkPriceBrand={item?.drinkBrand}
                        drinkPriceIndex={Math.round(item?.drinkIndex)}
                        showIndex={false}
                      />
                    ))}
                  </Box>
                ) : (
                  <Typography className="price-tier-no-data">{t('common.dataUnavailable')}</Typography>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2.5
        }}
      >
        <Box className="howtheydrink-container">
          <Typography className="howtheydrink-typo" variant="h5">
            {t('demandspace.template.consumption.customerHowTheyDrink')}
          </Typography>
          <Divider sx={{ flex: '1 0 0' }} className="howtheydrink-divider"></Divider>
        </Box>
        <Box className="demandspace-meetthepeople">
          <DemandspaceHowTheyDrink space={space} pageName={'demandspace'} />
        </Box>
      </Box>
    </div>
  );
}

SectionSix.propTypes = {
  space: PropTypes.string.isRequired,
  sectionData: PropTypes.object.isRequired
};

export default SectionSix;
