import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BuilderComponent, builder, useIsPreviewing } from '@builder.io/react';
import PropTypes from 'prop-types';
import { Theme } from '@abi-labs-hexa/web-v2/core/theme';
import CustomLink from './CustomLink';
import './builder-registry';

export default function BuilderRoute({ path }) {
  builder.init(window.env.REACT_APP_AZURE_VIDEO_URL_ID);

  const isPreviewingInBuilder = useIsPreviewing();
  const [notFound, setNotFound] = useState(false);
  const [content, setContent] = useState(null);

  const { page } = useParams();

  let urlPath = `/${path}`;

  if (page) {
    urlPath = `${urlPath}/${page}`;
  }

  useEffect(() => {
    async function fetchContent() {
      const content = await builder
        .get('page', {
          userAttributes: {
            urlPath: urlPath
          }
        })
        .promise();

      setContent(content);
      setNotFound(!content);

      if (content?.data.title) {
        document.title = content.data.title;
      }
    }
    fetchContent();
  }, [urlPath]);

  if (notFound && !isPreviewingInBuilder) {
    return <h1>404</h1>;
  }

  return (
    <Theme>
      <div className="builder-container">
        <BuilderComponent
          model="page"
          content={content}
          renderLink={(props) => <CustomLink {...props} />}
          data-testid="builder-component"
        />
      </div>
    </Theme>
  );
}

BuilderRoute.propTypes = {
  path: PropTypes.string.isRequired
};
