import React, { useState, useEffect } from 'react';

import { Box, Button, Typography, Grid2 } from '@mui/material';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { useMsal } from '@azure/msal-react';
import { b2cLoginRequest } from '../../auth-config';
import NotCompatibleComponent from './NotCompatibleComponent';

function Login() {
  const { t } = useTranslation(i18next.language);

  const [deviceType, setDeviceType] = useState('desktop');

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width > 1024) {
        setDeviceType('desktop');
      } else if (width >= 768 && width <= 1024) {
        setDeviceType('tablet');
      } else {
        setDeviceType('mobile');
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial device type detection

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /*function initiateLogin() {
    window.sessionStorage.setItem("isLoggedIn", true);
    //dispatch(setLoggedIn(true))
    // navigate('/terms')
    setHasLoggedIn(true)
  }*/
  const { instance } = useMsal();

  // B2C start
  const handleB2CRedirect = () => {
    instance
      .loginRedirect({
        ...b2cLoginRequest
      })
      .catch((error) => console.log(`B2C Login Error: ${error}`));
  };

  // B2C end

  useEffect(() => {
    if (localStorage.getItem('i18nnextLng')?.length > 2) {
      i18next.changeLanguage('en');
    }
  });

  return (
    <>
      {(deviceType === 'tablet' || deviceType === 'mobile') && <NotCompatibleComponent deviceType={deviceType} />}

      {deviceType === 'desktop' && (
        <Grid2
          container
          //gap={10}
          className="login-content"
          direction="column"
        >
          <Grid2 item xs={8}>
            <Box className="logo-container">
              <Typography className="logo-text">{t('login.hue')}</Typography>
            </Box>
          </Grid2>
          <Grid2 item xs={4}>
            <Box className="app-description-container">
              <Box className="app-introduction-container">
                <Typography className="login-title">{t('login.heading')}</Typography>
                <Typography variant="h1" className="login-project-title">
                  {t('projectTitle')}
                </Typography>
              </Box>

              <Typography className="login-project-summary">{t('login.projectDescription')}</Typography>

              <Box className="login-button-container">
                <Button className="signIn-button" variant="contained" onClick={handleB2CRedirect}>
                  {t('buttons.signIn')}
                </Button>
              </Box>
            </Box>
          </Grid2>
        </Grid2>
      )}
    </>
  );
}

export default Login;
