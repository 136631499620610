import PropTypes from 'prop-types';
import styles from './StatCard.module.css';

export const StatCard = ({ title, percentage, count, tagColor, peopleSegmentColor }) => {
  return (
    <div className={styles.statContainer}>
      <div className={styles.title} style={{ color: peopleSegmentColor }}>
        {title}
      </div>
      <div className={styles.stat}>
        <div className={styles.percentage}>{percentage}%</div>
        <div className={styles.tag} style={{ backgroundColor: tagColor }}>
          {count}
        </div>
      </div>
    </div>
  );
};

StatCard.propTypes = {
  title: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  tagColor: PropTypes.string.isRequired,
  peopleSegmentColor: PropTypes.string.isRequired
};
