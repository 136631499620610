const getSasKey = () => localStorage.getItem('sasKey') || '';

// Hub Images
// const hubHeroImage = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/hub/images/PS-Hub-HeroImage.jpg?${getSasKey()}`;
// // Whats in people segment
// const PS_WhatsInPeopleSegment_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/hub/images/whats-in-people-segment/PS_WhatsInPeopleSegment_front.jpg?${getSasKey()}`;
// const PS_WhatsInPeopleSegment_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/hub/images/whats-in-people-segment/PS_WhatsInPeopleSegment_back.jpg?${getSasKey()}`;

// Template Images
//Hero Images
// const comfortCraversHero = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/common/CC_Hero.jpg?${getSasKey()}`;
// const loyalTraditionalistsHero = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/common/LT_Hero.jpg?${getSasKey()}`;
// const balancedEnjoyersHero = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/common/BE_Hero.jpg?${getSasKey()}`;
// const carefreeFunLoversHero = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/common/CFL_Hero.jpg?${getSasKey()}`;
// const diligentDiscoverersHero = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/common/DD_Hero.jpg?${getSasKey()}`;
// const proudCelebratorsHero = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/common/PC_Hero.jpg?${getSasKey()}`;
// const creativeExplorersHero = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/common/CE_Hero.jpg?${getSasKey()}`;
// const socialCuratorsHero = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/common/SC_Hero.jpg?${getSasKey()}`;

// //Intro
// const CC_FeaturedPerson_IntroVideo = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/comfort-cravers/video/CC_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`;
// const CC_FeaturedPerson_IntroVideo_Thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/comfort-cravers/video/CC_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`;

// const BE_FeaturedPerson_IntroVideo = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/balanced-enjoyers/video/BE_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`;
// const BE_FeaturedPerson_IntroVideo_Thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/balanced-enjoyers/video/BE_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`;

// const CFL_FeaturedPerson_IntroVideo = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/carefree-fun-lovers/video/CFL_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`;
// const CFL_FeaturedPerson_IntroVideo_Thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/carefree-fun-lovers/video/CFL_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`;

// const CE_FeaturedPerson_IntroVideo = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/creative-explorers/video/CE_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`;
// const CE_FeaturedPerson_IntroVideo_Thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/creative-explorers/video/CE_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`;

// const DD_FeaturedPerson_IntroVideo = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/diligent-discoverers/video/DD_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`;
// const DD_FeaturedPerson_IntroVideo_Thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/diligent-discoverers/video/DD_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`;

// const LT_FeaturedPerson_IntroVideo = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/loyal-traditionalists/video/LT_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`;
// const LT_FeaturedPerson_IntroVideo_Thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/loyal-traditionalists/video/LT_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`;

// const PC_FeaturedPerson_IntroVideo = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/proud-celebrators/video/PC_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`;
// const PC_FeaturedPerson_IntroVideo_Thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/proud-celebrators/video/PC_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`;

// const SC_FeaturedPerson_IntroVideo = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/social-curators/video/SC_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`;
// const SC_FeaturedPerson_IntroVideo_Thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/social-curators/video/SC_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`;

// // Demographics
// const CC_Demographics_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/demographics/comfort-cravers/images/CC_Demographics_front.jpg?${getSasKey()}`;
// const CC_Demographics_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/demographics/comfort-cravers/images/CC_Demographics_back.jpg?${getSasKey()}`;
// const LT_Demographics_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/demographics/loyal-traditionalists/images/LT_Demographics_front.jpg?${getSasKey()}`;
// const LT_Demographics_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/demographics/loyal-traditionalists/images/LT_Demographics_back.jpg?${getSasKey()}`;
// const BE_Demographics_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/demographics/balanced-enjoyers/images/BE_Demographics_front.jpg?${getSasKey()}`;
// const BE_Demographics_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/demographics/balanced-enjoyers/images/BE_Demographics_back.jpg?${getSasKey()}`;
// const CFL_Demographics_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/demographics/carefree-fun-lovers/images/CFL_Demographics_front.jpg?${getSasKey()}`;
// const CFL_Demographics_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/demographics/carefree-fun-lovers/images/CFL_Demographics_back.jpg?${getSasKey()}`;
// const DD_Demographics_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/demographics/diligent-discoverers/images/DD_Demographics_front.jpg?${getSasKey()}`;
// const DD_Demographics_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/demographics/diligent-discoverers/images/DD_Demographics_back.jpg?${getSasKey()}`;
// const PC_Demographics_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/demographics/proud-celebrators/images/PC_Demographics_front.jpg?${getSasKey()}`;
// const PC_Demographics_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/demographics/proud-celebrators/images/PC_Demographics_back.jpg?${getSasKey()}`;
// const CE_Demographics_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/demographics/creative-explorers/images/CE_Demographics_front.jpg?${getSasKey()}`;
// const CE_Demographics_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/demographics/creative-explorers/images/CE_Demographics_back.jpg?${getSasKey()}`;
// const SC_Demographics_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/demographics/social-curators/images/SC_Demographics_front.jpg?${getSasKey()}`;
// const SC_Demographics_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/demographics/social-curators/images/SC_Demographics_back.jpg?${getSasKey()}`;

// //What drives them
// const CC_WhatDrivesThem_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/comfort-cravers/images/CC_WhatDrivesThem_front.jpg?${getSasKey()}`;
// const CC_WhatDrivesThem_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/comfort-cravers/images/CC_WhatDrivesThem_back.jpg?${getSasKey()}`;
// const LT_WhatDrivesThem_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_WhatDrivesThem_front.jpg?${getSasKey()}`;
// const LT_WhatDrivesThem_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_WhatDrivesThem_back.jpg?${getSasKey()}`;
// const BE_WhatDrivesThem_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_WhatDrivesThem_front.jpg?${getSasKey()}`;
// const BE_WhatDrivesThem_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_WhatDrivesThem_back.jpg?${getSasKey()}`;
// const CFL_WhatDrivesThem_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_WhatDrivesThem_front.jpg?${getSasKey()}`;
// const CFL_WhatDrivesThem_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_WhatDrivesThem_back.jpg?${getSasKey()}`;
// const DD_WhatDrivesThem_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_WhatDrivesThem_front.jpg?${getSasKey()}`;
// const DD_WhatDrivesThem_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_WhatDrivesThem_back.jpg?${getSasKey()}`;
// const PC_WhatDrivesThem_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/proud-celebrators/images/PC_WhatDrivesThem_front.jpg?${getSasKey()}`;
// const PC_WhatDrivesThem_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/proud-celebrators/images/PC_WhatDrivesThem_back.jpg?${getSasKey()}`;
// const CE_WhatDrivesThem_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/creative-explorers/images/CE_WhatDrivesThem_front.jpg?${getSasKey()}`;
// const CE_WhatDrivesThem_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/creative-explorers/images/CE_WhatDrivesThem_back.jpg?${getSasKey()}`;
// const SC_WhatDrivesThem_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/social-curators/images/SC_WhatDrivesThem_front.jpg?${getSasKey()}`;
// const SC_WhatDrivesThem_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/social-curators/images/SC_WhatDrivesThem_back.jpg?${getSasKey()}`;

// //What they drink
// const CC_WhatTheyDrink_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/comfort-cravers/images/CC_WhatTheyDrink_front.jpg?${getSasKey()}`;
// const CC_WhatTheyDrink_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/comfort-cravers/images/CC_WhatTheyDrink_back.jpg?${getSasKey()}`;
// const CC_WhatTheyDrink_Carousel1_Image = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/comfort-cravers/images/CC_WhatTheyDrink_Carousel1_Image.png?${getSasKey()}`;
// const CC_WhatTheyDrink_Carousel2_Image = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/comfort-cravers/images/CC_WhatTheyDrink_Carousel2_Image.png?${getSasKey()}`;
// const CC_WhatTheyDrink_Carousel3_Image = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/comfort-cravers/images/CC_WhatTheyDrink_Carousel3_Image.png?${getSasKey()}`;
// const CC_WhatTheyDrink_Audio = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/comfort-cravers/audio/CC_WhatTheyDrink_Audio.mp3?${getSasKey()}`
// const CC_WhatTheyDrink_Video1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/comfort-cravers/video/CC_WhatTheyDrink_Video1.mp4?${getSasKey()}`
// const CC_WhatTheyDrink_Video2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/comfort-cravers/video/CC_WhatTheyDrink_Video2.mp4?${getSasKey()}`

// const LT_WhatTheyDrink_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/loyal-traditionalists/images/LT_WhatTheyDrink_front.jpg?${getSasKey()}`;
// const LT_WhatTheyDrink_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/loyal-traditionalists/images/LT_WhatTheyDrink_back.jpg?${getSasKey()}`;
// const LT_WhatTheyDrink_Carousel1_Image = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/loyal-traditionalists/images/LT_WhatTheyDrink_Carousel1_Image.png?${getSasKey()}`;
// const LT_WhatTheyDrink_Carousel2_Image = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/loyal-traditionalists/images/LT_WhatTheyDrink_Carousel2_Image.png?${getSasKey()}`;
// const LT_WhatTheyDrink_Carousel3_Image = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/loyal-traditionalists/images/LT_WhatTheyDrink_Carousel3_Image.png?${getSasKey()}`;
// const LT_WhatTheyDrink_Audio = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/loyal-traditionalists/audio/LT_WhatTheyDrink_Audio.mp3?${getSasKey()}`
// const LT_WhatTheyDrink_Video1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/loyal-traditionalists/video/LT_WhatTheyDrink_Video1.mp4?${getSasKey()}`
// const LT_WhatTheyDrink_Video2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/loyal-traditionalists/video/LT_WhatTheyDrink_Video2.mp4?${getSasKey()}`

// const BE_WhatTheyDrink_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/balanced-enjoyers/images/BE_WhatTheyDrink_front.jpg?${getSasKey()}`;
// const BE_WhatTheyDrink_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/balanced-enjoyers/images/BE_WhatTheyDrink_back.jpg?${getSasKey()}`;
// const BE_WhatTheyDrink_Carousel1_Image = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/balanced-enjoyers/images/BE_WhatTheyDrink_Carousel1_Image.png?${getSasKey()}`;
// const BE_WhatTheyDrink_Carousel2_Image = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/balanced-enjoyers/images/BE_WhatTheyDrink_Carousel2_Image.png?${getSasKey()}`;
// const BE_WhatTheyDrink_Carousel3_Image = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/balanced-enjoyers/images/BE_WhatTheyDrink_Carousel3_Image.png?${getSasKey()}`;
// const BE_WhatTheyDrink_Audio = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/balanced-enjoyers/audio/BE_WhatTheyDrink_Audio.mp3?${getSasKey()}`
// const BE_WhatTheyDrink_Video1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/balanced-enjoyers/video/BE_WhatTheyDrink_Video1.mp4?${getSasKey()}`
// const BE_WhatTheyDrink_Video2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/balanced-enjoyers/video/BE_WhatTheyDrink_Video2.mp4?${getSasKey()}`

// const CFL_WhatTheyDrink_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/carefree-fun-lovers/images/CFL_WhatTheyDrink_front.jpg?${getSasKey()}`;
// const CFL_WhatTheyDrink_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/carefree-fun-lovers/images/CFL_WhatTheyDrink_back.jpg?${getSasKey()}`;
// const CFL_WhatTheyDrink_Carousel1_Image = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/carefree-fun-lovers/images/CFL_WhatTheyDrink_Carousel1_Image.png?${getSasKey()}`;
// const CFL_WhatTheyDrink_Carousel2_Image = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/carefree-fun-lovers/images/CFL_WhatTheyDrink_Carousel2_Image.png?${getSasKey()}`;
// const CFL_WhatTheyDrink_Carousel3_Image = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/carefree-fun-lovers/images/CFL_WhatTheyDrink_Carousel3_Image.png?${getSasKey()}`;
// const CFL_WhatTheyDrink_Audio = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/carefree-fun-lovers/audio/CFL_WhatTheyDrink_Audio.mp3?${getSasKey()}`
// const CFL_WhatTheyDrink_Video1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/carefree-fun-lovers/video/CFL_WhatTheyDrink_Video1.mp4?${getSasKey()}`
// const CFL_WhatTheyDrink_Video2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/carefree-fun-lovers/video/CFL_WhatTheyDrink_Video2.mp4?${getSasKey()}`

// const DD_WhatTheyDrink_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/diligent-discoverers/images/DD_WhatTheyDrink_front.jpg?${getSasKey()}`;
// const DD_WhatTheyDrink_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/diligent-discoverers/images/DD_WhatTheyDrink_back.jpg?${localStorage.getItem("sasKey"
//   )}`;
// const DD_WhatTheyDrink_Carousel1_Image = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/diligent-discoverers/images/DD_WhatTheyDrink_Carousel1_Image.png?${getSasKey()}`;
// const DD_WhatTheyDrink_Carousel2_Image = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/diligent-discoverers/images/DD_WhatTheyDrink_Carousel2_Image.png?${localStorage.getItem("sasKey"
//   )}`;
// const DD_WhatTheyDrink_Carousel3_Image = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/diligent-discoverers/images/DD_WhatTheyDrink_Carousel3_Image.png?${getSasKey()}`;
// const DD_WhatTheyDrink_Audio = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/diligent-discoverers/audio/DD_WhatTheyDrink_Audio.mp3?${getSasKey()}`
// const DD_WhatTheyDrink_Video1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/diligent-discoverers/video/DD_WhatTheyDrink_Video1.mp4?${getSasKey()}`
// const DD_WhatTheyDrink_Video2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/diligent-discoverers/video/DD_WhatTheyDrink_Video2.mp4?${getSasKey()}`

// const PC_WhatTheyDrink_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/proud-celebrators/images/PC_WhatTheyDrink_front.jpg?${getSasKey()}`;
// const PC_WhatTheyDrink_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/proud-celebrators/images/PC_WhatTheyDrink_back.jpg?${getSasKey()}`;
// const PC_WhatTheyDrink_Carousel1_Image = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/proud-celebrators/images/PC_WhatTheyDrink_Carousel1_Image.png?${getSasKey()}`;
// const PC_WhatTheyDrink_Carousel2_Image = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/proud-celebrators/images/PC_WhatTheyDrink_Carousel2_Image.png?${getSasKey()}`;
// const PC_WhatTheyDrink_Carousel3_Image = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/proud-celebrators/images/PC_WhatTheyDrink_Carousel3_Image.png?${getSasKey()}`;
// const PC_WhatTheyDrink_Audio = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/proud-celebrators/audio/PC_WhatTheyDrink_Audio.mp3?${getSasKey()}`
// const PC_WhatTheyDrink_Video1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/proud-celebrators/video/PC_WhatTheyDrink_Video1.mp4?${getSasKey()}`
// const PC_WhatTheyDrink_Video2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/proud-celebrators/video/PC_WhatTheyDrink_Video2.mp4?${getSasKey()}`

// const CE_WhatTheyDrink_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/creative-explorers/images/CE_WhatTheyDrink_front.jpg?${getSasKey()}`;
// const CE_WhatTheyDrink_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/creative-explorers/images/CE_WhatTheyDrink_back.jpg?${getSasKey()}`;
// const CE_WhatTheyDrink_Carousel1_Image = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/creative-explorers/images/CE_WhatTheyDrink_Carousel1_Image.png?${getSasKey()}`;
// const CE_WhatTheyDrink_Carousel2_Image = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/creative-explorers/images/CE_WhatTheyDrink_Carousel2_Image.png?${getSasKey()}`;
// const CE_WhatTheyDrink_Carousel3_Image = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/creative-explorers/images/CE_WhatTheyDrink_Carousel3_Image.png?${getSasKey()}`;
// const CE_WhatTheyDrink_Audio = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/creative-explorers/audio/CE_WhatTheyDrink_Audio.mp3?${getSasKey()}`
// const CE_WhatTheyDrink_Video1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/creative-explorers/video/CE_WhatTheyDrink_Video1.mp4?${getSasKey()}`
// const CE_WhatTheyDrink_Video2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/creative-explorers/video/CE_WhatTheyDrink_Video2.mp4?${getSasKey()}`

// const SC_WhatTheyDrink_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/social-curators/images/SC_WhatTheyDrink_front.jpg?${getSasKey()}`;
// const SC_WhatTheyDrink_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/social-curators/images/SC_WhatTheyDrink_back.jpg?${getSasKey()}`;
// const SC_WhatTheyDrink_Carousel1_Image = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/social-curators/images/SC_WhatTheyDrink_Carousel1_Image.png?${getSasKey()}`;
// const SC_WhatTheyDrink_Carousel2_Image = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/social-curators/images/SC_WhatTheyDrink_Carousel2_Image.png?${getSasKey()}`;
// const SC_WhatTheyDrink_Carousel3_Image = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-they-drink/social-curators/images/SC_WhatTheyDrink_Carousel3_Image.png?${getSasKey()}`;
// const SC_WhatTheyDrink_Audio = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/social-curators/audio/SC_WhatTheyDrink_Audio.mp3?${getSasKey()}`
// const SC_WhatTheyDrink_Video1 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/social-curators/video/SC_WhatTheyDrink_Video1.mp4?${getSasKey()}`
// const SC_WhatTheyDrink_Video2 = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/social-curators/video/SC_WhatTheyDrink_Video2.mp4?${getSasKey()}`

//What drives them ModularContent
// //Comfort Cravers
// const CC_Mod_PropensityToExplore_2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Explore_2.jpg?${getSasKey()}`;
// const CC_Mod_PropensityToExplore_3 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Explore_3.jpg?${getSasKey()}`;
// const CC_Mod_PropensityToExplore_4 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Explore_4.jpg?${getSasKey()}`;
// const CC_Mod_PropensityToExplore_5B = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Explore_5B.jpg?${getSasKey()}`;
// const CC_Mod_PropensityToExplore_5C = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Explore_5C.jpg?${getSasKey()}`;
// const CC_Mod_Health = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Health.jpg?${getSasKey()}`;
// const CC_Mod_Outlook = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Outlook.jpg?${getSasKey()}`;
// const CC_Mod_Sociability_2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Sociability_2.jpg?${getSasKey()}`;
// const CC_Mod_Sociability_3 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Sociability_3.jpg?${getSasKey()}`;
// const CC_Mod_Sociability_4 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Sociability_4.jpg?${getSasKey()}`;
// const CC_Mod_Sociability_5E = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Sociability_5E.jpg?${getSasKey()}`;
// const CC_Mod_Spending = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Spending.jpg?${getSasKey()}`;
// const CC_Mod_Status = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Status.jpg?${getSasKey()}`;
// const CC_PropensityToExplore_5A_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/videos/CC_PropensityToExplore_5A.mp4?${getSasKey()}`;
// const CC_Sociability_5A_Video = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F2c4ca2c6ebf54583833d9115e7e8245b%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_1}&alt=media&optimized=true`;
// const CC_Sociability_5B_Video = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F8bf8e481f5a34865b541784daa3a040c%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_2}&alt=media&optimized=true`;
// const CC_Sociability_5C_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/videos/CC_Sociability_5C.mp4?${getSasKey()}`;
// const CC_Sociability_5D_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/videos/CC_Sociability_5D.mp4?${getSasKey()}`;

// const CC_PropensityToExplore_5A_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/videos/CC_PropensityToExplore_5A_thumbnail.jpg?${getSasKey()}`;
// const CC_Sociability_5A_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/videos/CC_Sociability_5A_thumbnail.jpg?${getSasKey()}`;
// const CC_Sociability_5B_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/videos/CC_Sociability_5B_thumbnail.jpg?${getSasKey()}`;
// const CC_Sociability_5C_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/videos/CC_Sociability_5C_thumbnail.jpg?${getSasKey()}`;
// const CC_Sociability_5D_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/videos/CC_Sociability_5D_thumbnail.jpg?${getSasKey()}`;

// //Loyal Traditionalists
// const LT_Mod_PropensityToExplore_2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Explore_2.jpg?${getSasKey()}`;
// const LT_Mod_PropensityToExplore_3 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Explore_3.jpg?${getSasKey()}`;
// const LT_Mod_PropensityToExplore_4 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Explore_4.jpg?${getSasKey()}`;
// const LT_Mod_PropensityToExplore_5D = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Explore_5D.jpg?${getSasKey()}`;
// const LT_Mod_PropensityToExplore_5E = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Explore_5E.jpg?${getSasKey()}`;
// const LT_Mod_Health = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Health.jpg?${getSasKey()}`;
// const LT_Mod_Outlook = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Outlook.jpg?${getSasKey()}`;
// const LT_Mod_Sociability_2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Sociability_2.jpg?${getSasKey()}`;
// const LT_Mod_Sociability_3 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Sociability_3.jpg?${getSasKey()}`;
// const LT_Mod_Sociability_4 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Sociability_4.jpg?${getSasKey()}`;
// const LT_Mod_Sociability_5B = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Sociability_5B.jpg?${getSasKey()}`;
// const LT_Mod_Sociability_5C = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Sociability_5C.jpg?${getSasKey()}`;
// const LT_Mod_Sociability_5D = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Sociability_5D.jpg?${getSasKey()}`;
// const LT_Mod_Spending = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Spending.jpg?${getSasKey()}`;
// const LT_Mod_Status = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Status.jpg?${getSasKey()}`;
// const LT_PropensityToExplore_5A_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/videos/LT_PropensityToExplore_5A.mp4?${getSasKey()}`;
// const LT_PropensityToExplore_5B_Video = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fb7c5f608fa694d389793103b67f84eee%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_3}&alt=media&optimized=true`;
// const LT_PropensityToExplore_5C_Video = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F77a2dc05432e48dca69720789fa0cf3b%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_4}&alt=media&optimized=true`;
// const LT_Sociability_5A_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/videos/LT_Sociability_5A.mp4?${getSasKey()}`;
// //thumbnails
// const LT_PropensityToExplore_5A_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/videos/LT_PropensityToExplore_5A_thumbnail.jpg?${getSasKey()}`;
// const LT_PropensityToExplore_5B_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/videos/LT_PropensityToExplore_5B_thumbnail.jpg?${getSasKey()}`;
// const LT_PropensityToExplore_5C_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/videos/LT_PropensityToExplore_5C_thumbnail.jpg?${getSasKey()}`;
// const LT_Sociability_5A_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/videos/LT_Sociability_5A_thumbnail.jpg?${getSasKey()}`;

// // Balanced Enjoyers
// const BE_Mod_PropensityToExplore_2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Explore_2.jpg?${getSasKey()}`;
// const BE_Mod_PropensityToExplore_3 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Explore_3.jpg?${getSasKey()}`;
// const BE_Mod_PropensityToExplore_4 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Explore_4.jpg?${getSasKey()}`;
// const BE_Mod_PropensityToExplore_5C = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Explore_5C.jpg?${getSasKey()}`;
// const BE_Mod_Health = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Health.jpg?${getSasKey()}`;
// const BE_Mod_Outlook = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Outlook.jpg?${getSasKey()}`;
// const BE_Mod_Sociability_2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Sociability_2.jpg?${getSasKey()}`;
// const BE_Mod_Sociability_3 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Sociability_3.jpg?${getSasKey()}`;
// const BE_Mod_Sociability_4 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Sociability_4.jpg?${getSasKey()}`;
// const BE_Mod_Sociability_5D = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Sociability_5D.jpg?${getSasKey()}`;
// const BE_Mod_Spending = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Spending.jpg?${getSasKey()}`;
// const BE_Mod_Status = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Status.jpg?${getSasKey()}`;
// const BE_PropensityToExplore_5A_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/BE_PropensityToExplore_5A.mp4?${getSasKey()}`;
// const BE_PropensityToExplore_5B_Video = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F1a69e80846cf45b39eb6ce33dcf7f9ce%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_5}&alt=media&optimized=true`;
// const BE_PropensityToExplore_5D_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/BE_PropensityToExplore_5D.mp4?${getSasKey()}`;

// const BE_Sociability_5A_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/BE_Sociability_5A.mp4?${getSasKey()}`;
// const BE_Sociability_5B_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/BE_Sociability_5B.mp4?${getSasKey()}`;
// const BE_Sociability_5C_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/Be_Sociability_5C.mp4?${getSasKey()}`;
// //thumbnails
// const BE_PropensityToExplore_5A_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/BE_PropensityToExplore_5A_thumbnail.jpg?${getSasKey()}`;
// const BE_PropensityToExplore_5B_Video_thumbnail = `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F849a090961c041f6987c9875fe88f97f`;
// const BE_PropensityToExplore_5D_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/BE_PropensityToExplore_5D_thumbnail.jpg?${getSasKey()}`;

// const BE_Sociability_5A_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/BE_Sociability_5A_thumbnail.jpg?${getSasKey()}`;
// const BE_Sociability_5B_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/BE_Sociability_5B_thumbnail.jpg?${getSasKey()}`;
// const BE_Sociability_5C_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/BE_Sociability_5C_thumbnail.jpg?${getSasKey()}`;

// // Diligent Discoverers
// const DD_Mod_PropensityToExplore_2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Explore_2.jpg?${getSasKey()}`;
// const DD_Mod_PropensityToExplore_3 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Explore_3.jpg?${getSasKey()}`;
// const DD_Mod_PropensityToExplore_4 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Explore_4.jpg?${getSasKey()}`;
// const DD_Mod_PropensityToExplore_5D = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Explore_5D.jpg?${getSasKey()}`;
// const DD_Mod_PropensityToExplore_5E = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Explore_5E.jpg?${getSasKey()}`;
// const DD_Mod_Outlook = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Outlook.jpg?${getSasKey()}`;
// const DD_Mod_Sociability_2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Sociability_2.jpg?${getSasKey()}`;
// const DD_Mod_Sociability_3 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Sociability_3.jpg?${getSasKey()}`;
// const DD_Mod_Sociability_4 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Sociability_4.jpg?${getSasKey()}`;
// const DD_Mod_Sociability_5D = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Sociability_5D.jpg?${getSasKey()}`;
// const DD_Mod_Sociability_5E = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Sociability_5E.jpg?${getSasKey()}`;
// const DD_Mod_Spending = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Spending.jpg?${getSasKey()}`;
// const DD_Mod_Status = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Status.jpg?${getSasKey()}`;
// const DD_PropensityToExplore_5A_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_PropensityToExplore_5A.mp4?${getSasKey()}`;
// const DD_PropensityToExplore_5B_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_PropensityToExplore_5B.mp4?${getSasKey()}`;
// const DD_PropensityToExplore_5C_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_PropensityToExplore_5C.mp4?${getSasKey()}`;
// const DD_Sociability_5A_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_Sociability_5A.mp4?${getSasKey()}`;
// const DD_Sociability_5B_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_Sociability_5B.mp4?${getSasKey()}`;
// const DD_Sociability_5C_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_Sociability_5C.mp4?${getSasKey()}`;
// //thumbnails
// const DD_PropensityToExplore_5A_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_PropensityToExplore_5A_thumbnail.jpg?${getSasKey()}`;
// const DD_PropensityToExplore_5B_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_PropensityToExplore_5B_thumbnail.jpg?${getSasKey()}`;
// const DD_PropensityToExplore_5C_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_PropensityToExplore_5C_thumbnail.jpg?${getSasKey()}`;
// const DD_Sociability_5A_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_Sociability_5A_thumbnail.jpg?${getSasKey()}`;
// const DD_Sociability_5B_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_Sociability_5B_thumbnail.jpg?${getSasKey()}`;
// const DD_Sociability_5C_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_Sociability_5C_thumbnail.jpg?${getSasKey()}`;

// // Carefree Fun-Lovers
// const CFL_Mod_PropensityToExplore_2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Explore-2.jpg?${getSasKey()}`;
// const CFL_Mod_PropensityToExplore_3 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Explore-3.jpg?${getSasKey()}`;
// const CFL_Mod_PropensityToExplore_4 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Explore-4.jpg?${getSasKey()}`;
// const CFL_Mod_PropensityToExplore_5E = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Explore-5E.jpg?${getSasKey()}`;
// const CFL_Mod_Health = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Health.jpg?${getSasKey()}`;
// const CFL_Mod_Outlook = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Outlook.jpg?${getSasKey()}`;
// const CFL_Mod_Sociability_2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Sociability-2.jpg?${getSasKey()}`;
// const CFL_Mod_Sociability_3 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Sociability-3.jpg?${getSasKey()}`;
// const CFL_Mod_Sociability_4 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Sociability-4.jpg?${getSasKey()}`;
// const CFL_Mod_Sociability_5D = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Sociability-5D.jpg?${getSasKey()}`;
// const CFL_Mod_Sociability_5E = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Sociability-5E.jpg?${getSasKey()}`;
// const CFL_Mod_Spending = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Spending.jpg?${getSasKey()}`;
// const CFL_Mod_Status = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Status.jpg?${localStorage.getItem(
//     "sasKey"
//   )}`;
// const CFL_PropensityToExplore_5A_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_PropensityToExplore_5A.mp4?${localStorage.getItem("sasKey")}`;
// const CFL_PropensityToExplore_5B_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_PropensityToExplore_5B.mp4?${localStorage.getItem("sasKey")}`;
// const CFL_PropensityToExplore_5C_Video = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F7bb759795c46416983468045359bf03d%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_6}&alt=media&optimized=true`;
// const CFL_PropensityToExplore_5D_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_PropensityToExplore_5D.mp4?${localStorage.getItem("sasKey")}`;

// const CFL_Sociability_5A_Video = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fe71282aaad454a6fb66bfcc71994ec26%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_7}&alt=media&optimized=true`;
// const CFL_Sociability_5B_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_Sociability_5B.mp4?${localStorage.getItem("sasKey")}`;
// const CFL_Sociability_5C_Video = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Ff33e3ee2e765400c9f5abc04e4d1f4a1%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_8}&alt=media&optimized=true`;
// //thumbnails
// const CFL_PropensityToExplore_5A_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_PropensityToExplore_5A_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
// const CFL_PropensityToExplore_5B_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_PropensityToExplore_5B_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
// const CFL_PropensityToExplore_5C_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_PropensityToExplore_5C_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
// const CFL_PropensityToExplore_5D_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_PropensityToExplore_5D_thumbnail.jpg?${localStorage.getItem("sasKey")}`;

// const CFL_Sociability_5A_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_Sociability_5A_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
// const CFL_Sociability_5B_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_Sociability_5B_thumbnail.jpg?${localStorage.getItem("sasKey")}`;
// const CFL_Sociability_5C_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_Sociability_5C_thumbnail.jpg?${localStorage.getItem("sasKey")}`;

// // Proud Celebrators
// const PC_Mod_PropensityToExplore_2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Explore_2.jpg?${getSasKey()}`;
// const PC_Mod_PropensityToExplore_3 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Explore_3.jpg?${getSasKey()}`;
// const PC_Mod_PropensityToExplore_4 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Explore_4.jpg?${getSasKey()}`;
// const PC_Mod_PropensityToExplore_5E = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Explore_5E.jpg?${getSasKey()}`;
// const PC_Mod_Health = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Health.jpg?${getSasKey()}`;
// const PC_Mod_Outlook = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Outlook.jpg?${getSasKey()}`;
// const PC_Mod_Sociability_2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Sociability_2.jpg?${getSasKey()}`;
// const PC_Mod_Sociability_3 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Sociability_3.jpg?${getSasKey()}`;
// const PC_Mod_Sociability_4 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Sociability_4.jpg?${getSasKey()}`;
// const PC_Mod_Sociability_5D = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Sociability_5D.jpg?${getSasKey()}`;
// const PC_Mod_Sociability_5E = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Sociability_5E.jpg?${getSasKey()}`;
// const PC_Mod_Spending = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Spending.jpg?${getSasKey()}`;
// const PC_Mod_Status = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Status.jpg?${getSasKey()}`;
// const PC_PropensityToExplore_5A_Video = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Ff72294abd4b94b5b8310fd8cf98a5f8f%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_9}&alt=media&optimized=true`;
// const PC_PropensityToExplore_5B_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/videos/PC_PropenstiyToExplore_5B.mp4?${getSasKey()}`;
// const PC_PropensityToExplore_5C_Video = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F0eef85836cea44c2a5ff836e698d347d%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_10}&alt=media&optimized=true`;
// const PC_PropensityToExplore_5D_Video = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F07bddc2ccb6e44bc87d310849b9fae8c%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_11}&alt=media&optimized=true`;

// const PC_Sociability_5A_Video = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fd61d25566c7a44a7adc983e898ded0c1%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_12}&alt=media&optimized=true`;
// const PC_Sociability_5B_Video = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F6786725400214180976b32907749b290%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_13}&alt=media&optimized=true`;
// const PC_Sociability_5C_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/videos/PC_Sociability_5C.mp4?${getSasKey()}`;
// //thumbnails
// const PC_PropensityToExplore_5A_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/videos/PC_PropensityToExplore_5A_thumbnail.jpg?${getSasKey()}`;
// const PC_PropensityToExplore_5B_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/videos/PC_PropensityToExplore_5B_thumbnail.jpg?${getSasKey()}`;
// const PC_PropensityToExplore_5C_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/videos/PC_PropensityToExplore_5C_thumbnail.jpg?${getSasKey()}`;
// const PC_PropensityToExplore_5D_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/videos/PC_PropensityToExplore_5D_thumbnail.jpg?${getSasKey()}`;

// const PC_Sociability_5A_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/videos/PC_Sociability_5A_thumbnail.jpg?${getSasKey()}`;
// const PC_Sociability_5B_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/videos/PC_Sociability_5B_thumbnail.jpg?${getSasKey()}`;
// const PC_Sociability_5C_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/videos/PC_Sociability_5C_thumbnail.jpg?${getSasKey()}`;

// // Creative Explorers
// const CE_Mod_PropensityToExplore_2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Explore_2.jpg?${getSasKey()}`;
// const CE_Mod_PropensityToExplore_3 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Explore_2.jpg?${getSasKey()}`;
// const CE_Mod_PropensityToExplore_4 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Explore_4.jpg?${getSasKey()}`;
// const CE_Mod_PropensityToExplore_5D = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Explore_5D.jpg?${getSasKey()}`;
// const CE_Mod_PropensityToExplore_5C = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Explore_5C.jpg?${getSasKey()}`;
// const CE_Mod_Health = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Health.jpg?${getSasKey()}`;
// const CE_Mod_Outlook = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Outlook.jpg?${getSasKey()}`;
// const CE_Mod_Sociability_2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Sociability_2.jpg?${getSasKey()}`;
// const CE_Mod_Sociability_3 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Sociability_3.jpg?${getSasKey()}`;
// const CE_Mod_Sociability_4 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Sociability_4.jpg?${getSasKey()}`;
// const CE_Mod_Spending = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Spending.jpg?${getSasKey()}`;
// const CE_Mod_Status = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Status.jpg?${getSasKey()}`;
// const CE_PropensityToExplore_5B_Video = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fc35647ac7e2e44d789fa973e00b52cc2%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_14}&alt=media&optimized=true`;
// const CE_PropensityToExplore_5A_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/videos/CE_PropensityToExplore_5A.mp4?${getSasKey()}`;

// const CE_Sociability_5A_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/videos/CE_Sociability_5A.mp4?${getSasKey()}`;
// const CE_Sociability_5B_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/videos/CE_Sociability_5B.mp4?${getSasKey()}`;
// const CE_Sociability_5C_Video = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F5403fca56296468fa413e3e1683a2ea5%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_14}&alt=media&optimized=true`;
// const CE_Sociability_5D_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/videos/CE_Sociability_5D.mp4?${getSasKey()}`;
// //thumbnails
// const CE_PropensityToExplore_5B_Video_thumbnail = `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Feeef22e088234ee7bb4bcce00714d18c`;
// const CE_PropensityToExplore_5A_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/videos/CE_PropensityToExplore_5A_thumbnail.jpg?${getSasKey()}`;

// const CE_Sociability_5A_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/videos/CE_Sociability_5A_thumbnail.jpg?${getSasKey()}`;
// const CE_Sociability_5B_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/videos/CE_Sociability_5B_thumbnail.jpg?${getSasKey()}`;
// const CE_Sociability_5C_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/videos/CE_Sociability_5C_thumbnail.jpg?${getSasKey()}`;
// const CE_Sociability_5D_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/videos/CE_Sociability_5D_thumbnail.jpg?${getSasKey()}`;

// // Social Curators
// const SC_Mod_PropensityToExplore_2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Explore_2.jpg?${getSasKey()}`;
// const SC_Mod_PropensityToExplore_3 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Explore_3.jpg?${getSasKey()}`;
// const SC_Mod_PropensityToExplore_4 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Explore_4.jpg?${getSasKey()}`;
// const SC_Mod_PropensityToExplore_5D = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Explore_5D.jpg?${getSasKey()}`;
// const SC_Mod_PropensityToExplore_5E = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Explore_5E.jpg?${getSasKey()}`;
// const SC_Mod_Health = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Health.jpg?${getSasKey()}`;
// const SC_Mod_Outlook = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Outlook.jpg?${getSasKey()}`;
// const SC_Mod_Sociability_2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Sociability_2.jpg?${getSasKey()}`;
// const SC_Mod_Sociability_3 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Sociability_3.jpg?${getSasKey()}`;
// const SC_Mod_Sociability_4 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Sociability_4.jpg?${getSasKey()}`;
// const SC_Mod_Sociability_5E = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Sociability_5E.jpg?${getSasKey()}`;
// const SC_Mod_Spending = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Spending.jpg?${getSasKey()}`;
// const SC_Mod_Status = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Status.jpg?${getSasKey()}`;
// const SC_PropensityToExplore_5A_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_PropensityToExplore_5A.mp4?${getSasKey()}`;
// const SC_PropensityToExplore_5B_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_PropensityToExplore_5B.mp4?${getSasKey()}`;
// const SC_PropensityToExplore_5C_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_PropensityToExplore_5C.mp4?${getSasKey()}`;

// const SC_Sociability_5A_Video = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fc7e485a6f932404cbc777830ac213aad%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_16}&alt=media&optimized=true`;
// const SC_Sociability_5B_Video = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F1055c7c718bb47db9bbaf69983262a7d%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_17}&alt=media&optimized=true`;
// const SC_Sociability_5C_Video = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_Sociability_5C.mp4?${getSasKey()}`;
// const SC_Sociability_5D_Video = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Ff08345d3dff34edca515fc5c60e32eb9%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_18}&alt=media&optimized=true`;
// //thumbnails
// const SC_PropensityToExplore_5A_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_PropensityToExplore_5A_thumbnail.jpg?${getSasKey()}`;
// const SC_PropensityToExplore_5B_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_PropensityToExplore_5B_thumbnail.jpg?${getSasKey()}`;
// const SC_PropensityToExplore_5C_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_PropensityToExplore_5C_thumbnail.jpg?${getSasKey()}`;

// const SC_Sociability_5A_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_Sociability_5A_thumbnail.jpg?${getSasKey()}`;
// const SC_Sociability_5B_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_Sociability_5B_thumbnail.jpg?${getSasKey()}`;
// const SC_Sociability_5C_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_Sociability_5C_thumbnail.jpg?${getSasKey()}`;
// const SC_Sociability_5D_Video_thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_Sociability_5D_thumbnail.jpg?${getSasKey()}`;

// //Meet the people

// //Comfort Cravers
// const CC_MeetThePeople_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/images/CC_MeetThePeople_front.png?${getSasKey()}`;
// const CC_MeetThePeople_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/images/CC_MeetThePeople_back.png?${getSasKey()}`;

// const CC_Carousel1_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel1_Avatar.png?${getSasKey()}`;
// const CC_Carousel1_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel1_SquareImage1.png?${getSasKey()}`;
// const CC_Carousel1_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel1_SquareImage2.png?${getSasKey()}`;
// const CC_Carousel1_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel1_VerticalImage.png?${getSasKey()}`;
// const CC_Carousel1_VideoLeft = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/video/CC_Carousel1_VideoLeft.mp4?${getSasKey()}`;
// const CC_Carousel1_VideoRight = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F0688c780ceb24b299b0d88975e634289%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_19}&alt=media&optimized=true`;

// const CC_Carousel2_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel2_Avatar.png?${getSasKey()}`;
// const CC_Carousel2_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel2_SquareImage2.png?${getSasKey()}`;
// const CC_Carousel2_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel2_VerticalImage.png?${getSasKey()}`;
// const CC_Carousel2_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel2_SquareImage1.png?${getSasKey()}`;
// const CC_Carousel2_VideoLeft = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F6862740bc9814d66ae83e0b98f8eb86c%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_20}6862740bc9814d66ae83e0b98f8eb86c&alt=media&optimized=true`;
// const CC_Carousel2_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/video/CC_Carousel2_VideoRight.mp4?${getSasKey()}`;

// const CC_Carousel3_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel3_Avatar.png?${getSasKey()}`;
// const CC_Carousel3_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel3_SquareImage1.png?${getSasKey()}`;
// const CC_Carousel3_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel3_VerticalImage.png?${getSasKey()}`;
// const CC_Carousel3_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel3_SquareImage2.png?${getSasKey()}`;
// const CC_Carousel3_VideoLeft = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fa8eb3ce06cdd4173afa1d4d0217a5279%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_21}&alt=media&optimized=true`;
// const CC_Carousel3_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/video/CC_Carousel3_VideoRight.mp4?${getSasKey()}`;

// const CC_Carousel4_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel4_Avatar.png?${getSasKey()}`;
// const CC_Carousel4_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel4_SquareImage1.png?${getSasKey()}`;
// const CC_Carousel4_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel4_SquareImage2.png?${getSasKey()}`;
// const CC_Carousel4_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel4_VerticalImage.png?${getSasKey()}`;
// const CC_Carousel4_VideoLeft = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/video/CC_Carousel4_VideoLeft.mp4?${getSasKey()}`;
// const CC_Carousel4_VideoLeft_Thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/video/CC_Carousel4_VideoLeft_Thumbnail.jpg?${getSasKey()}`;
// const CC_Carousel4_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/video/CC_Carousel4_VideoRight.mp4?${getSasKey()}`;

// const CC_Carousel5_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel5_Avatar.png?${getSasKey()}`;
// const CC_Carousel5_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel5_SquareImage1.png?${getSasKey()}`;
// const CC_Carousel5_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel5_SquareImage2.png?${getSasKey()}`;
// const CC_Carousel5_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel5_VerticalImage.png?${getSasKey()}`;
// const CC_Carousel5_VideoLeft = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F139c8c2fba814282a328c59d1ee5f5b8%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_22}&alt=media&optimized=true`;
// const CC_Carousel5_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/comfort-cravers/video/CC_Carousel5_VideoRight.mp4?${getSasKey()}`;

// //Loyal Tradionalists
// const LT_MeetThePeople_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_MeetThePeople_front.jpg?${getSasKey()}`;
// const LT_MeetThePeople_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_MeetThePeople_back.jpg?${getSasKey()}`;

// const LT_Carousel1_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel1_Avatar.png?${getSasKey()}`;
// const LT_Carousel1_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel1_SquareImage1.png?${getSasKey()}`;
// const LT_Carousel1_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel1_SquareImage2.png?${getSasKey()}`;
// const LT_Carousel1_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel1_VerticalImage.png?${getSasKey()}`;
// const LT_Carousel1_VideoLeft = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/loyal-traditionalists/video/LT_Carousel1_VideoLeft.mp4?${getSasKey()}`;
// const LT_Carousel1_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/loyal-traditionalists/video/LT_Carousel1_VideoRight.mp4?${getSasKey()}`;

// const LT_Carousel2_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel2_Avatar.png?${getSasKey()}`;
// const LT_Carousel2_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel2_SquareImage1.png?${getSasKey()}`;
// const LT_Carousel2_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel2_SquareImage2.png?${getSasKey()}`;
// const LT_Carousel2_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel2_VerticalImage.png?${getSasKey()}`;
// const LT_Carousel2_VideoLeft = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/loyal-traditionalists/video/LT_Carousel2_VideoLeft.mp4?${getSasKey()}`;
// const LT_Carousel2_VideoRight = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fc21f511be0d847fb8bddb1ef076e9801%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_23}&alt=media&optimized=true`;

// const LT_Carousel3_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel3_Avatar.png?${getSasKey()}`;
// const LT_Carousel3_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel3_SquareImage1.png?${getSasKey()}`;
// const LT_Carousel3_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel3_SquareImage2.png?${getSasKey()}`;
// const LT_Carousel3_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel3_VerticalImage.png?${getSasKey()}`;
// const LT_Carousel3_VideoLeft = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fbf849854e97f43329df3cdbb9c829126%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_24}&alt=media&optimized=true`;
// const LT_Carousel3_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/loyal-traditionalists/video/LT_Carousel3_VideoRight.mp4?${getSasKey()}`;

// const LT_Carousel4_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel4_Avatar.png?${getSasKey()}`;
// const LT_Carousel4_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel4_SquareImage1.png?${getSasKey()}`;
// const LT_Carousel4_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel4_SquareImage2.png?${getSasKey()}`;
// const LT_Carousel4_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel4_VerticalImage.png?${getSasKey()}`;
// const LT_Carousel4_VideoLeft = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/loyal-traditionalists/video/LT_Carousel4_VideoLeft.mp4?${getSasKey()}`;
// const LT_Carousel4_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/loyal-traditionalists/video/LT_Carousel4_VideoRight.mp4?${getSasKey()}`;

// const LT_Carousel5_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel5_Avatar.png?${getSasKey()}`;
// const LT_Carousel5_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel5_SquareImage1.png?${getSasKey()}`;
// const LT_Carousel5_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel5_SquareImage2.png?${getSasKey()}`;
// const LT_Carousel5_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel5_VerticalImage.png?${getSasKey()}`;
// const LT_Carousel5_VideoLeft = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fd6db68f947c94b73833480377f8218e5%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_25}&alt=media&optimized=true`;
// const LT_Carousel5_VideoRight = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fad34e328e26440bcb484de530c406da1%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_26}&alt=media&optimized=true`;

// //Balanced Enjoyers
// const BE_MeetThePeople_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_MeetThePeople_front.jpg?${getSasKey()}`;
// const BE_MeetThePeople_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_MeetThePeople_back.jpg?${getSasKey()}`;

// const BE_Carousel1_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel1_Avatar.png?${getSasKey()}`;
// const BE_Carousel1_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel1_SquareImage1.png?${getSasKey()}`;
// const BE_Carousel1_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel1_SquareImage2.png?${getSasKey()}`;
// const BE_Carousel1_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel1_VerticalImage.png?${getSasKey()}`;
// const BE_Carousel1_VideoLeft = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fd5f20b99844d49a99d4b62437aa3dde0%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_27}&alt=media&optimized=true`;
// const BE_Carousel1_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/video/BE_Carousel1_VideoRight.mp4?${getSasKey()}`;

// const BE_Carousel2_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel2_Avatar.png?${getSasKey()}`;
// const BE_Carousel2_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel2_SquareImage1.png?${getSasKey()}`;
// const BE_Carousel2_SquareImage2 = `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F0b97590d535b4a4aa98b996162c881c9`;
// const BE_Carousel2_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel2_VerticalImage.png?${getSasKey()}`;
// const BE_Carousel2_VideoLeft = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/video/BE_Carousel2_VideoLeft.mp4?${getSasKey()}`;
// const BE_Carousel2_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/video/BE_Carousel2_VideoRight.mp4?${getSasKey()}`;

// const BE_Carousel3_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel3_Avatar.png?${getSasKey()}`;
// const BE_Carousel3_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel3_SquareImage1.png?${getSasKey()}`;
// const BE_Carousel3_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel3_VerticalImage.png?${getSasKey()}`;
// const BE_Carousel3_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel3_SquareImage2.png?${getSasKey()}`;
// const BE_Carousel3_VideoLeft = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/video/BE_Carousel3_VideoLeft.mp4?${getSasKey()}`;
// const BE_Carousel3_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/video/BE_Carousel3_VideoRight.mp4?${getSasKey()}`;

// const BE_Carousel4_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel4_Avatar.png?${getSasKey()}`;
// const BE_Carousel4_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel4_SquareImage1.png?${getSasKey()}`;
// const BE_Carousel4_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel4_SquareImage2.png?${getSasKey()}`;
// const BE_Carousel4_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel4_VerticalImage.png?${getSasKey()}`;
// const BE_Carousel4_VideoLeft = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/video/BE_Carousel4_VideoLeft.mp4?${getSasKey()}`;
// const BE_Carousel4_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/video/BE_Carousel4_VideoRight.mp4?${getSasKey()}`;

// const BE_Carousel5_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel5_Avatar.png?${getSasKey()}`;
// const BE_Carousel5_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel5_SquareImage1.png?${getSasKey()}`;
// const BE_Carousel5_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel5_SquareImage2.png?${getSasKey()}`;
// const BE_Carousel5_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel5_VerticalImage.png?${getSasKey()}`;
// const BE_Carousel5_VideoLeft = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/video/BE_Carousel5_VideoLeft.mp4?${getSasKey()}`;
// const BE_Carousel5_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/balanced-enjoyers/video/BE_Carousel5_VideoRight.mp4?${getSasKey()}`;

// //Diligent Discoverers
// const DD_MeetThePeople_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_MeetThePeople_front.jpg?${getSasKey()}`;
// const DD_MeetThePeople_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_MeetThePeople_back.jpg?${getSasKey()}`;

// const DD_Carousel1_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel1_Avatar.png?${getSasKey()}`;
// const DD_Carousel1_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel1_SquareImage1.png?${getSasKey()}`;
// const DD_Carousel1_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel1_VerticalImage.png?${getSasKey()}`;
// const DD_Carousel1_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel1_SquareImage2.png?${getSasKey()}`;
// const DD_Carousel1_VideoLeft = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/video/DD_Carousel1_VideoLeft.mp4?${getSasKey()}`;
// const DD_Carousel1_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/video/DD_Carousel1_VideoRight.mp4?${getSasKey()}`;

// const DD_Carousel2_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel2_Avatar.png?${getSasKey()}`;
// const DD_Carousel2_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel2_SquareImage1.png?${getSasKey()}`;
// const DD_Carousel2_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel2_SquareImage2.png?${getSasKey()}`;
// const DD_Carousel2_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel2_VerticalImage.png?${getSasKey()}`;
// const DD_Carousel2_VideoLeft = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F9b4616800ff84a198fd52cf3fc386125%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_28}&alt=media&optimized=true`;
// const DD_Carousel2_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/video/DD_Carousel2_VideoRight.mp4?${getSasKey()}`;

// const DD_Carousel3_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel3_Avatar.png?${getSasKey()}`;
// const DD_Carousel3_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel3_VerticalImage.png?${getSasKey()}`;
// const DD_Carousel3_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel3_SquareImage2.png?${getSasKey()}`;
// const DD_Carousel3_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel3_SquareImage1.png?${getSasKey()}`;
// const DD_Carousel3_VideoLeft = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/video/DD_Carousel3_VideoLeft.mp4?${getSasKey()}`;
// const DD_Carousel3_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/video/DD_Carousel3_VideoRight.mp4?${getSasKey()}`;

// const DD_Carousel4_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel4_Avatar.png?${getSasKey()}`;
// const DD_Carousel4_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel4_SquareImage1.png?${getSasKey()}`;
// const DD_Carousel4_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel4_VerticalImage.png?${getSasKey()}`;
// const DD_Carousel4_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel4_SquareImage2.png?${getSasKey()}`;
// const DD_Carousel4_VideoLeft = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/video/DD_Carousel4_VideoLeft.mp4?${getSasKey()}`;
// const DD_Carousel4_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/video/DD_Carousel4_VideoRight.mp4?${getSasKey()}`;

// const DD_Carousel5_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel5_Avatar.png?${getSasKey()}`;
// const DD_Carousel5_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel5_SquareImage1.png?${getSasKey()}`;
// const DD_Carousel5_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel5_SquareImage2.png?${getSasKey()}`;
// const DD_Carousel5_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel5_VerticalImage.png?${getSasKey()}`;
// const DD_Carousel5_VideoLeft = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fcb2d006bc5c848b5b4152511212bb70f%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_29}&alt=media&optimized=true`;
// const DD_Carousel5_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/diligent-discoverers/video/DD_Carousel5_VideoRight.mp4?${getSasKey()}`;

// //Carefree Fun Lovers
// const CFL_MeetThePeople_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_MeetThePeople_front.jpg?${getSasKey()}`;
// const CFL_MeetThePeople_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_MeetThePeople_back.jpg?${getSasKey()}`;

// const CFL_Carousel1_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel1_Avatar.png?${getSasKey()}`;
// const CFL_Carousel1_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel1_SquareImage1.png?${getSasKey()}`;
// const CFL_Carousel1_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel1_VerticalImage.png?${getSasKey()}`;
// const CFL_Carousel1_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel1_SquareImage2.png?${getSasKey()}`;
// const CFL_Carousel1_VideoLeft = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fb562ececfccf47faae3b71befd6592ea%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_30}&alt=media&optimized=true`;
// const CFL_Carousel1_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel1_VideoRight.mp4?${getSasKey()}`;
// const CFL_Carousel1_VideoRight_Thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel1_VideoRight_Thumbnail.jpg?${getSasKey()}`;

// const CFL_Carousel2_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel2_Avatar.png?${getSasKey()}`;
// const CFL_Carousel2_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel2_SquareImage1.png?${getSasKey()}`;
// const CFL_Carousel2_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel2_SquareImage2.png?${getSasKey()}`;
// const CFL_Carousel2_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel2_VerticalImage.png?${getSasKey()}`;
// const CFL_Carousel2_VideoLeft = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel2_VideoLeft.mp4?${getSasKey()}`;
// const CFL_Carousel2_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel2_VideoRight.mp4?${getSasKey()}`;

// const CFL_Carousel3_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel3_Avatar.png?${getSasKey()}`;
// const CFL_Carousel3_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel3_SquareImage1.png?${getSasKey()}`;
// const CFL_Carousel3_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel3_SquareImage2.png?${getSasKey()}`;
// const CFL_Carousel3_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel3_VerticalImage.png?${getSasKey()}`;
// const CFL_Carousel3_VideoLeft = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel3_VideoLeft.mp4?${getSasKey()}`;
// const CFL_Carousel3_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel3_VideoRight.mp4?${getSasKey()}`;

// const CFL_Carousel4_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel4_Avatar.png?${getSasKey()}`;
// const CFL_Carousel4_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel4_SquareImage1.png?${getSasKey()}`;
// const CFL_Carousel4_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel4_SquareImage2.png?${getSasKey()}`;
// const CFL_Carousel4_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel4_VerticalImage.png?${getSasKey()}`;
// // const CFL_Carousel4_VideoLeft = `${window.env.REACT_APP_AZURE_STORAGE_URL
// //   }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel4_VideoLeft.mp4?${localStorage.getItem(   "sasKey"   )}`;
// const CFL_Carousel4_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel4_VideoRight.mp4?${getSasKey()}`;

// const CFL_Carousel5_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel5_Avatar.png?${getSasKey()}`;
// const CFL_Carousel5_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel5_SquareImage1.png?${getSasKey()}`;
// const CFL_Carousel5_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel5_VerticalImage.png?${getSasKey()}`;
// const CFL_Carousel5_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel5_SquareImage2.png?${getSasKey()}`;
// const CFL_Carousel5_VideoLeft = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel5_VideoLeft.mp4?${getSasKey()}`;
// const CFL_Carousel5_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel5_VideoRight.mp4?${getSasKey()}`;

// //Proud Celebrators
// const PC_MeetThePeople_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/images/PC_MeetThePeople_front.jpg?${getSasKey()}`;
// const PC_MeetThePeople_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/images/PC_MeetThePeople_back.jpg?${getSasKey()}`;

// const PC_Carousel1_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel1_Avatar.png?${getSasKey()}`;
// const PC_Carousel1_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel1_SquareImage1.png?${getSasKey()}`;
// const PC_Carousel1_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel1_SquareImage2.png?${getSasKey()}`;
// const PC_Carousel1_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel1_VerticalImage.png?${getSasKey()}`;
// const PC_Carousel1_VideoLeft = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel1_VideoLeft.mp4?${getSasKey()}`;
// const PC_Carousel1_VideoLeft_Thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel1_VideoLeft_Thumbnail.jpg?${getSasKey()}`;
// const PC_Carousel1_VideoRight = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F61241220080f4a2a96017abe40e25f6e%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_31}&alt=media&optimized=true`;
// const PC_Carousel1_VideoRight_Thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel1_VideoRight_Thumbnail.jpg?${getSasKey()}`;

// const PC_Carousel2_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel2_Avatar.png?${getSasKey()}`;
// const PC_Carousel2_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel2_SquareImage1.png?${getSasKey()}`;
// const PC_Carousel2_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel2_VerticalImage.png?${getSasKey()}`;
// const PC_Carousel2_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel2_SquareImage2.png?${getSasKey()}`;
// const PC_Carousel2_VideoLeft = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel2_VideoLeft.mp4?${getSasKey()}`;
// const PC_Carousel2_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel2_VideoRight.mp4?${getSasKey()}`;

// const PC_Carousel3_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel3_Avatar.png?${getSasKey()}`;
// const PC_Carousel3_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel3_SquareImage1.png?${getSasKey()}`;
// const PC_Carousel3_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel3_SquareImage2.png?${getSasKey()}`;
// const PC_Carousel3_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel3_VerticalImage.png?${getSasKey()}`;
// const PC_Carousel3_VideoLeft = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel3_VideoLeft.mp4?${getSasKey()}`;
// const PC_Carousel3_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel3_VideoRight.mp4?${getSasKey()}`;

// const PC_Carousel4_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel4_Avatar.png?${getSasKey()}`;
// const PC_Carousel4_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel4_VerticalImage.png?${getSasKey()}`;
// const PC_Carousel4_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel4_SquareImage2.png?${getSasKey()}`;
// const PC_Carousel4_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel4_SquareImage1.png?${getSasKey()}`;
// const PC_Carousel4_VideoLeft = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel4_VideoLeft.mp4?${getSasKey()}`;
// const PC_Carousel4_VideoLeft_Thumbnail = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel4_VideoLeft_Thumbnail.jpg?${getSasKey()}`;
// const PC_Carousel4_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel4_VideoRight.mp4?${getSasKey()}`;

// const PC_Carousel5_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel5_Avatar.png?${getSasKey()}`;
// const PC_Carousel5_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel5_SquareImage1.png?${getSasKey()}`;
// const PC_Carousel5_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel5_SquareImage2.png?${getSasKey()}`;
// const PC_Carousel5_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel5_VerticalImage.png?${getSasKey()}`;
// const PC_Carousel5_VideoLeft = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel5_VideoLeft.mp4?${getSasKey()}`;
// const PC_Carousel5_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel5_VideoRight.mp4?${getSasKey()}`;

// //Creative Explorers
// const CE_MeetThePeople_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/images/CE_MeetThePeople_front.jpg?${getSasKey()}`;
// const CE_MeetThePeople_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/images/CE_MeetThePeople_back.jpg?${getSasKey()}`;

// const CE_Carousel1_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel1_Avatar.png?${getSasKey()}`;
// const CE_Carousel1_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel1_VerticalImage.png?${getSasKey()}`;
// const CE_Carousel1_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel1_SquareImage2.png?${getSasKey()}`;
// const CE_Carousel1_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel1_SquareImage1.png?${getSasKey()}`;
// const CE_Carousel1_VideoLeft = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/video/CE_Carousel1_VideoLeft.mp4?${getSasKey()}`;
// const CE_Carousel1_VideoRight = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fbe41326395e749d68e8d70b420e9d301%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_32}&alt=media&optimized=true`;

// const CE_Carousel2_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel2_Avatar.png?${getSasKey()}`;
// const CE_Carousel2_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel2_SquareImage1.png?${getSasKey()}`;
// const CE_Carousel2_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel2_SquareImage2.png?${getSasKey()}`;
// const CE_Carousel2_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel2_VerticalImage.png?${getSasKey()}`;
// const CE_Carousel2_VideoLeft = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F7123d8d515764c4082c5fa71c5e9e883%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_33}&alt=media&optimized=true`;
// const CE_Carousel2_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/video/CE_Carousel2_VideoRight.mp4?${getSasKey()}`;

// const CE_Carousel3_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel3_Avatar.png?${getSasKey()}`;
// const CE_Carousel3_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel3_SquareImage1.png?${getSasKey()}`;
// const CE_Carousel3_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel3_SquareImage2.png?${getSasKey()}`;
// const CE_Carousel3_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel3_VerticalImage.png?${getSasKey()}`;
// const CE_Carousel3_VideoLeft = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/video/CE_Carousel3_VideoLeft.mp4?${getSasKey()}`;
// const CE_Carousel3_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/video/CE_Carousel3_VideoRight.mp4?${getSasKey()}`;

// const CE_Carousel4_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel4_Avatar.png?${getSasKey()}`;
// const CE_Carousel4_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel4_SquareImage1.png?${getSasKey()}`;
// const CE_Carousel4_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel4_SquareImage2.png?${getSasKey()}`;
// // const CE_Carousel4_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
// //   }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel4_VerticalImage.png?${localStorage.getItem(   "sasKey"   )}`;
// const CE_Carousel4_VideoLeft = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/video/CE_Carousel4_VideoLeft.mp4?${getSasKey()}`;
// const CE_Carousel4_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/video/CE_Carousel4_VideoRight.mp4?${getSasKey()}`;

// const CE_Carousel5_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel5_Avatar.png?${getSasKey()}`;
// const CE_Carousel5_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel5_SquareImage2.png?${getSasKey()}`;
// const CE_Carousel5_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel5_VerticalImage.png?${getSasKey()}`;
// const CE_Carousel5_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel5_SquareImage1.png?${getSasKey()}`;
// const CE_Carousel5_VideoLeft = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/video/CE_Carousel5_VideoLeft.mp4?${getSasKey()}`;
// const CE_Carousel5_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/creative-explorers/video/CE_Carousel5_VideoRight.mp4?${getSasKey()}`;

// //Social Curators
// const SC_MeetThePeople_front = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/images/SC_MeetThePeople_front.jpg?${getSasKey()}`;
// const SC_MeetThePeople_back = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/images/SC_MeetThePeople_back.jpg?${getSasKey()}`;

// const SC_Carousel1_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel1_Avatar.png?${getSasKey()}`;
// const SC_Carousel1_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel1_SquareImage1.png?${getSasKey()}`;
// const SC_Carousel1_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel1_SquareImage2.png?${getSasKey()}`;
// const SC_Carousel1_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel1_VideoImage1.jpg?${getSasKey()}`;
// const SC_Carousel1_VideoImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel1_VerticalImage.png?${getSasKey()}`;
// const SC_Carousel1_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/video/SC_Carousel1_VideoRight.mp4?${getSasKey()}`;

// const SC_Carousel2_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel2_Avatar.png?${getSasKey()}`;
// const SC_Carousel2_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel2_SquareImage1.png?${getSasKey()}`;
// const SC_Carousel2_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel2_VerticalImage.png?${getSasKey()}`;
// const SC_Carousel2_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel2_SquareImage2.png?${getSasKey()}`;
// const SC_Carousel2_VideoLeft = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/video/SC_Carousel2_VideoLeft.mp4?${getSasKey()}`;
// const SC_Carousel2_VideoRight = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F939498076c454da7b4539bf2e192a874%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_34}&alt=media&optimized=true`;

// const SC_Carousel3_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel3_Avatar.png?${getSasKey()}`;
// const SC_Carousel3_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel3_SquareImage1.png?${getSasKey()}`;
// const SC_Carousel3_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel3_SquareImage2.png?${getSasKey()}`;
// const SC_Carousel3_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel3_VerticalImage.png?${getSasKey()}`;
// const SC_Carousel3_VideoImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel3_VideoImage1.jpg?${getSasKey()}`;
// const SC_Carousel3_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/video/SC_Carousel3_VideoRight.mp4?${getSasKey()}`;

// const SC_Carousel4_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel4_Avatar.png?${getSasKey()}`;
// const SC_Carousel4_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel4_SquareImage1.png?${getSasKey()}`;
// const SC_Carousel4_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel4_SquareImage2.png?${getSasKey()}`;
// const SC_Carousel4_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel4_VerticalImage.png?${getSasKey()}`;
// const SC_Carousel4_VideoLeft = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/video/SC_Carousel4_VideoLeft.mp4?${getSasKey()}`;
// const SC_Carousel4_VideoRight = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/video/SC_Carousel4_VideoRight.mp4?${getSasKey()}`;

// const SC_Carousel5_Avatar = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel5_Avatar.png?${getSasKey()}`;
// const SC_Carousel5_SquareImage1 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel5_SquareImage1.png?${getSasKey()}`;
// const SC_Carousel5_SquareImage2 = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel5_SquareImage2.png?${getSasKey()}`;
// const SC_Carousel5_VerticalImage = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel5_VerticalImage.png?${getSasKey()}`;
// const SC_Carousel5_VideoLeft = `${window.env.REACT_APP_AZURE_STORAGE_URL
//   }/people-segment/template/meet-the-people/social-curators/video/SC_Carousel5_VideoLeft.mp4?${getSasKey()}`;
// const SC_Carousel5_VideoRight = `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fc4206048d5274e91ac335a9569a75503%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_35}&alt=media&optimized=true`;

export const peopleSegmentAssets = {
  // Fram_PSGMT_2 : "Comfort Cravers",
  // Fram_PSGMT_3 : "Loyal Traditionalists",
  // Fram_PSGMT_5 : "Balanced Enjoyers",
  // Fram_PSGMT_6 : "Diligent Discoverer",
  // Fram_PSGMT_8 : "Carefree Fun Lovers",
  // Fram_PSGMT_9 : "Proud Celebrators",
  // Fram_PSGMT_11 : "Creative Explorers",
  // Fram_PSGMT_12 : "Social Curators",
  hubHeroImage: () =>
    `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/hub/images/PS-Hub-HeroImage.jpg?${getSasKey()}`,
  heroImages: {
    Fram_PSGMT_2: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/common/CC_Hero.jpg?${getSasKey()}`, //link to CC hero image
    Fram_PSGMT_3: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/common/LT_Hero.jpg?${getSasKey()}`, //link to LT hero image
    Fram_PSGMT_5: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/common/BE_Hero.jpg?${getSasKey()}`, //link to BE hero image
    Fram_PSGMT_6: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/common/DD_Hero.jpg?${getSasKey()}`, //link to DD hero image
    Fram_PSGMT_8: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/common/CFL_Hero.jpg?${getSasKey()}`, //link to CFL hero image
    Fram_PSGMT_9: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/common/PC_Hero.jpg?${getSasKey()}`, //link to PC hero image
    Fram_PSGMT_11: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/common/CE_Hero.jpg?${getSasKey()}`, //link to CE hero image
    Fram_PSGMT_12: () => `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/common/SC_Hero.jpg?${getSasKey()}` //link to SC hero image
  },
  whatsInPeopleSegment: {
    front: () =>
      `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/hub/images/whats-in-people-segment/PS_WhatsInPeopleSegment_front.jpg?${getSasKey()}`,
    back: () =>
      `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/hub/images/whats-in-people-segment/PS_WhatsInPeopleSegment_back.jpg?${getSasKey()}`
  },
  intro: {
    Fram_PSGMT_2: {
      //Comfort Cravers
      featuredPersonVideo: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/comfort-cravers/video/CC_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`,
      featuredPersonVideoThumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/comfort-cravers/video/CC_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`
    },
    Fram_PSGMT_3: {
      //Loyal Traditionalists
      featuredPersonVideo: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/loyal-traditionalists/video/LT_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`,
      featuredPersonVideoThumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/loyal-traditionalists/video/LT_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`
    },
    Fram_PSGMT_5: {
      //Balanced Enjoyers
      featuredPersonVideo: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/balanced-enjoyers/video/BE_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`,
      featuredPersonVideoThumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/balanced-enjoyers/video/BE_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`
    },
    Fram_PSGMT_6: {
      //Diligent Discoverer
      featuredPersonVideo: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/diligent-discoverers/video/DD_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`,
      featuredPersonVideoThumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/diligent-discoverers/video/DD_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`
    },
    Fram_PSGMT_8: {
      //Carefree Fun Lovers
      featuredPersonVideo: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/carefree-fun-lovers/video/CFL_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`,
      featuredPersonVideoThumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/carefree-fun-lovers/video/CFL_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`
    },
    Fram_PSGMT_9: {
      //Proud Celebrators
      featuredPersonVideo: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/proud-celebrators/video/PC_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`,
      featuredPersonVideoThumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/proud-celebrators/video/PC_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`
    },
    Fram_PSGMT_11: {
      //Creative Explorers
      featuredPersonVideo: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/creative-explorers/video/CE_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`,
      featuredPersonVideoThumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/creative-explorers/video/CE_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`
    },
    Fram_PSGMT_12: {
      //Social Curators
      featuredPersonVideo: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/social-curators/video/SC_FeaturedPerson_IntroVideo.mp4?${getSasKey()}`,
      featuredPersonVideoThumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/intro/social-curators/video/SC_FeaturedPerson_IntroVideo_Thumbnail.jpg?${getSasKey()}`
    }
  },
  whatDrivesThem: {
    Fram_PSGMT_2: {
      // Comfort Cravers
      front: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/images/CC_WhatDrivesThem_front.jpg?${getSasKey()}`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/images/CC_WhatDrivesThem_back.jpg?${getSasKey()}`,
      CC_Mod_PropensityToExplore_2: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Explore_2.jpg?${getSasKey()}`,
      CC_Mod_PropensityToExplore_3: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Explore_3.jpg?${getSasKey()}`,
      CC_Mod_PropensityToExplore_4: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Explore_4.jpg?${getSasKey()}`,
      CC_Mod_PropensityToExplore_5B: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Explore_5B.jpg?${getSasKey()}`,
      CC_Mod_PropensityToExplore_5C: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Explore_5C.jpg?${getSasKey()}`,
      CC_Mod_Health: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Health.jpg?${getSasKey()}`,
      CC_Mod_Outlook: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Outlook.jpg?${getSasKey()}`,
      CC_Mod_Sociability_2: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Sociability_2.jpg?${getSasKey()}`,
      CC_Mod_Sociability_3: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Sociability_3.jpg?${getSasKey()}`,
      CC_Mod_Sociability_4: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Sociability_4.jpg?${getSasKey()}`,
      CC_Mod_Sociability_5E: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Sociability_5E.jpg?${getSasKey()}`,
      CC_Mod_Spending: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Spending.jpg?${getSasKey()}`,
      CC_Mod_Status: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/images/CC_Mod-Status.jpg?${getSasKey()}`,
      CC_PropensityToExplore_5A_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/videos/CC_PropensityToExplore_5A.mp4?${getSasKey()}`,
      CC_Sociability_5A_Video: () =>
        `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F2c4ca2c6ebf54583833d9115e7e8245b%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_1}&alt=media&optimized=true`,
      CC_Sociability_5B_Video: () =>
        `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F8bf8e481f5a34865b541784daa3a040c%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_2}&alt=media&optimized=true`,
      CC_Sociability_5C_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/videos/CC_Sociability_5C.mp4?${getSasKey()}`,
      CC_Sociability_5D_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/videos/CC_Sociability_5D.mp4?${getSasKey()}`,
      CC_PropensityToExplore_5A_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/videos/CC_PropensityToExplore_5A_thumbnail.jpg?${getSasKey()}`,
      CC_Sociability_5A_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/videos/CC_Sociability_5A_thumbnail.jpg?${getSasKey()}`,
      CC_Sociability_5B_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/videos/CC_Sociability_5B_thumbnail.jpg?${getSasKey()}`,
      CC_Sociability_5C_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/videos/CC_Sociability_5C_thumbnail.jpg?${getSasKey()}`,
      CC_Sociability_5D_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/comfort-cravers/videos/CC_Sociability_5D_thumbnail.jpg?${getSasKey()}`
    },
    Fram_PSGMT_3: {
      //Loyal Traditionalists
      front: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_WhatDrivesThem_front.jpg?${getSasKey()}`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_WhatDrivesThem_back.jpg?${getSasKey()}`,
      LT_Mod_PropensityToExplore_2: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Explore_2.jpg?${getSasKey()}`,
      LT_Mod_PropensityToExplore_3: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Explore_3.jpg?${getSasKey()}`,
      LT_Mod_PropensityToExplore_4: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Explore_4.jpg?${getSasKey()}`,
      LT_Mod_PropensityToExplore_5D: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Explore_5D.jpg?${getSasKey()}`,
      LT_Mod_PropensityToExplore_5E: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Explore_5E.jpg?${getSasKey()}`,
      LT_Mod_Health: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Health.jpg?${getSasKey()}`,
      LT_Mod_Outlook: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Outlook.jpg?${getSasKey()}`,
      LT_Mod_Sociability_2: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Sociability_2.jpg?${getSasKey()}`,
      LT_Mod_Sociability_3: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Sociability_3.jpg?${getSasKey()}`,
      LT_Mod_Sociability_4: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Sociability_4.jpg?${getSasKey()}`,
      LT_Mod_Sociability_5B: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Sociability_5B.jpg?${getSasKey()}`,
      LT_Mod_Sociability_5C: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Sociability_5C.jpg?${getSasKey()}`,
      LT_Mod_Sociability_5D: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Sociability_5D.jpg?${getSasKey()}`,
      LT_Mod_Spending: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Spending.jpg?${getSasKey()}`,
      LT_Mod_Status: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/images/LT_Mod-Status.jpg?${getSasKey()}`,
      LT_PropensityToExplore_5A_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/videos/LT_PropensityToExplore_5A.mp4?${getSasKey()}`,
      LT_PropensityToExplore_5B_Video: () =>
        `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fb7c5f608fa694d389793103b67f84eee%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_3}&alt=media&optimized=true`,
      LT_PropensityToExplore_5C_Video: () =>
        `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F77a2dc05432e48dca69720789fa0cf3b%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_4}&alt=media&optimized=true`,
      LT_Sociability_5A_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/videos/LT_Sociability_5A.mp4?${getSasKey()}`,
      LT_PropensityToExplore_5A_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/videos/LT_PropensityToExplore_5A_thumbnail.jpg?${getSasKey()}`,
      LT_PropensityToExplore_5B_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/videos/LT_PropensityToExplore_5B_thumbnail.jpg?${getSasKey()}`,
      LT_PropensityToExplore_5C_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/videos/LT_PropensityToExplore_5C_thumbnail.jpg?${getSasKey()}`,
      LT_Sociability_5A_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/loyal-traditionalists/videos/LT_Sociability_5A_thumbnail.jpg?${getSasKey()}`
    },
    Fram_PSGMT_5: {
      //Balanced Enjoyers
      front: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_WhatDrivesThem_front.jpg?${getSasKey()}`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_WhatDrivesThem_back.jpg?${getSasKey()}`,
      BE_Mod_PropensityToExplore_2: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Explore_2.jpg?${getSasKey()}`,
      BE_Mod_PropensityToExplore_3: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Explore_3.jpg?${getSasKey()}`,
      BE_Mod_PropensityToExplore_4: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Explore_4.jpg?${getSasKey()}`,
      BE_Mod_PropensityToExplore_5C: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Explore_5C.jpg?${getSasKey()}`,
      BE_Mod_Health: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Health.jpg?${getSasKey()}`,
      BE_Mod_Outlook: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Outlook.jpg?${getSasKey()}`,
      BE_Mod_Sociability_2: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Sociability_2.jpg?${getSasKey()}`,
      BE_Mod_Sociability_3: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Sociability_3.jpg?${getSasKey()}`,
      BE_Mod_Sociability_4: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Sociability_4.jpg?${getSasKey()}`,
      BE_Mod_Sociability_5D: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Sociability_5D.jpg?${getSasKey()}`,
      BE_Mod_Spending: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Spending.jpg?${getSasKey()}`,
      BE_Mod_Status: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/images/BE_Mod-Status.jpg?${getSasKey()}`,
      BE_PropensityToExplore_5A_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/BE_PropensityToExplore_5A.mp4?${getSasKey()}`,
      BE_PropensityToExplore_5B_Video: () =>
        `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F1a69e80846cf45b39eb6ce33dcf7f9ce%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_5}&alt=media&optimized=true`,
      BE_PropensityToExplore_5D_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/BE_PropensityToExplore_5D.mp4?${getSasKey()}`,
      BE_Sociability_5A_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/BE_Sociability_5A.mp4?${getSasKey()}`,
      BE_Sociability_5B_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/BE_Sociability_5B.mp4?${getSasKey()}`,
      BE_Sociability_5C_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/Be_Sociability_5C.mp4?${getSasKey()}`,
      BE_PropensityToExplore_5A_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/BE_PropensityToExplore_5A_thumbnail.jpg?${getSasKey()}`,
      BE_PropensityToExplore_5B_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F849a090961c041f6987c9875fe88f97f`,
      BE_PropensityToExplore_5D_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/BE_PropensityToExplore_5D_thumbnail.jpg?${getSasKey()}`,
      BE_Sociability_5A_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/BE_Sociability_5A_thumbnail.jpg?${getSasKey()}`,
      BE_Sociability_5B_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/BE_Sociability_5B_thumbnail.jpg?${getSasKey()}`,
      BE_Sociability_5C_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/balanced-enjoyers/videos/BE_Sociability_5C_thumbnail.jpg?${getSasKey()}`
    },
    Fram_PSGMT_6: {
      //Diligent Discoverer
      front: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/images/DD_WhatDrivesThem_front.jpg?${getSasKey()}`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/images/DD_WhatDrivesThem_back.jpg?${getSasKey()}`,
      DD_Mod_PropensityToExplore_2: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Explore_2.jpg?${getSasKey()}`,
      DD_Mod_PropensityToExplore_3: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Explore_3.jpg?${getSasKey()}`,
      DD_Mod_PropensityToExplore_4: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Explore_4.jpg?${getSasKey()}`,
      DD_Mod_PropensityToExplore_5D: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Explore_5D.jpg?${getSasKey()}`,
      DD_Mod_PropensityToExplore_5E: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Explore_5E.jpg?${getSasKey()}`,
      DD_Mod_Health: () =>
        `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F31ceed3a02ef457bab78b45da48cff03`,
      DD_Mod_Outlook: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Outlook.jpg?${getSasKey()}`,
      DD_Mod_Sociability_2: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Sociability_2.jpg?${getSasKey()}`,
      DD_Mod_Sociability_3: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Sociability_3.jpg?${getSasKey()}`,
      DD_Mod_Sociability_4: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Sociability_4.jpg?${getSasKey()}`,
      DD_Mod_Sociability_5D: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Sociability_5D.jpg?${getSasKey()}`,
      DD_Mod_Sociability_5E: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Sociability_5E.jpg?${getSasKey()}`,
      DD_Mod_Spending: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Spending.jpg?${getSasKey()}`,
      DD_Mod_Status: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/images/DD_Mod-Status.jpg?${getSasKey()}`,
      DD_PropensityToExplore_5A_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_PropensityToExplore_5A.mp4?${getSasKey()}`,
      DD_PropensityToExplore_5B_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_PropensityToExplore_5B.mp4?${getSasKey()}`,
      DD_PropensityToExplore_5C_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_PropensityToExplore_5C.mp4?${getSasKey()}`,
      DD_Sociability_5A_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_Sociability_5A.mp4?${getSasKey()}`,
      DD_Sociability_5B_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_Sociability_5B.mp4?${getSasKey()}`,
      DD_Sociability_5C_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_Sociability_5C.mp4?${getSasKey()}`,
      DD_PropensityToExplore_5A_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_PropensityToExplore_5A_thumbnail.jpg?${getSasKey()}`,
      DD_PropensityToExplore_5B_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_PropensityToExplore_5B_thumbnail.jpg?${getSasKey()}`,
      DD_PropensityToExplore_5C_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_PropensityToExplore_5C_thumbnail.jpg?${getSasKey()}`,
      DD_Sociability_5A_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_Sociability_5A_thumbnail.jpg?${getSasKey()}`,
      DD_Sociability_5B_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_Sociability_5B_thumbnail.jpg?${getSasKey()}`,
      DD_Sociability_5C_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/diligent-discoverers/videos/DD_Sociability_5C_thumbnail.jpg?${getSasKey()}`
    },
    Fram_PSGMT_8: {
      //Carefree Fun Lovers
      front: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_WhatDrivesThem_front.jpg?${getSasKey()}`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_WhatDrivesThem_back.jpg?${getSasKey()}`,
      CFL_Mod_PropensityToExplore_2: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Explore-2.jpg?${getSasKey()}`,
      CFL_Mod_PropensityToExplore_3: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Explore-3.jpg?${getSasKey()}`,
      CFL_Mod_PropensityToExplore_4: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Explore-4.jpg?${getSasKey()}`,
      CFL_Mod_PropensityToExplore_5E: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Explore-5E.jpg?${getSasKey()}`,
      CFL_Mod_Health: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Health.jpg?${getSasKey()}`,
      CFL_Mod_Outlook: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Outlook.jpg?${getSasKey()}`,
      CFL_Mod_Sociability_2: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Sociability-2.jpg?${getSasKey()}`,
      CFL_Mod_Sociability_3: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Sociability-3.jpg?${getSasKey()}`,
      CFL_Mod_Sociability_4: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Sociability-4.jpg?${getSasKey()}`,
      CFL_Mod_Sociability_5D: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Sociability-5D.jpg?${getSasKey()}`,
      CFL_Mod_Sociability_5E: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Sociability-5E.jpg?${getSasKey()}`,
      CFL_Mod_Spending: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/images/CFL_Mod-Spending.jpg?${getSasKey()}`,
      CFL_Mod_Status: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_PropensityToExplore_5A.mp4?${getSasKey()}`,
      CFL_PropensityToExplore_5A_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_PropensityToExplore_5A.mp4?${getSasKey()}`,
      // CFL_PropensityToExplore_5B_Video: CFL_PropensityToExplore_5B_Video,
      CFL_PropensityToExplore_5C_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_PropensityToExplore_5D.mp4?${getSasKey()}`,
      CFL_PropensityToExplore_5D_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_PropensityToExplore_5D.mp4?${getSasKey()}`,
      CFL_Sociability_5A_Video: () =>
        `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fe71282aaad454a6fb66bfcc71994ec26%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_7}&alt=media&optimized=true`,
      CFL_Sociability_5B_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_Sociability_5B.mp4?${getSasKey()}`,
      CFL_Sociability_5C_Video: () =>
        `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Ff33e3ee2e765400c9f5abc04e4d1f4a1%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_8}&alt=media&optimized=true`,
      CFL_PropensityToExplore_5A_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_PropensityToExplore_5A_thumbnail.jpg?${getSasKey()}`,
      CFL_PropensityToExplore_5B_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_PropensityToExplore_5B_thumbnail.jpg?${getSasKey()}`,
      CFL_PropensityToExplore_5C_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_PropensityToExplore_5C_thumbnail.jpg?${getSasKey()}`,
      CFL_PropensityToExplore_5D_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_PropensityToExplore_5D_thumbnail.jpg?${getSasKey()}`,
      CFL_Sociability_5A_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_Sociability_5A_thumbnail.jpg?${getSasKey()}`,
      CFL_Sociability_5B_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_Sociability_5B_thumbnail.jpg?${getSasKey()}`,
      CFL_Sociability_5C_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/carefree-fun-lovers/videos/CFL_Sociability_5C_thumbnail.jpg?${getSasKey()}`
    },
    Fram_PSGMT_9: {
      //Proud Celebrators
      front: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/images/PC_WhatDrivesThem_front.jpg?${getSasKey()}`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/images/PC_WhatDrivesThem_back.jpg?${getSasKey()}`,
      PC_Mod_PropensityToExplore_2: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Explore_2.jpg?${getSasKey()}`,
      PC_Mod_PropensityToExplore_3: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Explore_3.jpg?${getSasKey()}`,
      PC_Mod_PropensityToExplore_4: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Explore_4.jpg?${getSasKey()}`,
      PC_Mod_PropensityToExplore_5E: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Explore_5E.jpg?${getSasKey()}`,
      PC_Mod_Health: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Health.jpg?${getSasKey()}`,
      PC_Mod_Outlook: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Outlook.jpg?${getSasKey()}`,
      PC_Mod_Sociability_2: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Sociability_2.jpg?${getSasKey()}`,
      PC_Mod_Sociability_3: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Sociability_3.jpg?${getSasKey()}`,
      PC_Mod_Sociability_4: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Sociability_4.jpg?${getSasKey()}`,
      PC_Mod_Sociability_5D: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Sociability_5D.jpg?${getSasKey()}`,
      PC_Mod_Sociability_5E: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Sociability_5E.jpg?${getSasKey()}`,
      PC_Mod_Spending: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Spending.jpg?${getSasKey()}`,
      PC_Mod_Status: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/images/PC_Mod-Status.jpg?${getSasKey()}`,
      PC_PropensityToExplore_5A_Video: () =>
        `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Ff72294abd4b94b5b8310fd8cf98a5f8f%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_9}&alt=media&optimized=true`,
      PC_PropensityToExplore_5B_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/videos/PC_PropenstiyToExplore_5B.mp4?${getSasKey()}`,
      PC_PropensityToExplore_5C_Video: () =>
        `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F0eef85836cea44c2a5ff836e698d347d%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_10}&alt=media&optimized=true`,
      PC_PropensityToExplore_5D_Video: () =>
        `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F07bddc2ccb6e44bc87d310849b9fae8c%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_11}&alt=media&optimized=true`,
      PC_Sociability_5A_Video: () =>
        `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fd61d25566c7a44a7adc983e898ded0c1%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_12}&alt=media&optimized=true`,
      PC_Sociability_5B_Video: () =>
        `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F6786725400214180976b32907749b290%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_13}&alt=media&optimized=true`,
      PC_Sociability_5C_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/videos/PC_Sociability_5C.mp4?${getSasKey()}`,
      PC_PropensityToExplore_5A_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/videos/PC_PropensityToExplore_5A_thumbnail.jpg?${getSasKey()}`,
      PC_PropensityToExplore_5B_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/videos/PC_PropensityToExplore_5B_thumbnail.jpg?${getSasKey()}`,
      PC_PropensityToExplore_5C_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/videos/PC_PropensityToExplore_5C_thumbnail.jpg?${getSasKey()}`,
      PC_PropensityToExplore_5D_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/videos/PC_PropensityToExplore_5D_thumbnail.jpg?${getSasKey()}`,
      PC_Sociability_5A_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/videos/PC_Sociability_5A_thumbnail.jpg?${getSasKey()}`,
      PC_Sociability_5B_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/videos/PC_Sociability_5B_thumbnail.jpg?${getSasKey()}`,
      PC_Sociability_5C_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/proud-celebrators/videos/PC_Sociability_5C_thumbnail.jpg?${getSasKey()}`
    },
    Fram_PSGMT_11: {
      //Creative Explorers
      front: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/images/CE_WhatDrivesThem_front.jpg?${getSasKey()}`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/images/CE_WhatDrivesThem_back.jpg?${getSasKey()}`,
      CE_Mod_PropensityToExplore_2: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Explore_2.jpg?${getSasKey()}`,
      CE_Mod_PropensityToExplore_3: () =>
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Explore_2.jpg?${getSasKey()}`,
      CE_Mod_PropensityToExplore_4: () =>
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Explore_4.jpg?${getSasKey()}`,
      CE_Mod_PropensityToExplore_5C: () =>
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Explore_5C.jpg?${getSasKey()}`,
      CE_Mod_PropensityToExplore_5D: () =>
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Explore_5D.jpg?${getSasKey()}`,
      CE_Mod_Health: () =>
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Health.jpg?${getSasKey()}`,
      CE_Mod_Outlook: () =>
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Outlook.jpg?${getSasKey()}`,
      CE_Mod_Sociability_2: () =>
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Sociability_2.jpg?${getSasKey()}`,
      CE_Mod_Sociability_3: () =>
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Sociability_2.jpg?${getSasKey()}`,
      CE_Mod_Sociability_4: () =>
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Sociability_4.jpg?${getSasKey()}`,
      CE_Mod_Spending: () =>
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Spending.jpg?${getSasKey()}`,
      CE_Mod_Status: () =>
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/what-drives-them/creative-explorers/images/CE_Mod-Status.jpg?${getSasKey()}`,
      CE_PropensityToExplore_5A_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/videos/CE_PropensityToExplore_5A.mp4?${getSasKey()}`,
      CE_PropensityToExplore_5B_Video: () =>
        `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fc35647ac7e2e44d789fa973e00b52cc2%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_14}&alt=media&optimized=true`,
      CE_Sociability_5A_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/videos/CE_Sociability_5A.mp4?${getSasKey()}`,
      CE_Sociability_5B_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/videos/CE_Sociability_5B.mp4?${getSasKey()}`,
      CE_Sociability_5C_Video: () =>
        `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F5403fca56296468fa413e3e1683a2ea5%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_14}&alt=media&optimized=true`,
      CE_Sociability_5D_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/videos/CE_Sociability_5D.mp4?${getSasKey()}`,
      CE_PropensityToExplore_5A_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/videos/CE_PropensityToExplore_5A_thumbnail.jpg?${getSasKey()}`,
      CE_PropensityToExplore_5B_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Feeef22e088234ee7bb4bcce00714d18c`,
      CE_Sociability_5A_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/videos/CE_Sociability_5A_thumbnail.jpg?${getSasKey()}`,
      CE_Sociability_5B_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/videos/CE_Sociability_5B_thumbnail.jpg?${getSasKey()}`,
      CE_Sociability_5C_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/videos/CE_Sociability_5C_thumbnail.jpg?${getSasKey()}`,
      CE_Sociability_5D_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/creative-explorers/videos/CE_Sociability_5D_thumbnail.jpg?${getSasKey()}`
    },
    Fram_PSGMT_12: {
      //Social Curators
      front: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/images/SC_WhatDrivesThem_front.jpg?${getSasKey()}`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/images/SC_WhatDrivesThem_back.jpg?${getSasKey()}`,
      SC_Mod_PropensityToExplore_2: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Explore_2.jpg?${getSasKey()}`,
      SC_Mod_PropensityToExplore_3: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Explore_3.jpg?${getSasKey()}`,
      SC_Mod_PropensityToExplore_4: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Explore_4.jpg?${getSasKey()}`,
      SC_Mod_PropensityToExplore_5D: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Explore_5D.jpg?${getSasKey()}`,
      SC_Mod_PropensityToExplore_5E: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Explore_5E.jpg?${getSasKey()}`,
      SC_Mod_Health: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Health.jpg?${getSasKey()}`,
      SC_Mod_Outlook: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Outlook.jpg?${getSasKey()}`,
      SC_Mod_Sociability_2: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Sociability_2.jpg?${getSasKey()}`,
      SC_Mod_Sociability_3: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Sociability_3.jpg?${getSasKey()}`,
      SC_Mod_Sociability_4: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Sociability_4.jpg?${getSasKey()}`,
      SC_Mod_Sociability_5E: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Sociability_5E.jpg?${getSasKey()}`,
      SC_Mod_Spending: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Spending.jpg?${getSasKey()}`,
      SC_Mod_Status: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/images/SC_Mod-Status.jpg?${getSasKey()}`,
      SC_PropensityToExplore_5A_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_PropensityToExplore_5A.mp4?${getSasKey()}`,
      SC_PropensityToExplore_5B_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_PropensityToExplore_5B.mp4?${getSasKey()}`,
      SC_PropensityToExplore_5C_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_PropensityToExplore_5C.mp4?${getSasKey()}`,
      SC_Sociability_5A_Video: () =>
        `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fc7e485a6f932404cbc777830ac213aad%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_16}&alt=media&optimized=true`,
      SC_Sociability_5B_Video: () =>
        `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F1055c7c718bb47db9bbaf69983262a7d%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_17}&alt=media&optimized=true`,
      SC_Sociability_5C_Video: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_Sociability_5C.mp4?${getSasKey()}`,
      SC_Sociability_5D_Video: () =>
        `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Ff08345d3dff34edca515fc5c60e32eb9%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_18}&alt=media&optimized=true`,
      SC_PropensityToExplore_5A_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_PropensityToExplore_5A_thumbnail.jpg?${getSasKey()}`,
      SC_PropensityToExplore_5B_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_PropensityToExplore_5B_thumbnail.jpg?${getSasKey()}`,
      SC_PropensityToExplore_5C_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_PropensityToExplore_5C_thumbnail.jpg?${getSasKey()}`,
      SC_Sociability_5A_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_Sociability_5A_thumbnail.jpg?${getSasKey()}`,
      SC_Sociability_5B_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_Sociability_5B_thumbnail.jpg?${getSasKey()}`,
      SC_Sociability_5C_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_Sociability_5C_thumbnail.jpg?${getSasKey()}`,
      SC_Sociability_5D_Video_thumbnail: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-drives-them/social-curators/videos/SC_Sociability_5D_thumbnail.jpg?${getSasKey()}`
    }
  },
  meetThePeople: {
    Fram_PSGMT_2: {
      // Comfort Cravers
      front: () =>
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/meet-the-people/comfort-cravers/images/CC_MeetThePeople_front.png?${localStorage.getItem('sasKey')}`,
      back: () =>
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/meet-the-people/comfort-cravers/images/CC_MeetThePeople_back.png?${localStorage.getItem('sasKey')}`,
      avatars: () => [
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel1_Avatar.png?${localStorage.getItem('sasKey')}`,
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel2_Avatar.png?${localStorage.getItem('sasKey')}`,
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel3_Avatar.png?${localStorage.getItem('sasKey')}`,
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel4_Avatar.png?${localStorage.getItem('sasKey')}`,
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel5_Avatar.png?${localStorage.getItem('sasKey')}`
      ],
      'carousel-0': {
        squareImages: () => [
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel1_SquareImage1.png?${localStorage.getItem('sasKey')}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel1_SquareImage2.png?${localStorage.getItem('sasKey')}`
        ],
        verticalImage: () =>
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel1_VerticalImage.png?${localStorage.getItem('sasKey')}`,
        videos: () => [
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/comfort-cravers/video/CC_Carousel1_VideoLeft.mp4?${localStorage.getItem('sasKey')}`,
          `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F0688c780ceb24b299b0d88975e634289%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_19}&alt=media&optimized=true`
        ]
      },
      'carousel-1': {
        squareImages: () => [
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel2_SquareImage2.png?${localStorage.getItem('sasKey')}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel2_VerticalImage.png?${localStorage.getItem('sasKey')}`
        ],
        verticalImage: () =>
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel2_SquareImage1.png?${localStorage.getItem('sasKey')}`,
        videos: () => [
          `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F6862740bc9814d66ae83e0b98f8eb86c%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_20}6862740bc9814d66ae83e0b98f8eb86c&alt=media&optimized=true`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/comfort-cravers/video/CC_Carousel2_VideoRight.mp4?${localStorage.getItem('sasKey')}`
        ]
      },
      'carousel-2': {
        squareImages: () => [
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel3_SquareImage1.png?${localStorage.getItem('sasKey')}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel3_VerticalImage.png?${localStorage.getItem('sasKey')}`
        ],
        verticalImage: () =>
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel3_SquareImage2.png?${localStorage.getItem('sasKey')}`,
        videos: () => [
          `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fa8eb3ce06cdd4173afa1d4d0217a5279%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_21}&alt=media&optimized=true`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/comfort-cravers/video/CC_Carousel3_VideoRight.mp4?${localStorage.getItem('sasKey')}`
        ]
      },
      'carousel-3': {
        squareImages: () => [
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel4_SquareImage1.png?${localStorage.getItem('sasKey')}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel4_SquareImage2.png?${localStorage.getItem('sasKey')}`
        ],
        verticalImage: () =>
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel4_VerticalImage.png?${localStorage.getItem('sasKey')}`,
        videos: () => [
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/comfort-cravers/video/CC_Carousel4_VideoLeft.mp4?${localStorage.getItem('sasKey')}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/comfort-cravers/video/CC_Carousel4_VideoRight.mp4?${localStorage.getItem('sasKey')}`
        ],
        thumbnails: () => [
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/comfort-cravers/video/CC_Carousel4_VideoLeft_Thumbnail.jpg?${localStorage.getItem('sasKey')}`,
          null
        ]
      },
      'carousel-4': {
        squareImages: () => [
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel5_SquareImage1.png?${localStorage.getItem('sasKey')}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel5_SquareImage2.png?${localStorage.getItem('sasKey')}`
        ],
        verticalImage: () =>
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/comfort-cravers/images/CC_Carousel5_VerticalImage.png?${localStorage.getItem('sasKey')}`,
        videos: () => [
          `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F139c8c2fba814282a328c59d1ee5f5b8%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_22}&alt=media&optimized=true`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/comfort-cravers/video/CC_Carousel5_VideoRight.mp4?${localStorage.getItem('sasKey')}`
        ]
      }
    },
    Fram_PSGMT_3: {
      // Loyal Traditionalists
      front: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_MeetThePeople_front.jpg?${getSasKey()}`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_MeetThePeople_back.jpg?${getSasKey()}`,
      avatars: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel1_Avatar.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel2_Avatar.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel3_Avatar.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel4_Avatar.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel5_Avatar.png?${getSasKey()}`
      ],
      'carousel-0': {
        squareImages: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel1_SquareImage1.png?${getSasKey()}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel1_SquareImage2.png?${getSasKey()}`
        ],
        verticalImage: () =>
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel1_VerticalImage.png?${getSasKey()}`,
        videos: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/loyal-traditionalists/video/LT_Carousel1_VideoLeft.mp4?${getSasKey()}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/loyal-traditionalists/video/LT_Carousel1_VideoRight.mp4?${getSasKey()}`
        ]
      },
      'carousel-1': {
        squareImages: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel2_SquareImage1.png?${getSasKey()}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel2_SquareImage2.png?${getSasKey()}`
        ],
        verticalImage: () =>
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel2_VerticalImage.png?${getSasKey()}`,
        videos: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/loyal-traditionalists/video/LT_Carousel2_VideoLeft.mp4?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fc21f511be0d847fb8bddb1ef076e9801%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_23}&alt=media&optimized=true`
        ]
      },
      'carousel-2': {
        squareImages: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel3_SquareImage1.png?${getSasKey()}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel3_SquareImage2.png?${getSasKey()}`
        ],
        verticalImage: () =>
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel3_VerticalImage.png?${getSasKey()}`,
        videos: () => [
          `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fbf849854e97f43329df3cdbb9c829126%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_24}&alt=media&optimized=true`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/loyal-traditionalists/video/LT_Carousel3_VideoRight.mp4?${getSasKey()}`
        ]
      },
      'carousel-3': {
        squareImages: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel4_SquareImage1.png?${getSasKey()}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel4_SquareImage2.png?${getSasKey()}`
        ],
        verticalImage: () =>
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel4_VerticalImage.png?${getSasKey()}`,
        videos: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/loyal-traditionalists/video/LT_Carousel4_VideoLeft.mp4?${getSasKey()}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/loyal-traditionalists/video/LT_Carousel4_VideoRight.mp4?${getSasKey()}`
        ]
      },
      'carousel-4': {
        squareImages: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel5_SquareImage1.png?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel5_SquareImage2.png?${getSasKey()}`
        ],
        verticalImage: () =>
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/loyal-traditionalists/images/LT_Carousel5_VerticalImage.png?${getSasKey()}`,
        videos: () => [
          `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fd6db68f947c94b73833480377f8218e5%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_25}&alt=media&optimized=true`,
          `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fad34e328e26440bcb484de530c406da1%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_26}&alt=media&optimized=true`
        ]
      }
    },
    Fram_PSGMT_5: {
      // Balanced Enjoyers
      front: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_MeetThePeople_front.jpg?${getSasKey()}`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_MeetThePeople_back.jpg?${getSasKey()}`,
      avatars: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel1_Avatar.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel2_Avatar.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel3_Avatar.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel4_Avatar.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel5_Avatar.png?${getSasKey()}`
      ],
      'carousel-0': {
        squareImages: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel1_SquareImage1.png?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel1_SquareImage2.png?${getSasKey()}`
        ],
        verticalImage: () =>
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel1_VerticalImage.png?${getSasKey()}`,
        videos: () => [
          `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fd5f20b99844d49a99d4b62437aa3dde0%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_27}&alt=media&optimized=true`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/balanced-enjoyers/video/BE_Carousel1_VideoRight.mp4?${getSasKey()}`
        ]
      },
      'carousel-1': {
        squareImages: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel2_SquareImage1.png?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_VIDEO_URL}/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F0b97590d535b4a4aa98b996162c881c9`
        ],
        verticalImage: () =>
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel2_VerticalImage.png?${getSasKey()}`,
        videos: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/balanced-enjoyers/video/BE_Carousel2_VideoLeft.mp4?${getSasKey()}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/balanced-enjoyers/video/BE_Carousel2_VideoRight.mp4?${getSasKey()}`
        ]
      },
      'carousel-2': {
        squareImages: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel3_SquareImage1.png?${getSasKey()}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel3_VerticalImage.png?${getSasKey()}`
        ],
        verticalImage: () =>
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel3_SquareImage2.png?${getSasKey()}`,
        videos: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/balanced-enjoyers/video/BE_Carousel3_VideoLeft.mp4?${getSasKey()}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/balanced-enjoyers/video/BE_Carousel3_VideoRight.mp4?${getSasKey()}`
        ]
      },
      'carousel-3': {
        squareImages: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel4_SquareImage1.png?${getSasKey()}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel4_SquareImage2.png?${getSasKey()}`
        ],
        verticalImage: () =>
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel4_VerticalImage.png?${getSasKey()}`,
        videos: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/balanced-enjoyers/video/BE_Carousel4_VideoLeft.mp4?${getSasKey()}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/balanced-enjoyers/video/BE_Carousel4_VideoRight.mp4?${getSasKey()}`
        ]
      },
      'carousel-4': {
        squareImages: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel5_SquareImage1.png?${getSasKey()}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel5_SquareImage2.png?${getSasKey()}`
        ],
        verticalImage: () =>
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/balanced-enjoyers/images/BE_Carousel5_VerticalImage.png?${getSasKey()}`,
        videos: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/balanced-enjoyers/video/BE_Carousel5_VideoLeft.mp4?${getSasKey()}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/balanced-enjoyers/video/BE_Carousel5_VideoRight.mp4?${getSasKey()}`
        ]
      }
    },
    Fram_PSGMT_6: {
      // Diligent Discoverers
      front: () =>
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_MeetThePeople_front.jpg?${localStorage.getItem('sasKey')}`,
      back: () =>
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_MeetThePeople_back.jpg?${localStorage.getItem('sasKey')}`,
      avatars: () => [
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel1_Avatar.png?${localStorage.getItem('sasKey')}`,
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel2_Avatar.png?${localStorage.getItem('sasKey')}`,
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel3_Avatar.png?${localStorage.getItem('sasKey')}`,
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel4_Avatar.png?${localStorage.getItem('sasKey')}`,
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel5_Avatar.png?${localStorage.getItem('sasKey')}`
      ],
      'carousel-0': {
        squareImages: () => [
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel1_SquareImage1.png?${localStorage.getItem('sasKey')}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel1_VerticalImage.png?${localStorage.getItem('sasKey')}`
        ],
        verticalImage: () =>
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel1_SquareImage2.png?${localStorage.getItem('sasKey')}`,
        videos: () => [
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/diligent-discoverers/video/DD_Carousel1_VideoLeft.mp4?${localStorage.getItem('sasKey')}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/diligent-discoverers/video/DD_Carousel1_VideoRight.mp4?${localStorage.getItem('sasKey')}`
        ]
      },
      'carousel-1': {
        squareImages: () => [
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel2_SquareImage1.png?${localStorage.getItem('sasKey')}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel2_SquareImage2.png?${localStorage.getItem('sasKey')}`
        ],
        verticalImage: () =>
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel2_VerticalImage.png?${localStorage.getItem('sasKey')}`,
        videos: () => [
          `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F9b4616800ff84a198fd52cf3fc386125%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_28}&alt=media&optimized=true`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/diligent-discoverers/video/DD_Carousel2_VideoRight.mp4?${localStorage.getItem('sasKey')}`
        ]
      },
      'carousel-2': {
        squareImages: () => [
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel3_VerticalImage.png?${localStorage.getItem('sasKey')}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel3_SquareImage2.png?${localStorage.getItem('sasKey')}`
        ],
        verticalImage: () =>
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel3_SquareImage1.png?${localStorage.getItem('sasKey')}`,
        videos: () => [
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/diligent-discoverers/video/DD_Carousel3_VideoLeft.mp4?${localStorage.getItem('sasKey')}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/diligent-discoverers/video/DD_Carousel3_VideoRight.mp4?${localStorage.getItem('sasKey')}`
        ]
      },
      'carousel-3': {
        squareImages: () => [
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel4_SquareImage1.png?${localStorage.getItem('sasKey')}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel4_VerticalImage.png?${localStorage.getItem('sasKey')}`
        ],
        verticalImage: () =>
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel4_SquareImage2.png?${localStorage.getItem('sasKey')}`,
        videos: () => [
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/diligent-discoverers/video/DD_Carousel4_VideoLeft.mp4?${localStorage.getItem('sasKey')}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/diligent-discoverers/video/DD_Carousel4_VideoRight.mp4?${localStorage.getItem('sasKey')}`
        ]
      },
      'carousel-4': {
        squareImages: () => [
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel5_SquareImage1.png?${localStorage.getItem('sasKey')}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel5_SquareImage2.png?${localStorage.getItem('sasKey')}`
        ],
        verticalImage: () =>
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/diligent-discoverers/images/DD_Carousel5_VerticalImage.png?${localStorage.getItem('sasKey')}`,
        videos: () => [
          `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fcb2d006bc5c848b5b4152511212bb70f%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_29}&alt=media&optimized=true`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/diligent-discoverers/video/DD_Carousel5_VideoRight.mp4?${localStorage.getItem('sasKey')}`
        ]
      }
    },
    Fram_PSGMT_8: {
      // Carefree Fun Lovers
      front: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_MeetThePeople_front.jpg?${localStorage.getItem('sasKey')}`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_MeetThePeople_back.jpg?${localStorage.getItem('sasKey')}`,
      avatars: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel1_Avatar.png?${localStorage.getItem('sasKey')}`,
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel2_Avatar.png?${localStorage.getItem('sasKey')}`,
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel3_Avatar.png?${localStorage.getItem('sasKey')}`,
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel4_Avatar.png?${localStorage.getItem('sasKey')}`,
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel5_Avatar.png?${localStorage.getItem('sasKey')}`
      ],
      'carousel-0': {
        squareImages: () => [
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel1_SquareImage1.png?${localStorage.getItem('sasKey')}`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel1_VerticalImage.png?${localStorage.getItem('sasKey')}`
        ],
        verticalImage: () =>
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel1_SquareImage2.png?${localStorage.getItem('sasKey')}`,
        videos: () => [
          `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fb562ececfccf47faae3b71befd6592ea%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_30}&alt=media&optimized=true`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel1_VideoRight.mp4?${localStorage.getItem('sasKey')}`
        ],
        thumbnails: () => [
          null,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel1_VideoRight_Thumbnail.jpg?${localStorage.getItem('sasKey')}`
        ]
      },
      'carousel-1': {
        squareImages: () => [
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel2_SquareImage1.png?${localStorage.getItem('sasKey')}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel2_SquareImage2.png?${localStorage.getItem('sasKey')}`
        ],
        verticalImage: () =>
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel2_VerticalImage.png?${localStorage.getItem('sasKey')}`,
        videos: () => [
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel2_VideoLeft.mp4?${localStorage.getItem('sasKey')}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel2_VideoRight.mp4?${localStorage.getItem('sasKey')}`
        ]
      },
      'carousel-2': {
        squareImages: () => [
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel3_SquareImage1.png?${localStorage.getItem('sasKey')}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel3_SquareImage2.png?${localStorage.getItem('sasKey')}`
        ],
        verticalImage: () =>
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel3_VerticalImage.png?${localStorage.getItem('sasKey')}`,
        videos: () => [
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel3_VideoLeft.mp4?${localStorage.getItem('sasKey')}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel3_VideoRight.mp4?${localStorage.getItem('sasKey')}`
        ]
      },
      'carousel-3': {
        squareImages: () => [
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel4_SquareImage1.png?${localStorage.getItem('sasKey')}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel4_SquareImage2.png?${localStorage.getItem('sasKey')}`
        ],
        verticalImage: () =>
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel4_VerticalImage.png?${localStorage.getItem('sasKey')}`,
        videos: () => [
          null,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel4_VideoRight.mp4?${localStorage.getItem('sasKey')}`
        ]
      },
      'carousel-4': {
        squareImages: () => [
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel5_SquareImage1.png?${localStorage.getItem('sasKey')}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel5_VerticalImage.png?${localStorage.getItem('sasKey')}`
        ],
        verticalImage: () =>
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/carefree-fun-lovers/images/CFL_Carousel5_SquareImage2.png?${localStorage.getItem('sasKey')}`,
        videos: () => [
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel5_VideoLeft.mp4?${localStorage.getItem('sasKey')}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/carefree-fun-lovers/video/CFL_Carousel5_VideoRight.mp4?${localStorage.getItem('sasKey')}`
        ]
      }
    },
    Fram_PSGMT_9: {
      // Proud Celebrators
      front: () =>
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/meet-the-people/proud-celebrators/images/PC_MeetThePeople_front.jpg?${getSasKey()}`,
      back: () =>
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/meet-the-people/proud-celebrators/images/PC_MeetThePeople_back.jpg?${getSasKey()}`,
      avatars: () => [
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel1_Avatar.png?${getSasKey()}`,
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel2_Avatar.png?${getSasKey()}`,
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel3_Avatar.png?${getSasKey()}`,
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel4_Avatar.png?${getSasKey()}`,
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel5_Avatar.png?${getSasKey()}`
      ],
      'carousel-0': {
        squareImages: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel1_SquareImage1.png?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel1_SquareImage2.png?${getSasKey()}`
        ],
        verticalImage: () =>
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel1_VerticalImage.png?${getSasKey()}`,
        videos: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel1_VideoLeft.mp4?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F61241220080f4a2a96017abe40e25f6e%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_31}&alt=media&optimized=true`
        ],
        thumbnails: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel1_VideoLeft_Thumbnail.jpg?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel1_VideoRight_Thumbnail.jpg?${getSasKey()}`
        ]
      },
      'carousel-1': {
        squareImages: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel2_SquareImage1.png?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel2_VerticalImage.png?${getSasKey()}`
        ],
        verticalImage: () =>
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel2_SquareImage2.png?${getSasKey()}`,
        videos: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel2_VideoLeft.mp4?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel2_VideoRight.mp4?${getSasKey()}`
        ]
      },
      'carousel-2': {
        squareImages: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel3_SquareImage1.png?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel3_SquareImage2.png?${getSasKey()}`
        ],
        verticalImage: () =>
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel3_VerticalImage.png?${getSasKey()}`,
        videos: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel3_VideoLeft.mp4?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel3_VideoRight.mp4?${getSasKey()}`
        ]
      },
      'carousel-3': {
        squareImages: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel4_VerticalImage.png?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel4_SquareImage2.png?${getSasKey()}`
        ],
        verticalImage: () =>
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel4_SquareImage1.png?${getSasKey()}`,
        videos: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel4_VideoLeft.mp4?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel4_VideoRight.mp4?${getSasKey()}`
        ],
        thumbnails: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel4_VideoLeft_Thumbnail.jpg?${getSasKey()}`,
          null
        ]
      },
      'carousel-4': {
        squareImages: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel5_SquareImage1.png?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel5_SquareImage2.png?${getSasKey()}`
        ],
        verticalImage: () =>
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/proud-celebrators/images/PC_Carousel5_VerticalImage.png?${getSasKey()}`,
        videos: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel5_VideoLeft.mp4?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/proud-celebrators/video/PC_Carousel5_VideoRight.mp4?${getSasKey()}`
        ]
      }
    },
    Fram_PSGMT_11: {
      // Creative Explorers
      front: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/images/CE_MeetThePeople_front.jpg?${getSasKey()}`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/images/CE_MeetThePeople_back.jpg?${getSasKey()}`,
      avatars: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel1_Avatar.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel2_Avatar.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel3_Avatar.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel4_Avatar.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel5_Avatar.png?${getSasKey()}`
      ],
      'carousel-0': {
        squareImages: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel1_VerticalImage.png?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel1_SquareImage2.png?${getSasKey()}`
        ],
        verticalImage: () =>
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel1_SquareImage1.png?${getSasKey()}`,
        videos: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/video/CE_Carousel1_VideoLeft.mp4?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fbe41326395e749d68e8d70b420e9d301%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_32}&alt=media&optimized=true`
        ]
      },
      'carousel-1': {
        squareImages: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel2_SquareImage1.png?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel2_SquareImage2.png?${getSasKey()}`
        ],
        verticalImage: () =>
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel2_VerticalImage.png?${getSasKey()}`,
        videos: () => [
          `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F7123d8d515764c4082c5fa71c5e9e883%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_33}&alt=media&optimized=true`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/video/CE_Carousel2_VideoRight.mp4?${getSasKey()}`
        ]
      },
      'carousel-2': {
        squareImages: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel3_SquareImage1.png?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel3_SquareImage2.png?${getSasKey()}`
        ],
        verticalImage: () =>
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel3_VerticalImage.png?${getSasKey()}`,
        videos: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/video/CE_Carousel3_VideoLeft.mp4?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/video/CE_Carousel3_VideoRight.mp4?${getSasKey()}`
        ]
      },
      'carousel-3': {
        squareImages: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel4_SquareImage1.png?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel4_SquareImage2.png?${getSasKey()}`
        ],
        verticalImage: () => '',
        videos: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/video/CE_Carousel4_VideoLeft.mp4?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/video/CE_Carousel4_VideoRight.mp4?${getSasKey()}`
        ]
      },
      'carousel-4': {
        squareImages: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel5_SquareImage2.png?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel5_VerticalImage.png?${getSasKey()}`
        ],
        verticalImage: () =>
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/images/CE_Carousel5_SquareImage1.png?${getSasKey()}`,
        videos: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/video/CE_Carousel5_VideoLeft.mp4?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/creative-explorers/video/CE_Carousel5_VideoRight.mp4?${getSasKey()}`
        ]
      }
    },
    Fram_PSGMT_12: {
      // Social Curators
      front: () =>
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/meet-the-people/social-curators/images/SC_MeetThePeople_front.jpg?${getSasKey()}`,
      back: () =>
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/meet-the-people/social-curators/images/SC_MeetThePeople_back.jpg?${getSasKey()}`,
      avatars: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/social-curators/images/SC_Carousel1_Avatar.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/social-curators/images/SC_Carousel2_Avatar.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/social-curators/images/SC_Carousel3_Avatar.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/social-curators/images/SC_Carousel4_Avatar.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/social-curators/images/SC_Carousel5_Avatar.png?${getSasKey()}`
      ],
      'carousel-0': {
        squareImages: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/social-curators/images/SC_Carousel1_SquareImage1.png?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/social-curators/images/SC_Carousel1_SquareImage2.png?${getSasKey()}`
        ],
        verticalImage: () =>
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/social-curators/images/SC_Carousel1_VideoImage1.jpg?${getSasKey()}`,
        videos: () => [
          '',
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/social-curators/video/SC_Carousel1_VideoRight.mp4?${getSasKey()}`
        ],
        videoImage: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/social-curators/images/SC_Carousel1_VerticalImage.png?${getSasKey()}`
        ]
      },
      'carousel-1': {
        squareImages: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/social-curators/images/SC_Carousel2_SquareImage1.png?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/social-curators/images/SC_Carousel2_VerticalImage.png?${getSasKey()}`
        ],
        verticalImage: () =>
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel2_SquareImage2.png?${getSasKey()}`,
        videos: () => [
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/social-curators/video/SC_Carousel2_VideoLeft.mp4?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F939498076c454da7b4539bf2e192a874%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_34}&alt=media&optimized=true`
        ]
      },
      'carousel-2': {
        squareImages: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/social-curators/images/SC_Carousel3_SquareImage1.png?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/social-curators/images/SC_Carousel3_SquareImage2.png?${getSasKey()}`
        ],
        verticalImage: () =>
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/social-curators/images/SC_Carousel3_VerticalImage.png?${getSasKey()}`,
        videos: () => [
          '',
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/social-curators/video/SC_Carousel3_VideoRight.mp4?${getSasKey()}`
        ],
        videoImage: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/social-curators/images/SC_Carousel3_VideoImage1.jpg?${getSasKey()}`
        ]
      },
      'carousel-3': {
        squareImages: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/social-curators/images/SC_Carousel4_SquareImage1.png?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/social-curators/images/SC_Carousel4_SquareImage2.png?${getSasKey()}`
        ],
        verticalImage: () =>
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/social-curators/images/SC_Carousel4_VerticalImage.png?${getSasKey()}`,
        videos: () => [
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/social-curators/video/SC_Carousel4_VideoLeft.mp4?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/meet-the-people/social-curators/video/SC_Carousel4_VideoRight.mp4?${getSasKey()}`
        ]
      },
      'carousel-4': {
        squareImages: () => [
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel5_SquareImage1.png?${getSasKey()}`,
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel5_SquareImage2.png?${getSasKey()}`
        ],
        verticalImage: () =>
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/social-curators/images/SC_Carousel5_VerticalImage.png?${getSasKey()}`,
        videos: () => [
          `${
            window.env.REACT_APP_AZURE_STORAGE_URL
          }/people-segment/template/meet-the-people/social-curators/video/SC_Carousel5_VideoLeft.mp4?${getSasKey()}`,
          `${window.env.REACT_APP_AZURE_VIDEO_URL}/o/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fc4206048d5274e91ac335a9569a75503%2Fcompressed?apiKey=${window.env.REACT_APP_AZURE_VIDEO_URL_ID}&token=${window.env.REACT_APP_AZURE_VIDEO_URL_ID_35}&alt=media&optimized=true`
        ]
      }
    }
  },
  consumption: {
    Fram_PSGMT_2: {
      // Comfort Cravers
      front: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/comfort-cravers/images/CC_WhatTheyDrink_front.jpg?${getSasKey()}`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/comfort-cravers/images/CC_WhatTheyDrink_back.jpg?${getSasKey()}`,
      carouselImages: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/comfort-cravers/images/CC_WhatTheyDrink_Carousel1_Image.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/comfort-cravers/images/CC_WhatTheyDrink_Carousel2_Image.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/comfort-cravers/images/CC_WhatTheyDrink_Carousel3_Image.png?${getSasKey()}`
      ],
      audio: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/comfort-cravers/images/CC_WhatTheyDrink_Carousel3_Image.png?${getSasKey()}`,
      videos: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/comfort-cravers/video/CC_WhatTheyDrink_Video1.mp4?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/comfort-cravers/video/CC_WhatTheyDrink_Video2.mp4?${getSasKey()}`
      ]
    },
    Fram_PSGMT_3: {
      // Loyal Traditionalists
      front: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/loyal-traditionalists/images/LT_WhatTheyDrink_front.jpg?${getSasKey()}`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/loyal-traditionalists/images/LT_WhatTheyDrink_back.jpg?${getSasKey()}`,
      carouselImages: () => [
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/what-they-drink/loyal-traditionalists/images/LT_WhatTheyDrink_Carousel1_Image.png?${getSasKey()}`,
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/what-they-drink/loyal-traditionalists/images/LT_WhatTheyDrink_Carousel2_Image.png?${getSasKey()}`,
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/what-they-drink/loyal-traditionalists/images/LT_WhatTheyDrink_Carousel3_Image.png?${getSasKey()}`
      ],
      audio: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/loyal-traditionalists/audio/LT_WhatTheyDrink_Audio.mp3?${getSasKey()}`,
      videos: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/loyal-traditionalists/video/LT_WhatTheyDrink_Video1.mp4?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/loyal-traditionalists/video/LT_WhatTheyDrink_Video2.mp4?${getSasKey()}`
      ]
    },
    Fram_PSGMT_5: {
      //Balanced Enjoyers
      front: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/balanced-enjoyers/images/BE_WhatTheyDrink_front.jpg?${getSasKey()}`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/balanced-enjoyers/images/BE_WhatTheyDrink_back.jpg?${getSasKey()}`,
      carouselImages: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/balanced-enjoyers/images/BE_WhatTheyDrink_Carousel1_Image.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/balanced-enjoyers/images/BE_WhatTheyDrink_Carousel2_Image.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/balanced-enjoyers/images/BE_WhatTheyDrink_Carousel3_Image.png?${getSasKey()}`
      ],
      audio: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/balanced-enjoyers/audio/BE_WhatTheyDrink_Audio.mp3?${getSasKey()}`,
      videos: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/balanced-enjoyers/video/BE_WhatTheyDrink_Video1.mp4?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/balanced-enjoyers/video/BE_WhatTheyDrink_Video2.mp4?${getSasKey()}`
      ]
    },
    Fram_PSGMT_6: {
      // "Diligent Discoverer",
      front: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/diligent-discoverers/images/DD_WhatTheyDrink_front.jpg?${getSasKey()}`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/diligent-discoverers/images/DD_WhatTheyDrink_back.jpg?${localStorage.getItem('sasKey')}`,
      carouselImages: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/diligent-discoverers/images/DD_WhatTheyDrink_Carousel1_Image.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/diligent-discoverers/images/DD_WhatTheyDrink_Carousel2_Image.png?${localStorage.getItem('sasKey')}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/diligent-discoverers/images/DD_WhatTheyDrink_Carousel3_Image.png?${getSasKey()}`
      ],
      audio: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/diligent-discoverers/audio/DD_WhatTheyDrink_Audio.mp3?${getSasKey()}`,
      videos: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/diligent-discoverers/video/DD_WhatTheyDrink_Video1.mp4?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/diligent-discoverers/video/DD_WhatTheyDrink_Video2.mp4?${getSasKey()}`
      ]
    },
    Fram_PSGMT_8: {
      // "Carefree Fun Lovers",
      front: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/carefree-fun-lovers/images/CFL_WhatTheyDrink_front.jpg?${getSasKey()}`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/carefree-fun-lovers/images/CFL_WhatTheyDrink_back.jpg?${getSasKey()}`,
      carouselImages: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/carefree-fun-lovers/images/CFL_WhatTheyDrink_Carousel1_Image.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/carefree-fun-lovers/images/CFL_WhatTheyDrink_Carousel2_Image.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/carefree-fun-lovers/images/CFL_WhatTheyDrink_Carousel3_Image.png?${getSasKey()}`
      ],
      audio: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/carefree-fun-lovers/audio/CFL_WhatTheyDrink_Audio.mp3?${getSasKey()}`,
      videos: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/carefree-fun-lovers/video/CFL_WhatTheyDrink_Video1.mp4?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/carefree-fun-lovers/video/CFL_WhatTheyDrink_Video2.mp4?${getSasKey()}`
      ]
    },
    Fram_PSGMT_9: {
      // "Proud Celebrators",
      front: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/proud-celebrators/images/PC_WhatTheyDrink_front.jpg?${getSasKey()}`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/proud-celebrators/images/PC_WhatTheyDrink_back.jpg?${getSasKey()}`,
      carouselImages: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/proud-celebrators/images/PC_WhatTheyDrink_Carousel1_Image.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/proud-celebrators/images/PC_WhatTheyDrink_Carousel2_Image.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/proud-celebrators/images/PC_WhatTheyDrink_Carousel3_Image.png?${getSasKey()}`
      ],
      audio: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/proud-celebrators/audio/PC_WhatTheyDrink_Audio.mp3?${getSasKey()}`,
      videos: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/proud-celebrators/video/PC_WhatTheyDrink_Video1.mp4?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/proud-celebrators/video/PC_WhatTheyDrink_Video2.mp4?${getSasKey()}`
      ]
    },
    Fram_PSGMT_11: {
      // "Creative Explorers",
      front: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/creative-explorers/images/CE_WhatTheyDrink_front.jpg?${getSasKey()}`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/creative-explorers/images/CE_WhatTheyDrink_back.jpg?${getSasKey()}`,
      carouselImages: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/creative-explorers/images/CE_WhatTheyDrink_Carousel1_Image.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/creative-explorers/images/CE_WhatTheyDrink_Carousel2_Image.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/creative-explorers/images/CE_WhatTheyDrink_Carousel3_Image.png?${getSasKey()}`
      ],
      audio: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/creative-explorers/audio/CE_WhatTheyDrink_Audio.mp3?${getSasKey()}`,
      videos: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/creative-explorers/video/CE_WhatTheyDrink_Video1.mp4?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/creative-explorers/video/CE_WhatTheyDrink_Video2.mp4?${getSasKey()}`
      ]
    },
    Fram_PSGMT_12: {
      // "Social Curators",
      front: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/social-curators/images/SC_WhatTheyDrink_front.jpg?${getSasKey()}`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/social-curators/images/SC_WhatTheyDrink_back.jpg?${getSasKey()}`,
      carouselImages: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/social-curators/images/SC_WhatTheyDrink_Carousel1_Image.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/social-curators/images/SC_WhatTheyDrink_Carousel2_Image.png?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/social-curators/images/SC_WhatTheyDrink_Carousel3_Image.png?${getSasKey()}`
      ],
      audio: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/social-curators/audio/SC_WhatTheyDrink_Audio.mp3?${getSasKey()}`,
      videos: () => [
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/social-curators/video/SC_WhatTheyDrink_Video1.mp4?${getSasKey()}`,
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/what-they-drink/social-curators/video/SC_WhatTheyDrink_Video2.mp4?${getSasKey()}`
      ]
    }
  },
  demographics: {
    Fram_PSGMT_2: {
      //Comfort Cravers
      front: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/demographics/comfort-cravers/images/CC_Demographics_front.jpg?${getSasKey()}`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/demographics/comfort-cravers/images/CC_Demographics_back.jpg?${getSasKey()}`
    },
    Fram_PSGMT_3: {
      //Loyal Traditionalists
      front: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/demographics/loyal-traditionalists/images/LT_Demographics_front.jpg?${getSasKey()}`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/demographics/loyal-traditionalists/images/LT_Demographics_back.jpg?${getSasKey()}`
    },
    Fram_PSGMT_5: {
      //Balanced Enjoyers
      front: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/demographics/balanced-enjoyers/images/BE_Demographics_front.jpg?${getSasKey()}`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/demographics/balanced-enjoyers/images/BE_Demographics_back.jpg?${getSasKey()}`
    },
    Fram_PSGMT_6: {
      //Diligent Discoverer
      front: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/demographics/diligent-discoverers/images/DD_Demographics_front.jpg?${getSasKey()}`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/demographics/diligent-discoverers/images/DD_Demographics_back.jpg?${getSasKey()}`
    },
    Fram_PSGMT_8: {
      //Carefree Fun Lovers
      front: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/demographics/carefree-fun-lovers/images/CFL_Demographics_front.jpg?${getSasKey()}`,
      back: () =>
        `${window.env.REACT_APP_AZURE_STORAGE_URL}/people-segment/template/demographics/carefree-fun-lovers/images/CFL_Demographics_back.jpg?${getSasKey()}`
    },
    Fram_PSGMT_9: {
      //Proud Celebrators
      front: () =>
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/demographics/proud-celebrators/images/PC_Demographics_front.jpg?${getSasKey()}`,
      back: () =>
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/demographics/proud-celebrators/images/PC_Demographics_back.jpg?${getSasKey()}`
    },
    Fram_PSGMT_11: {
      //Creative Explorers
      front: () =>
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/demographics/creative-explorers/images/CE_Demographics_front.jpg?${getSasKey()}`,
      back: () =>
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/demographics/creative-explorers/images/CE_Demographics_back.jpg?${getSasKey()}`
    },
    Fram_PSGMT_12: {
      //Social Curators
      front: () =>
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/demographics/social-curators/images/SC_Demographics_front.jpg?${getSasKey()}`,
      back: () =>
        `${
          window.env.REACT_APP_AZURE_STORAGE_URL
        }/people-segment/template/demographics/social-curators/images/SC_Demographics_back.jpg?${getSasKey()}`
    }
  }
};
