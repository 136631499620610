import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { CategoryCard } from './compound-components/CategoryCard';
import styles from './AdditionalCategories.module.css';

const spiritsData = {
  icon: 'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F8ea710a0bf974f5a9ea6545669661778'
};

const wineData = {
  icon: 'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fce2fe39f43004f489ffbf7871a881539'
};

export const AdditionalCategories = () => {
  const { t } = useTranslation(i18next.language);

  return (
    <div className={styles.cardContainer}>
      <div className={styles.categoryContainer}>
        <div className={styles.headerSection}>
          <h2 className={styles.mainTitle}>{t('categoryFramework.additionalCategories.mainTitle')}</h2>
          <p className={styles.description}>{t('categoryFramework.additionalCategories.description')}</p>
        </div>
        <div className={styles.cardsSection}>
          <CategoryCard
            title={t('categoryFramework.additionalCategories.spirits.title')}
            icon={spiritsData.icon}
            categories={t('categoryFramework.additionalCategories.spirits.categories', { returnObjects: true })}
            variant="blue"
          />
          <CategoryCard
            title={t('categoryFramework.additionalCategories.wine.title')}
            icon={wineData.icon}
            categories={t('categoryFramework.additionalCategories.wine.categories', { returnObjects: true })}
            variant="maroon"
          />
        </div>
      </div>
    </div>
  );
};
