import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { COLORS } from '../../../../constants/CatTripColors';
import { getAccentTextColor } from '../../../../constants/GetAccentTextColor';
import { formatSegmentTitle } from '../../../../constants/FormatSegmentTitle';
import StatBlock from './compound-components/StatBlock';
import SegmentTable from './compound-components/SegmentTable';
import PackSizeTable from './compound-components/PackSizeTable';
import PriceTierTable from './compound-components/PriceTierTable';
import styles from './BasketCategoryStats.module.css';

export const BasketCategoryStats = ({
  tripTypeColor,
  statCardData,
  segmentTableData,
  priceTierTableData,
  packSizeTableData,
  statCardData2,
  segmentTableData2,
  packSizeTableData2
}) => {
  const CATEGORY = Object.freeze({
    BEER: 'BEER',
    HARD_BEV: 'HARD_BEV'
  });

  const [selectedCategory, setSelectedCategory] = useState(CATEGORY.BEER);
  const accentTextColor = getAccentTextColor(tripTypeColor);
  const segmentTitle = formatSegmentTitle(selectedCategory);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  function getSelectedSegmentData(category) {
    const segmentData = {
      BEER: {
        statCardData: statCardData,
        segmentTableData: segmentTableData,
        packSizeTableData: packSizeTableData,
        priceTierTableData: priceTierTableData
      },
      HARD_BEV: {
        statCardData: statCardData2,
        segmentTableData: segmentTableData2,
        packSizeTableData: packSizeTableData2
      }
    };
    return segmentData[category] || segmentData[CATEGORY.BEER];
  }

  const selectedSegmentData = getSelectedSegmentData(selectedCategory);
  const { t } = useTranslation(i18next.language);

  return (
    <div className={styles.categories}>
      <div className={styles.buttonGroup}>
        <button
          onClick={() => handleCategoryClick(CATEGORY.BEER)}
          className={selectedCategory === CATEGORY.BEER ? styles.activeButton : styles.inactiveButton}
        >
          {t('tripTypesPageTemplate.basketCategoryStats.dataToggle.beerButtonCopy')}
        </button>
        <button
          onClick={() => handleCategoryClick(CATEGORY.HARD_BEV)}
          className={selectedCategory === CATEGORY.HARD_BEV ? styles.activeButton : styles.inactiveButton}
        >
          {t('tripTypesPageTemplate.basketCategoryStats.dataToggle.hardBevButtonCopy')}
        </button>
      </div>
      <div className={styles.statGroup}>
        <StatBlock statCardData={selectedSegmentData.statCardData} />
        <SegmentTable
          segmentTitle={segmentTitle}
          segmentTableData={selectedSegmentData.segmentTableData}
          tripTypeColor={COLORS[tripTypeColor]}
          accentTextColor={accentTextColor}
        />
        <div className={styles.tableContainer}>
          <PackSizeTable
            packSizeTableData={selectedSegmentData.packSizeTableData}
            tripTypeColor={COLORS[tripTypeColor]}
            accentTextColor={accentTextColor}
          />
          {selectedSegmentData.priceTierTableData && (
            <PriceTierTable
              priceTierTableData={selectedSegmentData.priceTierTableData}
              tripTypeColor={COLORS[tripTypeColor]}
              accentTextColor={accentTextColor}
            />
          )}
        </div>
      </div>
    </div>
  );
};

BasketCategoryStats.propTypes = {
  tripTypeColor: PropTypes.string.isRequired,
  statCardData: PropTypes.object.isRequired,
  segmentTableData: PropTypes.array.isRequired,
  priceTierTableData: PropTypes.array.isRequired,
  packSizeTableData: PropTypes.array.isRequired,
  statCardData2: PropTypes.object.isRequired,
  segmentTableData2: PropTypes.array.isRequired,
  packSizeTableData2: PropTypes.array.isRequired
};
