import React from 'react';
import PropTypes from 'prop-types';
import { CalloutSection } from './CalloutSection';
import { QuoteContent } from './QuoteContent';
import styles from './TestimonialCard.module.css';

export const TestimonialCard = ({ quote, name, age, sex, location, callout, imageSrc, cardAlignment, cardWidth }) => {
  return (
    <div
      className={styles.testimonialCard}
      data-testid="testimonial-card"
      style={{
        minWidth: cardWidth === 'FULL_WIDTH' ? '1224px' : '540px'
      }}
    >
      {cardAlignment === 'IMAGE_RIGHT' && (
        <>
          <QuoteContent quote={quote} name={name} age={age} sex={sex} location={location} cardWidth={cardWidth} />
          <CalloutSection imageSrc={imageSrc} callout={callout} cardWidth={cardWidth} />
        </>
      )}
      {cardAlignment === 'IMAGE_LEFT' && (
        <>
          <CalloutSection imageSrc={imageSrc} callout={callout} cardWidth={cardWidth} />
          <QuoteContent quote={quote} name={name} age={age} sex={sex} location={location} cardWidth={cardWidth} />
        </>
      )}
    </div>
  );
};

TestimonialCard.propTypes = {
  quote: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  age: PropTypes.string.isRequired,
  sex: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  callout: PropTypes.string,
  imageSrc: PropTypes.string.isRequired,
  cardAlignment: PropTypes.string.isRequired,
  cardWidth: PropTypes.string.isRequired
};
