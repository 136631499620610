import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import styles from './DescriptionBox.module.css';

export const DescriptionBox = ({ children, titleWidth, ...props }) => {
  const { t } = useTranslation(i18next.language);

  return (
    <div className={styles.description} {...props}>
      <div className={styles.descriptionTitle} style={{ width: titleWidth }}>
        {t('tripTypesPageTemplate.tripDuration.descriptionBoxTitle')}
      </div>
      <div className={styles.descriptionText}>{children}</div>
    </div>
  );
};

DescriptionBox.propTypes = {
  children: PropTypes.node.isRequired,
  titleWidth: PropTypes.string.isRequired
};
