import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import AuthDenialModal from './AuthDenialModal';

function ProtectedLayout() {
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [deniedSegment, setDeniedSegment] = useState('');

  return (
    <>
      <AuthDenialModal
        open={showAuthModal}
        setOpen={setShowAuthModal}
        modalTitle="ACCESS LIMITATION:"
        modalCopy={`HUE frameworks are proprietary company information, intended for limited internal audiences. Currently, ${deniedSegment} is not available to your user group. Please reach out to your relevant CIC partner if you believe this is an error or to learn about what other resources are available.`}
      />
      <Outlet context={{ setShowAuthModal, setDeniedSegment }} />
    </>
  );
}

export default ProtectedLayout;
