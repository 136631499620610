import { Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useParams } from 'react-router-dom';
import ScopeBadge from '../../custom/Badges/ScopeBadge';
import Runner from '../../custom/Runner';
import { peopleSegmentAssets } from '../../common/PeopleSegmentAssets';
import WayFinderPeopleTemplate from '../../common/page-sections/WayFinderPeopleTemplate';

export default function SectionZero({ sectionData }) {
  const { t } = useTranslation(i18next.language);

  const { microSegment } = useParams();

  return (
    <>
      <Box
        className="persona-bg-img"
        sx={{
          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url(${peopleSegmentAssets.heroImages[microSegment]()})`
        }}
      >
        <Stack direction="column" spacing={5} className="hero-content-container">
          <Box className={`hero-wayfinder ${microSegment}`}>
            <WayFinderPeopleTemplate ids={[microSegment]} />
          </Box>
          {/* <Box> */}
          <Grid container className="hero-content">
            <Grid item xs={8}>
              <Typography variant="h1" className="comfort-cravers-heading">
                {t(`people-segment.type.${microSegment}`)}
              </Typography>
              \
            </Grid>
            <Grid className="scope-badge-grid-item" item xs={4}>
              <Box className="scope-badge-container">
                <ScopeBadge
                  data={{
                    value: t('common.scopeBadge.scopeBadgeValue', { value: Math.round(sectionData.populationVolume) }),
                    label: t('common.scopeBadge.ofPopulation')
                  }}
                />
                <ScopeBadge
                  data={{
                    value: t('common.scopeBadge.scopeBadgeValue', { value: Math.round(sectionData.totalVolume) }),
                    label: t('common.scopeBadge.totalAlcoholVolume')
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          {/* </Box> */}
        </Stack>
      </Box>
      <div className="runner">
        <Runner forPage={'peopleSegmentTemplatePage'} forType={microSegment} />
      </div>
    </>
  );
}

SectionZero.propTypes = {
  sectionData: PropTypes.object.isRequired
};
