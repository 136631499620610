export const strategyIcons = [
  {
    id: 1,
    src: 'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F2875beec5c9946d298db357e9078d873',
    title: 'Category',
    alt: 'Category strategy icon'
  },
  {
    id: 2,
    src: 'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fa10c32b6ff01415a80949612b9319793',
    title: 'Portfolio',
    alt: 'Portfolio strategy icon'
  },
  {
    id: 3,
    src: 'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F981182c8c6824b8b9025a2645ae68c81',
    title: 'Innovation',
    alt: 'Innovation strategy icon'
  },
  {
    id: 4,
    src: 'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fdcb2c9302684433b8a90bfce6b9e4a77',
    title: 'Brand',
    alt: 'Brand strategy icon'
  }
];

export const activationIcons = [
  {
    id: 5,
    src: 'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fc55136373561413eafbdbffc91157d53',
    title: 'Media / Data',
    alt: 'Media and data activation icon'
  }
];

export const retailIcons = [
  {
    id: 6,
    src: 'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F7a6b3e6569444ebfb6609fb0b241f4fb',
    title: 'Trade',
    alt: 'Trade retail icon'
  },
  {
    id: 7,
    src: 'https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Ff3e3b69ab50e4abe97ddcc96146de572',
    title: (
      <>
        Business
        <br />
        Planning
      </>
    ),
    alt: 'Business planning retail icon'
  }
];
