import React from 'react';
import PropTypes from 'prop-types';
import styles from './QuoteContent.module.css';
export const QuoteContent = ({ quote, name, age, sex, location, cardWidth }) => {
  return (
    <div
      className={styles.quoteContent}
      style={{
        padding: cardWidth === 'FULL_WIDTH' ? '24px 30px' : '24px 20px'
      }}
    >
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2F9986377df53f4f03ab39d15caff9354d"
        alt=""
        className={styles.quoteIcon}
      />
      <div className={styles.quoteText}>{quote}</div>
      <div className={styles.authorInfo}>
        <div className={styles.authorName}>{name}</div>
        <div className={styles.authorDetails}>
          <div>{age}</div>
          <div className={styles.detailDivider} />
          <div>{sex}</div>
          <div className={styles.detailDivider} />
          <div>{location}</div>
        </div>
      </div>
    </div>
  );
};

QuoteContent.propTypes = {
  quote: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  age: PropTypes.string.isRequired,
  sex: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  cardWidth: PropTypes.string.isRequired
};
