import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider, Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import i18next from 'i18next';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import BeerIcon from '../../../../assets/images/Common/consumption-cards/BeerWrapUp.svg';
import SpiritsIcon from '../../../../assets/images/Common/consumption-cards/SpiritsWrapUp.svg';
import WineIcon from '../../../../assets/images/Common/consumption-cards/WineWrapUp.svg';
import HardbevIcon from '../../../../assets/images/Common/consumption-cards/HardBevWrapUp.svg';
import { demandSpaceAssets } from '../../DemandSpaceAssets';
import WrapUpHero from './WrapUpHero';

function DemandSpacesWrapUp({ macro, selectedMicro }) {
  const { t } = useTranslation(i18next.language);

  const sovVolume = Math.round(parseFloat(macro?.totalVolume));
  const sovVolumeRoundUp = JSON.stringify(sovVolume, null, 2);

  const sopVolume = Math.round(parseFloat(macro?.populationVolume));
  const sopVolumeRoundUp = JSON.stringify(sopVolume, null, 2);

  const wrapupContent = {
    title: t('demandspace.anchorItems.wrapUp'),
    micro: t(`demandspace.type.${selectedMicro}`),
    sov: sovVolumeRoundUp,
    sop: sopVolumeRoundUp,
    microDescription: t(`demandspace.template.wrapup.demandSpaceDescription.${selectedMicro}.paragraph`, {
      type: t(`demandspace.type.${selectedMicro}`)
    }),
    microImage: demandSpaceAssets.heroImages[selectedMicro]?.(),
    visualText: t(`demandspace.template.peopleSegmentandOccasions.${selectedMicro}.partOfMacro`, {
      type: t(`demandspace.type.${selectedMicro}`),
      macroOccasion: t(`demandspace.macroOccasion.${selectedMicro}`)
    }),
    microID: selectedMicro
  };

  function getBeverageIcon(type) {
    let icon = null;
    switch (type) {
      case 'Fram_CBR_1':
        icon = BeerIcon;
        break;
      case 'Fram_CS_1':
        icon = SpiritsIcon;
        break;
      case 'Fram_CW_1':
        icon = WineIcon;
        break;
      case 'Fram_CHB_1':
        icon = HardbevIcon;
        break;
      default:
        break;
    }
    return icon;
  }

  function getBackgroundColor(index) {
    if (index <= 85) {
      return '#CB4242'; // Red color
    } else if (index >= 115) {
      return '#309427'; // Green color
    } else {
      return '#434343'; // Neutral color
    }
  }

  function formatDrinkName(drinkName) {
    return drinkName
      .replace(/beverage/i, 'bev')
      .replace(/\s*\(NET\)/, '')
      .replace(/\s*\+ NAB/, '');
  }

  return (
    <Box className="wrapup-main">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 12.5
        }}
      >
        <Divider className="divider" />
        <Box className="wrapup-intro">
          <WrapUpHero content={wrapupContent} />
        </Box>
      </Box>
      <Grid container gap={7.5} className="main-section4-casual">
        <Grid item xs={12} className="right-item">
          <Box className="box">
            <Typography className="top-needs">{t('people-segment.section7.section4-casual.alc-bev-cat')}</Typography>
            <Grid container gap={1.5}>
              {macro?.consumptions?.drinks?.map((item, index) => (
                <Grid item xs={2.85} key={index}>
                  <Grid container gap={2.5} className="right-grid">
                    <Grid item xs={3.35}>
                      <Box className="icon-img">
                        <img className="icon" src={getBeverageIcon(item?.drinkIdentifier)} alt="icon-text" />
                      </Box>
                    </Grid>
                    <Grid item xs={7.35}>
                      <Box className="icon-text-margin">
                        <Typography className="icon-text">{formatDrinkName(item?.drinkName).toLowerCase()}</Typography>
                        <Box sx={{ display: 'flex', gap: 2.5 }}>
                          <Typography className="vol-per">{Math.round(item?.drinkVolume)}%</Typography>
                          <Typography
                            className="index"
                            style={{
                              backgroundColor: getBackgroundColor(Math.round(item?.drinkIndex))
                            }}
                          >
                            {Math.round(item?.drinkIndex)}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Box className="wrapup-interaction-tooltip">
        <QueryStatsIcon className="icon" color="primary" />
        <Typography variant="body1" color="secondary" className="interaction-tooltip-text">
          <Trans components={{ underline: <i /> }}>{t('demandspace.template.wrapup.interactionTooltipText')}</Trans>
        </Typography>
      </Box>
    </Box>
  );
}

DemandSpacesWrapUp.propTypes = {
  macro: PropTypes.object.isRequired,
  selectedMicro: PropTypes.string.isRequired
};

export default DemandSpacesWrapUp;
