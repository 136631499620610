import React from 'react';
import PropTypes from 'prop-types';
import RestaurantRoundedIcon from '@mui/icons-material/RestaurantRounded';

const RestaurantIcon = ({ fontSize = 'xs-small', color }) => {
  return (
    <RestaurantRoundedIcon
      style={{
        backgroundColor: '#FFFFFF',
        padding: '4px',
        color: color || '#3D3D3D',
        borderRadius: '50%'
      }}
      fontSize={fontSize}
    />
  );
};

RestaurantIcon.propTypes = {
  fontSize: PropTypes.string,
  color: PropTypes.string
};

export default RestaurantIcon;
